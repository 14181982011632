import React from "react";
// material-ui components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
// core components

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const useStyles = makeStyles(styles);

export default function CustomMultipleSelect(props) {
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [multipleSelect, setMultipleSelect] = React.useState([]);

  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);

    props.setvalue(event.target.value);
  };


  const handleMultiple = (event) => {
    setMultipleSelect(event.target.value);
    props.setvalue(event.target.value);
  };
 // console.log(props, 'props')
  React.useEffect(() => {
    //console.log("multipleSelect", props.value)
    setMultipleSelect(props.value);
  }, [props.value])

  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        {props.label}
      </InputLabel>
      <Select
        multiple
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={multipleSelect}
        onChange={handleMultiple}
        inputProps={{
          name: "simpleSelect",
          id: "simple-select",
        }}
      >
        {props.options.map((x, i) => (
          <MenuItem
            key={i}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={x.id}
          >
            {x.name ?? x.valueString}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
