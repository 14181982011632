import axios from "axios";
import { configUrl } from "config.js";

import { useMutation, useQueryClient } from "react-query";
export default function usePublishTest() {
    let queryClient = useQueryClient();
    return useMutation(
        async (testId) => {
            await axios.put(
                `${configUrl.AssessmentServer}/api/Tests/publishTest/${testId}`
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("tests");
            },
        }
    );
}
