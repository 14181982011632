
import CounselorDashboard from "views/Dashboard/CounselorDashboard";
import systemConfigurationListing from "views/Components/SystemConfigurationListing";
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Users from "views/Components/UserListing";
import NotificationListing from "views/Components/NotificationListing";
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MessageIcon from '@material-ui/icons/Message';
var counselorRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: CounselorDashboard,
    layout: "/admin",
  }, 
  {
    path: "/Configuration",
    name: "System Configurations",
    rtlName: "",
    icon: Apps,
    component: systemConfigurationListing,
    layout: "/admin",
  }, 
  {
    collapse: true,
    name: "Manage Counselling",
    rtlName: "rtl",
    icon: Apps,
    state: "testCollapse",
    views: [      
      {
        path: "/users",
        name: "Students",
        rtlName: "rtl",
        mini: "IU",
        icon: PersonOutlineIcon,
        rtlMini: "iu",
        component: Users,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Messages",
        rtlName: "rtl",
        icon:  MessageIcon,
        rtlMini: "iu",
        component: NotificationListing,
        layout: "/admin",
      },
    ]
  }, 
 
];
export default counselorRoutes;
