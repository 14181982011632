import { makeStyles } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";

import {
  cardTitle,
  roseColor,
} from "assets/jss/material-dashboard-pro-react.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Info from "components/Typography/Info";
import useGetAssignedCourses from "hooks/courseHooks/useGetAssignedCourses";
import { withRouter } from "react-router-dom";

import cardStyles from "assets/jss/material-dashboard-pro-react/views/componentsSections/sectionCards.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);
const useStylesCard = makeStyles(cardStyles);

function CoursesComponent(props) {
  const classes = useStyles();
  const classes2 = useStylesCard();

  const { data: courses } = useGetAssignedCourses();
  console.log(courses);

  const handleClick = (courseId) => {
    props.history.push("/admin/courseTestViewer", { state: courseId });
  };

  return (
    <div>
      <GridContainer>

        {courses &&
          courses.length > 0 &&
          courses.map((x, i) => (
            <GridItem md={4} key={i} style={{ textAlign: "center" }}>
              <Card profile>
                <CardHeader image>
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    <img src={x.image} alt="..." style={{ width: "320px", height: "400px", }} />

                  </a>
                  <div className={classes2.coloredShadow} />
                </CardHeader>
                <CardBody >
                  <Info >
                    <h6 className={classes2.cardCategory}>{x.code}</h6>
                  </Info>
                  <p className={classes2.cardDescription}>{x.description}</p>
                </CardBody>
                <CardFooter profile className={classes2.justifyContentCenter}>
                  <Button color="primary" onClick={() => handleClick(x.courseId)}>
                    View
                  </Button>
                </CardFooter>
              </Card>{" "}
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}

export default withRouter(CoursesComponent);
