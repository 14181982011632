import axios from "axios";
import { configUrl } from "config.js";
import { useMutation, useQueryClient } from "react-query";

export default function usePoolQuestionBulkInsert() {
  var queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      await axios.post(
        `${configUrl.AssessmentServer}/api/PoolQuestionMappings/bulkinsertPoolMappings`,
        payload
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("questionTopicIds");
        queryClient.invalidateQueries("questionsByPoolId");
      },
    }
  );
}
