import axios from "axios";
import Button from "components/CustomButtons/Button.js";
import React from "react";

import { CircularProgress, Tooltip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CustomAlert from "components/CustomAlert/CustomAlert";
import CustomModal from "components/CustomModal/CustomModal";
import { configUrl } from "config.js";
import testPreviewService from "services/Preview/testPreviewService";
import Player from "views/Player/Player";
import TestPlayer from "views/Player/TestPlayer";

function PreviewComponent(props) {


  const [player, setPlayer] = React.useState(null);
  const [flag, setFlag] = React.useState(false)
  const [isLoad, setIsLoad] = React.useState(false);
  const { questionId } = props;

  const { testId } = props;

  const showPlayer = async () => {
    if (props.multiple == false) {
      let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Questions/getQuestionForPreview/${questionId}`
      );
      console.log("data123", response);

      let data = response.data["$values"];


      setPlayer(
        <CustomModal
          modal={true}
          setModal={hidePlayer}
          content={<Player isShowPublishQuestion={props.isShowPublishQuestion} questions={data} preview={true} />}
          showOk={false}
          showCancel={false}
          onCancelClick={() => hidePlayer()}
          title={`Question Preview `} //${props.questionName}
          maxWidth="lg"
          fullWidth={true}
          hideTitle={false}
          showHeaderBackground={true}
        />
      );
    } else {
      let response = await testPreviewService.getPreviewTests(testId);

      let data = response.data;
      let qcount = 0
      console.log(response, 'responseforProvider')
      data && data.pools['$values'].map((x, i) => {
        qcount = qcount + x.questions['$values'].length;
      })
      setPlayer(
        response.status == 400 ?
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="warning"
            alertMessage={response.messege}
          /> :
          qcount ?
            <CustomModal
              modal={true}
              setModal={hidePlayer}
              content={<TestPlayer onClose={() => hidePlayer()} testStatus={props.testStatus} isShowPublish={props.isShowPublish} tests={data} testId={testId} preview={true} />}
              showOk={false}
              showCancel={false}
              onCancelClick={() => hidePlayer()}
              title={`Test Preview : ${props.testName}, Total Marks : ${data?.totalScore ?? null}`}
              maxWidth="lg"
              fullWidth={true}
              hideTitle={false}
              showHeaderBackground={true}
            // fullScreen 
            />
            :
            <CustomAlert
              open={true}
              openFlag={() => setFlag(flag ? false : true)}
              severity="warning"
              alertMessage="Questions are yet to be added to the test. Please do the needful."
            />
      );
    }
    setIsLoad(false)
  };

  const hidePlayer = (event, reason) => {

    if (reason === "backdropClick") {
      return;
    }

    setPlayer(null);
    props?.setReloadGrid && props?.setReloadGrid(props.reloadGridState ? false : true)
  };

  return (
    <>
      {
        props.buttonTitle ?
          <Button
            round
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              showPlayer();
              setIsLoad(true);
            }}
            style={{ size: "1px", textTransform: 'none', marginInlineEnd: '5px', float: 'right' }}
            disabled={isLoad}
          >
            {isLoad ? 'Opening...' :
              props.buttonTitle
            }
          </Button> :
          isLoad ? <CircularProgress style={{ height: '25px', width: '25px', color: '#DD4B39', marginInline: '3px' }} /> :
            <Tooltip title="View">

              <Button
                size="sm"
                justIcon
                round
                color="google"
                onClick={(e) => {
                  e.preventDefault();
                  showPlayer();
                  setIsLoad(true);
                }}
                style={{ size: "1px" }}
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>
      }
      {player}
    </>
  );
}

export default PreviewComponent;
