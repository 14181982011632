import axios from "axios";
import { configUrl } from "config.js";
import { useQuery } from "react-query";

export default function useGetAllCourses() {
  return useQuery("courses", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Courses?pageNumber=${1}&pageSize=${300}&content=${0}`
    );
    return response.data["$values"];
  });
}
