import axios from "axios";
import { configUrl } from "config.js";

const removeTopic = async (topicId) => {
    console.log(topicId, 'topicId')
    return axios.delete(`${configUrl.AssessmentServer}/api/Topics/${topicId}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status
            }
        });

};
export default {
    removeTopic: removeTopic,
}
