import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

export default function ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.source ?? defaultImage
  );
  let fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);

      if (props.setImageValue) props.setImageValue(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(defaultImage);
    if (props.setImageValue) props.setImageValue(defaultImage);

    fileInput.current.value = null;
  };
  React.useEffect(() => {
    if (props.handleClear == true) {
      handleRemove();
    }
  }, [props.handleClear])

  let {
    avatar,
    addButtonProps,
    changeButtonProps,
    removeButtonProps,
    source,
  } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {!props.source ? (
          <Button {...addButtonProps} style={{ textTransform: 'none' }} onClick={() => handleClick()}>
            {avatar ? "Add Photo" : props.label ? `Select Image For ${props.label}` : "Select image"}
          </Button>
        ) : (
          <GridContainer>
            <GridItem md={8}>
              <Button {...changeButtonProps} style={{ textTransform: 'none' }} onClick={() => handleClick()}>
                {`Change ${props.label}`}
              </Button>
            </GridItem>
            <GridItem md={4}>
              <Button
                justIcon
                round
                {...removeButtonProps}
                onClick={() => handleRemove()}
              >
                <i className="fas fa-times" />
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
