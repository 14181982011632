import { configUrl } from 'config';


// const changePasswordCall = async (props) => {
//     const { currentPassword, newPassword } = props;
//     const { UserId } = useContext(LoginContext);

//     try {
//         const response = await axios.post(
//             `${configUrl.AssessmentServer}/api/Counselling/changePassword/${currentPassword}/${newPassword}/${UserId}`
//         );
//         console.log(response, "res5");
//         return response.data;
//     } catch (error) {
//         console.log(error?.response?.data, "error");
//     }
// };

// export default {
//     changePasswordCall: changePasswordCall,
// };

import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import CryptoJS from 'crypto-js';
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function changePasswordCall() {

    const { userId } = useContext(LoginContext);
    console.log(userId, "user");
    const queryClient = useQueryClient();
    const key = 'pj23vs7nycq18uew';
    let secretKey = CryptoJS.enc.Utf8.parse(key);

    const encryptPassword = (password, secretKey) => {

        let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let encryptedString = encryptedBytes.toString();

        console.log(encryptedString, 'encryptedone');
        return encryptedString;
        // return CryptoJS.AES.encrypt(password, secretKey).toString();
    }
    const decryptPassword = (encryptedPassword, secretKey) => {

        let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

        console.log(decryptedText, 'decryptedone');
        return decryptedText;
        // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
        // return bytes.toString(CryptoJS.enc.Utf8);
    }
    return useMutation(
        async (props) => {
            const { currentPassword, newPassword } = props;
            const data = {
                OldPassword: encryptPassword(currentPassword, secretKey),
                NewPassword: encryptPassword(newPassword, secretKey)
            }
            // let currPass = encryptPassword(currentPassword, secretKey);
            // let newPass = encryptPassword(newPassword, secretKey);
            try {
                const response = await axios.post(
                    `${configUrl.AssessmentServer}/api/Counselling/changePassword/${userId}`, data
                );
                console.log(response.data, 'changePassResp')
                return response?.data;
            } catch (error) {
                console.log("Error changing password:", error);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("Provider");
            },
        }
    );
}
