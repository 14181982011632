import axios from "axios";
import { configUrl } from "config.js";

const getSimplifiedTesScheduletData = async (testId) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Tests/getProviderTestScheduling/${33}`
    );
    let data = response.data;
    console.log(data, 'data in service')

    return data;
};
export default {
    getSimplifiedTesScheduletData: getSimplifiedTesScheduletData,
}