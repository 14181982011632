import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetAssignedTestsCount() {
  const { user } = useContext(LoginContext);

  return useQuery(["getAssignedTestsCount"], async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Tests/getAssignedTestsCount/${user}`
    );

    return response.data;
  },
  {
    staleTime: 1000 * 60 * 5,
  }
  );
}
