import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useUpdateUser() {

  const { config } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (user) => {
      if (!user.address) userPayload.address = " ";
      if (!user.middleName) userPayload.middleName = " ";

      if (!user.passCode) userPayload.passCode = "test";

      await axios.put(`${config.api}/api/User/${user.userId}`, user);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
    }
  );
}
