import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import testCopyService from "../../services/TestService/testCopyService";

function TestCopyComponent(props) {
    const { state, afterFinish } = props;
    const [testCode, setTestCode] = React.useState(state.testCode + "-Copy");
    const [text, settext] = React.useState(state.text + "-Copy");
    const [showAlert, setShowAlert] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [serverity, setServerity] = React.useState("error");
    const [copying, setCopying] = React.useState(false);

    const copyTest = async () => {
        setCopying(true);
        let response = await testCopyService.createTestCopy(state.testId, testCode, text);
        setCopying(false);
        console.log(response?.status, 'respofcopy')
        if (response?.status == 200 || response?.status == 201) {
            setErrorMessage(`Test copy created successfully.`);
            setServerity("success");
            setShowAlert(true);
            setTimeout(() => {
                afterFinish();
            }, 2000);
        } else {
            setErrorMessage(`${response.messege}. Please update the test code and try again.`);
            setServerity("error");
            setShowAlert(true);
            console.log('Error occured.')
        }
    }

    return (
        <GridContainer alignItems="flex-end">
            <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                    //   success={testCodeState === "success"}
                    //   error={testCodeState === "error"}
                    labelProps={{
                        shrink: testCode,
                    }}
                    labelText={
                        <span>
                            Test Code <small>(required)</small>
                        </span>
                    }
                    id="testCode"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        onChange: (event) => {
                            //   if (!verifyLength(event.target.value, 3)) {
                            //     setTestCodeState("error");
                            //   } else {
                            //     setTestCodeState("success");
                            //   }
                            setTestCode(event.target.value);
                        },
                        maxLength: 20,
                        value: testCode,
                        // endAdornment: (
                        //   <InputAdornment position="end" className={classes.inputAdornment}>
                        //     <CodeIcon className={classes.inputAdornmentIcon} />
                        //   </InputAdornment>
                        // ),
                    }}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                    //   success={textState === "success"}
                    //   error={textState === "error"}
                    labelText={
                        <span>
                            Test Name <small>(required)</small>
                        </span>
                    }
                    labelProps={{
                        shrink: text,
                    }}
                    id="testText"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        onChange: (event) => {
                            //   if (!verifyLength(event.target.value, 3)) {
                            //     settextState("error");
                            //   } else {
                            //     settextState("success");
                            //   }
                            settext(event.target.value);
                        },
                        value: text,
                        // endAdornment: (
                        //   <InputAdornment position="end" className={classes.inputAdornment}>
                        //     <PermIdentityIcon className={classes.inputAdornmentIcon} />
                        //   </InputAdornment>
                        // ),
                    }}
                />
            </GridItem>
            <GridItem sm={12} md={12} lg={12}>
                <Button color="primary" onClick={copyTest} disabled={copying == true} >
                    {
                        copying ? "Copying Test..." :
                            "Create Test Copy"
                    }
                </Button>
            </GridItem>
            <CustomAlert
                open={showAlert}
                severity={serverity}
                alertMessage={errorMessage}
                handleClose={() => setShowAlert(false)}
                timeout={3000}
            />
        </GridContainer>

    );
}

export default TestCopyComponent;
