import axios from "axios";
import { configUrl } from "config.js";
import CryptoJS from 'crypto-js';
import { useQuery } from "react-query";


export default function useGetStudentById(studentId) {
  const key = 'pj23vs7nycq18uew';
  let secretKey = CryptoJS.enc.Utf8.parse(key);

  const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
  }
  const decryptPassword = (encryptedPassword, secretKey) => {

    let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedText, 'decryptedone');
    return decryptedText;
    // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    // return bytes.toString(CryptoJS.enc.Utf8);
  }
  return useQuery(["User", studentId], async () => {
    // if (configType == null) {
    //   console.log(configType);
    //   return null;
    // }
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/User/getStudentDetail/${studentId}`
    );
    console.log(response.data.studetnInfo.passCode, 'student resp');
    console.log(typeof (response.data.studetnInfo.passCode), 'typeofpasscode')
    response.data.studetnInfo.passCode = decryptPassword(response.data.studetnInfo.passCode, secretKey)
    console.log(response.data.studetnInfo.passCode, 'student resp1');
    return response.data;
  });
}
