import axios from "axios";
import { configUrl } from "config.js";
import { useMutation, useQueryClient } from "react-query";

export default function useBulkUploadUsers() {
  let queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      console.log("uploading", payload);
      // console.log("uploading",payload[0].studentInfo);
      // payload = payload.map((x) => {
      // if (!x.addressLine) x.addressLine = " ";
      // if (!x.middleName) x.middleName = " ";

      //   x.address = x.address
      //   x.mobile = x.mobile
      //   //

      //   return x
      // });

      // console.log("uploading",a);
      await axios.post(
        `${configUrl.AssessmentServer}/api/User/api/User/bulkUsers/`,
        payload
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("students");
      },
    }
  );
}
