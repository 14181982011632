import React, { useRef } from "react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import {
  DataGrid
} from "@material-ui/data-grid";
import Edit from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Tooltip } from "@mui/material";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import useDeleteNotification from "hooks/notificationHooks/useDeleteNotification";
import useGetAllNotifications from "hooks/notificationHooks/useGetAllNotifications";
import useUpdateNotification from "hooks/notificationHooks/useUpdateNotification";
import EventSchedule from "views/Forms/EventSchedule";
import Notifications from "views/Forms/Notifications";
const useStyles = makeStyles(styles);

export default function NotificationsListing(props) {
  const {
    data: notifications,
    error: notificationsFetchError,
    isLoading: notificationsFetchLoading,
  } = useGetAllNotifications();

  const updateNotification = useUpdateNotification();
  const deleteNotification = useDeleteNotification();

  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  console.log(rows);
  const [notificationsEditForm, setNotificationsEditForm] = React.useState(
    null
  );

  const [deleteModal, setDeleteModal] = React.useState(null);

  const ref = useRef(null);

  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(0);
  const refreshDashboard = () => {
    setRefresh(refresh + 1);
  };
  const showDeleteConfirmation = (id) => {
    setDeleteModal(
      <CustomModal
        modal={true}
        setModal={() => hideDeleteCustomModal()}
        content={<h3>Are you sure you want to delete the notifications?</h3>}
        cancelButtonMessage="Cancel"
        okButtonMessage={deleteNotification.isLoading ? "Deleting" : "Yes"}
        showOk={true}
        onCancelClick={() => hideDeleteCustomModal()}
        title="Delete Notification"
        maxWidth="sm"
        onOkClick={async () => {
          await deleteNotification.mutateAsync(id);
          hideDeleteCustomModal();
        }}
        showCancel={true}
        padding1={true}
      />
    );
  };

  const hideDeleteCustomModal = () => {
    setDeleteModal(null);
  };

  const onDelete = async (e, id) => {
    showDeleteConfirmation(id);
  };

  const onUpdate = (e, values) => {
    e.preventDefault();

    updateNotification.mutate(values.row);
  };

  const onInfo = (e, id) => {
    alert("info");
  };

  const handleRowSelection = (e) => {
    // setSelection(e);
    console.log(e);
  };

  const onCellEditCommit = (p) => { };

  const onCellBlur = (e, p) => { };

  const handleEdit = (p, e) => { };

  React.useEffect(() => {
    let cols = [
      {
        field: "title",
        headerName: "Title",
        editable: false,
        width: 260,
      },
      // {
      //   field: "createdDate",
      //   headerName: "CreatedDate",
      //   editable: true,
      //   width: 200,
      // },
      {
        field: "sendDateTime",
        headerName: "Schedule Date",
        editable: false,
        width: 220,
      },
      {
        field: "broadcast",
        headerName: "Interest",
        editable: false,
        width: 220,
        renderCell: (cellValues) => {
          return (
            <>
              <div>
                {cellValues.row.broadcast ? (
                  <span>Not Interest Specific</span>
                ) : (
                  <span>Interest Specific</span>
                )}
              </div>
            </>
          );
        },
      },
      {
        field: "Notification",
        headerName: "Notification",
        editable: false,
        width: 220,
        renderCell: (cellValues) => {
          if (
            cellValues.row.isWhatsAppNotification &&
            cellValues.row.isAppNotification &&
            cellValues.row.isPushNotification
          ) {
            return (
              <>
                <span>WhatsApp & App & Notification</span>
              </>
            );
          } else if (cellValues.row.isWhatsAppNotification &&
            cellValues.row.isAppNotification) {
            return (
              <>
                <span>WhatsApp & App </span>
              </>
            );
          }

          else if (cellValues.row.isWhatsAppNotification) {
            return (
              <>
                <span>WhatsApp</span>
              </>
            );
          } else if (cellValues.row.isAppNotification) {
            return (
              <>
                <span>App</span>
              </>
            );
          } else if (cellValues.row.isPushNotification) {
            return (
              <>
                <span>Notification</span>
              </>
            );
          } else {
            return (
              <>
                <span>-</span>
              </>
            );
          }
        },
      },
      // {
      //   field: "isAppNotification",
      //   headerName: "App Notification",
      //   editable: true,
      //   width: 200,
      //   renderCell: (cellValues) => {
      //     return (
      //       <>
      //         <div>{cellValues.row.isAppNotification ? <span>Yes</span> : <span>No</span>}</div>
      //       </>
      //     )
      //   }
      // },
      // {
      //   field: "createdBy",
      //   headerName: "CreatedBy",
      //   editable: true,
      //   width: 150,
      // },
      {
        field: "status",
        headerName: "Status",
        editable: true,
        width: 220,
        renderCell: (cellValues) => {
          console.log(cellValues.row.tempsendate);
          let tempdate = new Date(cellValues.row.tempsendate);
          console.log(tempdate);
          let currentdate = new Date();
          console.log(currentdate);
          function dateCompare(d1, d2) {
            return d1 >= d2;
          }
          let hideprivoudate = dateCompare(tempdate, currentdate);
          return (
            <>
              {hideprivoudate ? <span>Schedule</span> : <span>Delivered</span>}
            </>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        editable: false,
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          console.log(cellValues.row.tempsendate);
          let tempdate = new Date(cellValues.row.tempsendate);
          console.log(tempdate);
          let currentdate = new Date();
          console.log(currentdate);
          function dateCompare(d1, d2) {
            return d1 >= d2;
          }
          let hideprivoudate = dateCompare(tempdate, currentdate);
          return (
            <>
              {hideprivoudate ? (
                <Tooltip title="Edit">
                  <Button
                    size="sm"
                    justIcon
                    round
                    color={"info"}
                    className={classes.actionButton}
                    key={1}
                    onClick={() =>
                      createNotificationsEditForm(
                        cellValues.row.notificationId,
                        "Update Notification"
                      )
                    }
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Edit">
                  <Button
                    size="sm"
                    justIcon
                    round
                    disabled
                    color={"info"}
                    className={classes.actionButton}
                    key={1}
                    onClick={() =>
                      createNotificationsEditForm(
                        cellValues.row.notificationId,
                        "Update Notification"
                      )
                    }
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              )}

              {/* <Button
                size="sm"
                justIcon
                round
                color={"success"}
                className={classes.actionButton}
                key={2}
                onClick={(e) => onUpdate(e, cellValues)}
              >
                <UpdateIcon />
              </Button> */}
              {/* <Button
                size="sm"
                justIcon
                round
                color={"danger"}
                className={classes.actionButton}
                key={3}
                onClick={(e) => onDelete(e, cellValues.row.notificationsId)}
              >
                <Close />
              </Button> */}
            </>
          );
        },
      },
    ];

    setColumns(cols);
  }, []);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    let a = date.getHours() >= 12 ? "PM" : "AM";
    console.log(a);
    return (
      [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(a),
        // padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  // 👇️ 24/10/2021 (mm/dd/yyyy)
  console.log(formatDate(new Date()));

  //  👇️️ 24/07/2027 (mm/dd/yyyy)
  // console.log(formatDate(new Date(2027, 6, 24)));

  React.useEffect(() => {
    if (notifications) {
      setRows(
        notifications["$values"].map((x, i) => {
          console.log(x);
          return {
            ...x,
            id: i,
            sendDateTime: formatDate(new Date(x.sendDateTime)),
            tempsendate: x.sendDateTime,
            createdDate: formatDate(new Date(x.createdDate)),
          };
        })
      );
    }
  }, [notifications, refresh]);
  const createNotificationsEditForm = (notificationId, title) => {
    console.log(notificationId);
    setNotificationsEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideNotificationsEditForm()}
        content={
          <Notifications
            icon={PersonAddIcon}
            // notification={row}
            afterFinish={() => hideNotificationsEditForm()}
            noti={notificationId}
          />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideNotificationsEditForm()}
        title={<h5>New Message</h5>}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };
  const createScheduleEvent = (title) => {
    // console.log(notificationId);
    setNotificationsEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideNotificationsEditForm()}
        content={
          <EventSchedule
            icon={PersonAddIcon}
            // notification={row}
            afterFinish={() => hideNotificationsEditForm()}
          // noti={notificationId}
          />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideNotificationsEditForm()}
        title={<h5>Add Schedule Event</h5>}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };


  const hideNotificationsEditForm = () => {
    setNotificationsEditForm(null);
  };

  const getRowClassName = (row) => {
    return null;
  };
  const [filterModel, setFilterModel] = React
    .useState
    // {
    // items: [
    //   { id: 1, columnField: 'title', operatorValue: 'contains', value: null  },
    //   // { id: 2, columnField: 'board', operatorValue: 'contains', value: null  },
    //   // { id: 2, columnField: 'state', operatorValue: 'contains', value: null  },
    // ],
    // // linkOperator: GridLinkOperator.And,
    // }
    ();
  const onhandlechange = (e) => {
    console.log(e);
    setFilterModel(e);
  };
  // function CustomToolbar() {
  //   return (
  //     <GridContainer spacing={4}>
  //       <GridItem>
  //         <CustomInput
  //           formControlProps={{
  //             fullWidth: true,
  //           }}
  //           required={true}
  //           inputProps={{
  //             // name: "title",
  //             // id: 1, columnField: 'title', operatorValue: 'contains', value: null ,
  //             onChange:(e)=> setFilterModel(e),
  //             value:filterModel.items[0]{id: 1, columnField: 'title', operatorValue: 'contains', value: null }
  //           }}
  //         />
  //       </GridItem>
  //       <GridItem>
  //         <CustomInput variant="outlined" />
  //       </GridItem>
  //       <CustomInput variant="outlined" />
  //       {/* <CustomInput variant="outlined"/>
  //     <GridToolbarFilterButton/>
  //     <GridToolbarExport /> */}
  //     </GridContainer>
  //   );
  // }

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <MessageIcon />
        </CardIcon>
      </CardHeader>
      <CardBody>
        <GridContainer justifyContent="flex-end">
          {/* <Button
            color="rose"
            onClick={() => {
              refreshDashboard();
              //getQ();
            }}
            style={{
              textTransform: "none",
              marginLeft: 20,
              // width: 190,
              alignItems: "flex-end",
            }}
          >
            <RefreshIcon /> Refresh
          </Button> */}
          &nbsp;
          &nbsp;
          &nbsp;
          <Button
            color="rose"
            onClick={() => {
              createNotificationsEditForm(null, "New Notifications");
              //getQ();
            }}
            style={{
              textTransform: "none",
              // marginLeft: 170,
              // width: 190,
              marginBottom: 21,
              marginTop: 20,
              alignItems: "flex-end",
            }}
          // style={{textTransform: "none"}}
          >
            + Add
          </Button>
          {/* <Button
            color="rose"
            onClick={() => {
              createScheduleEvent("Schedule Event");
              //getQ();
            }}
            style={{
              textTransform: "none",
              // marginLeft: 170,
              width: 190,
              alignItems: "flex-end",
            }}
            // style={{textTransform: "none"}}
          >
            + Schedule Event
          </Button>
         */}
          <div style={{ height: 500, width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
              {rows ? (
                <div ref={ref} style={{ flexGrow: 1 }}>
                  <DataGrid
                    columns={columns}
                    rows={rows}
                    // components={{
                    //   Toolbar: CustomToolbar,
                    // }}
                    filterModel={filterModel}
                    // GridFilterModel={(e)=>{onhandlechange(e)}}
                    onFilterModelChange={(model) => {
                      onhandlechange(model);
                    }}
                    autoPageSize={true}
                    onEditCellPropsChange={handleEdit}
                    onCellEditCommit={onCellEditCommit}
                    onSelectionModelChange={handleRowSelection}
                    getRowClassName={getRowClassName}
                    onCellBlur={onCellBlur}
                  />
                </div>
              ) : (
                <div style={{ flexGrow: 1 }}>
                  <CircularProgress /> Loading notifications...
                </div>
              )}
            </div>
          </div>
        </GridContainer>
      </CardBody>
      {updateNotification.isError == true && (
        <CustomAlert
          open={true}
          severity="error"
          alertMessage="Error while updating Notification"
        />
      )}
      {updateNotification.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="Notification Updated successfully"
        />
      )}
      {deleteNotification.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="Notification Deleted successfully"
        />
      )}
      {notificationsEditForm}
      {deleteModal}
    </Card>
  );
}
