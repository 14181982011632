import axios from "axios";
import { configUrl } from "config.js";

const getObjective = async (isActive, isContentProvider) => {

  if (isActive == undefined || isActive == "") {
    isActive = false;
  }
  if (isContentProvider == undefined) {
    isContentProvider = false;
  }

  try {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/DemoghraphicInformation/Objective/${isContentProvider}/${isActive}`
    );
    let data = response.data;
    return data;
  } catch (error) {
    console.log("error fetching data ", error);
  }
};

export default {
  getObjective: getObjective,
}