import axios from "axios";
import { configUrl } from "config.js";

const deleteTestMappedToCourse = async (courseId, testId) => {
    console.log(courseId, 'courseIdinHook')

    let response = await axios.delete(`${configUrl.AssessmentServer}/api/CourseTestMappings/deleteTestMappedToCourse/${courseId}/${testId}`)
    console.log(response, 'deleteTestResponse')
    return response
}

export default {
    deleteTestMappedToCourse: deleteTestMappedToCourse
}
