// let response = await axios.get(`${config.api}/api/Provider`);
import axios from "axios";
import { configUrl } from "config.js";

const getAllProviders = async (page, pageSize, providerTypeId) => {
    let Response = await axios.get(
        `${configUrl.AssessmentServer}/api/Provider?pageNumber=${page}&pageSize=${pageSize}&providerTypeId=${providerTypeId}`

    );
    return Response.data;
};
export default {
    getAllProviders: getAllProviders,
}
