import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetAssignedTests(courseId, userId) {
  return useQuery(["assignedTests", courseId, userId], async () => {
    if (!courseId || !userId) {
      return null;
    }

    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Courses/assignedTests/${courseId}/${userId}`
    );
    return response.data["$values"];
  });
}
