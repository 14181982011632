import axios from "axios";
import { configUrl } from "config.js";

  const getTestData = async (testId) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Tests/testData/${testId}`
      );
    let data = response.data;
  
    
  return data;
  };
 

const getTestDetails = async (id) => {
try {
  const res = await axios.get(`${configUrl.AssessmentServer}/api/Tests/viewTestDetails/${id}`);
  console.log("calllllllllllllled");
  return res.data;
} catch (e) {
  console.log("error fetching details");
}
 }


export default{
  getTestData:getTestData,
  getTestDetails:getTestDetails
}
