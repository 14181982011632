import axios from "axios";
import { configUrl } from "config.js";

const dupulicateCodeCheck = async (courseCode) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Courses/isCourseCodeExists/${courseCode}`
    );
    // let data = response.data;
    console.log(response, 'response1')
    return response.data;
};
const dupulicateTextCheck = async (courseText) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/CourseLanguages/isCourseNameExists/${courseText}`
    );
    console.log(response, 'response2')
    // let data = response.data;
    return response.data;
};

export default {
    dupulicateCodeCheck: dupulicateCodeCheck,
    dupulicateTextCheck: dupulicateTextCheck,

}