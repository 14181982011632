import { makeStyles } from '@material-ui/core';
import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import styles from 'assets/jss/material-dashboard-pro-react/customSelectStyle';
import CustomAlert from 'components/CustomAlert/CustomAlert';
import Button from 'components/CustomButtons/Button';
import CustomMultipleSelect from "components/CustomSelect/CustomMultipleSelect";
import React from "react";
import getAllStudents from 'services/ApplicationUser/getAllStudents';
const useStyles = makeStyles(styles);

function AcceptRejectStudentForm(props) {
    const classes = useStyles();
    console.log(props, 'recvd Props')
    console.log("courses", props.courses)
    const [showAlert, setShowAlert] = React.useState(null)
    const [flag, setFlag] = React.useState(false)
    const [loading, setIsLoading] = React.useState(false);
    const [model, setModel] = React.useState({
        course: [],
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(e.target, 'triggeredVals')

        setModel({ ...model, [name]: value })

    }
    const handleAssignndAccept = async (students, courses) => {
        setIsLoading(true);
        let assignandAcceptResp = await getAllStudents.activateBulkUsers(students, courses);
        console.log(assignandAcceptResp, 'assignResp')
        props.setSelectedEnabled(null)
        setIsLoading(false);

        if (assignandAcceptResp.status == 200 || 204) {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="success"
                    alertMessage={'Student(s) activated successfully'}
                />
            )
            setTimeout(() => {
                props.afterFinish()
            }, 2000);
        } else {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage={'Student(s) activation failed'}
                />
            )
        }
    }

    const handleDeletendReject = async (students) => {
        setIsLoading(true);
        let deleteRejectResp = await getAllStudents.deleteUsers(students);
        console.log(deleteRejectResp, 'rejectResp')
        props.setSelectedEnabled(null)
        setIsLoading(false);
        if (deleteRejectResp.status == 200 || 204) {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="success"
                    alertMessage={'Student(s) deleted successfully'}
                />
            )
            setTimeout(() => {
                props.afterFinish()
            }, 2000);
        } else {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage={'Student(s) deletion failed.'}
                />
            )
        }
    }

    console.log(model, 'modellogg')
    return (
        <div style={{ paddingInline: '5%' }}>
            {
                props.actionVal == 'accept' ?
                    <>
                        <h5>Do you want to assign test series ?</h5>

                        <CustomMultipleSelect
                            label=" Select Test Series"
                            selectLabel="Assp"
                            options={props.courses ? props.courses : ''}
                            setvalue={(v) => {
                                console.log('slected field', v)
                                handleChange({
                                    target: { name: "course", value: v },
                                });

                            }}
                            id="course"
                            name="course"
                            value={model.course}
                        />
                    </> : null

            }
            <div style={{ textAlign: 'end' }}>
                <Button
                    onClick={() => {
                        props.afterFinish();
                        props.setSelectedEnabled(null);
                    }}
                    style={{ marginTop: 20, marginRight: 20, textTransform: "none" }}
                    disabled={loading}
                >
                    Close
                </Button>
                <Button
                    color="rose"
                    onClick={() => {
                        props.actionVal == 'reject' ?
                            handleDeletendReject(props.selectedUsers)
                            :
                            handleAssignndAccept(props.selectedUsers, model.course)
                    }}
                    style={{ marginTop: 20, textTransform: "none" }}
                    disabled={loading}
                >
                    {
                        props.actionVal == 'reject' ? loading ? "Rejecting..." :
                            "Reject & Delete" : loading ? "Accepting..." : "Assign & Accept"
                    }
                </Button>
            </div>
            {showAlert}
        </div>
    );
}

export default AcceptRejectStudentForm;
