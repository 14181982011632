import React from "react";
// material-ui components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
// core components

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
  //console.log(props.options)
  const [simpleSelect, setSimpleSelect] = React.useState(props.value);



  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);

    props.setvalue(event.target.value)
  };

  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        {props.label}
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={simpleSelect}
        onChange={handleSimple}
        inputProps={{
          name: "simpleSelect",
          id: "simple-select",
        }}
        disabled={props.disabled ? props.disabled : false}
      >
        {props.showNone ?
          <MenuItem value={null}>
            <em>None</em>
          </MenuItem> : null
        }
        {props.showNotConf ?

          <MenuItem value={"none"}>
            <em>Not configured</em>
          </MenuItem>
          : null
        }
        {props.options.map((x, i) =>
          <MenuItem
            key={i}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={x.id}
          >
            {x.name}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
