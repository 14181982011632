import axios from "axios";
import { useQuery } from "react-query";
import { configUrl } from "../../config";

export default async function UseGetGraph(lOid) {
  
  return axios.get(`${configUrl.AssessmentServer}/api/MentorAnalytics/getChartAnalytics/${lOid}`)
    .then((response) => {
   
      return response.data;
      
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}