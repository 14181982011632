import axios from "axios";
import { configUrl } from "config.js";

const getAllStudents = async (page, pageSize, pendingStudents) => {
  let coursesResponse = await axios.get(
    `${configUrl.AssessmentServer}/api/User/getStudent?pageNumber=${page}&pageSize=${pageSize}&isActive=${pendingStudents}`
  );
  console.log("USer response dat :  ", coursesResponse.data);
  return coursesResponse.data;
};

const deleteUsers = async (students) => {

  return axios.post(`${configUrl.AssessmentServer}/api/User/deleteRejectedUsers`, students)
    .then((response) => {
      console.log(response, 'responseinService')
      let statResp = 'Users deleted successfully.'
      return response;
    })
    .catch((error) => {
      console.log(error?.response, "prevError");
      // return 'not Uploaded'
      return {
        messege: error?.response?.data,
        status: error?.response?.status
      }
    });
  // if (response.status === 204 || 200) {
  // let statResp = 'Users deleted successfully.'
  // return statResp;
  // } else {
  //   let statResp = 'Failed to delete students.'
  //   return statResp;
  // }
};

const activateBulkUsers = async (students, courses) => {
  console.log("array is : ", students, 'courses', courses);
  const activeStudents = {studentIds:students, courseIds:courses}
  console.log("activateStudents Object Is",activeStudents);
  return axios.post(`${configUrl.AssessmentServer}/api/User/activateBulkUsers`, activeStudents)
    .then((response) => {
      console.log(response, 'responseinService')
      let statResp = 'Students activated successfully.';
      return response;
    })
    .catch((error) => {
      console.log(error?.response, "prevError");
      // return 'not Uploaded'
      return {
        messege: error?.response?.data,
        status: error?.response?.status
      }
    });

  // if (response.status === 204 || 200) {
  // let statResp = 'Students activated successfully.';
  // return statResp;
  // } else {
  //   console.error('Failed to activate students.');
  //   let statResp = 'Failed to activate students.';
  //   return statResp;
  // }
};




export default {
  getAllStudents: getAllStudents,
  deleteUsers: deleteUsers,
  activateBulkUsers: activateBulkUsers
  // deletStudent:deletStudent,
}