import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useUpdateProvider() {
    const { config, user, providerId } = useContext(LoginContext);

    const queryClient = useQueryClient();
    return useMutation(
        async (providerPayload) => {
            providerPayload.createdBy = user ?? "system";
            providerPayload.updatedBy = user ?? "system";
            console.log(providerPayload, 'providerPayload')
            // let filteredPayload = {}
            // // delete providerPayload.providerType;
            // filteredPayload.ContentCuratorIds = providerPayload.contentsCurator;
            // delete providerPayload.contentsCurator;
            // filteredPayload.provider = providerPayload;


            console.log(providerPayload, 'ObjUpdate')
            let res = await axios.put(`${config.api}/api/Provider/${providerPayload?.provider?.providerId}`, providerPayload);
            console.log(res.data.providerId)
            return res.data.providerId;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("Provider");
            },
        }
    );
}
