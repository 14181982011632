import axios from "axios";
import { configUrl } from "config.js";

const getTestseriesScheduletData = async (courseId) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Courses/getProviderTestSeriesScheduling/${courseId}`
    );
    let data = response.data;
    console.log(data, 'data in service')

    return data;
};
export default {
    getTestseriesScheduletData: getTestseriesScheduletData,
}