import { Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUploadSharp } from '@material-ui/icons';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { re1 } from 'config';
import { LoginContext } from 'contexts/LoginContext';
import useGetCategoryOnly from "hooks/categoryHooks/useGetCategoryOnly";
import useQuickTestQuestionBulkImport from "hooks/questionBulkImportHooks/useQuickTestQuestionBulkImport";
import React, { useContext } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import QuestionBulkImportService from "services/QuestionsBulkImportServices/QuestionBulkImportService";
// const styles = {
//     infoText: {
//         fontWeight: "300",
//         margin: "10px 0 30px",
//         textAlign: "center",
//     },
//     inputAdornmentIcon: {
//         color: "#555",
//     },
//     inputAdornment: {
//         position: "relative",
//     },
// };
const useStyles = makeStyles(styles);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function QuickTestZipUpload(props) {
    console.log("testId", props.testId);

    console.log("in Constructor Start");
    const classes = useStyles();

    const { user, providerId } = useContext(LoginContext)
    // will replace hooks call by passing category id as global
    const { data: categories, isLoading, refetch } = useGetCategoryOnly();
    console.log("category id", categories);


    let fileInput = React.createRef();

    const deleteFile = (index) => {
        console.log(index);
        let tempFile = fileIn[index];
        let l = fileIn.filter((el) => el !== tempFile);
        console.log(l);
        setFileIn(l);
    };

    const [feedbackPass, setFeedbackPass] = React.useState('');
    const [feedbackFail, setFeedbackFail] = React.useState('');
    const [pointsPerQuestion, setPointsPerQuestion] = React.useState();
    const [numberOfChoices, setNumberOfChoices] = React.useState(4);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("error");
    const [error1, setError1] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState([]);
    const [fileIn, setFileIn] = React.useState([]);
    const [filenames, setFileNames] = React.useState([]);
    const [formData, setFormDate] = React.useState(new FormData());
    const [fileNames, setSelectedFileNames] = React.useState(new FormData());
    const [flag, setFlag] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(null)
    const [validating, setValidating] = React.useState(false);
    const [choiceFormats, setchoiceFormats] = React.useState([
        { id: 0, name: "a), b), c), d)" },
        { id: 1, name: "(a), (b), (c), (d)" },
        { id: 2, name: "a., b., c., d." },
        { id: 3, name: "A), B), C), D)" },
        { id: 4, name: "(A), (B), (C), (D)" },
        { id: 5, name: "A., B., C., D." },
        { id: 6, name: "1), 2), 3), 4)" },
        { id: 7, name: "(1), (2), (3), (4)" },
        { id: 8, name: "1., 2., 3., 4." },
        { id: 9, name: "i), ii), iii), iv)" },
        { id: 10, name: "(i), (ii), (iii), (iv)" },
        { id: 11, name: "i., ii., iii., iv." }
    ]);
    const [choiceFormatId, setchoiceFormatId] = React.useState(0);
    console.log(choiceFormatId, 'choiceFormatId123')

    const verifyLength = (value, length) => {
        if (!value) return false;
        if (value.length >= length) {
            return true;
        }
        return false;
    };
    const isValidated = () => {
        if (
            objectiveState === "success"
        ) {
            return true;
        } else {
            if (objectiveState !== "success") {
                setObjectiveState("error");
            }
        }
        return false;
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        setError1(false);
    };
    const showErrorSnackBar = () => {
        setError1(true);
    };
    let filelengthValidate = fileIn.length + filenames.length;

    const handleImageChange = (e) => {
        console.log(e.target.files[0], "handleIMg");
        const file = e.target.files[0].name;
        const up = [];
        console.log("selected file", e.target.files[0])

        console.log(filelengthValidate.length);
        if (!re1.exec(e.target.files[0].name)) {
            setErrorMessage(
                "This File Extension Is Not Supported!. Suported extension is zip. "
            );
            showErrorSnackBar();
            return;
        }
        const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
        if (e.target.files[0].size > maxSize) {
            setErrorMessage("Please upload file less than 10 MB. ");
            showErrorSnackBar();
            return;
        } else if (filelengthValidate >= 1) {
            setErrorMessage("Please do not upload more than 1 file.");
            showErrorSnackBar();
            return;
        } else if (
            fileIn.find((e) => e.name === file) ||
            filenames.find((e) => e === file)
        ) {
            setErrorMessage("Dublicate file is not Attached.");
            showErrorSnackBar();
            return;
        } else {
            setFileIn([...fileIn, e.target.files[0]]);
            console.log("selected file", fileIn);
        }
    };
    const handleClick = (e) => {
        e.preventDefault();
        fileInput.current.value = null;
        fileInput.current.click();

        // props.refresh()
    };
    const qusetionBulkUpload = useQuickTestQuestionBulkImport();
    console.log('qusetionBulkUpload123', qusetionBulkUpload);

    const handleImportConfirmation = () => {
        if (fileIn.length === 0) {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    // afterfinish={() => props.closeForm()}
                    severity="warning"
                    alertMessage={"Please select the file."}
                />
            )
            return;
        }
        setShowAlert(<SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            // title="Confirm Import"
            onConfirm={() => handleProceed()}
            showCancel={true}
            cancelBtnText={'Cancel'}
            confirmBtnText={'Proceed'}
            onCancel={() => setShowAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.warning}
        >
            <p ><strong >Please ensure your zip file meets the following prerequisites before import:</strong></p>
            <ul style={{ textAlign: 'left' }}>
                <li>The zip contains required topic(folder) hierarchy.</li>
                <li>The questions folder like SCQ, MCQ, TF, Numerical should be part of the root level topic, while the
                    <strong> Solutions </strong> folder should be inside the respective question type.</li>
                <li>All content is correctly formatted and named.</li>
                <li>The data provided is accurate and complete.</li>

            </ul>
            <p><strong>Failure to comply may result in unsuccessful import or data errors, and the data cannot be reverted.</strong></p>
            <p><strong>Do you want to proceed with importing the content?</strong></p>
        </SweetAlert>)
    }

    const handleProceed = () => {
        console.log("Proceeding with import...");
        setShowAlert(false);
        handleSubmit();
    };

    const handleSubmit = async () => {
        setValidating(true);
        console.log(filelengthValidate, 'filelengthValidate')
        if (filelengthValidate == 0) {
            setErrorMessage("Please a select file.");
            showErrorSnackBar();
            return;
        }
        if (filelengthValidate > 4) {
            errorText =
                errorText + "Please do not select more than 4 file.";
        }
        if (file.length > 2) {
            errorText =
                errorText +
                "Please add minmum three attachment files...";
        }
        var tempFormData = new FormData();

        fileIn.forEach((element) => {
            tempFormData.append("file", element);

            console.log(element);
        });
        let validateZip = await QuestionBulkImportService.QuestionBulkImportService({ tempFormData })
        setValidating(false);
        console.log(validateZip, 'zipValidation')
        if (validateZip.status == 200) {
            let questionObj = {
                'choiceFormatId': choiceFormatId == null ? 0 : choiceFormatId,
                'numberOfChoices': numberOfChoices,
                'points': pointsPerQuestion,
                'passFeedback': feedbackPass,
                'failFeedback': feedbackFail,
                "createdBy": user,
                "createdDate": new Date(),
                "updatedBy": user,
                "updatedDate": new Date(),
                "providerId": providerId,
                "categoryId": 0,  //categories?.length > 0 ? categories[0]?.id : 0,
                "testId": props.testId ?? 0,
                "poolId": props.poolId,
            };
            let questionObjString = JSON.stringify(questionObj);
            tempFormData.append("questionObj", questionObjString);
            setFormDate(tempFormData);

            qusetionBulkUpload.mutate({ tempFormData });
        } else if (validateZip.status == 400) {
            setShowAlert(
                // TODO
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={''}
                    onConfirm={() => setShowAlert(null)}
                    onCancel={() => setShowAlert(null)}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                >
                    <b style={{ marginBottom: '10px' }}> Zip is not validate!</b>  <br />
                    {
                        validateZip?.messege['$values']?.map((x, i) => (
                            <>
                                <p>{x.message}</p>
                                <p>{x.content != null && x.content['$values']?.map((x, i) => (
                                    `${x} , `
                                ))}</p>
                            </>
                        ))
                    }
                    {/* Total tests: {update.data.totalCount} <br />
                    Ready tests: {update.data.readyCount} <br />
                    Draft tests: {update.data.draftCount} <br /> */}
                    <br />
                    {/* <small>Note: If the test status is not set to 'Ready', it means that the 'Ready' test criteria have not been met.</small> */}
                </SweetAlert>

                // <CustomAlert
                //     open={true}
                //     openFlag={() => setFlag(flag ? false : true)}
                //     // afterfinish={() => props.closeForm()}
                //     severity="error"
                //     alertMessage={validateZip?.messege['$values'][0]?.message ?? "Zip is not validate!"}
                // />
            )
        }
    }
    React.useEffect(() => {
        if (qusetionBulkUpload.isSuccess == true) {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    // afterfinish={() => props.closeForm()}
                    severity="success"
                    alertMessage={qusetionBulkUpload.data ?? "Questions imported successfully!"}
                />
            )
            setTimeout(() => {
                props.afterFinish();
            }, 2000);
        }
    }, [qusetionBulkUpload.isSuccess])
    console.log(qusetionBulkUpload, 'qusetionBulkUploadisError')
    React.useEffect(() => {
        if (qusetionBulkUpload.isError == true) {

            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    // afterfinish={() => props.closeForm()}
                    severity="error"
                    alertMessage={"Error occured"}
                />
            )
        }
    }, [qusetionBulkUpload.isError])


    return (
        <>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={4}>
                    <Button color='white' disabled={qusetionBulkUpload.isLoading == true || validating == true} onClick={(e) => handleClick(e)} style={{ marginTop: 20, float: 'left', textTransform: 'none', border: 'none', color: 'gray', boxshadow: 'none !important' }}>
                        <CloudUploadSharp fontSize="large" />
                        <br />
                        <Typography> Select & Upload File </Typography>
                    </Button>
                    <input
                        type="file"
                        multiple={true}
                        onChange={handleImageChange}
                        ref={fileInput}
                        style={{ display: "none" }}
                    />
                    <GridItem xs={12}>
                        {fileIn.map((item, index) => {
                            console.log(item.name);
                            console.log(index);
                            return (
                                <GridContainer>
                                    <GridItem>
                                        <h6>{item.name} </h6>
                                    </GridItem>
                                    <GridItem>
                                        {/* <span    
                                > */}
                                        <HighlightOffIcon
                                            onClick={() => {
                                                deleteFile(index);
                                            }}
                                        />
                                        {/* </HighlightOffIcon> */}
                                        {/* </span> */}
                                    </GridItem>
                                </GridContainer>
                            );
                        })}
                    </GridItem>
                    <GridItem xs={12}>
                        {filenames.map((item, index) => {
                            console.log(item);
                            console.log(index);
                            return (
                                <GridContainer>
                                    <GridItem>
                                        <h6>{item} </h6>
                                    </GridItem>
                                    <GridItem>
                                        <HighlightOffIcon
                                            onClick={() => {
                                                formgefiledetete(index);
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            );
                        })}
                    </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                        labelText={<span>Number Of Choices </span>}
                        id="numberOfChoices"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            maxLength: 6,
                            minLength: 2,
                            step: 1,
                            name: "numberOfChoices",
                            onChange: (event) => {
                                setNumberOfChoices(event.target.value);
                            },
                            value: numberOfChoices,
                            type: "number",

                        }}
                        disabled={qusetionBulkUpload.isLoading == true || validating == true}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <CustomSelect
                        name="choiceFormatId"
                        label="Choice Format"
                        options={choiceFormats}
                        setvalue={(v) => setchoiceFormatId(v)}
                        value={choiceFormatId}
                        disabled={qusetionBulkUpload.isLoading == true || validating == true}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}></GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {/* <UserFileUpload onClick={()=>uploaddta()} label="Select File"/> */}
                    <small style={{ marginLeft: '7%', color: 'red' }}>Existing Sections will be update through import this file</small>
                    <Button
                        color="rose"
                        // round
                        onClick={handleImportConfirmation}
                        style={{ marginTop: 20, marginLeft: 20, float: 'right', textTransform: 'none' }}
                        disabled={qusetionBulkUpload.isLoading == true || validating == true}
                    >{
                            validating == true ? 'Validating...' :
                                qusetionBulkUpload.isLoading == true ? 'Uploading...' :
                                    'Upload'
                        }
                    </Button>
                </GridItem>
            </GridContainer>
            {showAlert}
            <Snackbar
                open={error1}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity='error'>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

