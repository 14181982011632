import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetAllTopics() {
  return useQuery("topicQuestions", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/TopicQuestionMappings/allTopics`
    );

    let topics = response.data["$values"].map((x) => {
      return { id: x.id, name: x.lang };
    });
   
    return topics
  });
}
