import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState } from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CustomAlert({ open, openFlag, severity, alertMessage, handleClose, timeout }) {
  const [alertOpen, setAlertOpen] = useState(open);

  React.useEffect(() => {
    setAlertOpen(open);
  }, [open, openFlag]);

  const onclose = () => {
    setAlertOpen(false);
  };

  return (
    <Snackbar
      open={alertOpen}
      autoHideDuration={timeout ? timeout : 2000}
      onClose={handleClose ? handleClose : onclose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleClose ? handleClose : onclose} severity={severity}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

export default CustomAlert;
