import axios from "axios";
import { configUrl } from "config";
import { useMutation, useQueryClient } from "react-query";

export default function useTestBulkUpdate() {
  const queryClient = useQueryClient();

  return useMutation(async ({ selected, option, value }) => {

    let response = await axios.post(`${configUrl.AssessmentServer}/api/Tests/bulkUpdateTests?option=${option.key}&value=${value}`, selected);

    console.log("response122", response);
    return response.data;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries("tests");
    },
  });
}
