import { makeStyles } from "@material-ui/core/styles";
import { default as banner5, default as banner6, default as banner7, default as banner8 } from "assets/homepage/learn_01.jpeg";
import React from "react";
import CustomAppBar from "views/Components/CustomAppBar";
//import {config} from "config.js";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    navlinks: {
        marginLeft: theme.spacing(10),
        display: "flex",
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",
        width: "calc(100%)",
        height: "50px",
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: theme.spacing(3),
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
    },
}));

export default function CelemberHomePage() {
    const classes = useStyles();
    const [imageList, setImageList] = React.useState({ currentImage: 0, images: [banner7, banner8, banner5, banner6] })
    const switchImage = () => {
        if (imageList.currentImage < imageList.images.length - 1) {
            setImageList({ ...imageList, ['currentImage']: imageList.currentImage + 1 });
        } else {
            setImageList({ ...imageList, ['currentImage']: 0 });
        }
    }

    React.useEffect(() => {
        const interval = setInterval(() => switchImage(), 3000);
        return () => clearInterval(interval);
    }, [imageList]);

    return (
        <div className={classes.root}>
            <CustomAppBar></CustomAppBar>
            <h1>Coming soon counseling....</h1>
        </div>
    );
}
