// let response = await axios.get(`${config.api}/api/Provider`);
import axios from "axios";
import { configUrl } from "config.js";

const ActiveInactiveProvider = async (providerId, status) => {
    return axios.put(`${configUrl.AssessmentServer}/api/Provider/activeInactiveProvider/${providerId}/${status}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {
                messege: error.response.data,
                status: error.response.status
            }
        })
};
export default {
    ActiveInactiveProvider: ActiveInactiveProvider
}
