import React, { useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Button from "components/CustomButtons/Button.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useDeleteUserMapping from "hooks/testUserMappingsHooks/useDeleteUserMapping";
import UserMappingGridForTest from "services/TestUserMappingService/UserMappingGridForTest";

function TestUserMappedGrid(props) {
  console.log("propsinTestUserMapped", props);

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  const deleteUser = useDeleteUserMapping(props.testId);
  console.log(deleteUser, 'deleteUsers')
  const fetchTaskData = async (testId, pageNumber, pageSize) => {
    setPageState(old => ({ ...old, isLoading: true }))
    let getUsers = await UserMappingGridForTest.getTestUserMappingByTestId(testId, pageNumber, pageSize);
    console.log(getUsers, 'getObjective1234')
    setPageState(old => ({ ...old, isLoading: false, data: getUsers, total: getUsers?.length > 0 ? getUsers[0].totalCount : 0 }))
  }
  useEffect(() => {
    fetchTaskData(props.testId, pageState.page, pageState.pageSize)
    console.log('here is called')
  }, [props.testId, pageState.page, pageState.pageSize, props.isRefreshState, deleteUser?.isSuccess])

  // const { data: users, isLoading } = useGetTestUserMappingByTestId(
  //   props.testId
  // );



  console.log("users", pageState.data);

  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    setColumns([
      {
        field: "userId",
        headerName: "Id",
        editable: false,
        width: 100,
        hide: true,
      },
      {
        field: "fullName",
        headerName: "Full Name",
        editable: false,
        width: 200,
      },
      {
        field: "userName",
        headerName: "User Name",
        editable: false,
        width: 200,
      },
      {
        field: "mobile",
        headerName: "Mobile No",
        editable: false,
        width: 200,
      },
      {
        field: "actions",
        headerName: "Action",
        editable: false,
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          return (
            <Button
              size="sm"
              justIcon
              round
              color="reddit"
              disabled={props.status >= 3 ? true : false}
              onClick={(e) => {
                e.preventDefault();
                deleteUser.mutate(cellValues.row.userId);
              }}
            >
              <DeleteForeverIcon />
            </Button>
          );
        },
      },
    ]);
  }, []);

  const handleEdit = (p, e) => { };

  const onCellEditCommit = (p) => {
    let row = questionData.questions.find((x) => x.id == p.id);
    if (row[p.field] == p.value) {
      return;
    }
  };
  const getRowClassName = (row) => {
    if (row.isLocked == true) {
      return "gray";
    }

    return null;
  };
  return (
    <GridContainer>
      {pageState.isLoading == false && pageState.data ? (
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ height: 400, width: "100%" }}>
            <div style={{ display: "flex", height: "90%" }}>
              <div style={{ flexGrow: 1 }}>
                <CustomDataGrid
                  columns={columns}
                  rows={pageState.data}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  rowsPerPageOptions={[10, 30, 50, 70, 100]}
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                  onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                  onEditCellPropsChange={handleEdit}
                  onCellEditCommit={onCellEditCommit}
                  getRowClassName={getRowClassName}
                />
              </div>
            </div>
          </div>
        </GridItem>
      ) : (
        <CircularProgress />
      )}
    </GridContainer>
  );
}

export default TestUserMappedGrid;
