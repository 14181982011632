// import Card from '@mui/material/Card';
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TimelineOutlined, VisibilityOutlined, WarningOutlined } from "@material-ui/icons";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { Tooltip } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import Card from 'components/Card/Card';
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import { LoginContext } from 'contexts/LoginContext';
import { useContext, useState } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from "react-moment";
// import Moment from "react-moment";

const useStyles = makeStyles(styles);

function MyCard({ cardData, onEdit, onView, onUserMaps, onTestSchedule, onAnalyticsClick, onRemoveTest, onClearTest, onLoading }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, userPrivileges, providerId } = useContext(LoginContext);
    const [alert, setAlert] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        console.log(cardData, 'cardData')
        onEdit(cardData);
        handleMenuClose();
    };

    const handleViewClick = () => {
        onView(cardData);
        handleMenuClose();
    };
    const handleTestUserMapClick = () => {
        onUserMaps(cardData);
    }
    const handleTestScheduleClick = () => {
        onTestSchedule(cardData);
    }
    const handleAnalyticsClick = () => {
        onAnalyticsClick(cardData);
    }
    const handleRemoveClick = () => {
        onRemoveTest(cardData.testId)
        setAlert(null)
        handleMenuClose();

    }
    const handleCleanupUsers = () => {
        onClearTest(cardData.testId)
        setAlert(null)
        handleMenuClose();

    }
    const isPrivilege = (x) => {
        if (userPrivileges?.Privileges?.SimplifiedTest.indexOf(x) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        // #ec9706
        <Card
            style={
                cardData.scheduleStatus == 4 ?
                    { border: '2px #0fb7cb solid' } :
                    cardData.scheduleStatus == 5 ?
                        { border: '2px #c74488 solid' } :
                        cardData.status == 1 ?
                            { border: '2px #ec9706 solid' }
                            : cardData.status == 2 ?
                                { border: '2px #53ad57 solid' }
                                // : cardData.status == 4 ? 
                                // { border: '2px #0fb7cb solid' } 
                                : { border: '2px #ec9706 solid' }}
        >
            {/* {
                onLoading ?
                    <h4 style={{ zIndex: '9', position: 'absolute', padding: '8% 31%' }}><CircularProgress style={{ marginInline: '25%', color: '#E63673', height: '25px', width: '25px' }} /><br /><small style={{ color: '#E63673' }}>Opening...</small></h4>
                    : null
            } */}

            <CardContent style={{ padding: '11px 16px' }}>
                <div>
                    <div style={{ float: 'left', paddingTop: '5px' }}>
                        <h5 className={classes.cardTitle} style={{ fontSize: '16px', textAlign: 'left', color: '#E63673', fontWeight: '500' }}>{cardData.text?.length > 35 ? cardData.text?.substr(0, 35) + '...' : cardData.text}</h5>
                    </div>
                    <CardActions style={{ padding: '0px', float: 'right' }}>
                        <IconButton size="small" onClick={handleMenuClick}>
                            <GridMoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {
                                isPrivilege("UpdateSimplifiedTest") == true ?
                                    <MenuItem onClick={handleEditClick} disabled={
                                        // cardData.providerId == providerId ? cardData.isLocked == true ? cardData.lockedBy == user ? false : true : cardData.status >= 5 ? true : false : true}
                                        cardData.providerId == providerId ? cardData.status != 3 ? cardData.scheduleStatus != 5 ? false : true : true : true}
                                    >Edit</MenuItem> : ''
                            }
                            {
                                isPrivilege("DeleteSimplifiedTest") == true ?
                                    <MenuItem onClick={
                                        (e) => {
                                            e.preventDefault();
                                            setAlert(
                                                <SweetAlert
                                                    style={{ display: "block", marginTop: "-100px" }}
                                                    title={''}
                                                    onConfirm={() => handleRemoveClick()}
                                                    showCancel={true}
                                                    cancelBtnText={'CANCEL'}
                                                    confirmBtnText={'OK'}
                                                    onCancel={() => setAlert(null)}
                                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                                    cancelBtnCssClass={classes.button + " " + classes.warning}
                                                >
                                                    <div style={{ display: 'inline-flex' }}>
                                                        <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                                                        <h4> Are you sure you want to delete this test?</h4>
                                                    </div>
                                                </SweetAlert>
                                            );
                                        }
                                    }
                                        disabled={cardData.providerId == providerId ? cardData.isLocked == true ? cardData.lockedBy == user ? false : true : cardData.scheduleStatus >= 3 ? true : false : true}
                                    >Delete</MenuItem> : ''
                            }
                            {
                                isPrivilege("CleanData") == true ?
                                    <MenuItem onClick={(e) => {
                                        e.preventDefault();
                                        setAlert(
                                            <SweetAlert
                                                style={{ display: "block", marginTop: "-100px" }}
                                                title={''}
                                                onConfirm={() => handleCleanupUsers()}
                                                showCancel={true}
                                                cancelBtnText={'CANCEL'}
                                                confirmBtnText={'OK'}
                                                onCancel={() => setAlert(null)}
                                                confirmBtnCssClass={classes.button + " " + classes.success}
                                                cancelBtnCssClass={classes.button + " " + classes.warning}
                                            >
                                                <div style={{ display: 'inline-flex' }}>
                                                    <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                                                    <h4> Are you sure you want to cleanup the test?</h4>
                                                </div>
                                            </SweetAlert>
                                        );
                                    }}
                                        disabled={cardData.scheduleStatus != 5}
                                    >Clean Data</MenuItem> : ''
                            }
                        </Menu>
                    </CardActions>
                </div>
            </CardContent>
            <div style={{ paddingInline: '16px' }}>
                <h6 style={{ float: 'left', textTransform: 'none' }}>Students : <span style={{ color: '#de2668' }}>{cardData.studentsCount == 0 ? <i style={{ color: 'gray' }}> none</i> : cardData.studentsCount}</span></h6>
                <h6 style={{ float: 'right', textTransform: 'none' }}>Scheduled on :{cardData?.scheduleDate ? <Moment style={{ color: '#fd940c', marginLeft: '5px' }} format="DD-MMM-YYYY">{cardData?.scheduleDate}</Moment> : <i style={{ color: 'gray' }}> none</i>}</h6>
            </div>
            {/* #ec9706 */}
            <CardFooter style={
                cardData.scheduleStatus == 4 ?
                    { justifyContent: 'flex-end', margin: '0', padding: '10px 15px 10px', height: '40px', backgroundColor: '#0fb7cb' } :
                    cardData.scheduleStatus == 5 ?
                        { justifyContent: 'flex-end', margin: '0', padding: '10px 15px 10px', height: '40px', backgroundColor: '#c74488' } :
                        cardData.status == 1 ?
                            { justifyContent: 'flex-end', margin: '0', padding: '10px 15px 10px', height: '40px', backgroundColor: '#ec9706' }
                            : cardData.status == 2 ?
                                { justifyContent: 'flex-end', margin: '0', padding: '10px 15px 10px', height: '40px', backgroundColor: '#53ad57' }
                                // : cardData.scheduleStatus == 4 ?
                                //     { justifyContent: 'flex-end', margin: '0', padding: '10px 15px 10px', height: '40px', backgroundColor: '#0fb7cb' }
                                // : cardData.scheduleStatus == 5 ?
                                // {justifyContent: 'flex-end', margin: '0', padding: '10px 15px 10px', height: '40px', backgroundColor: '#c74488' }
                                : { justifyContent: 'flex-end', margin: '0', padding: '10px 15px 10px', height: '40px', backgroundColor: '#ec9706' }} >
                {
                    onLoading ?
                        <CircularProgress style={{ marginTop: '12px', color: '#ffffff', height: '25px', width: '25px' }} /> :
                        isPrivilege("PreviewSimplifiedTest") == true ?
                            <Tooltip title="View Test">
                                <Button
                                    size="sm"
                                    justIcon
                                    round
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleViewClick();
                                    }}
                                    style={{ size: "1px", marginInline: '10px', backgroundColor: "transparent" }}
                                    disabled={onLoading}
                                >
                                    {<VisibilityOutlined style={{ color: '#ffffff' }} />}
                                </Button>
                            </Tooltip> : ''
                }
                {
                    isPrivilege("AssignStudents") == true ?
                        <Tooltip title="Assign To Student">
                            <Button
                                size="sm"
                                justIcon
                                round
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleTestUserMapClick();
                                }}
                                style={{ size: "1px", marginInline: '10px', backgroundColor: "transparent" }}
                            >
                                <PersonAddIcon style={{ color: '#ffffff' }} />
                            </Button>
                        </Tooltip> : ''
                }
                {
                    isPrivilege("AssignSimplifiedTest") == true ?
                        <Tooltip title="Schedule">
                            <Button
                                size="sm"
                                justIcon
                                round
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleTestScheduleClick();
                                }}
                                style={{ size: "1px", marginInline: '10px', backgroundColor: "transparent" }}
                                disabled={cardData.isLocked == true ? true : !cardData.isActive}
                            >
                                <ScheduleIcon style={{ color: '#ffffff' }} />
                            </Button>
                        </Tooltip> : ''
                }
                {
                    isPrivilege("SimplifiedTestAnalytics") == true ?
                        <Tooltip title="Analytics">
                            <Button
                                size="sm"
                                justIcon
                                round
                                style={{ size: "1px", marginInline: '10px', backgroundColor: "transparent" }}
                                onClick={() => handleAnalyticsClick()}
                                disabled={cardData.scheduleStatus != 5}
                            >
                                <TimelineOutlined style={{ color: '#ffffff' }} />
                            </Button>
                        </Tooltip> : ''
                }
            </CardFooter>
            {alert}
        </Card >
    );
};
export default MyCard;