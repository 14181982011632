import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import CryptoJS from 'crypto-js';
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useUpdateUser() {
    const { config, user, providerId } = useContext(LoginContext);

    const queryClient = useQueryClient();
    const key = 'pj23vs7nycq18uew';
    let secretKey = CryptoJS.enc.Utf8.parse(key);

    const encryptPassword = (password, secretKey) => {

        let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let encryptedString = encryptedBytes.toString();

        console.log(encryptedString, 'encryptedone');
        return encryptedString;
        // return CryptoJS.AES.encrypt(password, secretKey).toString();
    }
    const decryptPassword = (encryptedPassword, secretKey) => {

        let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

        console.log(decryptedText, 'decryptedone');
        return decryptedText;
        // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
        // return bytes.toString(CryptoJS.enc.Utf8);
    }
    return useMutation(
        async (userPayload, useCreateUser) => {
            console.log(userPayload, useCreateUser, 'cheding for selected providerTypeId')

            if (!userPayload.address) userPayload.address = " ";
            if (!userPayload.middleName) userPayload.middleName = " ";
            if (!userPayload.userName) userPayload.userName = userPayload.email;


            if (!userPayload.passCode) userPayload.passCode = encryptPassword("test", secretKey);;

            userPayload.passCode = encryptPassword(userPayload.passCode, secretKey);
            userPayload.createdBy = user ?? "system";
            userPayload.updatedBy = user ?? "system";
            // userPayload.providerId = providerId;
            userPayload.isActive = true;
            console.log("userPayload " + userPayload)
            console.log(userPayload.providerTypeId, "userPayload11 ")

            let res = await axios.put(`${config.api}/api/User/${userPayload.userId}/${userPayload.providerTypeId}`, userPayload);
            console.log(res.data, "updateuserres");
            res.data.passCode = decryptPassword(res.data.passCode, secretKey);
            return res.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("users");
            },
        }
    );
}
