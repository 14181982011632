import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PageNavBar from "views/Components/PageNavBar";
//import celemberLogo from "assets/img/celember/Celember.jpeg";

import {
    useMediaQuery,
    useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    navlinks: {
        marginLeft: theme.spacing(10),
        display: "flex",
    },
    logo: {
        flexGrow: "1",
        width: "calc(100%)",
        height: "40px",
        borderRadius: "15%"
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: theme.spacing(3),
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
    },
}));

export default function CustomAppBar() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <>
            <AppBar position="static">
                <CssBaseline />
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        {isMobile ? "" : <img src="apple-icon.png" className={classes.logo}></img>}
                    </IconButton>
                    <Typography variant="h2" className={classes.title}>
                        LearningFitBit
                    </Typography>
                    <PageNavBar></PageNavBar>
                </Toolbar>
            </AppBar>
        </>

    );
}
