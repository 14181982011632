import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import {configUrl} from "config.js";

export default function useDeleteUserMapping(testId){
    let queryClient = useQueryClient()
    return useMutation(async(userId) => {
        await axios.delete(
            `${configUrl.AssessmentServer}/api/TestUserMappings/deleteUserMapping/${testId}/${userId}`
          );
    },{
        onSuccess: () =>{
            queryClient.invalidateQueries(`availableUserByTestIds${testId}`)
            queryClient.invalidateQueries(`userByTestIds${testId}`)
        }
    })
}