import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetCourseUserMappingByCourseId(courseId) {
 
  return useQuery([`userByCourseIds${courseId}`, courseId], async () => {
    console.log("testId in here", courseId);
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/CourseUserMapping/${courseId}`
    );

    return response.data["$values"].map((x, i) => {
      return { ...x, id: i };
    });
  });
}
