import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";

export default function useUpdateNotification() {

  const { config, user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (notificationPayload) => {
      console.log(notificationPayload)
      
      let formData = notificationPayload.fData
      console.log(formData)
      // delete notificationPayload.tempFormData

      // notificationPayload.fileName ="";
      
      // console.log(notificationPayload.file)
      notificationPayload.notification.notification.createdBy = user ?? "system";
      notificationPayload.notification.notification.updatedBy = user ?? "system";
      notificationPayload.notification.notification.providerId = providerId;
      
      console.log(notificationPayload)
      let res = await axios.put(`${config.api}/api/Notification`,notificationPayload.notification);
      console.log(res)
      // if (formData) {
        let re = await axios.post(`${config.api}/api/Attachment/saveAttachmentFile/${res.data.notificationId}`, formData);
        console.log(re)
      // }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Notifications");
      },
    }
  );
}
