import React from "react";
// used for making the prop types of this component
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import * as XLSX from "xlsx";

// core components
import Button from "components/CustomButtons/Button.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import CustomSelect from "components/CustomSelect/CustomSelect";


import CustomAlert from "components/CustomAlert/CustomAlert";
import { LoginContext } from "contexts/LoginContext";
import useQuickTestBulkUploadQuestions from "hooks/questionHooks/useQuickTestBulkUploadQuestions";
import getObjectiveService from "services/ConfigObjective/getObjectiveService";
import getTargetAudience from "services/TargetAudience/getTargetAudience";
import questionType from "utils/questionType";

const useStyles = makeStyles(styles);

export default function QuickTestFileUpload(props) {
    const {
        data: uploadDetails,
        mutate: uploadQuestions,
        isSuccess,
        isLoading,
    } = useQuickTestBulkUploadQuestions();

    console.log("uploadDetails", uploadDetails);
    const { user, providerId } = React.useContext(LoginContext);

    const getObjectsndTargetAudience = async () => {
        try {
            let getOjbectives = await getObjectiveService.getObjective(true, false);
            setObjectiveData(getOjbectives['$values']);

            let getTargetAudiences = await getTargetAudience.getTargetAudience(true, false);
            setTargetAudienceData(getTargetAudiences['$values']);
        } catch (error) {
            console.log("error fetching objecitve and target", error);
        }
    }
    React.useEffect(() => {
        getObjectsndTargetAudience();
    }, []);

    const [getObjectiveData, setObjectiveData] = React.useState([]);
    const [getTargetAudienceData, setTargetAudienceData] = React.useState([]);

    const [file, setFile] = React.useState(null);
    const [fileName, setFileName] = React.useState("");
    const classes = useStyles();
    const [alert, setAlert] = React.useState(null);
    const [payload, setPayload] = React.useState(null);
    const [languages, setLanguages] = React.useState([
        { id: 1, name: "English" },
    ]);
    const [languageId, setLanguageId] = React.useState(null);
    const [objective, setObjective] = React.useState([]);
    const [targetAudience, setTargetAudience] = React.useState([]);

    const [showAlert, setShowAlert] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [fileIn, setFileIn] = React.useState([]);
    const [choiceFormats, setchoiceFormats] = React.useState([
        { id: 0, name: "a), b), c), d)" },
        { id: 1, name: "(a), (b), (c), (d)" },
        { id: 2, name: "a., b., c., d." },
        { id: 3, name: "A), B), C), D)" },
        { id: 4, name: "(A), (B), (C), (D)" },
        { id: 5, name: "A., B., C., D." },
        { id: 6, name: "1), 2), 3), 4)" },
        { id: 7, name: "(1), (2), (3), (4)" },
        { id: 8, name: "1., 2., 3., 4." },
        { id: 9, name: "i), ii), iii), iv)" },
        { id: 10, name: "(i), (ii), (iii), (iv)" },
        { id: 11, name: "i., ii., iii., iv." }
    ]);
    const [choiceFormatId, setchoiceFormatId] = React.useState(0);
    let fileInput = React.createRef();

    const map = {
        "Question Code": "code",
        Question: "text",
        "Question Image ?": "image",
        Hint: "hint",
        Objective: "objective",
        Solution: "solution",
        "Solution Text": "solutionText",
        "Feedback right": "passFeedback",
        "Feedback wrong": "failFeedback",
        "Description ?": "description",
        "Difficulty level": "difficultyLevel",
    };

    const translateDifficultyLevel = (val) => {

        console.log("val", val)
        const defaultDifficulty = "2";

        if (!val) {
            console.log("val null", val)
            return defaultDifficulty;
        }

        if (val.toUpperCase() == "LOW") {
            console.log("val low", val)
            return "1";
        }

        if (val.toUpperCase() == "MEDIUM") {
            console.log("val med", val)
            return "2";
        }

        if (val.toUpperCase() == "HIGH") {
            console.log("val high", val)
            return "3";
        }

        if (isNaN(val)) {
            console.log("val nan", val)
            return defaultDifficulty;
        }

        console.log("val later", val)
        let diffLevel = 2;

        try {
            diffLevel = parseInt(val);

            if (diffLevel > 0 && diffLevel < 4) {
                return val;
            } else if (diffLevel > 3) return "3";
            else return defaultDifficulty;
        } catch (e) {
            console.log(e);
            return defaultDifficulty;
        }
    };

    const getMappedName = (d) => {
        if (d in map) {
            return map[d];
        }

        return null;
    };
    const handleChange = (e) => {
        e.preventDefault();
        setFileIn([...fileIn, e.target.files[0]]);

        let reader = new FileReader();
        let file = e.target.files[0];
        console.log(file, 'file1')
        reader.onload = (e) => {
            const btsr = e.target.result;
            const wb = XLSX.read(btsr, { type: "binary" });
            const wbname = wb.SheetNames[0];

            console.log(wb.SheetNames);

            let mappedData = [];
            console.log(wb, 'file2')
            wb.SheetNames.forEach((x) => {
                console.log("questionType1", x);
                if (x in questionType) {
                    console.log("questionType2", x);
                    let data = createPayload(x, wb);
                    mappedData = mappedData.concat(data);
                }
            });
            console.log("mappdedData5", mappedData)
            setPayload(mappedData);
        };

        reader.onloadend = () => {
            setFile(file);
            setFileName(file.name);
        };

        reader.readAsBinaryString(e.target.files[0]);
    };

    const hideAlert = () => {
        setAlert(null);

        if (props.callback) {
            props.callback();
        }
    };

    const createPayload = (sheetName, wb) => {
        const ws = wb.Sheets[sheetName];

        let data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            raw: false,
            blankrows: false,
            defval: "",
        });
        console.log("SheetData", data);

        let header = data[0];
        console.log("headerSheetData", header);
        let filteredData = data.filter((_, i) => i > 0);
        console.log("filteredSheetData", filteredData);
        let mappedData = [];
        let mapped = { questionChoices: new Array(6) };
        let choiceCount = 0;

        for (let i = 0; i < filteredData.length; i++) {
            if (filteredData[i][0] && !filteredData[i][1]) {
                continue;
            }

            if (i > 0 && filteredData[i][0]) {
                mappedData.push({ ...mapped });
            }

            if (filteredData[i][0]) {
                mapped = { questionChoices: new Array(6) };
                choiceCount = 0;
            }

            filteredData[i].map((x, j) => {
                if (header[j] == "Choice") {
                    mapped.questionChoices[choiceCount++] = x;
                }
                console.log("mapped", mapped);

                if (header[j] == "Is Answer") {
                    if (x == "X" || x == "x") {
                        if (questionType[sheetName] == 2) {

                            mapped["isAnswer"] = filteredData[i][j - 2];
                            mapped["points"] = filteredData[i][j + 1];

                        } else if (questionType[sheetName] == 1) {

                            let isAnswer = mapped["isAnswer"] ? JSON.parse(mapped["isAnswer"]) : [];
                            isAnswer.push(filteredData[i][j - 2])
                            let answerStringify = JSON.stringify(isAnswer);
                            mapped["isAnswer"] = answerStringify;

                            let points = mapped["points"] ? `${mapped["points"]},` : "";
                            mapped["points"] = points + filteredData[i][j + 1];
                        }
                    }

                    if (questionType[sheetName] == 3) {
                        console.log("in here");
                        mapped.questionChoices[0] = "true";
                        mapped.questionChoices[1] = "false";
                        mapped["isAnswer"] = x == "T" ? "true" : "false";
                        mapped["points"] = filteredData[i][j + 1];
                    }

                    if (questionType[sheetName] == 4) {
                        mapped["isAnswer"] = x;
                        mapped["points"] = filteredData[i][j + 1];
                    }
                }

                if (x) {
                    let k = getMappedName(header[j]);

                    if (k) {
                        if (k == "difficultyLevel") {
                            mapped[k] = translateDifficultyLevel(x);
                        } else mapped[k] = x;
                    }
                }
                console.log("objective", objective);
                console.log("targetAudience", targetAudience);

                mapped["languageId"] = languageId;
                mapped["choiceFormatId"] = choiceFormatId;
                mapped["objective"] = JSON.stringify(objective);
                mapped["targetAudience"] = JSON.stringify(targetAudience)
                mapped["QuestionType"] = questionType[sheetName];
                mapped["topicId"] = props.topicId;
                mapped["status"] = 1;
                mapped["contentType"] = 1;
                mapped["choicesAlignment"] = 1;
                console.log('mappedinBind', mapped);
            });
        }

        if (mapped["code"]) {
            mappedData.push({ ...mapped });
        }
        console.log("mappedData", mappedData);

        return mappedData;
    };

    const successAlert = () => {
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title={uploadDetails.totalQuestionCount === uploadDetails.duplicateQuestionCodeCount ? `Upload Failed` : 'Upload Success'}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={uploadDetails.totalQuestionCount === uploadDetails.duplicateQuestionCodeCount ? classes.button + " " + classes.warning : classes.button + " " + classes.success}
            >
                File has been uploaded. Check Task manager for status <br />
                Total Question Uploaded: {uploadDetails.totalQuestionCount} <br />
                Succefully Uploaded: {uploadDetails.uploadedQuestionCount} <br />
                Duplicate Question Code Count: {uploadDetails.duplicateQuestionCodeCount} <br />
                Duplicate Question Codes: {uploadDetails.duplicateQuestionCodes} <br />
            </SweetAlert>
        );
    };

    const handleClick = () => {
        fileInput.current.click();
    };

    const handleUpload = async () => {
        // TODO
        var tempFormData = new FormData();

        fileIn.forEach((element) => {
            tempFormData.append("questionsObj", element);
        });
        let excelObj = {
            "objective": JSON.stringify(objective),
            "targetAudience": JSON.stringify(targetAudience),
            "languageId": languageId ?? 1,
            "choiceFormatId": choiceFormatId ?? 1,
            "providerId": providerId,
            "topicId": props.topicId || 0,
            "poolId": props.poolId,
            "createdBy": user,
            "updatedBy": user
        }
        let excelObjString = JSON.stringify(excelObj);
        tempFormData.append("excelObj", excelObjString);
        console.log("excelObj1", Object.fromEntries(tempFormData))

        console.log("payload", payload);
        // let errors = validatePayload(payload);
        // console.log(errors);

        // if (errors) {
        //     setErrorMessage(`${errors}. Fix file and try again `);
        //     setShowAlert(true);
        //     return;
        // }

        // let response = await taskService.createTask(
        //     providerId,
        //     fileName,
        //     "BULK UPLOAD",
        //     "QUESTION IMPORT"
        // );

        console.log(payload, 'Finalpayload')
        uploadQuestions({ tempFormData });

        // await taskService.updateTask(response);
    };

    // const validatePayload = (payload) => {
    //     let errors = null;
    //     if (payload) {
    //         payload.forEach((x) => {

    //             if (x.QuestionType != 4) {
    //                 if (!x.questionChoices || x.questionChoices.length == 0) {
    //                     errors = `missing choices for ${x.code}`;
    //                     return;
    //                 }

    //                 let fil = x.questionChoices.filter((x) => x != "");

    //                 if (!fil || fil.length == 0) {
    //                     errors = `missing choices for ${x.code}`;
    //                     return;
    //                 }

    //                 if (!x.isAnswer) {
    //                     errors = `Answer missing for ${x.code}`;
    //                     return;
    //                 }
    //             }
    //         });

    //         return errors;
    //     }
    // };

    React.useEffect(() => {
        let fn = async () => {
            if (isSuccess == true) {
                successAlert();

                setFile(null);
            }
        };
        fn();
    }, [isSuccess]);

    return (
        <div>
            {alert}
            <input
                type="file"
                onChange={handleChange}
                ref={fileInput}
                accept=".xls,.xlsx"
                style={{ display: "none" }}
            />

            <div>
                {file === null ? (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomSelect
                                label="Select langauge"
                                options={languages}
                                setvalue={(v) => setLanguageId(v)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomSelect
                                name="choiceFormatId"
                                label="Choice Format"
                                options={choiceFormats}
                                // disabled={qusetionBulkUpload.isLoading == true}
                                setvalue={(v) => setchoiceFormatId(v)}
                                value={choiceFormatId}
                            />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={12}>
                            <CustomMultipleSelect
                                label="Objective"
                                selectLabel="Assp"
                                options={getObjectiveData ? getObjectiveData : ''}
                                setvalue={(v) => {
                                    console.log('slected field', v)
                                    setObjective(v)
                                }}
                                id="course"
                                name="course"
                                value={objective}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomMultipleSelect
                                label=" Target Audience"
                                selectLabel="Assp"
                                options={getTargetAudienceData ? getTargetAudienceData : ''}
                                setvalue={(v) => {
                                    console.log('slected field', v)
                                    setTargetAudience(v)
                                }}
                                id="course"
                                name="course"
                                value={targetAudience}
                            />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                color="primary"
                                onClick={() => handleClick()}
                                style={{ marginTop: 20, textTransform: 'none' }}
                            >
                                Select File
                            </Button>
                        </GridItem>
                    </GridContainer>
                ) : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                id="filename"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    fullWidth: true,
                                    value: fileName,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                color="primary"
                                onClick={handleUpload}
                                style={{ marginTop: 10, textTransform: 'none' }}
                                disabled={isLoading == true}
                            >
                                {isLoading == true ? "Uploading..." : "Upload"}
                            </Button>
                        </GridItem>
                        <CustomAlert
                            open={showAlert}
                            severity="error"
                            alertMessage={errorMessage}
                            handleClose={() => setShowAlert(false)}
                            timeout={5000}
                        />
                    </GridContainer>
                )}
            </div>
        </div>
    );
}

QuickTestFileUpload.propTypes = {
    avatar: PropTypes.bool,
    addButtonProps: PropTypes.object,
    changeButtonProps: PropTypes.object,
    removeButtonProps: PropTypes.object,
};
