import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";



const TopicwiseTestTopics = React.forwardRef((props, ref) => {

  const [pointsPerQuestion, setPointsPerQuestion] = React.useState(
    props.st.pointsPerQuestion
  );
  const [questionsAllowed, setQuestionsAllowed] = React.useState(

  );
  const [IsMandatory, setIsMandatory] = React.useState(0);

  const [questionsAllowedState, setQuestionsAllowedState] = React.useState("");
  const [pointsPerQuestionState, setPointsPerQuestionState] = React.useState("");

  // consoling state 
  React.useEffect(() => {
    console.log("yo", props);
  }, [props, ref]);

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      type: "test",
      //   
      IsMandatory,
      pointsPerQuestion,
      questionsAllowed,
      // 
    },
  }));
  const sendState = () => {
    return {
      type: "test",
      //
      IsMandatory,
      pointsPerQuestion,
      questionsAllowed,

      // 
    };
  };
  // start 
  const verifyRange = (value, min, max) => {
    if (!value) return false;

    if (value >= min) {
      return true;
    }
    return false;
  };
  const isValidated = () => {
    if (questionsAllowedState == "success" && pointsPerQuestionState == "success") {
      return true;
    } else {
      if (questionsAllowedState !== "success") {
        setQuestionsAllowedState("error");
      }
      if (pointsPerQuestionState !== "success") {
        setPointsPerQuestionState("error")
      }
    }
    return false;
  };
  // end




  return (
    <div>
      <GridContainer justify="center">
        {/* <GridItem xs={12} sm={12} md={2}>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={4}>
          <small style={{ color: 'red' }}>Note: Questions are allowed, and points are applicable for each topic.</small>
        </GridItem>
        <GridItem xs={12} sm={12} md={7}>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            success={questionsAllowedState === "success"}
            error={questionsAllowedState === "error"}
            labelText={
              <span>
                Questions Allowed
              </span>
            }
            id="questionsAllowed"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                if (!verifyRange(event.target.value, 1)) {
                  setQuestionsAllowedState("error");
                } else {
                  setQuestionsAllowedState("success");
                }
                setQuestionsAllowed(event.target.value);
              },
              value: questionsAllowed,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            success={pointsPerQuestionState == 'success'}
            error={pointsPerQuestionState == 'error'}
            labelText={<span>Points per Question</span>}
            id="pointsPerQuestion"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                if (event.target.value > 0) {
                  setPointsPerQuestionState('success');
                  setPointsPerQuestion(event.target.value);
                } else {
                  setPointsPerQuestionState('error')
                  setPointsPerQuestion('')
                }
              },
              value: pointsPerQuestion,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={7}>
          {/* <FormControlLabel
                  control={
                  <Switch
                      value={IsMandatory}
                      onChange={(e) => {
                        setIsMandatory(e.target.checked == true ? 1:0);
                      }}
                      checked={IsMandatory}
                  />
                  }
                  label={<small>Mandatory To Pass</small>}
              /> */}
        </GridItem>
      </GridContainer>
    </div>
  );
});

TopicwiseTestTopics.displayName = "Step3";

export default TopicwiseTestTopics;



