import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useCreateProvider() {
  const { config, user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (providerPayload) => {
      // if (!interestPayload.address) interestPayload.address = " ";
      // if (!interestPayload.middleName) interestPayload.middleName = " ";

      // if (!interestPayload.passCode) interestPayload.passCode = "test";

      providerPayload.provider.createdBy = user ?? "system";
      providerPayload.provider.updatedBy = user ?? "system";
      // providerPayload.providerId = providerId;
      // providerPayload.isActive = true;
      // providerPayload.logo = "";
      console.log(providerPayload, 'providerPayload')
      // let providerNcurator = {};
      // providerNcurator.type = providerPayload.providerType;
      // providerNcurator.curators = providerPayload.contentsCurator;
      let filteredPayload = {}
      // delete providerPayload.providerType;
      filteredPayload.ContentCuratorIds = providerPayload.contentsCurator;
      delete providerPayload.contentsCurator;
      filteredPayload.provider = providerPayload.provider;


      console.log(filteredPayload, 'splitedObj')
      let res = await axios.post(`${config.api}/api/Provider`, filteredPayload);
      console.log(res.data.providerId)
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Provider");
      },
    }
  );
}
