import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";

export default function useUpdateUser() {
    
  const { config } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (interestPayload) => {
      console.log(interestPayload)
      let interests = {
        interestId: interestPayload.row.interestId,
        providerId: interestPayload.row.providerId,
        interests: interestPayload.row.interests,
        description: interestPayload.row.description,
        parentInterestId: interestPayload.row.parentInterestId,
        isActive: interestPayload.row.isActive,
        createdBy: interestPayload.row.createdBy,
        createdDate: interestPayload.row.createdDate,
        updatedBy: interestPayload.row.updatedBy,
        updatedDate: interestPayload.row.updatedDate
      }
      console.log(interests);
      await axios.put(`${config.api}/api/Interest/${interests.interestId}`, interests);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Interests");
      },
      isError: () => {
        queryClient.invalidateQueries("Interests");
      },
    }
  );
}
