import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetQuestionsCount() {
  return useQuery("questionsCount", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Questions/getQuestionsCount`
    );
    return response.data;
  });
}
