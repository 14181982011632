import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import {configUrl} from "config.js";

export default function useUpdateQuestionChoices() {

  return useMutation(async ({qanswers}) => {
    console.log("useUpdateQuestionChoices",qanswers['$values'])
    let a = []
    qanswers['$values'].map((x,i)=>{
      a.push(x.questionChoices)
      console.log("mapped",x.questionChoices)
    })
    
    console.log("arrmapped",a)
    if (qanswers) {
     
      let response = await axios.put(
        `${configUrl.AssessmentServer}/api/QuestionChoices`,
        a
      );

      let uploadDetails = response.data;

      if(!uploadDetails){
        throw 'Not Found'
      }

      console.log("useUpdateQuestionChoices",response);
      return response.data;
    }
  });
}
