import React from "react";

import MuiAlert from "@material-ui/lab/Alert";
import Button from "components/CustomButtons/Button.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";

import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreTwoTone";
import usePoolQuestionLockUnLock from "hooks/poolQuestionHooks/usePoolQuestionLockUnLock";
import useDecreaseQuestionSequence from "hooks/useDecreaseQuestionSequence";
import useGetQuestionsByPoolId from "hooks/useGetQuestionByPoolId";
import useIncreaseQuestionSequence from "hooks/useIncreaseQuestionSequence";

import { DeleteForever } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip } from "@mui/material";
import CustomAlert from "components/CustomAlert/CustomAlert";
import CustomModal from "components/CustomModal/CustomModal";
import useDeletePoolQuestion from "hooks/poolQuestionHooks/useDeletePoolQuestion";
import useSetMandatory from "hooks/poolQuestionHooks/useSetMandatory";
import useGetQuestionByIdService from "hooks/questionHooks/useGetQuestionByIdService";
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import Latex from "react-latex-next";
import QuickTestQuestionForm from "views/Forms/QuestionForm/QuickTestQuestionForm";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function QuickTestPoolQuestionMappedGrid(props) {
    const { isLoading, data, refetch } = useGetQuestionsByPoolId(props.poolId);

    console.log(data, 'poolInfo');

    const lockUnlock = usePoolQuestionLockUnLock();
    const mandatory = useSetMandatory();
    const decreaseSequence = useDecreaseQuestionSequence();

    const increaseSequence = useIncreaseQuestionSequence();

    const deleteQuestion = useDeletePoolQuestion();

    const [columns, setColumns] = React.useState([]);
    const [select, setSelection] = React.useState([]);
    const [poolEditModal, setPoolEditModal] = React.useState(null);

    const handleRowSelection = (e) => {
        setSelection(e);
    };

    const handleEditQuestion = async (questionId, poolId, questionType) => {
        let e = "SCQ";
        let questionDetails = await useGetQuestionByIdService.useGetQuestionByIdService(questionId)
        console.log(questionDetails, 'questionResp')
        console.log('selected type is ', e, poolId)

        let title = questionType == 1 ? "MCQ" : questionType == 2 ? "SCQ" : questionType == 3 ? "True/False" : questionType == "4" ? "NUMERICAL" : "";

        setPoolEditModal(
            <CustomModal
                modal={true}
                setModal={() => hidePoolEditModal()}
                content={
                    <QuickTestQuestionForm
                        questionType={title == "MCQ" ? 1 : title == "SCQ" ? 2 : title == "TF" ? 3 : title == "Numerical" ? 4 : title}
                        state={questionDetails ?? null}
                        poolId={poolId}
                        afterFinish={() => hidePoolEditModal()}
                    />
                }
                cancelButtonMessage="cancel"
                okButtonMessage="Create"
                showOk={false}
                onCancelClick={() => hidePoolEditModal()}
                title={`${title} type question`}
                maxWidth="md"
                fullWidth={false}
            />
        );

    }
    const hidePoolEditModal = () => {
        setPoolEditModal(null);
    };

    React.useEffect(() => {
        let cols = [
            {
                field: "id",
                headerName: "Id",
                editable: false,
                width: 100,
                hide: true,
            },
            {
                field: "code",
                headerName: "Code",
                editable: false,
                width: 180,
            },
            {
                field: "text",
                headerName: "Questions",
                editable: false,
                width: 520,
                renderCell: (cellValues) => {
                    return (
                        console.log(cellValues, 'cellVal123'),
                        <span>{cellValues.row.text == null || cellValues.row.text == "" ? <span style={{ fontStyle: 'italic' }}>"Image Based Question"</span> : cellValues.row.contentType == 1 || cellValues.row.contentType == 2 ? cellValues.row.text?.trimStart() : cellValues.row.contentType == 3 ? <Latex>{cellValues.row.text?.trimStart()}</Latex> : cellValues.row.contentType == 4 ? ReactHtmlParser(cellValues.row.text?.trimStart()) : cellValues.row.text?.trimStart()}</span>
                    );
                },
            },
            {
                field: "isLocked",
                headerName: "isLocked",
                hide: true,
            },
            {
                field: "isMandatory",
                headerName: "isLocked",
                hide: true,
            },
            {
                field: "questionType",
                headerName: "Type",
                editable: false,
                width: 250,
                renderCell: (cellValues) => {
                    console.log("cellvalues", cellValues)
                    return (
                        <span>{cellValues.row.questionType == "1" ? "MCQ" : cellValues.row.questionType == "2" ? "SCQ" : cellValues.row.questionType == "3" ? "True/False" : cellValues.row.questionType == "4" ? "NUMERICAL" : ""}</span>
                    );
                },
            },
            {
                field: "sequence",
                headerName: "Sequence",
                editable: false,
                width: 200,
                hide: true,
                renderCell: (cellValues) => {
                    return (
                        <>
                            <IconButton
                                aria-label="up"
                                size="small"
                                style={{ color: "blue" }}
                                onClick={() => {
                                    let qId = cellValues.row.id;
                                    decreaseSequence.mutate({
                                        poolId: props.poolId,
                                        questionId: qId,
                                    });
                                }}
                            >
                                <ExpandLessIcon />
                            </IconButton>
                            {cellValues.row.sequence}
                            <IconButton
                                aria-label="up"
                                size="small"
                                style={{ color: "blue" }}
                                onClick={() => {
                                    let qId = cellValues.row.id;
                                    increaseSequence.mutate({
                                        poolId: props.poolId,
                                        questionId: qId,
                                    });
                                }}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </>
                    );
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                editable: false,
                width: 250,
                filterable: false,
                sortable: false,
                renderCell: (cellValues) => {
                    return (
                        <>
                            {/* {cellValues.row.isLocked == false ? (
                <Tooltip title="Lock">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="dodgerblue"
                    onClick={(e) => {
                      e.preventDefault();
                      lockUnlock.mutate({
                        id: props.poolId,
                        questionId: cellValues.row.id,
                        lock: true,
                      });
                    }}
                  >
                    <Lock />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Unlock">
                  <Button
                    size="sm"
                    justIcon
                    round
                    color="white"
                    onClick={(e) => {
                      e.preventDefault();
                      lockUnlock.mutate({
                        id: props.poolId,
                        questionId: cellValues.row.id,
                        lock: false,
                      });
                    }}
                  >
                    <LockOpen />
                  </Button>
                </Tooltip>
              )} */}
                            <Tooltip title="Edit">

                                <Button
                                    size="sm"
                                    justIcon
                                    round
                                    color="reddit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleEditQuestion(cellValues.row.id, props.poolId, cellValues.row.questionType);          //x
                                    }}
                                >
                                    <EditIcon />
                                </Button>
                            </Tooltip>

                            {/* <Button
                                size="sm"
                                justIcon
                                round
                                color="white"
                                onClick={(e) => {
                                    e.preventDefault();
                                    mandatory.mutate({
                                        id: props.poolId,
                                        questionId: cellValues.row.id,
                                        mandatory: cellValues.row.isMandatory == 0 ? 1 : 0,
                                    });
                                }}
                            >
                                <GradeIcon
                                    style={{
                                        color: cellValues.row.isMandatory == 0 ? "black" : "green",
                                    }}
                                />
                            </Button> */}
                            <Tooltip title="Delete">
                                <Button
                                    size="sm"
                                    justIcon
                                    round
                                    color="reddit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteQuestion.mutate({
                                            poolId: props.poolId,
                                            questionId: cellValues.row.id,
                                        });
                                    }}
                                >
                                    <DeleteForever />
                                </Button>
                            </Tooltip>
                        </>
                    );
                },
            },
        ];



        setColumns(cols);
    }, []);

    const getRowClassName = (row) => {
        if (row.isLocked == true) {
            return "gray";
        }

        return null;
    };

    const handleEdit = (p, e) => { };

    const onCellEditCommit = (p) => {
        let row = data.find((x) => (x.id = p.id));
        if (row[p.field] == p.value) {
            return;
        }
    };

    return (
        <div>
            {isLoading == false && data && data.length > 0 && columns.length > 0 ? (
                /*  <CustomTable
                    header={Object.keys(data[0])}
                    rows={data.filter((_, i) => i > 0).map((x) => Object.values(x))}
                  /> */

                <div style={{ height: 300, width: "100%" }}>
                    <div style={{ display: "flex", height: "90%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <CustomDataGrid
                                columns={columns}
                                rows={data}
                                autoPageSize={true}
                                onEditCellPropsChange={handleEdit}
                                onCellEditCommit={onCellEditCommit}
                                onSelectionModelChange={handleRowSelection}
                                getRowClassName={getRowClassName}
                            />
                        </div>
                    </div>
                    {deleteQuestion.isSuccess &&
                        <CustomAlert
                            open={true}
                            severity="success"
                            alertMessage="Question deleted"
                        />}
                </div>
            ) : null}
            {poolEditModal}
        </div>
    );
}
