import { useMediaQuery, useTheme, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import DrawerComponent from "views/Components/DrawerComponent";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    navlinks: {
        marginLeft: theme.spacing(10),
        display: "flex",
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: theme.spacing(3),
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
    },
}));

export default function PageNavBar() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>  {isMobile ? (
            <DrawerComponent />
        ) : (
            <div className={classes.navlinks}>
                <Link to="" className={classes.link}> Home </Link>
                <Link to="/assessment" className={classes.link}>Assessment</Link>
                <Link to="/counseling" className={classes.link}>Counseling </Link>
                <Link to="/contactus" className={classes.link}> Contact Us</Link>
                <Link to="/auth" className={classes.link}> Login </Link>
            </div>)}

        </>

    );
}
