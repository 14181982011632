import Snackbar from "@material-ui/core/Snackbar";
import { ListAltOutlined } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import CustomAccordion from "components/Accordion/Accordion";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PreviewComponent from "components/PreviewComponent/PreviewComponent";
import { LoginContext } from "contexts/LoginContext";
import { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import activateService from "services/TestService/activateService";
import AssignStudents from "views/Forms/QuickTestSteps/AssignStudents";
import EditSections from "views/Forms/QuickTestSteps/EditSections";
import ScheduleTest from "views/Forms/QuickTestSteps/ScheduleTest";
import TestProperties from "views/Forms/QuickTestSteps/TestProperties";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function QuickTestForm(props) {
    let state = null;
    const { userPrivileges } = useContext(LoginContext);

    if (props.location && props.location.state) {

        state = props.location.state.state;
    }
    console.log(state, 'inQuickTestForm123')
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [severity, setSeverity] = useState("success");
    const [testText, setTestText] = useState(state != null ? state.text : 'null');
    const [testId, setTestId] = useState(state != null ? state.testId : 0);
    const [tabId, setTabId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [enableSearch, setEnableSearch] = useState(false);
    const CreatedTestId = (testid, text,) => {
        console.log(testid, text, 'newtestheader');
        setTestId(testid)
        setTestText(text)
    }
    const setTab = (tabId) => {
        setTabId(tabId)
    }

    const isPrivilege = (x) => {
        if (userPrivileges?.Privileges?.SimplifiedTest.indexOf(x) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }
    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };

    const handleActivateAndRedirectTest = async () => {
        setLoading(true);
        // for test activation just comment below code. (read comment from EditSections.js file by searching this keyword - BSS121
        let activateTest = await activateService.activateTestService(testId)
        // console.log(activateTest, 'activateTest');
        // if (activateTest.status == 200) {
        //     showSnackBar("Test activated and saved successfully. ");
        setTimeout(() => {
            props.history.push("/admin/simplifiedtesthome")
            setLoading(false);
        }, 3000);
        // }
        // else {
        //     showSnackBar(activateTest?.messege ?? "Test not activated.", "error");
        //     setTimeout(() => {
        //         props.history.push("/admin/simplifiedtesthome")
        //         setLoading(false);
        //     }, 3000);
        //     console.log('error in activate test')
        // }
    }
    return (
        <div>
            <Card>
                <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <ListAltOutlined />
                    </CardIcon>
                </CardHeader>
                <CardBody>
                    <CustomAccordion
                        collapses={[
                            {
                                title: <h4>Test Details </h4>,
                                isOpen: tabId,
                                quickTest: true,
                                content:
                                    <TestProperties testData={state} testId={CreatedTestId} setTab={setTab}
                                    />
                            },
                            // {
                            //     title: <h4>Questions</h4>,
                            //     content:
                            //         <UploadQuestions testId={testId} />
                            // },
                            {
                                title: <h4>Sections</h4>,
                                isDisabled: testId ? false : true,
                                isOpen: tabId,
                                quickTest: true,
                                content:
                                    <EditSections testId={testId} setTab={setTab} isSearch={() => setEnableSearch(enableSearch ? false : true)} />
                            },
                            {
                                title: <h4>Test Schedule</h4>,
                                isDisabled: testId ? false : true,
                                isOpen: tabId,
                                quickTest: true,
                                content:
                                    <ScheduleTest testData={state} testId={testId} setTab={setTab} />
                            },
                            {
                                title: <h4>Students Onboarding</h4>,
                                isDisabled: testId ? false : true,
                                isOpen: tabId,
                                quickTest: true,
                                content:
                                    <AssignStudents testId={testId} setTab={setTab} />
                                // <h2>Assign/Distribute test to students</h2>
                            },
                        ]}
                    />
                </CardBody>
                <CardFooter>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                round
                                small
                                color="primary"
                                onClick={() => { handleActivateAndRedirectTest() }}
                                style={{ textTransform: 'none', float: 'right' }}
                                disabled={loading == true}
                            >
                                {
                                    loading == true ? 'Saving...' :
                                        'Save'
                                }
                            </Button>

                            <PreviewComponent isShowPublish={isPrivilege("PublishSimplifiedTest") == true ? true : false} buttonTitle={'Preview'} testName={testText} testId={testId} multiple={true} />
                            {
                                tabId == 3 ?
                                    <Button
                                        round
                                        small
                                        color="primary"
                                        onClick={() => setTabId(2)}
                                        style={{ textTransform: 'none', marginRight: '5px', float: 'right' }}
                                        disabled={loading == true}

                                    >
                                        {
                                            // saving ? 'Saving...' :
                                            'Previous'
                                        }
                                    </Button> : null
                            }
                        </GridItem>
                    </GridContainer>
                </CardFooter>
            </Card>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}
export default withRouter(QuickTestForm)