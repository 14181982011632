import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import {configUrl} from "config.js";

export default function useCreateCourse() {
  const { user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient()
  return useMutation(async (data) => {
    let course = {
      providerId: providerId,
      code: data.courseCode,
      owner: user,
      createdBy: user,
      createdDate: new Date(),
      updatedBy: user,
      updatedDate: new Date(),
      difficultyLevel : data.difficultyLevel ?? 2,
      isActive: true,
      isDynamic: data.isDynamic ?? false,
      expiryDate: data.expiryDate,
      duration: data.duration,
      categories: data.selectedCategories,
      topics: data.selectedTopics?.join(','),
      status: data.status ?? 1,
      objective: data.objective != null ? JSON.stringify(data.objective) : null,
      targetAudience: data.targetAudience != null ? JSON.stringify(data.targetAudience) : null,
      image:data.image
    };

    let response = await axios.post(`${configUrl.AssessmentServer}/api/courses`, course);
    let id = response.data.courseId;

    let language = {
        courseId: id,
      languageId: 1,
      text: data.text,
      image: "",
      textHtml: "",     
      isActive: true,
      status: data.status ?? 1,
      createdBy: user,
      createdDate: new Date(),
      updatedBy: user,
      updatedDate: new Date(),
      description: data.description,
    };

    let langResponse = await axios.post(
      `${configUrl.AssessmentServer}/api/CourseLanguages`,
      language
    );

    let languageId = langResponse.data.id;

    return id;
  },
  {
      onSuccess: () => {
        queryClient.invalidateQueries("courses")
      }
  });
}
