import Dashboard from "views/Dashboard/Dashboard.js";
import StudentDashboard from "views/Dashboard/StudentDashboard.js";
import ImportQuestion from "views/Components/ImportQuestion";
import QuestionBulkUpdate from "views/Components/QuestionBulkUpdate/QuestionBulkUpdate";
import TaskManager from "views/Components/TaskManager";
import CategoryTreeView from "views/Components/CategoryTreeView"
import ImportUsers from "views/Components/ImportUsers";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TestWizard from "views/Forms/TestWIzard";
import PoolForm from "views/Forms/PoolForms/PoolForm";
import PoolQuestionMappingGrid from "views/Components/PoolQuestionMappingGrid";
import HelpIcon from '@material-ui/icons/Help';
import TestHomeComponent from "views/Components/TestHomeComponent";
import Users from "views/Components/UserListing";
import QuestionForm from "views/Forms/QuestionForm/QuestionForm";
import TestViewer from "views/Components/TestViewer";
import RegisterPage from "views/Pages/RegisterPage";
import CoursesComponent from "views/Components/CoursesComponent";
import CourseTestViewer from "views/Components/CourseTestViewer";

var userRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: StudentDashboard,
    layout: "/admin",
  }, 
  {
    collapse: true,
    name: "Test",
    rtlName: "rtl",
    icon: Apps,
    state: "testCollapse",
    views: [      
      {
        path: "/testviewer",
        name: "Assigned Tests",
        rtlName: "rtl",
        mini: "IU",
        rtlMini: "iu",
        component: TestViewer,
        layout: "/admin",
      },
      {
        path: "/courses",
        name: "Test Series",
        rtlName: "rtl",
        mini: "R",
        rtlMini: "iu",
        component: CoursesComponent,
        layout: "/admin",
      },
      {
        path: "/courseTestViewer",
        name: "Courses",
        rtlName: "rtl",
        mini: "R",
        rtlMini: "iu",
        component: CourseTestViewer,
        layout: "/admin",
        redirect: true
      },
     
    ]
  }, 
 
 
];
export default userRoutes;
