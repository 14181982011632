import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetTestsForCourse(courseId) {
  return useQuery([`courseTests${courseId}`, courseId], async () => {

    if (!courseId) {
      return;
    }

    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/CourseTestMappings/getByCourseId/${courseId}`
    );

    return response.data["$values"];
  });
}
