import axios from "axios";
import { configUrl } from "config";

const QuestionBulkImportService = async ({ tempFormData }) => {

    console.log("questionObj4", Object.fromEntries(tempFormData))
    return axios.post(`${configUrl.AssessmentServer}/api/SimplifiedTest/validateZip`, tempFormData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error?.response, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status
            }
        });
};
export default {
    QuestionBulkImportService: QuestionBulkImportService,
}