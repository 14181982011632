import axios from "axios";
import { configUrl } from "config.js";

const getAllQuestions = async (page, pageSize, topicids, difficultylevel, Status, Objective, TargetAudience, code, contentType, questionType, contentFilter) => {
  let topicId = topicids ?? 0;
  let difficultyLevel = difficultylevel ?? 0;
  let points = 0;
  let status = Status ?? 0;
  let objective = Objective ?? null;
  let targetAudience = TargetAudience ?? null;
  let Code = code ?? null;
  let ContentType = contentType ?? 0;
  let QuestionType = questionType ?? 0;
  let ContentFilter = contentFilter ? parseInt(contentFilter) : 0;
  console.log(contentFilter, ContentFilter, 'contentFilterVals')
  let response = await axios.get(
    `${configUrl.AssessmentServer}/api/Questions?pageNumber=${page}&pageSize=${pageSize}&topicId=${topicId}&difficultyLevel=${difficultyLevel}&points=${points}&status=${status}&objective=${objective}&targetAudience=${targetAudience}&code=${Code}&contentType=${ContentType}&questionType=${QuestionType}&contentFilter=${ContentFilter}`
  );

  let questionData = response.data["$values"];
  return questionData;
};
export default {
  getAllQuestions: getAllQuestions,
}


