import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery } from "react-query";

export default function useGetNotificationsById(notificatioId) {
  const { config,providerId } = useContext(LoginContext);
  return useQuery(
    "getNotification",
async () => {

      console.log(notificatioId)
      let response = await axios.get(`${config.api}/api/Notification/getNotification/${notificatioId}`);
      console.log(response)
      return response.data;
    }
  );
}
