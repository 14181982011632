import React from "react";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import authPageStyles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

import QuestionWrapper from "components/Questions/QuestionWrapper";
import { TestContext } from "contexts/TestContext";

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const authUseStyles = makeStyles(authPageStyles);

export default function Player(props) {
  const classes = useStyles();
  const classes2 = authUseStyles();

  let allQuestions = props.questions;
  console.log("QQ", allQuestions)

  const [questions, setQuestions] = React.useState(allQuestions);

  let testData = {
    $id: "1",
    welcomeMessage: "",
    concludeMessage: "",
    feedbackFail: "",
    feedbackPass: "",
    feedbackmastery: "",
    passingScore: 0,
    masteryScore: 0,
    pointsPerQuestion: 0,
    showHint: 1,
    questionsPerPage: 1,
    navigation: 0,
    isQuestionPreviewOnly: true,
    pools: {
      $id: "2",
      $values: [
        {
          $id: "3",
          id: 1,
          text: "",
          sequence: 1,
          poolScore: 0,
          masteryScore: 0,
          isMandatoryToPass: false,
          questions: {
            $id: "4",
            $values: [...allQuestions],
          },
        },
      ],
    },
  };

  const [test, setTest] = React.useState(testData);

  return (
    <div className={classes.wrapper}>
      <div
        style={{
          // background: "#9c27b0",
          //  padding: "120px 0",
          position: "relative",
          minHeight: "70vh",
          display: "flex!important",
          margin: "0",
          border: "0",
          color: "white",
          alignItems: "center",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <TestContext.Provider value={{ test, setTest }}>
          <GridContainer justifyContent="center" style={{ width: "calc(100% + 15px)" }}>
            <GridItem xs={11} sm={11} md={12} style={{ marginLeft: 10 }}>
              <QuestionWrapper isShowPublishQuestion={props.isShowPublishQuestion} preview={props.preview} />
            </GridItem>
          </GridContainer>
        </TestContext.Provider>
      </div>
    </div>
  );
}
