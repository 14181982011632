import { useQuery } from "react-query";
import axios from "axios";
import {configUrl} from "config.js";

export default function useGetTestResults(id, testId, isCourse) {
  return useQuery([`testsByUser${testId}`, id, testId,isCourse], async () => {
      console.log('in here')
    if (!id || !testId) {
      return;
    }
    let result = await axios.get(
      `${configUrl.AssessmentServer}/api/TestResults/getResults/${id}/${testId}/${isCourse}`
    );

    return result.data;
  });
}
