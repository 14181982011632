import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";

export default function useCreateUserNotificationMapping() {
  const { config, user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (notificationId,notificationsPayload) => {
      // notificationsPayload.userId
      // notificationsPayload.notificationId

      // if (!notificationsPayload.broadcast) notificationsPayload.broadcast = false;

      // notificationsPayload.createdBy = user ?? "system";
      // notificationsPayload.updatedBy = user ?? "system";
      // notificationsPayload.providerId = providerId;
      

      await axios.post(`${config.api}/api/UserNotificationMapping ${notificationId}`, notificationsPayload.userId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("UserNotificationMapping");
      },
    }
  );
}
