import axios from "axios";
import { configUrl } from "config.js";

const taskDetailsService = async (taskID) => {
    // let response = await axios.get(
    //     `${configUrl.AssessmentServer}/api/TaskManagers/getOutputDetails/${taskID}`
    // );
    // let data = response.data;
    // console.log(data, 'data in service')

    // return data;

    return axios.get(`${configUrl.AssessmentServer}/api/TaskManagers/getOutputDetails/${taskID}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error.response.data, "prevError");
            return {
                messege: error.response.data,
                status: error.response.status
            }
        });
};
export default {
    taskDetailsService: taskDetailsService,
}