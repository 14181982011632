import React from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import DateRange from "@material-ui/icons/DateRange";
import Language from "@material-ui/icons/Language";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { Help, ListAlt } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import useGetAssignedCoursesCount from "hooks/courseHooks/useGetAssignedCoursesCount";
import useGetQuestionsCount from "hooks/questionHooks/useGetQuestionsCount";
import useGetAssignedTestsCount from "hooks/testsHooks/useGetAssignedTestsCount";
import useGetAssignedTestsDetails from "hooks/testsHooks/useGetAssignedTestsDetails";
import { withRouter } from "react-router-dom";


var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

const useStyles = makeStyles(styles);

function StudentDashboard(props) {
  const classes = useStyles();

  const { data: courseCount } = useGetAssignedCoursesCount();



  const { data: testsCount } = useGetAssignedTestsCount();

  const { data: questionsCount } = useGetQuestionsCount();

  const { data: details } = useGetAssignedTestsDetails();


  const [trendData, setTrendData] = React.useState([["High Score Topic", "Low Score Topic"]]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => {
              props.history.push("/admin/courses");
            }}

            style={{ cursor: "pointer" }}
          >
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <ListAlt />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                Test Series ({courseCount ?? "..."})
              </h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => {
              props.history.push("/admin/testviewer");
            }}

            style={{ cursor: "pointer" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <ListAlt />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                Tests ({details ? details.length : "..."})
              </h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => {
              props.history.push("/admin/testviewer");
            }}

            style={{ cursor: "pointer" }}
          >
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Help />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                Pending ({details ? details.filter(x => x.attemptNumber < 1).length : "..."})
              </h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => {
              props.history.push("/admin/testviewer");
            }}

            style={{ cursor: "pointer" }}
          >
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Check />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                Completed ({details ? details.filter(x => x.attemptNumber >= x.maxAttempts).length : "..."})
              </h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Language />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Top Scoring Topics</h4>
            </CardHeader>
            <CardBody>
              <GridContainer justifyContent="space-between">
                <GridItem xs={12} sm={12} md={5}>
                  <Table tableData={trendData} />
                </GridItem>

              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}

export default withRouter(StudentDashboard);
