import React, { useContext } from "react";
// @material-ui/icons

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Switch from "@material-ui/core/Switch";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { FormControlLabel } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { LoginContext } from "contexts/LoginContext";
import DropdownTreeSelect from "react-dropdown-tree-select";
import getObjectiveService from "services/ConfigObjective/getObjectiveService";
import getTargetAudience from "services/TargetAudience/getTargetAudience";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  codeNname: {
    paddingTop: '50px !important'
  }
};

const useStyles = makeStyles(styles);

const TreeDropDown = React.memo(
  ({ data, onChange, className }) => {
    return (
      <>
        <DropdownTreeSelect
          data={data}
          onChange={onChange}
          className={className}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);

const TopicwiseTestConfiguration = React.forwardRef((props, ref) => {
  const { user, providerId } = useContext(LoginContext);

  const classes = useStyles();

  React.useEffect(() => {
    console.log("topicwiseyo", JSON.stringify(props));
  }, [props, ref]);

  const dateRef = React.useRef();
  // start

  const [passingScore, setPassingScore] = React.useState(props.st.passingScore);
  const [masteryScore, setMasteryScore] = React.useState(props.st.masteryScore);
  const [attemptsAllowed, setAttemptsAllowed] = React.useState(
    props.st.attemptsAllowed ?? 1
  );
  const [questionsPerPage, setQuestionsPerPage] = React.useState(
    1
  );
  const [navigationAllowed, setNavigationAllowed] = React.useState(false);
  const [showHint, setShowHint] = React.useState(false);
  const [showTimer, setShowTimer] = React.useState(false);
  const [showResult, setShowResult] = React.useState(false);
  // const [testIsMandatory, setTestisMandatory] = React.useState(props.st.testIsMandatory);

  const [welcomeMessage, setWelcomeMessage] = React.useState(
    props.st.welcomeMessage ?? 'Welcome'
  );
  const [concludeMessage, setConcludeMessage] = React.useState(
    props.st.concludeMessage ?? 'Thank You!'
  );
  const [feedbackFail, setFeedbackFail] = React.useState(props.st.feedbackFail ?? 'Failed');

  const [feedbackPass, setFeedbackPass] = React.useState(props.st.feedbackPass ?? 'Pass');
  const [feedbackMastery, setFeedbackfeedbackMastery] = React.useState(props.st.feedbackMastery ?? 'Excellent !');
  const [duration, setDuration] = React.useState(props.st.duration ?? 180);
  const [testCodePrefix, setTestCodePrefix] = React.useState("");
  const [testNamePrefix, setTestNamePrefix] = React.useState("");

  const [masteryState, setMasteryState] = React.useState("");
  const [durationState, setDurationState] = React.useState("success");
  const [attemptsAllowedState, setAttemptsAllowedState] = React.useState("");
  const [questionsPerPageState, setQuestionsPerPageState] = React.useState("");
  const [expiryDate, setExpiryDate] = React.useState(props.st.expiryDate ?? "2099-12-31T00:00:00");

  const getObjectsndTargetAudience = async () => {
    try {
      let getOjbectives = await getObjectiveService.getObjective(true, true);
      setObjectiveData(getOjbectives['$values']);
      let getTargetAudiences = await getTargetAudience.getTargetAudience(true, true);
      setTargetAudienceData(getTargetAudiences['$values']);
    } catch (error) {
      console.log("error fetching objecitve and target", error);
    }
  }
  React.useEffect(() => {
    getObjectsndTargetAudience();
  }, []);

  const [getObjectiveData, setObjectiveData] = React.useState([]);
  const [getTargetAudienceData, setTargetAudienceData] = React.useState([]);

  const [objective, setObjective] = React.useState(null);
  const [targetAudience, setTargetAudience] = React.useState(null);

  console.log("TestObj", objective);
  React.useEffect(() => {
    if (!verifyRange(questionsPerPage, 1, 20)) {
      setQuestionsPerPageState("error");
    } else {
      setQuestionsPerPageState("success");
    }
  }, [questionsPerPage]);

  const verifyRange = (value, min, max) => {
    if (!value) return false;

    if (value >= min && value <= max) {
      return true;
    }
    return false;
  };
  // end

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {

      type: "configuration",
      providerId: providerId,
      createdBy: user,
      createdDate: new Date(),
      updatedBy: user,
      updatedDate: new Date(),
      testCodePrefix,
      testNamePrefix,
      testType: 0,
      masteryScore,
      passingScore,
      attemptsAllowed,
      questionsPerPage,
      objective,
      targetAudience,
      showHint,
      navigationAllowed,
      welcomeMessage,
      concludeMessage,
      feedbackPass,
      feedbackFail,
      feedbackMastery,
      duration,
      expiryDate,
      showTimer,
      showResult,
      // testIsMandatory,

    },
  }));
  const sendState = () => {
    return {

      type: "configuration",
      providerId: providerId,
      createdBy: user,
      createdDate: new Date(),
      updatedBy: user,
      updatedDate: new Date(),
      testCodePrefix,
      testNamePrefix,
      testType: 0,
      masteryScore,
      passingScore,
      attemptsAllowed,
      questionsPerPage,
      objective,
      targetAudience,
      showHint,
      navigationAllowed,
      welcomeMessage,
      concludeMessage,
      feedbackPass,
      feedbackFail,
      feedbackMastery,
      duration,
      expiryDate,
      showTimer,
      showResult,
    };
  };

  const isValidated = () => {
    if (durationState == "success") { //&& duration <= 360 && duration > 0
      return true;
    } else {
      if (durationState !== "success") {
        setDurationState("error");
      }
      return false
    }
  };

  const verifyLength = (value, length) => {

    if (value >= parseInt(length)) {
      return true;
    }
    return false;
  };


  return (
    <GridContainer justify="center" style={{ marginInline: '10px' }}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={2}>
          <CustomInput
            success={durationState === "success"}
            error={durationState === "error"}
            labelText={<span>Duration <small> Range 1-360 minutes *</small></span>}
            id="duration"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                if (event.target.value > 0 && event.target.value <= 360) {
                  setDuration(event.target.value);
                  setDurationState('success');
                } else if (event.target.value <= 0 || event.target.value > 360) {
                  setDurationState('error');
                  setDuration('');
                } else {
                  setDuration('');
                  setDurationState('success');
                }
                // setDuration(event.target.value);
              },
              value: duration,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={2}>
          <CustomInput
            success={true}
            labelText={<span>Passing Score</span>}
            id="passingScore"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                setPassingScore(event.target.value);
              },
              value: passingScore,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={2}>
          <CustomInput
            success={masteryState === "success"}
            error={masteryState === "error"}
            labelText={<span>Mastery Score<small> ( {'>'} Passing )</small></span>}
            id="masteryScore"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                console.log(event.target.value < passingScore, "passingScore")
                let passing = passingScore;
                console.log(typeof (passingScore), "rss")
                if (!verifyLength(event.target.value, passingScore)) {
                  setMasteryState("error");
                } else {
                  setMasteryState("success");
                }
                setMasteryScore(event.target.value);
              },
              value: masteryScore,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={2}>
          <CustomInput
            success={attemptsAllowedState === "success"}
            error={attemptsAllowedState === "error"}
            labelText={<span>Attempts</span>}
            id="attemptsAllowed"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                if (event.target.value > 0) {
                  setAttemptsAllowed(event.target.value);
                  setAttemptsAllowedState('success')
                }
                else {
                  setAttemptsAllowedState('error')
                  setAttemptsAllowed('')
                }
              },
              value: attemptsAllowed,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={2}>
          <CustomInput
            success={questionsPerPageState === "success"}
            error={questionsPerPageState === "error"}
            labelText={
              <span>
                Questions per Page <small>( 1 to 20 )</small>
              </span>
            }
            id="questionsPerPage"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                if (!verifyRange(event.target.value, 1, 20)) {
                  setQuestionsPerPageState("error");
                } else {
                  setQuestionsPerPageState("success");
                }
                setQuestionsPerPage(event.target.value);
              },
              value: questionsPerPage,
            }}
          />
        </GridItem>
      </GridContainer>

      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={2} className={classes.codeNname}>
          <CustomInput
            success={true}
            labelText={<span>Code Prefix</span>}
            id="testCodePrefix"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "string",
              onChange: (event) => {
                setTestCodePrefix(event.target.value);
              },
              value: testCodePrefix,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={2} className={classes.codeNname}>
          <CustomInput
            success={true}
            labelText={<span>Name Prefix</span>}
            id="testNamePrefix"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "string",
              onChange: (event) => {
                setTestNamePrefix(event.target.value);
              },
              value: testNamePrefix,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <CustomInput
            success={welcomeMessage}
            error={!welcomeMessage}
            labelText={
              <span>
                Welcome Message <small>(required)</small>
              </span>
            }
            labelProps={{
              shrink: welcomeMessage,
            }}
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setWelcomeMessage(event.target.value);
              },
              value: welcomeMessage,
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <FormatAlignJustifyIcon
                    className={classes.inputAdornmentIcon}
                  />
                </InputAdornment>
              ),
              rows: 3,
              multiline: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <CustomInput
            success={concludeMessage}
            error={!concludeMessage}
            labelText={<span>Conclude Message</span>}
            labelProps={{
              shrink: concludeMessage,
            }}
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setConcludeMessage(event.target.value);
              },
              value: concludeMessage,
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <FormatAlignJustifyIcon
                    className={classes.inputAdornmentIcon}
                  />
                </InputAdornment>
              ),
              rows: 3,
              multiline: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            success={true}
            labelText={<span>Feedback Pass</span>}
            id="feedbackPass"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setFeedbackPass(event.target.value);
              },
              value: feedbackPass,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            success={true}
            labelText={<span>Feedback Fail</span>}
            id="feedbackFail"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setFeedbackFail(event.target.value);
              },
              value: feedbackFail,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            success={true}
            labelText={<span>Feedback Mastery</span>}
            id="feedbackMastery"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setFeedbackfeedbackMastery(event.target.value);
              },
              value: feedbackMastery,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <FormControlLabel
            style={{ marginTop: '15%' }}
            control={
              <Switch
                value={showResult}
                onChange={(e) => {
                  setShowResult(e.target.checked);
                }}
                checked={showResult}
              />
            }
            label={<small>Show Result</small>}
          />
        </GridItem>
      </GridContainer>
      {/* <GridItem xs={12} sm={12} md={2}>
        </GridItem> */}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={2}>
          <CustomSelect
            label="Objective"
            selectLabel="Assp"
            showNone={false}
            showNotConf={true}
            options={getObjectiveData && getObjectiveData.map((x) => ({
              id: x.id,
              name: x.valueString
            }))}
            setvalue={(v) => {
              setObjective(v)
            }}
            id="objective"
            name="objective"
            value={objective}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomSelect
            label=" Target Audience"
            selectLabel="Assp"
            showNone={false}
            showNotConf={true}
            options={getTargetAudienceData && getTargetAudienceData.map((x) => ({
              id: x.id,
              name: x.valueString
            }))}
            setvalue={(v) => {
              setTargetAudience(v)
            }}
            id="targetAudience"
            name="targetAudience"
            value={targetAudience}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <FormControlLabel
            style={{ marginTop: '15%' }}
            control={
              <Switch
                value={navigationAllowed}
                onChange={(e) => {
                  setNavigationAllowed(e.target.checked);
                }}
                checked={navigationAllowed}
              />
            }
            label={<small>Navigation Allowed</small>}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <FormControlLabel
            style={{ marginTop: '15%' }}
            control={
              <Switch
                value={showHint}
                onChange={(e) => {
                  console.log("showHint", e)
                  setShowHint(e.target.checked);
                }}
                checked={showHint}
              />
            }
            label={<small>Show Hint</small>}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={2}>
          <FormControlLabel
            style={{ marginTop: '15%' }}
            control={
              <Switch
                value={showTimer}
                onChange={(e) => {
                  setShowTimer(e.target.checked);
                }}
                checked={showTimer}
              />
            }
            label={<small>Show Timer</small>}
          />
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
});

TopicwiseTestConfiguration.displayName = "Step1";

export default TopicwiseTestConfiguration;
