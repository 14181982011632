import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import React from "react";

export default function useAddCategory() {
  let { user, providerId } = React.useContext(LoginContext);

  let queryClient = useQueryClient();

  return useMutation(
    async ({ name, parentId }) => {
      console.log("creating category", name, parentId);
      await axios.post(`${configUrl.AssessmentServer}/api/Categories`, {
        parentCategoryId: parentId,
        providerId: providerId,
        isActive: true,
        owner: user,
        createdBy: user,
        createdDate: new Date(),
        updatedBy: user,
        updatedDate: new Date(),
        categoryLanguages: [
          {
            languageId: 1,
            text: name,
            isActive: true,
            status: 1,
            createdBy: user,
            createdDate: new Date(),
            updatedBy: user,
            updatedDate: new Date(),
          },
        ],
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("categories");
      },
    }
  );
}

const data = {
  $id: "1",
  $values: [
    {
      $id: "2",
      categoryId: 11,
      parentCategoryId: null,
      providerId: 1000,
      isActive: true,
      owner: "ranjit",
      createdBy: "ranjit",
      createdDate: "2021-12-11T07:01:36.49",
      updatedBy: "ranjit",
      updatedDate: "2021-12-11T07:01:36.49",
      provider: null,
      categoryLanguages: {
        $id: "3",
        $values: [
          {
            $id: "4",
            id: 11,
            categoryId: 11,
            languageId: 1,
            text: "PCMB",
            isActive: true,
            status: 1,
            createdBy: "ranjit",
            createdDate: "2021-12-11T07:01:36.49",
            updatedBy: "ranjit",
            updatedDate: "2021-12-11T07:01:36.49",
            category: { $ref: "2" },
            language: null,
          },
        ],
      },
    },
    {
      $id: "5",
      categoryId: 12,
      parentCategoryId: 11,
      providerId: 1000,
      isActive: true,
      owner: "ranjit",
      createdBy: "ranjit",
      createdDate: "2021-12-11T07:01:56.44",
      updatedBy: "ranjit",
      updatedDate: "2021-12-11T07:01:56.44",
      provider: null,
      categoryLanguages: {
        $id: "6",
        $values: [
          {
            $id: "7",
            id: 12,
            categoryId: 12,
            languageId: 1,
            text: "8th Standard",
            isActive: true,
            status: 1,
            createdBy: "ranjit",
            createdDate: "2021-12-11T07:01:56.44",
            updatedBy: "ranjit",
            updatedDate: "2021-12-11T07:01:56.44",
            category: { $ref: "5" },
            language: null,
          },
        ],
      },
    },
    {
      $id: "8",
      categoryId: 13,
      parentCategoryId: 11,
      providerId: 1000,
      isActive: true,
      owner: "ranjit",
      createdBy: "ranjit",
      createdDate: "2021-12-11T07:02:09.91",
      updatedBy: "ranjit",
      updatedDate: "2021-12-11T07:02:09.91",
      provider: null,
      categoryLanguages: {
        $id: "9",
        $values: [
          {
            $id: "10",
            id: 13,
            categoryId: 13,
            languageId: 1,
            text: "9th Standard",
            isActive: true,
            status: 1,
            createdBy: "ranjit",
            createdDate: "2021-12-11T07:02:09.91",
            updatedBy: "ranjit",
            updatedDate: "2021-12-11T07:02:09.91",
            category: { $ref: "8" },
            language: null,
          },
        ],
      },
    },
    {
      $id: "11",
      categoryId: 14,
      parentCategoryId: null,
      providerId: 1000,
      isActive: true,
      owner: "ranjit",
      createdBy: "ranjit",
      createdDate: "2021-12-12T14:48:11.793",
      updatedBy: "ranjit",
      updatedDate: "2021-12-12T14:48:11.793",
      provider: null,
      categoryLanguages: {
        $id: "12",
        $values: [
          {
            $id: "13",
            id: 14,
            categoryId: 14,
            languageId: 1,
            text: "7th Standard",
            isActive: true,
            status: 1,
            createdBy: "ranjit",
            createdDate: "2021-12-12T14:48:11.793",
            updatedBy: "ranjit",
            updatedDate: "2021-12-12T14:48:11.793",
            category: { $ref: "11" },
            language: null,
          },
        ],
      },
    },
    {
      $id: "14",
      categoryId: 15,
      parentCategoryId: null,
      providerId: 1000,
      isActive: true,
      owner: "ranjit",
      createdBy: "ranjit",
      createdDate: "2021-12-12T14:50:04.18",
      updatedBy: "ranjit",
      updatedDate: "2021-12-12T14:50:04.18",
      provider: null,
      categoryLanguages: {
        $id: "15",
        $values: [
          {
            $id: "16",
            id: 15,
            categoryId: 15,
            languageId: 1,
            text: "10th Standard",
            isActive: true,
            status: 1,
            createdBy: "ranjit",
            createdDate: "2021-12-12T14:50:04.18",
            updatedBy: "ranjit",
            updatedDate: "2021-12-12T14:50:04.18",
            category: { $ref: "14" },
            language: null,
          },
        ],
      },
    },
    {
      $id: "17",
      categoryId: 16,
      parentCategoryId: null,
      providerId: 1000,
      isActive: true,
      owner: "ranjit",
      createdBy: "ranjit",
      createdDate: "2021-12-12T14:52:11.367",
      updatedBy: "ranjit",
      updatedDate: "2021-12-12T14:52:11.367",
      provider: null,
      categoryLanguages: {
        $id: "18",
        $values: [
          {
            $id: "19",
            id: 16,
            categoryId: 16,
            languageId: 1,
            text: "10th Standard",
            isActive: true,
            status: 1,
            createdBy: "ranjit",
            createdDate: "2021-12-12T14:52:11.367",
            updatedBy: "ranjit",
            updatedDate: "2021-12-12T14:52:11.367",
            category: { $ref: "17" },
            language: null,
          },
        ],
      },
    },
    {
      $id: "20",
      categoryId: 17,
      parentCategoryId: null,
      providerId: 1000,
      isActive: true,
      owner: "ranjit",
      createdBy: "ranjit",
      createdDate: "2021-12-12T14:53:10.203",
      updatedBy: "ranjit",
      updatedDate: "2021-12-12T14:53:10.203",
      provider: null,
      categoryLanguages: {
        $id: "21",
        $values: [
          {
            $id: "22",
            id: 17,
            categoryId: 17,
            languageId: 1,
            text: "10th Standard",
            isActive: true,
            status: 1,
            createdBy: "ranjit",
            createdDate: "2021-12-12T14:53:10.203",
            updatedBy: "ranjit",
            updatedDate: "2021-12-12T14:53:10.203",
            category: { $ref: "20" },
            language: null,
          },
        ],
      },
    },
    {
      $id: "23",
      categoryId: 18,
      parentCategoryId: null,
      providerId: 1000,
      isActive: true,
      owner: "ranjit",
      createdBy: "ranjit",
      createdDate: "2021-12-12T14:54:51.993",
      updatedBy: "ranjit",
      updatedDate: "2021-12-12T14:54:51.993",
      provider: null,
      categoryLanguages: {
        $id: "24",
        $values: [
          {
            $id: "25",
            id: 18,
            categoryId: 18,
            languageId: 1,
            text: "10th STandard",
            isActive: true,
            status: 1,
            createdBy: "ranjit",
            createdDate: "2021-12-12T14:54:51.993",
            updatedBy: "ranjit",
            updatedDate: "2021-12-12T14:54:51.993",
            category: { $ref: "23" },
            language: null,
          },
        ],
      },
    },
  ],
};
