import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {configUrl} from "config.js";

export default function useDecreaseQuestionSequence() {
  let queryClient = useQueryClient();
  return useMutation( async(props) => {
      await axios.put(
        `${configUrl.AssessmentServer}/api/PoolQuestionMappings/decreaseSequence/${props.poolId}/${props.questionId}`
      );
    },
    {
      onSuccess: () => {
          console.log('decrese successful')
        queryClient.invalidateQueries("questionsByPoolId");
      },
    }
  );
}
