import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import {configUrl} from "config.js";

export default function useTestLockUnLock() {
  let queryClient = useQueryClient();

  const {user} = useContext(LoginContext)

  return useMutation(
    async ({ id, lock }) => {
      await axios.put(`${configUrl.AssessmentServer}/api/Tests/lockUnlock/${id}/${lock}/${user}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tests");
      },
    }
  );
}


