import axios from "axios";
import { configUrl } from "config.js";

const addTestsIntoCourses = async (courseId, selected) => {

    let selectedIds = selected.map((x) => {
        return {
            courseId: courseId,
            testId: x,
        };
    });
    console.log(selectedIds, 'selectedIds123')
    let response = await axios.post(`${configUrl.AssessmentServer}/api/CourseTestMappings/updateTestsForCourse`, selectedIds)
    console.log(response, 'respin123')
    return response

};

export default {
    addTestsIntoCourses: addTestsIntoCourses
}
