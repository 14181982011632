import axios from "axios";
import { configUrl } from "config.js";
import { useQuery } from "react-query";

export default function useGetAssignedTestsofCourses(courseId) {
    return useQuery(["assignedTests", courseId], async () => {

        console.log('in here', courseId)


        if (!courseId) {
            return null;
        }

        try {
            let response = await axios.get(
                `${configUrl.AssessmentServer}/api/CourseTestMappings/getTestsByCourseId/${courseId}`
            );
            let result = response.data["$values"];

            return result.map((x) => {
                x.id = x.testId;
                return x;
            });
        } catch (e) {
            console.log("error", e);
        }
    });
}
