import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetAllDashboardCounts() {
    return useQuery("assignedCoursesCount", async () => {
        let response = await axios.get(
          `${configUrl.AssessmentServer}/api/Analytics/getStudentCount`
        );
      
        return response.data;
      });
}
