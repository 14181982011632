import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { Tooltip } from "@mui/material";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import { LoginContext } from "contexts/LoginContext";
import useDeleteUser from "hooks/userHooks/useDeleteUser";
import useGetAllUsers from "hooks/userHooks/useGetAllUsers";
import useUpdateUser from "hooks/userHooks/useUpdateUser";
import React, { useContext, useRef } from "react";
import getUserDetailsService from "services/ApplicationUser/getUserDetailsService";
import User from "views/Forms/User";

const useStyles = makeStyles(styles);

export default function ApplicationUserListing(props) {
  const {
    data: users,
    error: userFetchError,
    isLoading: userFetchLoading,
  } = useGetAllUsers();



  const updateUser = useUpdateUser();
  const deleteUser = useDeleteUser();

  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [userEditForm, setUserEditForm] = React.useState(null);
  const [deleteModal, setDeleteModal] = React.useState(null);
  const { user, userPrivileges } = useContext(LoginContext);


  const showDeleteConfirmation = (id) => {
    setDeleteModal(
      <CustomModal
        modal={true}
        setModal={() => hideDeleteCustomModal()}
        content={<h3>Are you sure you want to delete the user?</h3>}
        cancelButtonMessage="Cancel"
        okButtonMessage="Yes"
        showOk={true}
        onCancelClick={() => hideDeleteCustomModal()}
        title="Delete User"
        maxWidth="sm"
        onOkClick={async () => {
          await deleteUser.mutateAsync(id);
          hideDeleteCustomModal();
        }}
        showCancel={true}
        padding1={true}
      />
    );
  };

  const hideDeleteCustomModal = () => {
    setDeleteModal(null);
  };

  const ref = useRef(null);

  const classes = useStyles();

  const onDelete = async (e, id) => {
    showDeleteConfirmation(id);
  };

  const onUpdate = (e, values) => {
    e.preventDefault();

    updateUser.mutate(values.row);
  };

  const onInfo = (e, id) => {
    alert("info");
  };

  const handleRowSelection = (e) => {
    setSelection(e);
  };

  const onCellEditCommit = (p) => { };

  const onCellBlur = (e, p) => { };

  const handleEdit = (p, e) => { };

  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.User.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  React.useEffect(() => {
    let cols = [
      {
        field: "userId",
        headerName: "#",
        width: 90,
        hide: true,
      },
      {
        field: "userName",
        headerName: "User Name",
        editable: false,
        width: 200,
      },
      {
        field: "firstName",
        headerName: "First Name",
        editable: false,
        width: 200,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        editable: false,
        width: 200,
      },
      {
        field: "email",
        headerName: "Email",
        editable: false,
        width: 200,
      },
      {
        field: "mobile",
        headerName: "Mobile No",
        editable: false,
        width: 200,
      },
      {
        field: "roleName",
        headerName: "Role",
        editable: false,
        width: 200,
      },
      {
        field: "actions",
        headerName: "Actions",
        editable: false,
        width: 310,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          return (
            // fillButtons

            <>
              {
                isPrivilege("UpdateUser") == true ?
                  <Tooltip title="Edit">
                    <Button
                      size="sm"
                      justIcon
                      round
                      // color={"info"}
                      className={classes.actionButton}
                      style={{ backgroundColor: "#DD4B39" }}
                      key={1}
                      onClick={(e) =>
                        createUserEditForm(cellValues.row, "Update User")
                      }
                    >
                      <Edit />
                    </Button>
                  </Tooltip> : ''
              }
              {/* {
                isPrivilege("ScheduleUser") == true ?
                  <Tooltip title="Schedule">
                    <Button
                      size="sm"
                      justIcon
                      round
                      // color={"success"}
                      style={{ backgroundColor: "#DD4B39" }}
                      className={classes.actionButton}
                      key={2}
                      onClick={(e) => onUpdate(e, cellValues)}
                    >
                      <UpdateIcon />
                    </Button>
                  </Tooltip> : ''
              } */}
              {
                isPrivilege("CloseUser") == true ?
                  <Tooltip title="Close">
                    <Button
                      size="sm"
                      justIcon
                      round
                      color={"danger"}
                      className={classes.actionButton}
                      key={3}
                      onClick={(e) => onDelete(e, cellValues.row.userId)}
                    >
                      <Close />
                    </Button>
                  </Tooltip> : ''
              }
            </>
          );
        },
      },
    ];

    setColumns(cols);
  }, []);

  React.useEffect(() => {
    if (users) {
      setRows(
        users["$values"]
          .filter((x) => x.user.userTypeId !== 4)
          .map((x, i) => {
            return { ...x.user, id: i, roleName: x.roleName };
          })
      );
    }
  }, [users]);

  const createUserEditForm = async (row, title) => {
    console.log(row, 'rowforID')
    let UserDetails = await getUserDetailsService.getUserDetails(row.userId);
    console.log(UserDetails, 'UserDetails123')

    setUserEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideUserEditForm()}
        content={
          <User
            user={UserDetails?.user ?? row}
            icon={PersonAddIcon}
            afterFinish={() => hideUserEditForm()}
            userType={1}
            roleId={UserDetails?.roleId}
          />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideUserEditForm()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };



  const hideUserEditForm = () => {
    setUserEditForm(null);
  };

  const getRowClassName = (row) => {
    return null;
  };

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <SupervisorAccountIcon />
        </CardIcon>
      </CardHeader>
      <CardBody>
        <GridContainer style={{ marginBottom: 21 }} justifyContent="flex-end" alignItems="flex-end" spacing={2} >

          {/* <GridItem xs={12} sm={12} md={6}>
             <h1>Application Users</h1> 
          </GridItem> */}
          {
            isPrivilege("AddUser") == true ?
              <Button
                color="primary"
                onClick={() => {
                  createUserEditForm({ userTypeId: 2 }, "Add User");
                  //getQ();
                }}
                style={{ marginTop: 20, marginRight: 20, textTransform: "none" }}
              >
                + Add
              </Button> : ''
          }


          {/* {
            isPrivilege("ImportUser") == true ?
              <UserFileUpload userTypeId={2} /> : ''
          } */}

        </GridContainer>

        <div style={{ height: 650, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            {rows.length > 0 ? (
              <div ref={ref} style={{ flexGrow: 1 }}>
                {
                  isPrivilege("ViewUser") == true ?
                    <CustomDataGrid
                      columns={columns}
                      rows={rows}
                      autoPageSize={true}
                      onEditCellPropsChange={handleEdit}
                      onCellEditCommit={onCellEditCommit}
                      onSelectionModelChange={handleRowSelection}
                      getRowClassName={getRowClassName}
                      onCellBlur={onCellBlur}
                    /> : ''
                }
              </div>
            ) : (
              <div style={{ flexGrow: 1 }}>
                <CircularProgress style={{ marginInline: '45%' }} /><h6 style={{ marginInline: '45%' }}> Loading...</h6>
              </div>
            )}
          </div>
        </div>
      </CardBody>
      {updateUser.isError == true && (
        <CustomAlert
          open={true}
          severity="error"
          alertMessage="Error while updating user"
        />
      )}
      {updateUser.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="User Updated successfully"
        />
      )}
      {deleteUser.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="User Deleted successfully"
        />
      )}
      {userEditForm}
      {deleteModal}
    </Card>
  );
}
