import axios from "axios";
import { useMutation } from "react-query";
import {configUrl} from "config.js";

export default function useGetAttempCount() {
  return useMutation(async (payload) => {
    console.log("payload", payload);
    await axios.get(
      `${configUrl.AssessmentServer}/api/TestResults/attemptCount/${payload.userId}/${payload.testId}`,
      result
    );
  });
}
