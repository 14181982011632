//import QuestionComponent from "./QuestionComponent";

import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";

import Box from "@material-ui/core/Box";

// @material-ui/icons

// core components

const WelcomeScreen = ({ message, handleConfirm }) => {
  return (
    <div>
      {/* <Card > */}
      {/* <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <HelpOutlineIcon />
          </CardIcon>
        </CardHeader> */}
      <CardBody style={{ textAlign: 'center', marginTop: '12%' }}>
        <h4 style={{ color: 'black', textAlign: 'center' }}>{message}</h4>
        <Box p={1} justifyContent="center" alignContent="center">
          <Button round color="primary" onClick={handleConfirm}>
            Start
          </Button>
        </Box>
      </CardBody>
      {/* </Card> */}
    </div>
  );
};

export default WelcomeScreen;
