import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetAllUserCount() {
  return useQuery("userCount", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/User/getStudentUsersCount`
    );
    return response.data;
  });
}
