import axios from "axios";
import { configUrl } from "config";

const QuickTestBulkUploadUsers = async (payload) => {
    console.log(payload, 'payloadUsers1234')

    return axios.post(`${configUrl.AssessmentServer}/api/SimplifiedTest/bulkUsers/`, payload)
        .then((response) => {
            console.log(response, 'responseinService')
            return response;
        })
        .catch((error) => {
            console.log(error?.response, "prevError");
            // return 'not Uploaded'
            return {
                messege: error?.response?.data,
                status: error?.response?.status
            }
        });
};
export default {
    QuickTestBulkUploadUsers: QuickTestBulkUploadUsers,
}