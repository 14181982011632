import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import {configUrl} from "config.js";

export default function useDeleteCourseUserMapping(courseId){
    let queryClient = useQueryClient()
    return useMutation(async(userId) => {
        await axios.delete(
            `${configUrl.AssessmentServer}/api/CourseUserMapping/deleteUserMapping/${courseId}/${userId}`
          );
    },{
        onSuccess: () =>{
            queryClient.invalidateQueries(`availableUserByCourseIds${courseId}`)
            queryClient.invalidateQueries(`userByCourseIds${courseId}`)
        }
    })
}