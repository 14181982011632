import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import {configUrl} from "config.js";

export default function useUpdateCourse() {
  const { user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {

      console.log("data",data)


      let course = {
        courseId: data.courseId,
        providerId: providerId,
        code: data.courseCode,
        owner: user,
        createdBy: user,
        createdDate: new Date(),
        updatedBy: user,
        updatedDate: new Date(),
        isActive: true,
        status: data.status ?? 1,
        languageId: 1,
        difficultyLevel: data.difficultyLevel ?? 2,
        expiryDate: data.expiryDate,
        isDynamic: data.isDynamic ?? false,
        duration: data.duration,
        categories: data.selectedCategories ?? data.categories,
        topics: data.selectedTopics?.join(','),
        objective: data.objective === null ? null : JSON.stringify(data.objective),
        targetAudience: data.targetAudience === null ? null : JSON.stringify(data.targetAudience),
        image: data.image
      };

      console.log("courseupdate", course);

      await axios.put(
        `${configUrl.AssessmentServer}/api/courses/${data.courseId}`,
        course
      );

      let language = {
        courseId: data.courseId,
        languageId: 1,
        text: data.text,
        image: "",
        textHtml: "",
        isActive: true,
        status: 1,
        createdBy: user,
        createdDate: new Date(),
        updatedBy: user,
        updatedDate: new Date(),
        description: data.description,
      };

      await axios.put(
        `${configUrl.AssessmentServer}/api/CourseLanguages/${data.courseId}`,
        language
      );

      if (!data.selected) {
        return;
      }

      let selected = data.selected.map((x) => {
        return {
          courseId: data.courseId,
          testId: x,
        };
      });

      if (selected) {
        await axios.post(
          `${configUrl.AssessmentServer}/api/CourseTestMappings/updateTestMapping`,
          selected
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("courses");
      },
    }
  );
}
