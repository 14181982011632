import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";

export default function useIsInterestExists() {
  const queryClient = useQueryClient();

  const { config } = useContext(LoginContext);

  return useQuery(
    // "Interests",
    async (Interests) => {
      let response = await axios.get(`${config.api}api/Interest/isInterestExist/${Interests}`);
      console.log(response)
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Interests");
      },
    }
  );
}
