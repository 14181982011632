import axios from "axios";
import { configUrl } from "config";

const createQuestion = async (payload, topicsId) => {


  return axios.post(`${configUrl.AssessmentServer}/api/Questions?topicId=${topicsId}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error?.response?.data, "prevError");
      return {
        messege: error?.response?.data,
        status: error?.response?.status
      }
    });
};
export default {
  createQuestion: createQuestion,
}