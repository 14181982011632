import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetAssignedTest(userId, testStatus) {
  return useQuery(["assignedTests", userId], async () => {
    

    console.log("userId",userId)
    
    if (!userId) {
      return;
    }

    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Tests/getAssignedTests/${userId},/${testStatus}`
    );

    return response.data["$values"];
  });
}
