import axios from "axios";
import { configUrl } from "config.js";

const createTestCopy = async (testId, testCode, text) => {
  const data = {
    id: testId,
    testCode: testCode,
    testText: text,
  };
  return axios.post(`${configUrl.AssessmentServer}/api/Tests/copyTest`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data, "prevError");
      return {
        messege: error.response.data,
        status: error.response.status
      }
    });

};
export default {
  createTestCopy: createTestCopy,
}
