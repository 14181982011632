

import axios from "axios";
import { configUrl } from "config.js";

const activateTestService = async (testId) => {
  activateQuestions(testId);
  return axios.put(`${configUrl.AssessmentServer}/api/Tests/activate/${testId}`)
    .then((response) => {
      return {
        messege: 'Test saved successfully',
        status: response.status,
      }
    })
    .catch((error) => {
      console.log(error.response.data, "prevError");
      return {
        messege: error.response.data.message,
        status: error.response.status
      }
    });

};
const activateQuestions = async (testId) => {
  return axios.put(`${configUrl.AssessmentServer}/api/SimplifiedTest/activateQuestions/${testId}`)
    .then((response) => {
      return {
        messege: 'Test saved successfully',
        status: response.status,
      }
    })
    .catch((error) => {
      console.log(error.response.data, "prevError");
      return {
        messege: error.response.data.message,
        status: error.response.status
      }
    });

};
export default {
  activateTestService: activateTestService,
}
