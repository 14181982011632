import axios from "axios";
import { configUrl } from "config.js";

const createTask = async (provider, fileName, taskType, taskDetail) => {
  const payload = {
    providerId: provider,
    taskType: taskType,
    taskDetails: taskDetail,
    taskArguments: fileName,
    startDate: new Date(),
    taskStatus: 0,
    createdBy: "ranjit",
    createdDate: new Date(),
    updatedBy: "ranjit",
    updatedDate: new Date()
  };


  let response = await axios.post(`${configUrl.AssessmentServer}/api/TaskManagers`, payload)

  return response.data

};

const updateTask = async (task) => {
  task.taskStatus = 1
  task.updatedBy = "ranjit"
  task.updatedDate = new Date()
  task.endDate = new Date()

  let response = await axios.put(`${configUrl.AssessmentServer}/api/TaskManagers/${task.taskId}`, task)

  return response.data
}


const getAllTasks = async (page, pageSize) => {
  console.log(page, pageSize, 'pagewithPageSize')
  let response = await axios.get(`${configUrl.AssessmentServer}/api/TaskManagers?pageNumber=${page}&pageSize=${pageSize}`)

  console.log(response.data)
  let tasks = response.data["$values"].sort((x, y) => y.taskId - x.taskId)
  console.log(tasks, 'tasks')
  let taskData = tasks.map((x, i) => ({
    ...x,
    id: x.taskId,
  }));
  console.log(taskData, 'tasks1')
  return taskData
}

export default {
  createTask: createTask,
  updateTask: updateTask,
  getAllTasks, getAllTasks
}
