import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import React from "react";


import CategoryIcon from "@material-ui/icons/Category";
import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import CustomModal from "components/CustomModal/CustomModal";
import FileUpload from "components/CustomUpload/FileUpload";
import Tree from "react-animated-tree-v2";
import QuestionBulkUpdate from "views/Components/QuestionBulkUpdate/QuestionBulkUpdate";

const useStylesAlert = makeStyles(styles);

const config = (open) => ({
  from: { height: 0, opacity: 0, transform: "translate3d(20px,0,0)" },
  to: {
    height: open ? "auto" : 0,
    opacity: open ? 1 : 0,
    transform: open ? "translate3d(0px,0,0)" : "translate3d(20px,0,0)",
  },
});

const useStyles = makeStyles({
  root: {
    height: 400,
    flexGrow: 1,
    maxWidth: 800,
  },
});




export default function CustomTreeV2(props) {
  const classes = useStyles();
  const classesAlert = useStylesAlert();
  const [alert, setAlert] = React.useState(null);
  const [topicAlert, setTopicAlert] = React.useState(null);

  const [questionImportModal, setQuestionImportModal] = React.useState(null);
  const [questionUpdateModal, setQuestionUpdateModal] = React.useState(null);

  const [modal, setModal] = React.useState(true);
  const [updateModal, setUpdateModal] = React.useState(true);

  React.useEffect(() => {
    console.log("questionUpdateModal", questionUpdateModal);
  }, [questionUpdateModal]);

  let root = props.rawData ? props.rawData.find((x) => x.parent == null) : null;

  const createHierarchy = (root) => {
    if (!root) return;

    let children = props.rawData.filter((x) => x.parent == root.id);

    if (children && children.length > 0) {
      root.children = [];

      children.forEach((x) => {
        root.children.push(x);
        createHierarchy(x);
      });
    }

    return;
  };

  const canAddTopic = (nodes) => {
    const isParent = props.rawData.some((x) => x.parent == nodes.id);

    if (nodes.questionCount && nodes.questionCount > 0) return;

    if (isParent) {
      const children = props.rawData.filter((x) => x.parent == nodes.id);

      if (children && children.some((x) => x.isCategory == true)) {
        return false;
      }

      return true;
    }

    return true;
  };

  const canUploadQuestions = (nodes) => {
    if (nodes.isCategory == true) return false;
    if (nodes.questionCount && nodes.questionCount > 0) return false;

    const isParent = props.rawData.some((x) => x.parent == nodes.id);

    if (isParent) {
      return false;
    }

    return true;
  };

  const canAddCategory = (nodes) => {
    if (nodes.id.includes("T")) return false;

    const isParent = props.rawData.some((x) => x.parent == nodes.id);

    if (isParent) {
      const children = props.rawData.filter((x) => x.parent == nodes.id);

      if (children && children.some((x) => x.isCategory == false)) {
        return false;
      }

      return true;
    }

    return true;
  };

  createHierarchy(root);

  const renderTree = (nodes) => {
    return (
      <Tree
        icons={{
          closeIcon: "Minus",
        }}
        content={nodes.name}
        type={nodes.isCategory ? "Category" : "Topic"}
        open
        onClick={console.log}
        itemId={nodes.id}
        onItemClick={(itemId) => console.log(itemId)}
        springConfig={config}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </Tree>
    );
  };

  const handleCategoryAlertConfirm = (e, nodes) => {
    props.addCategory({ name: e, parentId: nodes ? nodes.id : null });
    hideAlert();
  };

  const handleTopicAlertConfirm = (e, nodes) => {
    console.log(e);

    let rawNode = props.rawData.find((x) => x.id == nodes.id);
    console.log("rawNode", rawNode);

    props.addTopic({
      name: e,
      parentId: nodes.id,
      categoryId: rawNode.categoryId ?? rawNode.id,
    });
    hideTopicAlert();
  };

  const createCategoryAlert = (nodes) => {
    let desc = nodes ? (
      <>
        <h6>Create Category under Parent Category</h6>
        <h5>{nodes.name}</h5>
      </>
    ) : (
      <>
        <h6>Create Root Category</h6>
      </>
    );
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <InfoArea
            title="Create Category"
            description={desc}
            icon={CategoryIcon}
            iconColor="rose"
          />
        }
        onConfirm={(e) => handleCategoryAlertConfirm(e, nodes)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.info}
        cancelBtnCssClass={classesAlert.button + " " + classesAlert.warning}
      />
    );
  };

  const createTopicAlert = (nodes) => {
    let desc = (
      <>
        <h6>
          Create Topic under Parent{" "}
          {nodes.id.includes("T") ? "Topic" : "Category"}
        </h6>
        <h5>{nodes.name}</h5>
      </>
    );

    setTopicAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <InfoArea
            title="Create Topic"
            description={desc}
            icon={CategoryIcon}
            iconColor="info"
          />
        }
        onConfirm={(e) => handleTopicAlertConfirm(e, nodes)}
        onCancel={() => hideTopicAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.info}
        cancelBtnCssClass={classesAlert.button + " " + classesAlert.warning}
      />
    );
  };

  const createQuestionImportModal = (nodes) => {
    let title = `Upload question file under Topic "${nodes.name}"`;

    setModal(true);
    setQuestionImportModal(
      <CustomModal
        modal={modal}
        padding1={true}
        setModal={() => hideImportQuestionModal()}
        content={
          <FileUpload
            topicId={nodes.id.replace("T", "")}
            callback={() => {
              hideImportQuestionModal();
              props.refresh();
            }}
          />
        }
        cancelButtonMessage="Cancel"
        okButtonMessage="Ok"
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideImportQuestionModal()}
        title={title}
      />
    );
  };

  const createQuestionUpdateModal = (nodes) => {
    let title = `Update question file under topic "${nodes.name}"`;

    setUpdateModal(true);
    setQuestionUpdateModal(
      <CustomModal
        modal={updateModal}
        setModal={() => hideUpdateQuestionModal()}
        content={
          <QuestionBulkUpdate hideHeader={true} topicId={nodes.topicId} />
        }
        cancelButtonMessage="Cancel"
        okButtonMessage="Ok"
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideUpdateQuestionModal()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const hideTopicAlert = () => {
    setTopicAlert(null);
  };

  const hideImportQuestionModal = () => {
    setQuestionImportModal(null);
  };

  const hideUpdateQuestionModal = () => {
    console.log("yo");
    setQuestionUpdateModal(null);
  };

  return (
    <>
      {alert}
      {topicAlert}
      {questionImportModal}
      {questionUpdateModal}
      {props.rawData ? (
        props.rawData.length ? (
          <>
            <h3>Category/Topic Tree</h3>
            {renderTree(root)}
          </>
        ) : null
      ) : (
        <div>
          <h4>Create your first Category</h4>
          <Button color="rose" style={{ textTransform: 'none' }} onClick={() => createCategoryAlert()}>
            Create Category
          </Button>
        </div>
      )}
    </>
  );
}
