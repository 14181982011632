import React, { useContext } from "react";

// @material-ui/core components
import { CircularProgress } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { LoginContext } from "contexts/LoginContext";
import CryptoJS from 'crypto-js';
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ForgotPassService from "services/ForgotPassService";

const useStyles = makeStyles(styles);

function ForgotPassword(props) {
    const { userId, reqDateTime } = useParams();
    console.log(userId, reqDateTime, 'userIdfromUrl')
    // const [loginUsername, setLoginUsername] = React.useState("");
    const [showAlert, setShowAlert] = React.useState(false);
    const [severty, setSeverty] = React.useState("success")
    const [alertMessage, setAlertMessage] = React.useState("");
    // const [intialLoad, setInitialLoad] = React.useState(true);
    // const [shouldFetch, setShouldFetch] = React.useState(false);
    // const [signIn, setSignIn] = React.useState(true);
    const [checkMandatory, setCheckMandatory] = React.useState(false);
    const [isLoadingPass, setIsLoading] = React.useState(false);
    const [expirationTime, setExpirationTime] = React.useState(null);
    const [isExpired, setIsExpired] = React.useState(false);
    React.useEffect(() => {
        const url = location.pathname;
        // Spliting URL by '/'
        const segments = url.split('/');
        console.log(segments, 'segments')
        // Removing first two elements (indexes 0 and 1)
        const restOfParams = segments.slice(3);
        console.log(restOfParams, 'respParams')
        // Join the remaining segments back into a string
        const restOfParamsString = restOfParams.join('/');

        console.log(restOfParamsString, 'lastParams1')
        const givenTime = decryptPassword(restOfParamsString, secretKey);
        const parsedTime = new Date(givenTime);
        // adding expiring hours can replace accordingly
        let exprireHours = 24;
        // Adding 1 hour to the parsed time
        parsedTime.setHours(parsedTime.getHours() + exprireHours);
        setExpirationTime(parsedTime);
        console.log(givenTime, 'givenTime')
        console.log(parsedTime, 'parsedTime123')
        // Check if link is expired
        const interval = setInterval(() => {
            const currentTime = new Date();
            setIsExpired(currentTime > parsedTime);
        }, 1000); // Check every second

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);

    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const { setUser, setUserId, setUserPrivileges, setProviderId, setProviderType, setUserType, setContentProviderIds } = useContext(LoginContext);

    const [loginPassword, setLoginPassword] = React.useState("");
    const [passwordSame, setPasswordSame] = React.useState(true);
    const [rePassWord, setRePassWord] = React.useState("");

    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });
    const classes = useStyles();

    const key = 'pj23vs7nycq18uew';
    let secretKey = CryptoJS.enc.Utf8.parse(key);

    const encryptPassword = (password, secretKey) => {

        let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let encryptedString = encryptedBytes.toString();

        console.log(encryptedString, 'encryptedone');
        return encryptedString;
        // return CryptoJS.AES.encrypt(password, secretKey).toString();
    }
    const decryptPassword = (encryptedPassword, secretKey) => {

        let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

        console.log(decryptedText, 'decryptedone');
        return decryptedText;
        // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
        // return bytes.toString(CryptoJS.enc.Utf8);
    }
    const handlePasswordChange = (e) => {
        setLoginPassword(e.target.value);
        if (rePassWord != e.target.value) {
            setPasswordSame(false);
        } else {
            setPasswordSame(true);
        }
    };

    // const handleFirstNameChange = (e) => {
    //     setFirstName(e.target.value);
    // };

    // const handleLastNameChange = (e) => {
    //     setLastName(e.target.value);
    // };

    // const handleMobileChange = (e) => {
    //     setMobile(e.target.value);
    // };

    // const handleEmailChange = (e) => {
    //     setEmail(e.target.value);
    // };

    const handleRePasswordChange = (e) => {
        setRePassWord(e.target.value);
        if (loginPassword != e.target.value) {
            setPasswordSame(false);
        } else {
            setPasswordSame(true);
        }
    };

    // React.useEffect(() => {
    //     setInitialLoad(false);
    // }, []);
    // const findPrivilege = async (userId) => {
    //   const Privilege = await getPrivileges.getPrivileges(userId)
    //   setUserPrivileges(Privilege);
    // }

    // React.useEffect(async () => {

    //     if (!loginDetails?.userDetails) {
    //         return;
    //     }

    //     if (signIn == false) {
    //         if (loginDetails?.userDetails && loginDetails?.userDetails.userName) {
    //             setUserExists(true);
    //         } else {
    //             setUserExists(false);
    //         }

    //         return;
    //     }
    //     console.log(loginDetails, 'userDetails123');

    //     if (
    //         loginDetails?.userDetails &&
    //         loginDetails?.userDetails.userName == loginUsername &&
    //         loginDetails?.userDetails.passCode == loginPassword
    //     ) {
    //         //
    //         //findPrivilege(loginDetails?.userDetails?.userId);
    //         let privileges = loginDetails?.privilegeObject ? JSON.parse(loginDetails?.privilegeObject) : "";

    //         setUserPrivileges(privileges?.Value);
    //         setUser(loginUsername);
    //         setUserId(loginDetails?.userDetails?.userId);
    //         setProviderId(loginDetails?.userDetails.providerId);
    //         setProviderType(loginDetails?.userDetails.providerTypeId);
    //         setContentProviderIds(loginDetails?.userDetails.contentProviderIds["$values"]);
    //         setUserType(loginDetails?.userDetails.userTypeId);

    //         axios.defaults.headers.common = {
    //             providerId: loginDetails?.userDetails.providerId,
    //             contentProviderIds: loginDetails?.userDetails.contentProviderIds["$values"]
    //         };

    //         props.history.push("/admin");
    //     } else {
    //         setRetry(false);
    //         setShowAlert(true);
    //     }
    // }, [loginDetails]);

    // React.useEffect(() => {
    //     if (userFetchError && signIn == true) {
    //         console.log("in here");
    //         setShowAlert(true);
    //     }
    //     if (userFetchError && signIn == false) {
    //         setUserExists(false);
    //     }
    // }, [userFetchError]);

    // const handleSubmit = async () => {
    //     if (!loginUsername) {
    //         return;
    //     }

    //     getUserDetails(loginUsername);
    //     //setRetry(true);
    // };

    const handleResetPass = async () => {
        if (
            // !firstName ||
            // !loginUsername ||
            // !lastName ||
            // !mobile ||
            // !email ||
            !loginPassword ||
            !rePassWord
        ) {
            setCheckMandatory(true);
            return;
        }

        if (passwordSame == false) {
            return;
        }
        setIsLoading(true);
        let ForgotPasswordResp = await ForgotPassService.ForgotPassService(loginPassword, rePassWord, userId)
        setIsLoading(false)
        console.log(ForgotPasswordResp, 'forgotPassResp')
        if (ForgotPasswordResp.status == 200 || ForgotPasswordResp.status == 201) {
            setShowAlert(true)
            setSeverty("success")
            setAlertMessage(ForgotPasswordResp.data)
            setTimeout(() => {
                props.history.push("/auth")
            }, 3000);
        } else {
            setShowAlert(true)
            setSeverty("error")
            setAlertMessage(ForgotPasswordResp.messege)
        }
        // setUser(loginUsername);
        // setProviderId(1000);
        // setUserType(2);

        // axios.defaults.headers.common = {
        //     providerId: 1000,
        // };

        // createUser.mutate({
        //     userName: loginUsername,
        //     firstName: firstName,
        //     lastName: lastName,
        //     middleName: " ",
        //     mobile: mobile,
        //     email: email,
        //     passCode: loginPassword,
        //     address: " ",
        //     isActive: true,
        //     userTypeId: 2,
        //     topics: topics.join(",")
        // });
    };

    // React.useEffect(() => {
    //     if (createUser.isSuccess == true) {
    //         props.history.push("/admin");
    //     }
    // }, [createUser.isSuccess]);

    // React.useEffect(() => {
    //     if (createUser.error == true) {
    //         setShowAlert(true);
    //     }
    // }, [createUser.error]);

    const [passwordType, setPasswordType] = React.useState("password");
    const [ConfirmPasswordType, setConfirmPasswordType] = React.useState("password");
    const togglePassword = (e) => {
        if (e == "Pass") {
            if (passwordType === "password") {
                setPasswordType("text")
                return;
            }
            else {
                setPasswordType("password")
                return;
            }
        } else {
            if (ConfirmPasswordType === "password") {
                setConfirmPasswordType("text")
                return;
            }
            else {
                setConfirmPasswordType("password")
                return;
            }
        }
    }
    return (
        <div className={classes.container} style={{ marginTop: '10%' }}>
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={6} md={4}>

                    <form autoComplete="off">
                        <Card
                            // login
                            className={classes[cardAnimaton]}
                        // style={{ width: 500 }}
                        >
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color={isExpired ? "" : "rose"}
                            >
                                {isExpired ?
                                    <h4 style={{ color: 'red' }} className={classes.cardTitle}>Link is expired !</h4>
                                    :
                                    <h4 className={classes.cardTitle}>Reset Password</h4>
                                }
                                {/* <h4 className={classes.cardTitle}>Reset Password</h4> */}
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Password"
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    required={true}
                                    error={checkMandatory && !loginPassword}
                                    disabled={isExpired}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}
                                                    onClick={() => togglePassword("Pass")}>
                                                    {passwordType === "password" ? <VisibilityOff /> : <Visibility />}
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: passwordType,
                                        autoComplete: "off",
                                        onChange: handlePasswordChange,
                                    }}
                                />

                                <CustomInput
                                    labelText="Confirm Password"
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    helperText="Password does not match"
                                    required={true}
                                    error={
                                        passwordSame == false   // || (checkMandatory && !rePassWord)
                                    }
                                    disabled={isExpired}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}
                                                    onClick={() => togglePassword("ConfirmPass")}>
                                                    {ConfirmPasswordType === "password" ? <VisibilityOff /> : <Visibility />}
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: ConfirmPasswordType,
                                        autoComplete: "off",
                                        onChange: handleRePasswordChange,
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                {isLoadingPass == true ? (
                                    <CircularProgress
                                        color="secondary"
                                        style={{ size: "1.5rem" }}
                                    />
                                ) : (
                                    <Button
                                        color="rose"
                                        simple
                                        size="lg"
                                        block
                                        onClick={handleResetPass}
                                        style={{ textTransform: 'none' }}
                                        disabled={isExpired}
                                    >
                                        Update
                                    </Button>
                                )}
                            </CardFooter>
                            <CardFooter
                                className={classes.justifyContentCenter}
                                style={{ textTransform: "none" }}
                            >
                                Back to  &nbsp;{" "}
                                <a href="#" onClick={() => props.history.push("/auth")}>
                                    {" "}
                                    Sign In
                                </a>
                            </CardFooter>
                        </Card>
                    </form>

                    <CustomAlert
                        open={showAlert}
                        severity={severty}
                        alertMessage={alertMessage}
                        handleClose={() => setShowAlert(false)}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default ForgotPassword;
