import { InputLabel, Snackbar, TextField } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import axios from 'axios';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { configUrl } from 'config';
import { useEffect, useState } from 'react';
import simplifiedTestDetailsService from 'services/SimplifiedTestService/simplifiedTestDetailsService';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function ScheduleTest(props) {
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [severity, setSeverity] = useState("success");
    const [isScheduling, setIsScheduling] = useState(false);
    const [assignDateValue, setAssignDateValue] = useState(new Date());
    const [scheduleStartDateValue, setScheduleDateValue] = useState(new Date());
    const [scheduleEndDateValue, setScheduleEndDateValue] = useState(new Date());

    useEffect(async () => {
        let simplifiedTestScheduleData;
        if (props.testId) {
            simplifiedTestScheduleData = await simplifiedTestDetailsService.getSimplifiedTesScheduletData(props.testId);
            console.log(simplifiedTestScheduleData, 'simplifiedTestScheduleData');
        }
        if (props.testData && props.testData.startDate !== null && props.testData.endDate !== null && props.testData.scheduleDate !== null) {

            // // existing scheduledStart date assigning
            // let startDateTime = new Date(simplifiedTestScheduleData.scheduleStartDateTime);
            // let assignedDateTime = new Date(startDateTime);
            // setScheduleDateValue(assignedDateTime.toISOString().slice(0, 16));
            setScheduleDateValue(props.testData.startDate)

            // // existing scheduledEnd date assigning
            // let exisingStartDate = new Date(simplifiedTestScheduleData.scheduleEndDateTime);
            // let endDateTime = new Date(exisingStartDate);
            // setScheduleEndDateValue(endDateTime.toISOString().slice(0, 16));
            setScheduleEndDateValue(props.testData.endDate)

            // // existing ScheduledAssigned date assigning
            // let exisingAssignDate = new Date(simplifiedTestScheduleData.assignDateTime);
            let assignDateTime = new Date(props.testData.scheduleDate);
            // console.log(assignDateTime.toISOString(), 'assignExistingTime')
            // setAssignDateValue(assignDateTime.toISOString().slice(0, 10));
            setAssignDateValue(props.testData.scheduleDate.slice(0, 10))
        }
    }, [])


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };
    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };
    const handleChange = (e) => {
        if (e.target.name == "assignDateValue") {
            setAssignDateValue(e.target.value);
        }
    };

    const handleStartDateChange = (event) => {
        const newStartDate = event.target.value;
        console.log(newStartDate, 'startDateTime')
        setScheduleDateValue(newStartDate);

        // Automatically set the assigned date to be 48 hours before the start date
        const startDateTime = new Date(newStartDate);
        const assignedDateTime = new Date(startDateTime.getTime() - 48 * 60 * 60 * 1000);
        setAssignDateValue(assignedDateTime.toISOString().slice(0, 10)); // Format as "YYYY-MM-DDTHH:mm"
    };

    const handleEndDateChange = (event) => {
        setScheduleEndDateValue(event.target.value);
    };



    const updateSchedule = async () => {
        let response;
        const currentDateTime = new Date();
        const startDateTime = new Date(scheduleStartDateValue);
        const endDateTime = new Date(scheduleEndDateValue);
        const assignedDateTime = new Date(assignDateValue);
        console.log(props.testId, 'testIdValidation')

        if (!props.testId) {
            showSnackBar('Need to create test for test schedule', "error");
            return;
        }

        // Check if the assigned date is 48 hours before the start date
        if (startDateTime - assignedDateTime < 48 * 60 * 60 * 1000) {
            showSnackBar('Assigned date must be 48 hours before the start date.', 'error');
            return;
        }

        console.log(startDateTime, 'startDateTime', currentDateTime, 'currentDateTime');
        console.log(startDateTime - currentDateTime, 'startDateTimecurrentDatetime', 48 * 60 * 60 * 1000, '48hours future');
        console.log(startDateTime - currentDateTime <= 48 * 60 * 60 * 1000, 'currentDateTime');

        if (startDateTime - currentDateTime <= 46 * 60 * 60 * 1000) {
            showSnackBar('Start date must be at least 48 hours in the future.', "error");
            return;
        }
        // Check if the end date is at least 15 minutes after the start date
        if (endDateTime - startDateTime < 15 * 60 * 1000) {
            showSnackBar('End date must be at least 15 minutes after the start date.', 'error');
            return;
        }
        setIsScheduling(true);
        response = await axios.put(`${configUrl.AssessmentServer}/api/Tests/updateSchedule/${props.testId}`, {
            scheduleStartDateTime: scheduleStartDateValue,
            scheduleEndDateTime: scheduleEndDateValue,
            assignDateTime: assignDateValue
        })
        setIsScheduling(false);

        if (response.status == 200 || response.status == 204) {
            showSnackBar("Test scheduled successfully")
            props.setTab(3)
        }
        // setTimeout(() => {
        //     afterFinish()
        // }, 2000);

    }
    const handlePrevious = () => {
        props.setTab(1)
    }
    return (

        <GridContainer alignItems="flex-end">
            <GridItem sm={12} md={12} lg={12}>
                <fieldset style={{ border: 'none', boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', }}>
                    <legend>
                        <InputLabel shrink><h4><b>Test Window</b></h4></InputLabel>
                    </legend>
                    <GridContainer>
                        <GridItem sm={12} md={6} lg={6}>
                            <TextField
                                type="datetime-local"
                                name="scheduleStartDateValue"
                                value={scheduleStartDateValue}
                                onChange={handleStartDateChange}
                                label="Start DateTime"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ borderColor: "primary", marginLeft: 15 }}
                                disabled={isScheduling == true ? true : false}
                            />
                        </GridItem>
                        <GridItem sm={12} md={6} lg={6}>
                            <TextField
                                type="datetime-local"
                                name="scheduleEndDateValue"
                                value={scheduleEndDateValue}
                                onChange={handleEndDateChange}
                                label="End DateTime"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ borderColor: "primary" }}
                                disabled={isScheduling == true ? true : false}

                            />
                        </GridItem>
                        <GridItem sm={12} md={6} lg={6}>
                            <TextField
                                type="date"
                                name="assignDateValue"
                                value={assignDateValue}
                                onChange={handleChange}
                                label="Assign Date Time"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ borderColor: "primary", marginTop: 20, marginBottom: 20, marginLeft: 15 }}
                                disabled={isScheduling == true ? true : false}
                            />
                        </GridItem>
                    </GridContainer>
                </fieldset>
            </GridItem>

            <GridItem sm={12} md={12} lg={12}>
                <Button color="primary" round small disabled={isScheduling == true} style={{ marginTop: '15px', float: 'right' }} onClick={updateSchedule}>
                    {isScheduling == true ? 'Saving...' : 'Save & Next'}
                </Button>
                <Button round small color="primary" onClick={() => handlePrevious()} style={{ textTransform: 'none', marginTop: '15px', marginRight: '7px', float: 'right' }}>
                    {
                        // saving ? 'Saving...' :
                        'Previous'
                    }
                </Button>
            </GridItem>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </GridContainer>
    )
}
