import axios from "axios";
import { configUrl } from "config.js";

const getTestUserMappingByTestId = async (testId, pageNumber, pageSize) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/TestUserMappings/${testId}/${pageNumber}/${pageSize}`
    );
    let data = response.data["$values"].map((x, i) => {
        return { ...x, id: i };
    });
    return data;
};
const getAvailableUserForTest = async (Id, pageNumber, pageSize) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/User/availableForTest/${Id}/${pageNumber}/${pageSize}`
    );
    let users = response.data["$values"].map((x, i) => {
        return { ...x, id: x.userId };
    });
    return users;
};


export default {
    getTestUserMappingByTestId: getTestUserMappingByTestId,
    getAvailableUserForTest: getAvailableUserForTest
}
