import { useState } from 'react';

import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import axios from "axios";
import Card from 'components/Card/Card';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { configUrl } from "config";
import UseGetAnalytics2D from 'hooks/analyticHooks/UseGetAnalytics2D';
import fileDownload from "js-file-download";
import styles from '../../Styles/DetailingTable.module.css';
import AnalyticsCharts from './AnalyticsCharts';
import AnalyticsTable from './AnalyticsTable';
import ChartTable from './ChartTable';

export default function Analytics({ totalTestScore, lOid, studentId }) {

  const [typeId, setTypeId] = useState(1);
  const [subTypeId, setSubTypeId] = useState(1);

  const { data } = UseGetAnalytics2D(typeId, subTypeId, lOid);

  const handleContentChange = (event) => {
    setTypeId(event.target.value);
  };

  const handleExecutionChange = (event) => {
    setSubTypeId(event.target.value);


  };
  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  function download() {
    console.log("Here");
    axios.get(`${configUrl.AssessmentServer}/api/MentorAnalytics/ExportExcel?lOId=` + lOid, {
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, "ResultDetails.xlsx");
    });
  }
  return (
    <GridContainer style={{ padding: '10px 50px', width: 'calc(100% + 16px)' }}>
      <GridItem xs={12} sm={12} md={5} lg={3}>
        <h4 className={styles.tablefont} style={{ color: '#DE2668' }}>Objective - <span style={{ color: 'gray' }}>{data ? data.objective.length == 0 ? <i>none</i> : data.objective : null}</span></h4>
      </GridItem>
      <GridItem xs={12} sm={12} md={5} lg={3}>
        <h4 className={styles.tablefont} style={{ color: '#DE2668' }}>Target Audience - <span style={{ color: 'gray' }}>{data ? data.targetAudience.length == 0 ? <i>none</i> : data.targetAudience : null}</span></h4>
      </GridItem>
      <GridItem xs={12} sm={12} md={5} lg={3}>
        <h4 className={styles.tablefont} style={{ color: '#DE2668' }}>Total Marks - <span style={{ color: 'gray' }}>{totalTestScore ? totalTestScore : <i>none</i>}</span></h4>
      </GridItem>
      <GridItem xs={12} sm={12} md={2} lg={3} style={{ textAlign: "right" }}>
        <Button
          color="primary"
          onClick={download}
          style={{ marginTop: 20, textTransform: "none" }}
        >
          Export To Excel
        </Button>
      </GridItem>
      {/* Chart  */}
      <GridItem xs={12} sm={12} md={5} lg={5}>
        <Card style={{ cursor: "pointer" }}>
          <AnalyticsCharts
            lOid={lOid}
          />
        </Card>
      </GridItem>
      {/* chart Table */}
      <GridItem xs={12} sm={12} md={7} lg={7}>
        <ChartTable
          lOid={lOid}
        />
      </GridItem>

      {/* analytics Table */}
      <GridItem xs={12} sm={12} md={11} lg={11}>
        <Box sx={{ minWidth: 100 }}>
          <FormControl style={{ width: "47%" }}>
            <InputLabel className={styles.tablefont} style={{ color: '#DE2668' }} id="demo-simple-select">Content</InputLabel>
            <Select
              className={styles.tablefont}
              labelId="demo-simple-select"
              id="demo-simple-select"
              value={typeId}
              label="Content"
              onChange={handleContentChange}
            >
              <MenuItem value={1}>Category Subject</MenuItem>
              <MenuItem value={2}>Dificulty Level</MenuItem>
              <MenuItem value={3}>Question Type</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ float: 'right', width: "47%" }}>
            <InputLabel className={styles.tablefont} style={{ color: '#DE2668' }} id="demo-simple-select-label">Execution </InputLabel>
            <Select
              className={styles.tablefont}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={subTypeId}
              label="Execution"
              onChange={handleExecutionChange}
            >
              <MenuItem value={1}>Score </MenuItem>
              <MenuItem value={2}>Attempt Rate </MenuItem>
              <MenuItem value={3}>Accuracy Rate </MenuItem>
              <MenuItem value={4}>Duration <small style={{ marginInline: '1%' }}> (Sec.)</small> </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Card style={{ cursor: "pointer" }}>
          <AnalyticsTable
            analyticsData={data && data.analyticsResult ? isJSON(data.analyticsResult) ? JSON.parse(data.analyticsResult) : '' : ''}
          />
        </Card>
      </GridItem>
    </GridContainer>
  )
}




