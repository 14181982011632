import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Wizard from "components/Wizard/Wizard.js";



import CustomAlert from "components/CustomAlert/CustomAlert.js";
import { useCreateTopicwiseTest } from "hooks/topicwisetestHooks/useCreateTopicwiseTest.js";
import { withRouter } from "react-router";
import TopicwiseSelectTopics from "./TopicwiseWizardSteps/TopicwiseSelectTopics.js";
import TopicwiseTestConfiguration from "./TopicwiseWizardSteps/TopicwiseTestConfiguration.js";
import TopicwiseTestTopics from "./TopicwiseWizardSteps/TopicwiseTestTopics.js";

function TopicwiseTestWizard(props, ref) {

  // const { mutate: createTopicwiseTest } = useCreateTopicwiseTest();

  let state = null;

  const [courseId, setCourseId] = React.useState(state ? state.courseId : null);
  const [details, setDetails] = React.useState({ ...state });
  const [flag, setFlag] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(null)
  const [Loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log(props, "dataObject")
  }, [props, ref]);

  const onNextClick = (data) => {
    let mergedData = { ...details, ...data };
    console.log("mergedDataC", mergedData)
    setDetails(mergedData);

    console.log("onnextmerged Data", mergedData);
    console.log("onnextData", data);
    console.log("onnextDetails", details);
  }

  const submitTest = async (data) => {
    setLoading(true);
    console.log("Test2352", data);
    data.TestConfiguration.objective = data.TestConfiguration.objective != null ? JSON.stringify(data.TestConfiguration.objective) : null;
    data.TestConfiguration.targetAudience = data.TestConfiguration.targetAudience != null ? JSON.stringify(data.TestConfiguration.targetAudience) : null;
    data.TestConfiguration.navigationAllowed = data.TestConfiguration.navigationAllowed == true ? 2 : 1;
    data.TestConfiguration.showHint = data.TestConfiguration.showHint == true ? 2 : 1;
    data.TestConfiguration.showResult = data.TestConfiguration.showResult == true ? 2 : 1;
    data.TestConfiguration.showTimer = data.TestConfiguration.showTimer == true ? 2 : 1;

    let mergedData = { ...details, ...data };
    let poolconfiguration = mergedData?.PoolConfiguration;
    let testconfiguration = mergedData?.TestConfiguration;
    let topics = mergedData?.Topics;
    let postmergedData = { topics, poolconfiguration, testconfiguration }
    console.log("onsavemerged Data", postmergedData);
    const postPayload = await useCreateTopicwiseTest(postmergedData);

    console.log("postPayload", postPayload)
    if (postPayload.status == 200) {
      setShowAlert(
        postPayload.data == "" ?
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            // onOk={"/admin/testhome"}
            severity="success"
            alertMessage={postPayload.data == "" ? "Tests created successfully." : postPayload.data.message + "and test already created"}
          />
          :
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            // onOk={"/admin/testhome"}
            severity="error"
            alertMessage={postPayload.data.message}
          />
      )
      setTimeout(() => {
        props.history.push("/admin/testhome");
      }, 2000);
      setLoading(false);
    } else {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // onOk={"/admin/tests"}
          severity="error"
          alertMessage="Error occured."
        />
      )
      setTimeout(() => {
        props.history.push("/admin/testhome");
      }, 2000);
      setLoading(false);
    }
    // props.history.push("/admin/testhome");
  };

  const updateTestAsDraft = async () => {
    setShowAlert(
      <CustomAlert
        open={true}
        openFlag={() => setFlag(flag ? false : true)}
        // onOk={"/admin/testhome"}
        severity="success"
        alertMessage="Test Created in Draft."
      />
    )
    setTimeout(() => {
      props.history.push("/admin/testhome");
    }, 2000);

    /*  if (props.afterFinish) {
      props.afterFinish();
    } */
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Wizard
          validate
          steps={[
            {
              stepName: "Select Topics",
              stepComponent: TopicwiseSelectTopics,
              stepId: "Topics",
              st: { ...state },
            },
            {
              stepName: "Section Configuration",
              stepComponent: TopicwiseTestTopics,
              stepId: "PoolConfiguration",
              st: { courseId: courseId },
            },
            {
              stepName: "Test Configuration",
              stepComponent: TopicwiseTestConfiguration,
              stepId: "TestConfiguration",
              st: { ...state },
            },
          ]}
          hideTitle={props.hideTitle}
          title={"Topicwise Test"}
          subtitle=""
          finishButtonClick={submitTest}
          onNextClick={onNextClick}
          showDraftButton={false}
          draftButtonClick={updateTestAsDraft}
          finishButtonText={Loading == true ? "Creating Tests..." : "Create Tests"}
          isLoading={Loading}
          draftButtonText="Save as Draft"
          routeKey="testhome"
        />
      </GridItem>
      {showAlert}
    </GridContainer>
  );
}

export default withRouter(TopicwiseTestWizard);
