import axios from "axios";
import { configUrl } from "config.js";

const generateQuestion = async (courseId, scheduleStartDateValue, scheduleEndDateValue, assignDateValue) => {
  return axios.put(`${configUrl.AssessmentServer}/api/Courses/assignCourseTests/${courseId}`, {
    scheduleStartDateTime: scheduleStartDateValue,
    scheduleEndDateTime: scheduleEndDateValue,
    assignDateTime: assignDateValue
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error?.response?.data, "prevError");
      return {
        messege: error?.response?.data,
        status: error?.response?.status
      }
    });

};
export default {
  generateQuestion: generateQuestion,
}
