import axios from "axios";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation } from "react-query";

export default function useQuickTestBulkUploadQuestions() {
    const { user, providerId } = useContext(LoginContext);

    return useMutation(async ({ tempFormData }) => {
        if (tempFormData) {
            // payload = payload.map((x) => {
            //   return { ...x, createdBy: user, providerId: providerId };
            // });
            // console.log("PayloadHook", payload)

            let response = await axios.post(
                `${configUrl.AssessmentServer}/api/SimplifiedTest/uploadExcelQuestions`,
                tempFormData
            );
            console.log(response, 'resp of excel')
            let uploadDetails = response.data;

            if (!uploadDetails) {
                throw 'Not Found'
            }

            //console.log("bulkQuestions",response);
            return response.data;
        }
    });
}
