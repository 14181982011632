import axios from "axios";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useAddTopic() {
  const queryClient = useQueryClient();
  const { user, providerId } = useContext(LoginContext);

  return useMutation(
    async ({ name, parentId, categoryId }) => {
      console.log(name, parentId, categoryId);

      let parentTopicId = parentId.includes("T")
        ? parentId.replace("T", "")
        : null;

      const response = await axios.post(
        `${configUrl.AssessmentServer}/api/Topics`,
        {
          parentTopicId: parentTopicId,
          categoryId: categoryId,
          providerId: providerId,
          isActive: true,
          owner: user,
          createdBy: user,
          createdDate: new Date(),
          updatedBy: user,
          updatedDate: new Date(),
          topicLanguages: [
            {
              languageId: 1,
              text: name,
              isActive: true,
              status: 1,
              createdBy: user,
              createdDate: new Date(),
              updatedBy: user,
              updatedDate: new Date(),
            },
          ],
        }
      );

      return response.data; // Return the response data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("categories");
      },
    }
  );
}
