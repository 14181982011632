import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useUpdateConfiguration() {

  const { config } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (configurationPayload) => {
      console.log(configurationPayload)
      let demographicInformation = {
        id: configurationPayload.row.id,
        providerId: configurationPayload.row.providerId,
        key: configurationPayload.row.key,
        sequenceId: configurationPayload.row.sequenceId,
        valueInt: configurationPayload.row.valueInt,
        valueString: configurationPayload.row.valueString,
        isDefault: configurationPayload.row.isDefault,
        isActive: configurationPayload.row.isActive,
        createdBy: configurationPayload.row.createdBy,
        createdDate: configurationPayload.row.createdDate,
        updatedBy: configurationPayload.row.updatedBy,
        updatedDate: configurationPayload.row.updatedDate
      }
      console.log(demographicInformation, 'demographicInformation123');
      await axios.put(`${config.api}/api/DemoghraphicInformation/${demographicInformation.id}`, demographicInformation);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DemoghraphicInformations");
      },
      onError: () => {
        queryClient.invalidateQueries("DemoghraphicInformations");
      }
    }

  );
}
