import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetAvailableUserForCourse(courseId) {
  return useQuery([`availableUserByCourseIds${courseId}`, courseId], async () => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/User/availableForCourse/${courseId}`
      );

      let users = response.data["$values"].map((x, i) => {
        return { ...x, id: x.userId };
      });

      return users
  });
}
