import { InputLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useGetAllInterests from "hooks/interestsHooks/useGetAllInterests";
import useGetNotificationsById from "hooks/notificationHooks/useGetNotificationsById";
import useCreateNotification from "hooks/notificationHooks/useNotification";
import useUpdateNotification from "hooks/notificationHooks/useUpdateNotification";
import React from "react";
//import useGetAllActiveInterest from "hooks/interestsHooks/useGetAllActiveInterest";
// import Switch from "@material-ui/core/Switch";

import { FormControlLabel } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MessageIcon from "@material-ui/icons/Message";
import MuiAlert from "@material-ui/lab/Alert";
import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { allowedExtensions, re } from "config";
import useCreateUserNotificationMapping from "hooks/notificationHooks/useCreateUserNotificationMapping";
import useGetUserByUserName from "hooks/userHooks/useGetUserByUserName";
// import Button from '@material-ui/core/Button';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Notifications(props) {
  const {
    data: userDetails,
    isLoading,
    mutate: getUserDetails,
    error: userFetchError,
  } = useGetUserByUserName();

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
    // +
    // ' ' +
    // [
    //   padTo2Digits(date.getHours()),
    //   padTo2Digits(date.getMinutes()),
    //   padTo2Digits(date.getSeconds()),
    // ].join(':')
  }
  const [model, setModel] = React.useState({
    notificationId: 0,
    sendDateTime:
      formatDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)) +
      "T00:00:00",
    title: "",
    body: "",
    broadcast: false,
    isWhatsAppNotification: false,
    isEmailNotification: false,
    isAppNotification: false,
    isPushNotification: false,
    userId: 0,
    createdBy: "",
    createdDate: new Date(),
    updatedBy: " ",
    updatedDate: new Date(),
  });
  console.log(allowedExtensions);
  console.log(model.sendDateTime);
  const [file, setFile] = React.useState([]);
  console.log(file);
  const [filenames, setFileNames] = React.useState([]);
  console.log(filenames);
  const [open, setOpen] = React.useState(false);
  const [error1, setError1] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setError1(false);
  };

  const showSnackBar = () => {
    setOpen(true);
  };
  const showErrorSnackBar = () => {
    setError1(true);
  };

  // console.log(filename)
  // const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
  //   birthdayImage
  // );
  // const [eventData, setMessageTemplate] = React.useState(props.data);
  const intrests = useGetAllInterests();
  console.log("props", props);

  const updateNotification = useUpdateNotification();
  console.log(updateNotification.isSuccess);
  const createnotification = useCreateNotification();
  console.log(createnotification.isSuccess);
  const { data: getnotification } = useGetNotificationsById(props.noti);
  console.log(getnotification);
  // if (getnotification) {
  //   console.log(getnotification)
  //   console.log(getnotification.notification)
  //   console.log(getnotification.interestList["$values"])
  // }
  const createUserNotifiaction = useCreateUserNotificationMapping();
  const getallinterest = useGetAllInterests();
  const {
    data: interests,
    error: interestsFetchError,
    isLoading: interestsFetchLoading,
  } = useGetAllInterests();
  React.useEffect(() => {
    if (interests) {
      setInterest(
        interests["$values"].map((x, i) => {
          return { ...x, id: i };
        })
      );
    }
  }, [interests]);
  React.useEffect(async () => {
    if (props.noti != null) {
      if (getnotification) {
        let a = await getnotification;
        setModel(getnotification.notification);
        // setModel(getnotification.notification.broadcast="isAllSelected")
        // isAllSelected=getnotification.notification.broadcast
        let c = [];
        Interest.map((a) => {
          // d.push(a.interestId)
          c.push(a.interestId);
        });

        console.log(c);
        if (getnotification.notification.broadcast) {
          setInterestInfo(c);
        } else {
          setInterestInfo(getnotification.interestList["$values"]);
        }

        setFileNames(getnotification.fileNames["$values"]);
        const tempData = new FormData();
        // data.forEach(element => {
        //   tempData.append('file', element[0]);

        //   console.log(element)
        // });
        // tempData.append('file',getnotification.fileNames["$values"]);
        // console.log(tempData)
        // let data=[]

        // for(let i = 0;i < getnotification.fileNames["$values"].length; i++)
        // {
        //        console.log(getnotification.fileNames["$values"])
        //        data.push(getnotification.fileNames["$values"])
        // }
        // console.log(data)
        // setFile([...file,data])
      }
    }
  }, [getnotification]);
  // const {
  //   data: notification,
  //   isLoading,
  //   mutate: notification,
  //   error: notificationFetchError,
  // } = useGetAllNotifications();

  const [Interest, setInterest] = React.useState([]);
  const [extension, setExtesion] = React.useState("");
  console.log(extension);
  const [interestList, setInterestInfo] = React.useState(
    []
    // isCheackAll:false
  );

  console.log("Interest", Interest);
  let d = [];
  let c = [];
  Interest.map((a) => {
    d.push(a.interestId);
    c.push(a.interestId);
  });
  // console.log(Interest)
  // model.broadcast=    d.length > 0 && interestList.length === d.length;
  // if(model.broadcast)
  // {
  //   interestList.push(d)

  // }
  const isAllSelected = d.length > 0 && interestList.length === d.length;

  console.log(isAllSelected);
  const onhandlecheckChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    let c = [];
    Interest.map((a) => {
      c.push(a.interestId);
    });
    console.log(value);
    if (event.target.checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      console.log(name);
      if (name === "broadcast") {
        setInterestInfo(interestList.length === c.length ? [] : c);
        return;
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setInterestInfo([...interestList, parseInt(value)]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "broadcast") {
        setInterestInfo([]);
      } else {
        setInterestInfo(interestList.filter((el) => el !== parseInt(value)));
      }
    }
  };
  // const onhandlecheckChange = (e) => {
  //   if (e.target.checked) {

  //     setInterestInfo( [...interestinfo, parseInt(e.target.value)] )

  //   }
  //   else {
  //     setInterestInfo([...interestinfo.filter(el => el !== parseInt(e.target.value))])

  //   }
  //   console.log(interestinfo)
  // }
  const handleChange = (e) => {
    console.log(e);
    let { name, value } = e.target;
    if (name == "isPushNotification") {
      if (value) {
        model.sendDateTime =
          formatDate(new Date(new Date().getTime())) +
          "T00:00:00";
      }
      else {
        model.sendDateTime =
          formatDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)) +
          "T00:00:00";
      }
    }

    console.log(value);

    if (name == "broadcast") {
      setInterestInfo({ interestList: [] });
    }

    setModel({ ...model, [name]: value });
  };

  let fileInput = React.createRef();

  const deleteFile = (index) => {
    console.log(index);
    let tempFile = fileIn[index];
    let l = fileIn.filter((el) => el !== tempFile);
    console.log(l);
    setFileIn(l);
  };
  const formgefiledetete = (index) => {
    console.log(index);
    let tempFileName = filenames[index];
    let u = filenames.filter((el) => el !== tempFileName);
    setFileNames(u);
    console.log(u);
  };
  const [formData, setFormDate] = React.useState(new FormData());
  const [seleccionArchivo, setSeleccionArchivo] = React.useState(null);
  const [fileIn, setFileIn] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(null)
  const [flag, setFlag] = React.useState(false)

  // console.log(fileIn)
  let filelengthValidate = fileIn.length + filenames.length;

  console.log(filelengthValidate);
  const handleImageChange = (e) => {
    console.log(e.target.files[0].name);
    const file = e.target.files[0].name;
    const up = [];
    const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

    // console.log(filelengthValidate.length);
    if (!re.exec(e.target.files[0].name)) {
      setErrorMessage(
        "This File Extension Is Not Supported!. Suported One Are As Follow-: jpg,jpeg,gif,png,pdf,mp4,mov,wmv,avi,xlsx,.xls,pptx,ppt,docx,doc. "
      );
      showErrorSnackBar();
      return;
    }
    // else if (fileIn.length > 3) {
    //   setErrorMessage("Please upload  less than 4 files.");
    //   showErrorSnackBar();
    //   return;
    // }
    else if (e.target.files[0].size > maxSize) {
      setErrorMessage("Please upload file less than 10 MB. ");
      showErrorSnackBar();
      return;
    } else if (filelengthValidate >= 4) {
      setErrorMessage("Please do not upload more than 4 files.");
      showErrorSnackBar();
      return;
    } else if (
      fileIn.find((e) => e.name === file) ||
      filenames.find((e) => e === file)
    ) {
      setErrorMessage("Dublicate file is not Attached.");
      showErrorSnackBar();
      return;
    } else {
      setFileIn([...fileIn, e.target.files[0]]);
      console.log(fileIn);
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.value = null;
    fileInput.current.click();

    // props.refresh()
  };
  React.useEffect(() => {
    if (updateNotification.isSuccess) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="success"
          alertMessage={'Notification updated successfully'}
        />
      )
      setTimeout(() => {
        console.log("Delayed for 1 second.");
        props.afterFinish();
      }, 2000);
    }
  }, [updateNotification.isSuccess]);

  React.useEffect(() => {
    if (createnotification.isSuccess) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="success"
          alertMessage={'Notification created successfully'}
        />
      )
      setTimeout(() => {
        console.log("Delayed for 1 second.");
        props.afterFinish();
      }, 2000);
    }
  }, [createnotification.isSuccess]);
  return (
    <>
      <form autoComplete="off">
        <GridItem xs={12}>
          <Card style={{ paddingLeft: 10, paddingRight: 10 }}>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MessageIcon />
              </CardIcon>
            </CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <Card
                // style={{ padding: 5 }}
                >
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={<span>Message Title</span>}
                      id="title"
                      success={model.title}
                      error={!model.title}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      required={true}
                      inputProps={{
                        name: "title",
                        onChange: handleChange,
                        value: model.title,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={<span>Message</span>}
                      id="body"
                      success={model.body}
                      error={!model.body}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      required={true}
                      inputProps={{
                        rows: 3,
                        multiline: true,
                        name: "body",
                        onChange: handleChange,
                        value: model.body,
                      }}
                    />
                  </GridItem>

                  {/* </div> */}

                  <GridItem xs={12}>
                    {
                      <Card style={{ padding: 5 }}>
                        <InputLabel
                          style={{ marginTop: -25, marginLeft: 15 }}
                          shrink
                        >
                          <h4>
                            <b>Interest</b>
                          </h4>
                        </InputLabel>

                        <FormGroup row>
                          <FormControlLabel
                            style={{ marginTop: 2 }}
                            control={
                              <Checkbox
                                type="checkbox"
                                // onChange={allcheacks}
                                // value={x.interestId}
                                name="broadcast"
                                value={model.broadcast}
                                onChange={onhandlecheckChange}
                                checked={isAllSelected}
                              />
                            }
                            label={<small>Select All Interest</small>}
                          />
                        </FormGroup>

                        <GridContainer
                          style={{ marginLeft: 2 }}
                          justify-items={"center"}
                        >
                          <br />
                          {Interest.map((x, i) => {
                            if (x.isActive == true) {
                              return (
                                <>
                                  <FormGroup row key={x.interestId}>
                                    <FormControlLabel
                                      style={{ marginTop: 2 }}
                                      control={
                                        <Checkbox
                                          type="checkbox"
                                          value={x.interestId}
                                          onChange={onhandlecheckChange}
                                          checked={interestList.includes(
                                            x.interestId
                                          )}

                                        // checked={cheackall}
                                        // onChange={onhandlecheckChange}
                                        // value={x.interestId}
                                        />
                                      }
                                      label={x.interests}
                                    />
                                  </FormGroup>
                                </>
                              );
                            }

                          })}
                        </GridContainer>
                      </Card>
                    }
                  </GridItem>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                {/* <GridItem xs={12} > */}
                <Card style={{ paddingLeft: 10, paddingTop: 5 }}>
                  <InputLabel style={{ marginTop: -25, marginLeft: 15 }} shrink>
                    <h4>
                      <b>Notification Types</b>
                    </h4>
                  </InputLabel>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            value={model.isAppNotification}
                            onChange={(e) => {
                              let val = {
                                target: {
                                  name: "isAppNotification",
                                  value: !model.isAppNotification,
                                },
                              };
                              handleChange(val);
                            }}
                            checked={model.isAppNotification}
                          />
                        }
                        label={<small>App</small>}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <br /> */}
                      <FormControlLabel
                        control={
                          <Switch
                            value={model.isWhatsAppNotification}
                            onChange={(e) => {
                              let val = {
                                target: {
                                  name: "isWhatsAppNotification",
                                  value: !model.isWhatsAppNotification,
                                },
                              };
                              handleChange(val);
                            }}
                            checked={model.isWhatsAppNotification}
                          />
                        }
                        label={<small>WhatsApp</small>}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <br /> */}
                      <FormControlLabel
                        control={
                          <Switch
                            value={model.isPushNotification}
                            onChange={(e) => {
                              let val = {
                                target: {
                                  name: "isPushNotification",
                                  value: !model.isPushNotification,
                                },
                              };
                              handleChange(val);
                            }}
                            checked={model.isPushNotification}
                          />
                        }
                        label={<small>Notification</small>}
                      />
                    </GridItem>
                  </GridContainer>

                  {/* </>
                   }}
                 /> */}
                </Card>
                <Card style={{ paddingLeft: 15 }}>
                  <CardBody>
                    <GridContainer>
                      <InputLabel
                        style={{
                          marginTop: 28,
                          // marginLeft: 10,
                          borderColor: "grey.500",
                        }}
                        shrink
                      >
                        <h4>
                          <b>Schedule Date:</b>
                        </h4>
                      </InputLabel>

                      {/* <GridItem xs={12} sm={12} md={4}> */}
                      <CustomInput
                        // labelText={<span>Send Date</span>}
                        // id="sendDateTime"
                        formControlProps={{
                          fullWidth: true,
                          disabled: model.isPushNotification
                        }}
                        // required={true}
                        error={model.isPushNotification}
                        helperText={"Schedule Date Is Not Editable for Push Notification!."}
                        inputProps={{
                          // id:"component-disabled",
                          type: "dateTime-local",
                          // type:"date",
                          min: "2020-10-10",
                          name: "sendDateTime",
                          value: model.sendDateTime,
                          onChange: handleChange,
                        }}
                      />
                      {/* </GridItem> */}
                    </GridContainer>
                  </CardBody>
                </Card>
                <Card style={{ paddingLeft: 70 }}>
                  <GridItem xs={12}>
                    <Tooltip
                      id="uploadImageToolTip"
                      title="Upload Attachment"
                      placement="recurring"
                    // classes={{ tooltip: classes.tooltip
                    // }}
                    >
                      <Button color="rose" onClick={(e) => handleClick(e)}>
                        <CloudUploadIcon
                        //  className={classes.underChartIcons}
                        />
                        Upload Attachment
                      </Button>
                    </Tooltip>
                    <input
                      type="file"
                      multiple={true}
                      onChange={handleImageChange}
                      ref={fileInput}
                      style={{ display: "none" }}
                    />
                  </GridItem>
                  {/* <br /> */}

                  <GridItem xs={12}>
                    {fileIn.map((item, index) => {
                      console.log(item.name);
                      console.log(index);
                      return (
                        <GridContainer>
                          <GridItem>
                            <h6>{item.name} </h6>
                          </GridItem>
                          <GridItem>
                            {/* <span    
                            > */}
                            <HighlightOffIcon
                              onClick={() => {
                                deleteFile(index);
                              }}
                            />
                            {/* </HighlightOffIcon> */}
                            {/* </span> */}
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                  </GridItem>
                  <GridItem xs={12}>
                    {filenames.map((item, index) => {
                      console.log(item);
                      console.log(index);
                      return (
                        <GridContainer>
                          <GridItem>
                            <h6>{item} </h6>
                          </GridItem>
                          <GridItem>
                            <HighlightOffIcon
                              onClick={() => {
                                formgefiledetete(index);
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                  </GridItem>
                </Card>
              </GridItem>
              {/* <GridContainer spacing={2}></GridContainer> */}
              <GridItem xs={12} sm={6} md={4}></GridItem>
              <GridItem xs={12} sm={6} md={4}></GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={4}

              // spacing={2}
              // style={{ textAlign: "right" }}
              >
                <Button
                  color="rose"
                  style={{ marginLeft: 110, marginBottom: 20, textTransform: 'none' }}
                  onClick={() => {
                    // let filelengthValidate = file.length + filenames.length;
                    // find(element => element > 10);
                    console.log(filelengthValidate);
                    let errorText = "";
                    if (filelengthValidate > 4) {
                      errorText =
                        errorText + "please do not select more than 4 file.";
                    }
                    if (model.title === "") {
                      errorText = errorText + "Please enter title.";
                    }
                    if (model.body === "") {
                      errorText = errorText + "Please enter message.";
                    }
                    if (file.length > 3) {
                      errorText =
                        errorText +
                        "Please add minmum three attachment files...";
                    }
                    if (interestList.length < 1 && model.broadcast == false) {
                      errorText =
                        errorText + "Please select at least one interest...";
                    }
                    if (
                      model.isAppNotification == false &&
                      model.isWhatsAppNotification == false &&
                      model.isPushNotification == false
                    ) {
                      errorText =
                        errorText +
                        "Please select at least one notification...";
                    }
                    // if (allowedExtensions == ) {
                    //   errorText = errorText + "Please select at least one notification..."
                    // }
                    if (errorText != "") {
                      setErrorMessage(errorText);
                      showErrorSnackBar();

                      return;
                    } else {
                      if (!model.notificationId) {
                        let notification;
                        notification = { ...model, broadcast: isAllSelected };
                        console.log(notification.broadcast);
                        let a;
                        console.log(file);
                        let reader = new FileReader();
                        const tempFormData = new FormData();
                        console.log(fileIn);
                        // reader.onload=()=>{
                        fileIn.forEach((element) => {
                          tempFormData.append("file", element);

                          console.log(element);
                          // return;
                        });
                        // }
                        // setSeleccionArchivo(tempFormData)
                        setFormDate(tempFormData);
                        console.log(tempFormData);
                        console.log(formData);
                        if (notification.broadcast) {
                          console.log(formData);
                          a = { interestList: [], notification, fileName: [] };
                          console.log(a);
                          // createnotification.mutate(a);
                        } else {
                          a = {
                            interestList: interestList,
                            notification,
                            fileName: [],
                          };
                          console.log(a);
                        }

                        createnotification.mutate({
                          notification: a,
                          fData: tempFormData,
                        });
                      } else {
                        let notification;
                        notification = { ...model, broadcast: isAllSelected };
                        console.log(notification.broadcast);
                        let a;
                        console.log(file);
                        const tempFormData = new FormData();
                        console.log(fileIn);
                        // reader.onload=()=>{
                        fileIn.forEach((element) => {
                          tempFormData.append("file", element);

                          console.log(element);
                          // return;
                        });
                        setFormDate(tempFormData);
                        console.log(tempFormData);
                        console.log(formData);
                        if (isAllSelected) {
                          console.log(formData);
                          a = {
                            interestList: [],
                            notification,
                            fileName: filenames,
                          };
                          console.log(a);
                          // createnotification.mutate(a);
                        } else {
                          a = {
                            interestList: interestList,
                            notification,
                            fileName: filenames,
                          };
                          console.log(a);
                        }
                        updateNotification.mutate({
                          notification: a,
                          fData: tempFormData,
                        });
                      }
                    }
                  }}
                  disabled={!model.title || !model.body || updateNotification.isLoading || createnotification.isLoading}
                >
                  {model.notificationId ? updateNotification.isLoading ? "Updating..." : "Update" : createnotification.isLoading ? "Adding..." : "  Add"}
                </Button>
                {/* <span></span>
                <span></span> */}
                &nbsp;
                <Button
                  color="rose"
                  onClick={props.afterFinish}
                  style={{ marginBottom: 20, textTransform: 'none' }}
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>

        {/* </GridContainer> */}
        {updateNotification.isError && (
          <CustomAlert
            open={true}
            severity="error"
            alertMessage="Notification update failed"
          />
        )}
        {/* {updateNotification.isSuccess && (
          <CustomAlert
            open={true}
            severity="success"
            alertMessage="Notification Updated Successfully"
          />
        )} */}

        {/* {createnotification.isSuccess && (
          <CustomAlert
            open={true}
            severity="success"
            alertMessage="Notification created Successfully"
          />
        )} */}
        {createnotification.isError && (
          <CustomAlert
            open={true}
            severity="error"
            alertMessage="Notification creation failed"
          />
        )}
        {/* <CustomAlert
          open={}
          severity="error"
          alertMessage="Notification creation failed"
        /> */}
        {/* {createnotification.isSuccess &&(
        <Snackbar
          open={true}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="succuss">
            Successfully save
          </Alert>
        </Snackbar>)} */}
        {showAlert}
        <Snackbar
          open={error1}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        {/* <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={handleClose} severity="success">
              Orgniazation Data updated successfully
            </Alert>
          </Snackbar> */}
      </form>
    </>
  );
}

export default Notifications;
