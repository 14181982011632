import React from "react";
// @material-ui/icons


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import DropDownSelect from "components/DropDownSelect/DropDownSelect";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

const TopicwiseSelectTopics = React.forwardRef((props, ref) => {
  const classes = useStyles();


  const [topicIds, setTopicIds] = React.useState([]);

  console.log("topicIds", topicIds);
  const isValidated = () => {
    if (topicIds.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      topicIds
    },
  }));
  const sendState = () => {
    return {
      type: "topics",
      topicIds
    };
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={11}>
          <small style={{ color: 'red' }}>Note: Category is mandatory and only child nodes are selectable.</small>

        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={11}>
          <DropDownSelect setTopics={setTopicIds} isContentProvider={true} showCategoriesOnly={false} disableParentSelection={true} />
        </GridItem>
      </GridContainer>
    </div>
  );
});

TopicwiseSelectTopics.displayName = "Step1";

export default TopicwiseSelectTopics;
