import axios from "axios";
import { configUrl } from "config.js";
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import Latex from "react-latex-next";

const getMappedQuestions = (questionData) => {
    let questions = questionData.reduce((acc, curr) => {
        console.log(curr, 'curr12345')
        let choices = curr.questionChoices["$values"][0];
        let languages = curr.questionLanguages["$values"][0];

        let question = {};
        question["id"] = curr.questionId;
        question["code"] = curr.code;

        // let QuestionType = "";

        // for (let k in questionType) {
        //   if (questionType[k] == curr.QuestionType) {
        //     QuestionType = k;
        //     break;
        //   }
        // }

        question["text"] = curr.text != null || curr.text?.length !== 0 ? (curr.questionType == 1 && curr.contentType == 2 ? languages.text : curr.contentType == 3 ? <Latex>{languages.text}</Latex> : curr.contentType == 4 ? ReactHtmlParser(languages.text) : languages.text) : null;
        question["questionType"] = curr.questionType;
        question["providerName"] = curr.provider.name;
        question["contentType"] = curr.contentType;
        question["choice1"] = choices.choiceId01;
        question["choice2"] = choices.choiceId02;
        question["choice3"] = choices.choiceId03;
        question["choice4"] = choices.choiceId04;
        question["choice5"] = choices.choiceId05;
        question["choice6"] = choices.choiceId06;
        // question["choice1"] = curr?.contentType == 1 && curr?.contentType == 2 ? choices?.choiceId01 : curr?.contentType == 3 ?<Latex>{choices?.choiceId01}</Latex> : curr?.contentType == 4 ? ReactHtmlParser(choices?.choiceId01) : choices?.choiceId01;
        // question["choice2"] = curr?.contentType == 1 && curr?.contentType == 2 ? choices?.choiceId02 : curr?.contentType == 3 ?<Latex>{choices?.choiceId02}</Latex> : curr?.contentType == 4 ? ReactHtmlParser(choices?.choiceId02) : choices?.choiceId02;
        // question["choice3"] = curr?.contentType == 1 && curr?.contentType == 2 ? choices?.choiceId03 : curr?.contentType == 3 ?<Latex>{choices?.choiceId03}</Latex> : curr?.contentType == 4 ? ReactHtmlParser(choices?.choiceId03) : choices?.choiceId03;
        // question["choice4"] = curr?.contentType == 1 && curr?.contentType == 2 ? choices?.choiceId04 : curr?.contentType == 3 ?<Latex>{choices?.choiceId04}</Latex> : curr?.contentType == 4 ? ReactHtmlParser(choices?.choiceId04) : choices?.choiceId04;
        // question["choice5"] = curr?.contentType == 1 && curr?.contentType == 2 ? choices?.choiceId05 : curr?.contentType == 3 ?<Latex>{choices?.choiceId05}</Latex> : curr?.contentType == 4 ? ReactHtmlParser(choices?.choiceId05) : choices?.choiceId05;
        // question["choice6"] = curr?.contentType == 1 && curr?.contentType == 2 ? choices?.choiceId06 : curr?.contentType == 3 ?<Latex>{choices?.choiceId06}</Latex> : curr?.contentType == 4 ? ReactHtmlParser(choices?.choiceId06) : choices?.choiceId06;
        question["answer"] = choices.choiceIdAnswers;
        question["image"] = languages.image;
        question["hint"] = languages.hint;
        question["points"] = curr.points;
        question["owner"] = curr.owner;

        question["status"] = curr.status;
        question["isLocked"] = curr.isLocked;
        question["lockedBy"] = curr.lockedBy;
        question["isActive"] = curr.isActive;
        question["difficultyLevel"] = curr.difficultyLevel;

        question["passFeedback"] = languages.passFeedback ?? "";
        question["failFeedback"] = languages.failFeedback ?? "";

        question["expiryDate"] = curr.expiryDate;

        acc.push(question);

        return acc;
    }, []);

    return questions;
};

const questionByTopicsService = async (selectedTopics, poolId, objective, targetAudience, readyToFetch, difficultyLevel, points, code, contentType, questionType, page, pageSize) => {

    console.log(selectedTopics, poolId, objective, targetAudience, readyToFetch, difficultyLevel, points, code, contentType, questionType, page, pageSize, "data4");

    const questionObj = {};
    let response = await axios.post(
        `${configUrl.AssessmentServer}/api/TopicQuestionMappings/questionsByTopics/${page}/${pageSize}/${poolId}/${objective}/${targetAudience}?difficultyLevel=${difficultyLevel}&points=${points}&code=${code}&contentType=${contentType}&questionType=${questionType}`,
        selectedTopics);

    console.log(response.data, "respon45");
    let questionData = response.data.filteredQuestions["$values"];

    console.log(questionData, "questiondata");
    let questions = getMappedQuestions(questionData);
    questionObj.getDetails = questions;
    questionObj.totalCount = response.data.totalCount;
    return questionObj;
};
export default {
    questionByTopicsService: questionByTopicsService,
}


