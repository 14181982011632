import axios from "axios";
import { configUrl } from "config.js";

const generateTests = async (testId, scheduleStartDateValue, scheduleEndDateValue, assignDateValue) => {
  console.log(scheduleStartDateValue, scheduleEndDateValue, assignDateValue, 'hooksassignedservice')
  return axios.put(`${configUrl.AssessmentServer}/api/Tests/generateTests/${testId}`, {
    scheduleStartDateTime: scheduleStartDateValue,
    scheduleEndDateTime: scheduleEndDateValue,
    assignDateTime: assignDateValue
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error?.response?.data, "prevError");
      return {
        messege: error?.response?.data,
        status: error?.response?.status
      }
    });

};
export default {
  generateTests: generateTests,
}
