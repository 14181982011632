import React from "react";
//import QuestionComponent from "./QuestionComponent";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Question from "components/Questions/Question";

// @material-ui/icons

// core components
import CustomSelect from "components/CustomSelect/CustomSelect";
import useGetTestResults from "hooks/resultHooks/useGetTestResults";

const EvaluationQuestionWrapper = ({ userId, testId, isCourse, handleSubmit }) => {
  const { data } = useGetTestResults(userId, testId, isCourse);

  const [questions, setQuestions] = React.useState(null);
  const [attempts, setAttempts] = React.useState(null);

  const [actualScore, setActualScore] = React.useState(null);
  const [totalScore, setTotalScore] = React.useState(null);

  React.useEffect(() => {
    console.log("ResultData", data);
    if (data && data["$values"]) {
      let attempOptions = [];

      for (let i = 1; i <= data["$values"].length; i++) {
        attempOptions.push(i);
      }

      setAttempts([...attempOptions]);

      for (let i = 0; i < data["$values"].length; i++) {
        console.log("Q", JSON.parse(data["$values"][i].attemptAnswers).flat());
      }

      getQuestions(1)
    }
  }, [data]);

  const getQuestions = (attempt) => {
    let qs = data["$values"].filter((x) => x.attemptNumber == attempt);

    setActualScore(qs[0].actualScore);
    setTotalScore(qs[0].totalScore);
    console.log("qs", qs);
    setQuestions(JSON.parse(qs[0].attemptAnswers).flat());
  };

  return (
    <div>
      <GridContainer justifyContent="center">
        <GridItem xs={11} sm={11} md={4}>
          {attempts && attempts.length > 0 && (
            <CustomSelect
              options={attempts.map((x) => ({ id: x, name: x }))}
              value={1}
              setvalue={(v) => {
                console.log(v);
                getQuestions(v);
              }}
              label="Select Attempt"
            />
          )}
        </GridItem>
        <GridItem xs={11} sm={11} md={6}></GridItem>

        {actualScore && totalScore && (
          <GridItem xs={11} sm={11} md={10}>
            <h2>Score {actualScore}/{totalScore}</h2>
          </GridItem>
        )}

        <GridItem xs={11} sm={11} md={10}>
          {questions && (
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <HelpOutlineIcon />
                </CardIcon>
              </CardHeader>
              <CardBody>
                {questions &&
                  questions.length > 0 &&
                  questions.map((x, i) => (
                    <Question
                      key={i}
                      question={{ ...x, isSubmitted: true }}
                      updateQuestion={() => { }}
                      handleSubmit={handleSubmit}
                    />
                  ))}
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default EvaluationQuestionWrapper;
