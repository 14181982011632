import axios from "axios";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation } from "react-query";

export default function useQuestionBulkImport() {
  const { user, providerId } = useContext(LoginContext);

  return useMutation(async ({ tempFormData }) => {
    console.log("questionObj2", Object.fromEntries(tempFormData))

    let response = await axios.post(
      `${configUrl.AssessmentServer}/api/Questions/SyncQuestions/`,
      tempFormData
    );

    let uploadDetails = response.data;

    if (!uploadDetails) {
      throw 'Not Found'
    }

    console.log("bulkQuestions", response);
    return response.data;
  }
  );
}
