import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery } from "react-query";

export default function useGetAllNotifications() {

  const { config,providerId } = useContext(LoginContext);

  return useQuery(
    "Notifications",
    async () => {
      let response = await axios.get(`${config.api}/api/Notification/getNotificationByproviderId/${providerId}`);
      console.log(response)
      return response.data;
    },
    // {
    //   staleTime: 1000 * 60 * 5,
    // }
  );
}
