import { useQuery } from "react-query";
import axios from "axios";
import { configUrl } from "../../config";


export default function UseGetAnalytics2D(typeId,subTypeId, lOid) {
  return useQuery([`testsByUser${lOid}`, typeId,subTypeId, lOid], async () => {
      console.log('in here',typeId,subTypeId, lOid)
    if (!typeId ||!subTypeId || !lOid ) {
      return;
    }
    let result = await axios.get(
      `${configUrl.AssessmentServer}/api/MentorAnalytics/getAnalytics/${typeId}/${subTypeId}/${lOid}`
    );
       console.log("2Danay",result.data)

    return result.data;
});
}
