import { CircularProgress, Tooltip } from "@material-ui/core";
import { ClearOutlined, SearchOutlined, WarningOutlined } from "@material-ui/icons";
import Language from "@material-ui/icons/Language";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import { LoginContext } from "contexts/LoginContext";
import React, { useContext, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import axios from "axios";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { configUrl } from "config.js";

import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import useGetCategories from "hooks/categoryHooks/useGetCategories";
import usePoolQuestionBulkInsert from "hooks/poolQuestionHooks/usePoolQuestionBulkInsert";
import useGetAllTopics from "hooks/topicQuestionMapping/useGetAllTopics";
import 'katex/dist/katex.min.css';
import DropdownTreeSelect from "react-dropdown-tree-select";
import questionByTopicsService from "services/QuestionService/questionByTopicsService.js";
import sweetAlertStyles from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  ...sweetAlertStyles,
  tooltip: {
    fontSize: "1em",
    backgroundColor: 'white'
  },
}));
const TreeDropDown = React.memo(
  ({ data, onChange, className }) => {
    return (
      <>
        <DropdownTreeSelect
          data={data}
          onChange={onChange}
          className={className}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);

export default function PoolQuestionMappingGrid(props) {
  const classes = useStyles();
  const { data: topics, isLoading } = useGetAllTopics();
  const { data: categories } = useGetCategories(true);
  let difficultyLevel = 0;
  let points = 0;
  let code = null;
  let contentType = 0;
  let questionType = 0;
  // const [multipleSelect, setMultipleSelect] = React.useState(props.state.topics?.split(',').map(Number));
  const [readyToFetch, setReadyToFetch] = React.useState(false);

  const { user, providerId } = useContext(LoginContext);
  const [model, setModel] = React.useState(props.state);
  const [isLodingT2Pool, setIsLoadingT2Pool] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");
  const [contentFilter, setContentFilter] = React.useState('0')
  const [categoryTree, setCategoryTree] = React.useState('');
  const [selectedTopics, setSelectedTopics] = React.useState(props.state.topics?.split(',').map(Number));
  const [alert, setAlert] = React.useState(null);

  const [bulkOptions, setBulkOptions] = React.useState([]);
  const [reloadGrid, setReloadGrid] = React.useState(false);
  const [isLoadingService, setIsLoadingService] = React.useState(false);
  const [bulkValue, setBulkValue] = React.useState("");
  const [bulkOption, setBulkOption] = React.useState({});
  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  const [data1, seData] = React.useState(
    [
      {
        id: 56,
        code: "Code20",
        text: "Question 20 : question Prove that the real part of all non-trivial zeros of the function\n\\(\\zeta(z)\\) is \\(\\frac{1}{2}\\)",
        status: 2,
        questionType: 1,
        contentType: 1,
        difficultyLevel: 3
      },
      {
        id: 61,
        code: "Code27",
        text: "Question 27 : \\question Prove that the real part of all non-trivial zeros of the function\n\\(\\zeta(z)\\) is \\(\\frac{1}{2}\\)",
        questionType: 3,
        contentType: 1,
        difficultyLevel: 3,
        status: 2
      },
      {
        id: 62,
        code: "Code28",
        text: "Question28 : \\question Prove that the real part of all non-trivial zeros of the function\n\\(\\zeta(z)\\) is \\(\\frac{1}{2}\\)",
        questionType: 3,
        contentType: 1,
        difficultyLevel: 3,
        status: 2
      },
    ]
  )

  const getTopicIds = (node, topics) => {
    console.log(node, 'categoryis111')
    const children = categories.filter((x) => x.parent == node.id);
    // if (!children || children.length == 0) {
    topics.push(node.topicId);
    // }

    if (children && children.length > 0) {
      children.forEach((y) => {
        getTopicIds(y, topics);
      });
    }

  };
  const setTopicIds = (root, tops) => {

    const children = categories.filter((x) => x.parent == root.id);
    // if (!children || children.length == 0) {
    if (tops.includes(root.topicId)) {
      root.checked = true;
    }
    else {
      root.checked = false;
    }
    // }

    if (children && children.length > 0) {
      children.forEach((y) => {
        setTopicIds(y, tops);
      });
    }

  };
  const createHierarchy = (root) => {
    if (!root) return;

    root.label = root.name;
    root.value = root.id;
    if (props.state.topics == undefined) {
      root.checked = false;
    }
    // commented code for multiple category topic hierarchy added.
    // start
    // let children = [...categories].map(x => { return { ...x } }).filter(
    //   (x) => x.parent == root.id
    // );
    // end
    let children = [...categories].filter((x) => x.parent == root.id);


    if (children && children.length > 0) {

      root.children = [];

      children.forEach((x) => {
        x.value = x.id;
        x.label = x.name;
        root.children.push(x);
        createHierarchy(x);
      });
    }
    return;
  };

  React.useEffect(() => {
    if (categories) {
      console.log(categories, 'categories123')
      // let root = [...categories].map(x => { return { ...x } }).find((x) => x.parent == null);
      // console.log(root, 'root123')
      let root = [...categories].filter((x) => x.parent == null && x.isCategory == true);
      console.log(root, 'root1234')
      createHierarchy(root);
      if (props.state.topics) {
        const tops = props.state.topics.split(",").map(Number);
        setTopicIds(root, tops);
      }
      setCategoryTree(root)
    }
  }, [categories]);

  console.log(bulkOption.name, 'bulkOption123')
  console.log(bulkValue, 'bulkValue123')
  console.log(selectedTopics, 'selectedTopics123')

  if (bulkOption.name == 'Difficulty Level') {
    difficultyLevel = bulkValue;
    console.log(difficultyLevel, 'difficultyLevel124')

  }
  if (bulkOption.name == 'Code') {
    code = bulkValue;
  }
  if (bulkOption.name == 'Content Type') {
    contentType = bulkValue;
  }
  if (bulkOption.name == 'Question Type') {
    questionType = bulkValue;
  }




  // const { data, isLoading: isQuestionLoading } = useGetQuestionsByTopicIds(
  //   selectedTopics,

  //   props.poolId,
  //   props.objective,
  //   props.targetAudience,
  //   readyToFetch,
  //   difficultyLevel,
  //   points,
  //   code,
  //   contentType,
  //   questionType
  // );

  const fetchData = async (readyToFetch, page, pageSize) => {
    setPageState(old => ({ ...old, isLoading: true }))
    let questionObj = await questionByTopicsService.questionByTopicsService(
      selectedTopics,
      props.poolId,
      props.objective,
      props.targetAudience,
      readyToFetch,
      difficultyLevel,
      points,
      code,
      contentType,
      questionType,
      page,
      pageSize,
    );
    console.log(questionObj, 'getdetails1234')
    setReadyToFetch(false);
    setPageState(old => ({ ...old, isLoading: false, data: questionObj.getDetails, total: questionObj.getDetails?.length > 0 ? questionObj.totalCount : 0 }))
  }

  useEffect(() => {
    fetchData(readyToFetch, pageState.page, pageState.pageSize);
  }, [readyToFetch, pageState.page, pageState.pageSize]);

  console.log(pageState, 'poolQmappingData')
  const handleInputChange = (e) => {
    setBulkValue(e.target.value);
  };
  const handleBulkOptionFieldChange = (v) => {
    setBulkOption(bulkOptions.find((x) => x.id == v));
  };
  const getCustomSelectForTopic = (options) => {
    if (options.length <= 0) return;
    return (
      <CustomSelect
        options={options.map((x) => ({ id: x.id, name: x.name }))}
        setvalue={(v) => handleInputChange({ target: { value: v } })}
      />
    );
  };

  const getCustomInput = (type) => {
    return (
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          fullWidth: true,
          onBlur: handleInputChange,
          type: type,
          autoComplete: "off",
        }}
      />
    );
  };

  const handleClear = () => {
    // setSelectedTopics([]);         //for clear selected topics.
    setBulkOption([]);
    setBulkValue("");
  }

  const poolTopicMapping = () => {
    const topicsArray = [];

    selectedTopics?.forEach(element => {

      topicsArray.push(element);
    });
    console.log(topicsArray, 'topicsArray3')

    // if (topicsArray.length > 0) {
    // let eduPylod = Object.assign(props.state.topics=JSON.stringify(topicsArray))
    // console.log(eduPylod,"eduPylod")

    setModel(props.state.topics = topicsArray.toString());
    console.log(topicsArray.toString(), "eduPylod")
    if (model.poolId) {
      updatePool(model);
    }
    // }

  };

  const updatePool = async (model) => {
    setIsLoadingT2Pool(true);
    model.topics = model.topics == "" ? null : model.topics;
    let response = await axios.put(
      `${configUrl.AssessmentServer}/api/pools/${model.poolId}`,
      model
    );
    if (response.status == 204 || response.status == 200) {
      showSnackBar("Topics added to pool successfully");
      setTimeout(() => {
        props.onCancelClick()
      }, 2000);
    } else {
      showSnackBar("Error occured", 'error');
    }
    setIsLoadingT2Pool(false);

    console.log(response, 'updatingPool');

  };

  const update = usePoolQuestionBulkInsert();

  const [orgData, setOrgData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [select, setSelection] = React.useState([]);
  console.log(select, 'selectedquestions')
  const handleRowSelection = (e) => {
    console.log(e, 'selectedquestions1')
    // setSelection([...select, e]);
    setSelection(e);

  };

  React.useEffect(() => {
    let cols = [
      // {
      //   field: "id",
      //   headerName: "Id",
      //   editable: false,
      //   width: 100,
      // },
      {
        field: "code",
        headerName: "Code",
        editable: false,
        width: 180,
      },
      {
        field: "text",
        headerName: "Text",
        editable: false,
        width: 460,
        renderCell: (cellValues) => {
          console.log("selectquestionsforPool", cellValues)
          return (
            <span>{cellValues.row.text == null || cellValues.row.text == "" ? <Tooltip classes={{ tooltip: classes.tooltip }} title={<img style={{ border: '1px solid #E63673', borderRadius: 5, padding: 2 }} src={cellValues.row.image} /> ?? 'none'}><span style={{ fontStyle: 'italic' }}>"Image Based Question"</span></Tooltip> : cellValues.row.text}</span>
          );
        },
      },
      {
        field: "providerName",
        headerName: "Provider Name",
        editable: false,
        width: 180,
      },

      {
        field: "questionType",
        headerName: "Type",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          console.log("selectquestionsforPool cellvalues", cellValues)
          return (
            console.log(cellValues, 'inCell'),
            <span>{cellValues.row.questionType == "1" ? "MCQ" : cellValues.row.questionType == "2" ? "SCQ" : cellValues.row.questionType == "3" ? "True/False" : cellValues.row.questionType == "4" ? "NUMERICAL" : ''}</span>
          );
        },
      },
      // {
      //   field: "choice1",
      //   headerName: "Choice 1",
      //   editable: false,
      //   width: 200,
      //   renderCell: (cellValues) => {
      //     console.log("selectquestionsforPool cellvalues", cellValues)
      //     return (
      //       <span>{cellValues.row.choice1 != null && (cellValues.row.contentType == 1 ? cellValues.row.choice1 : cellValues.row.contentType == 3 ? <Latex>{cellValues.row.choice1}</Latex> : cellValues.row.contentType == 4 ? ReactHtmlParser(cellValues.row.choice1) : cellValues.row.choice1)} </span>
      //     );
      //   },
      // },
      // {
      //   field: "choice2",
      //   headerName: "Choice 2",
      //   editable: false,
      //   width: 200,
      //   renderCell: (cellValues) => {
      //     console.log("selectquestionsforPool cellvalues", cellValues)
      //     return (
      //       // <span> {cellValues.row.choice2 == null ? '' : <Latex>{cellValues.row.choice2}</Latex>}</span>
      //       <span>{cellValues.row.choice2 != null && (cellValues.row.contentType == 1 ? cellValues.row.choice2 : cellValues.row.contentType == 3 ? <Latex>{cellValues.row.choice2}</Latex> : cellValues.row.contentType == 4 ? ReactHtmlParser(cellValues.row.choice2) : cellValues.row.choice2)} </span>
      //     );
      //   },
      // },
      // {
      //   field: "choice3",
      //   headerName: "Choice 3",
      //   editable: false,
      //   width: 200,
      //   renderCell: (cellValues) => {
      //     console.log("selectquestionsforPool cellvalues", cellValues)
      //     return (
      //       // <span>{cellValues.row.choice3 == null ? '' : <Latex>{cellValues.row.choice3}</Latex>} </span>
      //       <span>{cellValues.row.choice3 != null && (cellValues.row.contentType == 1 ? cellValues.row.choice3 : cellValues.row.contentType == 3 ? <Latex>{cellValues.row.choice3}</Latex> : cellValues.row.contentType == 4 ? ReactHtmlParser(cellValues.row.choice3) : cellValues.row.choice3)} </span>
      //     );
      //   },
      // },
      // {
      //   field: "choice4",
      //   headerName: "Choice 4",
      //   editable: false,
      //   width: 200,
      //   renderCell: (cellValues) => {
      //     console.log("selectquestionsforPool cellvalues", cellValues)
      //     return (
      //       // <span>{cellValues.row.choice4 == null ? '' : <Latex>{cellValues.row.choice4}</Latex>} </span>
      //       <span>{cellValues.row.choice4 != null && (cellValues.row.contentType == 1 ? cellValues.row.choice4 : cellValues.row.contentType == 3 ? <Latex>{cellValues.row.choice4}</Latex> : cellValues.row.contentType == 4 ? ReactHtmlParser(cellValues.row.choice4) : cellValues.row.choice4)} </span>

      //     );
      //   },
      // },
      // {
      //   field: "choice5",
      //   headerName: "Choice 5",
      //   editable: false,
      //   width: 200,
      //   renderCell: (cellValues) => {
      //     console.log("selectquestionsforPool cellvalues", cellValues)
      //     return (
      //       // <span>{cellValues.row.choice5 == null ? '' : <Latex>{cellValues.row.choice5}</Latex>} </span>
      //       <span>{cellValues.row.choice5 != null && (cellValues.row.contentType == 1 ? cellValues.row.choice5 : cellValues.row.contentType == 3 ? <Latex>{cellValues.row.choice5}</Latex> : cellValues.row.contentType == 4 ? ReactHtmlParser(cellValues.row.choice5) : cellValues.row.choice5)} </span>
      //     );
      //   },
      // },
      // {
      //   field: "choice6",
      //   headerName: "Choice 6",
      //   editable: false,
      //   width: 200,
      //   renderCell: (cellValues) => {
      //     console.log("selectquestionsforPool cellvalues", cellValues)
      //     return (
      //       // <span>{cellValues.row.choice6 == null ? '' : <Latex>{cellValues.row.choice6}</Latex>} </span>
      //       <span>{cellValues.row.choice6 != null && (cellValues.row.contentType == 1 ? cellValues.row.choice6 : cellValues.row.contentType == 3 ? <Latex>{cellValues.row.choice6}</Latex> : cellValues.row.contentType == 4 ? ReactHtmlParser(cellValues.row.choice6) : cellValues.row.choice6)} </span>
      //     );
      //   },
      // },
      // {
      //   field: "answer",
      //   headerName: "Answer",
      //   editable: false,
      //   width: 200,
      //   renderCell: (cellValues) => {
      //     console.log("selectquestionsforPool cellvalues", cellValues)
      //     return (
      //       // <span>{cellValues.row.answer == null ? '' : <Latex>{cellValues.row.answer}</Latex>} </span>
      //       <span>{cellValues.row.answer != null && (cellValues.row.contentType == 1 ? cellValues.row.answer : cellValues.row.contentType == 3 ? <Latex>{cellValues.row.answer}</Latex> : cellValues.row.contentType == 4 ? ReactHtmlParser(cellValues.row.answer) : cellValues.row.choice6)} </span>
      //     );
      //   },
      // },
      // {
      //   field: "points",
      //   headerName: "Points",
      //   editable: false,
      //   width: 200,
      // },
      // {
      //   field: "hint",
      //   headerName: "Hint",
      //   editable: false,
      //   width: 200,
      //   renderCell: (cellValues) => {
      //     console.log("selectquestionsforPool cellvalues", cellValues)
      //     return (
      //       // <span>{cellValues.row.hint == null ? '' : <Latex>{cellValues.row.hint}</Latex>} </span>
      //       <span>{cellValues.row.hint != null && (cellValues.row.contentType == 1 ? cellValues.row.hint : cellValues.row.contentType == 3 ? <Latex>{cellValues.row.hint}</Latex> : cellValues.row.contentType == 4 ? ReactHtmlParser(cellValues.row.hint) : cellValues.row.hint)} </span>

      //     );
      //   },
      // },
      // {
      //   field: "owner",
      //   headerName: "Owner",
      //   editable: false,
      //   width: 200,
      // },
      {
        field: "status",
        headerName: "status",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          return (
            <span>{cellValues.row.status == "1" ? "Draft" : cellValues.row.status == "2" ? "Ready" : "Published"}</span>
          );
        },
      },
      // {
      //   field: "isLocked",
      //   headerName: "Is Locked",
      //   editable: false,
      //   width: 200,
      // },
      // {
      //   field: "lockedBy",
      //   headerName: "Locked By",
      //   editable: false,
      //   width: 200,
      // },
      // {
      //   field: "isActive",
      //   headerName: "Is Active",
      //   editable: false,
      //   width: 200,
      // },
      {
        field: "difficultyLevel",
        headerName: "Difficulty Level",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          return (
            <span>
              {cellValues.row.difficultyLevel == "3"
                ? "Difficult"
                : cellValues.row.difficultyLevel == "1"
                  ? "Easy"
                  : "Medium"}
            </span>
          );
        },
      },
      // {
      //   field: "passFeedback",
      //   headerName: "Pass Feedback",
      //   editable: false,
      //   width: 200,
      // },
      // {
      //   field: "failFeedback",
      //   headerName: "Fail Feedback",
      //   editable: false,
      //   width: 200,
      // },
      // {
      //   field: "expiryDate",
      //   headerName: "Expiry Date",
      //   editable: false,
      //   width: 200,
      // },
    ];

    setColumns(cols);

    setBulkOptions([
      {
        id: 1,
        name: "Difficulty Level",
        inputType: "text",
        key: "difficultyLevel",
        // updateMethod: updateQuestion,
        component: getCustomSelectForTopic([
          {
            id: 1,
            name: "Easy",
          },
          {
            id: 2,
            name: "Medium",
          },
          {
            id: 3,
            name: "Difficult",
          },
        ]),
      },
      // {
      //   id: 3,
      //   name: "Status",
      //   inputType: "text",
      //   key: "status",
      //   // updateMethod: updateQuestion,
      //   component: getCustomSelectForTopic([
      //     {
      //       id: 1,
      //       name: "Draft",
      //     },
      //     {
      //       id: 2,
      //       name: "Ready",
      //     },
      //   ]),
      // },
      // {
      //   id: 8,
      //   name: "Objective",
      //   inputType: "text",
      //   key: "objective",
      //   updateMethod: updateQuestion,
      //   // component: getCustomSelectMultiple(objectiveOptions),
      //   component: getCustomSelectForTopic(objectiveOptions),
      // },
      // {
      //   id: 9,
      //   name: "Target Audience",
      //   inputType: "text",
      //   key: "targetAudience",
      //   updateMethod: updateQuestion,
      //   // component: getCustomSelectMultiple(targetAudienceOptions),
      //   component: getCustomSelectForTopic(targetAudienceOptions)
      // },
      {
        id: 10,
        name: "Content Type",
        inputType: "text",
        key: "contenttype",
        // updateMethod: updateQuestion,
        component: getCustomSelectForTopic([
          {
            id: 1,
            name: "Text Based",
          },
          {
            id: 3,
            name: "Latex Based",
          },
        ]),
      },
      {
        id: 11,
        name: "Question Type",
        inputType: "text",
        key: "questiontype",
        // updateMethod: updateQuestion,
        component: getCustomSelectForTopic([
          {
            id: 1,
            name: "MCQ",
          },
          {
            id: 2,
            name: "SCQ",
          },
          {
            id: 3,
            name: "True/False",
          },
          {
            id: 4,
            name: "Numerical",
          },
        ]),
      },
      {
        id: 12,
        name: "Code",
        inputType: "text",
        key: "code",
        // updateMethod: updateQuestion,
        component: getCustomInput("text"),
      },
    ]);
  }, []);

  const handleEdit = (p, e) => { };

  const onCellEditCommit = (p) => {
    let row = pageState.data.find((x) => (x.id = p.id));

    if (row[p.field] == p.value) {
      return;
    }
  };

  const mapToPool = () => {
    // changes for selected questions carrying on next page (pagination next clik)
    // const mergedArray = [].concat(...select);
    // const uniqueMergedArray = [...new Set(mergedArray)];
    let payload = select.map((x) => {
      return {
        poolId: props.poolId,
        questionId: x,
        sequenceId: 0,
        status: 0,
        isLocked: false,
        lockedBy: "",
        lockedDate: null,
        duration: null,
        points: 0,
        isMandatory: 0,
        createdBy: user,
        createdDate: new Date(),
        updatedBy: user,
        updatedDate: new Date(),
      };
    });

    update.mutate(payload);

    //await getQuestions();
  };
  useEffect(() => {
    if (update.isSuccess == true) {
      showSnackBar("Questions added to pool successfully");
      fetchData(readyToFetch, pageState.page, pageState.pageSize);

    }
  }, [update.isSuccess])
  console.log(update, 'checkingupdate')
  useEffect(() => {
    if (update.isError == true) {
      showSnackBar("Error occured", 'error');
    }
  }, [update.isError])
  const showSnackBar = (message, sev) => {
    setSeverity(sev ?? "success");
    setAlertMessage(message);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(null);
    setOpen(false);
  };
  function checkTopicIds(topicIds) {
    return topicIds != undefined;
  }
  const onChange = (currentNode, selectedNodes) => {
    let topicIds = [];
    console.log(selectedNodes, 'selectedNodes')
    selectedNodes.forEach((x) => {
      // topicIds.push(x.topicId);
      getTopicIds(x, topicIds);
      console.log(topicIds, 'topicIds')
    });
    console.log(topicIds, 'selectedNo1')
    let Ids = topicIds.filter(checkTopicIds)
    console.log(Ids, 'selectedNo2')
    setSelectedTopics(Ids);
  };

  return (
    <Card>
      {alert}
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <Language />
        </CardIcon>
      </CardHeader>
      <CardBody>
        {props.hideHeader == true ? null : <h1>Section : {props.poolName}</h1>}
        {/* <h1>Questions</h1> */}
        <GridContainer alignItems="flex-end">
          {/* <GridItem xs={12} sm={12} md={4}>
            {topics && topics.length > 0 && (
              <CustomMultipleSelect
                label="Select Topics"
                options={topics}
                setvalue={setMultipleSelect}
              />
            )}
          </GridItem> */}

          <GridItem xs={12} sm={12} md={7}>
            <GridContainer>
              {/* TODO */}
              {/* css not working for align tree dropdown */}
              <GridItem xs={12} sm={12} md={6}>
                <h6 style={{ textTransform: "none" }}>Topics <small>(required)</small></h6>
                {categoryTree ? (
                  <TreeDropDown
                    data={categoryTree}
                    onChange={onChange}
                    className="mdl-demo"
                  />
                ) : <div>Loading..</div>}
              </GridItem>
              <GridItem xs={6} sm={6} md={3}>
                <CustomSelect
                  label="Select field"
                  options={bulkOptions}
                  setvalue={handleBulkOptionFieldChange}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={3}>
                {bulkOption && bulkOption.component ? (
                  bulkOption.component
                ) : (
                  <CustomInput
                    id="filename"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      fullWidth: true,
                      onChange: handleInputChange,
                      type: "text",
                      value: bulkValue,
                      autoComplete: "off",
                    }}
                  />
                )}
              </GridItem>

              {/* will work for clear TreeDropDown selection as well */}
              {/* <GridItem xs={12} sm={12} md={2}>
                <Button
                  color="primary"
                  onClick={handleClear}
                  style={{ marginTop: 35, marginRight: 8, padding: 12, textTransform: "none" }}
                // disabled={pageState.isLoading == true}
                >
                  <ClearOutlined style={{ marginRight: '0px' }} />
                </Button>
                <Button
                  // size="sm"
                  // justIcon
                  // round
                  color="primary"
                  onClick={() => setReadyToFetch(true)}
                  disabled={isQuestionLoading == true}
                  style={{ marginTop: 35, padding: 12, textTransform: "none" }}
                >
                  <SearchOutlined style={{ marginRight: '0px' }} />
                </Button>
              </GridItem> */}
            </GridContainer>
            {/* <DropDownSelect  setTopics={setMultipleSelect} showCategoriesOnly={false} />  */}
          </GridItem>
          <GridItem xs={12} sm={12} md={5}>
            {/* will work for clear TreeDropDown selection as well */}
            <Button
              color="primary"
              onClick={handleClear}
              style={{ margin: '0px 8px 17px 0px', padding: 12, textTransform: "none" }}
            // disabled={pageState.isLoading == true}
            >
              <ClearOutlined style={{ marginRight: '0px' }} />
            </Button>
            <Button
              // size="sm"
              // justIcon
              // round
              color="primary"
              onClick={() => setReadyToFetch(true)}
              //disabled={false}
              style={{ margin: '0px 8px 17px 0px', padding: 12, textTransform: "none" }}
            >
              <SearchOutlined style={{ marginRight: '0px' }} />
            </Button>
            <Button
              color="primary"
              onClick={(e) => {
                e.preventDefault()
                setAlert(
                  <SweetAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={''}
                    onConfirm={() => poolTopicMapping()}
                    showCancel={true}
                    cancelBtnText={'CANCEL'}
                    confirmBtnText={'OK'}
                    onCancel={() => setAlert(null)}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.warning}
                  >
                    <div style={{ display: 'inline-flex' }}>
                      <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                      <h4> Topic(s) mapping with the pool will be updated/added/removed based on current selection. Are you sure you want to continue?</h4>
                    </div>
                  </SweetAlert>
                )
              }
              }
              disabled={isLodingT2Pool}     //|| select?.length > 0 || selectedTopics?.length == 0 || selectedTopics == undefined
              style={{ textTransform: 'none', margin: '0px 8px 17px 0px' }}
            >
              {isLodingT2Pool ? "Adding/Removing Topics..." : "Add/Remove Topics"}
            </Button>
            <Button
              color="primary"
              onClick={mapToPool}
              disabled={update.isLoading || select?.length == 0}
              style={{ textTransform: 'none', marginBottom: '22px' }}
            >
              {update.isLoading ? "Adding Questions..." : "Add Questions"}
            </Button>
          </GridItem>
        </GridContainer>
        {pageState.data && pageState.isLoading == false ? (
          /*  <CustomTable
            header={Object.keys(data[0])}
            rows={data.filter((_, i) => i > 0).map((x) => Object.values(x))}
          /> */
          <div style={{ height: 500, width: "100%" }}>
            <div style={{ display: "flex", height: "90%" }}>
              <div style={{ flexGrow: 1 }}>
                <CustomDataGrid
                  columns={columns}
                  rows={pageState.data}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  rowsPerPageOptions={[10, 30, 50, 70, 100]}
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  // below line is for carrying selected question on pagination next click.
                  // onPageChange={(newPage) => { setPageState(old => ({ ...old, page: newPage + 1 })), setSelection([...select]) }}
                  onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                  onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                  onEditCellPropsChange={handleEdit}
                  onCellEditCommit={onCellEditCommit}
                  onSelectionModelChange={handleRowSelection}
                  checkboxSelection
                />
              </div>
            </div>
          </div>
        ) : readyToFetch && pageState.isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}><CircularProgress /> </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}></div>}
      </CardBody>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
}
