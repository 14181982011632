import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetCourseCount() {
  return useQuery("courseCount", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Courses/getCourseCount`
    );
    return response.data;
  });
}
