import axios from "axios";
import { configUrl } from "config.js";

const getPoolsByTestId = async (testId) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/TestPoolMappings/${testId}`
    );

    let poolData = response.data["$values"];

    let poolDetails = [];

    for (let i = 0; i < poolData.length; i++) {
        let poolResponse = await axios.get(
            `${configUrl.AssessmentServer}/api/Pools/${poolData[i].poolId}`
        );

        let language = { ...poolResponse.data.poolLanguages["$values"][0] };
        let p = { ...poolResponse.data };

        p.poolLanguages = [language];
        p.showQuestions = false;
        poolDetails.push(p);
    }

    return [...poolDetails.sort((a, b) => b.sequenceId - a.sequenceId)];
};
export default {
    getPoolsByTestId: getPoolsByTestId,
}