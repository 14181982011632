import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";

export default function useCreateUser() {
  const { config, user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (interestPayload) => {
      // if (!interestPayload.address) interestPayload.address = " ";
      // if (!interestPayload.middleName) interestPayload.middleName = " ";

      // if (!interestPayload.passCode) interestPayload.passCode = "test";
      interestPayload.interests=interestPayload.interests.trim();
      interestPayload.createdBy = user ?? "system";
      interestPayload.updatedBy = user ?? "system";
      interestPayload.providerId = providerId;
      interestPayload.isActive = true;

      await axios.post(`${config.api}/api/Interest`, interestPayload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Interests");
      },
    }
  );
}
