import { makeStyles } from "@material-ui/core";
import Today from "@material-ui/icons/Today";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";

import Switch from "@material-ui/core/Switch";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import axios from "axios";

import { FormControlLabel } from "@material-ui/core";
import CustomAlert from "components/CustomAlert/CustomAlert";
import CustomMultipleSelect from "components/CustomSelect/CustomMultipleSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";

const useStyles = makeStyles(styles);

export default function PoolForm(props) {
  const { user, providerId } = React.useContext(LoginContext);

  const [model, setModel] = React.useState(
    props.state ?? {
      providerId: providerId,
      code: "",
      sequenceId: 0,
      difficultyLevel: 0,
      status: 1,
      isLocked: false,
      lockedBy: "",
      lockedDate: new Date(),
      isActive: true,
      expiryDate: "2099-12-31T00:00:00",
      duration: null,
      pointsPerQuestion: 0,
      negativePointsPerQuestion: 0,
      totalScore: 0,
      masteryScore: 0,
      passingScore: 0,
      randomizeType: 0,
      questionCount: 0,
      maxQuestionForScore: 0,
      questionsPerPage: 0,
      questionsPerTopic: 0,
      questionType: null,
      isMandatory: 0,
      displayType: 0,
      owner: user,
      createdBy: user,
      createdDate: new Date(),
      updatedBy: user,
      updatedDate: new Date(),
      isMandatoryToPass: false,
      poolLanguages: [
        {
          languageId: 1,
          text: "",
          image: "",
          objective: "",
          textHtml: "",
          passFeedback: "",
          failFeedback: "",
          masteryFeedback: "",
          isActive: true,
          status: 1,
          createdBy: user,
          createdDate: new Date(),
          updatedBy: user,
          updatedDate: new Date(),
        },
      ],
    }
  );
  const [flag, setFlag] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(null)

  const classes = useStyles();

  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleChange = (e) => {
    console.log(e, "e");
    console.log(e.target.name, "e.target.name");
    console.log(e.target.value, "e.target.value");
    if ((e.target.name == 'questionCount') || (e.target.name == 'maxQuestionForScore') || (e.target.name == 'pointsPerQuestion') || (e.target.name == 'passingScore') || (e.target.name == 'masteryScore') || (e.target.name == 'questionsPerTopic')) {

      if ((e.target.name == 'questionCount') || (e.target.name == 'maxQuestionForScore') || (e.target.name == 'pointsPerQuestion') || (e.target.name == 'passingScore') || (e.target.name == 'masteryScore') || (e.target.name == 'questionsPerTopic') && e.target.value == '') {
        setModel({ ...model, [e.target.name]: 0 });
      }
      if (e.target.value >= 0) {
        setModel({ ...model, [e.target.name]: e.target.value });
      }
      else {
        setModel({ ...model, [e.target.name]: 0 });
        console.log("negative values will not update")
        return
      }
    } else {
      setModel({ ...model, [e.target.name]: e.target.value });
    }
  };

  const handleNegativePointsChange = (e) => {
    if (e.target.value <= 0) {
      setModel({ ...model, [e.target.name]: e.target.value });
    }
    else {
      setModel({ ...model, [e.target.name]: 0 });
      return
    }
  };

  const handleLanguageChange = (e) => {
    console.log(e.target.name, e.target.value);
    model.poolLanguages[0][e.target.name] = e.target.value;
    setModel({ ...model });
  };

  const handleCodeChange = (e) => {
    console.log(e.target.name, e.target.value, 'CODE');
    if (e.target.name == 'code') {
      const regex = /^[a-zA-Z0-9-_]*$/;
      if (regex.test(e.target.value)) {
        model[e.target.name] = e.target.value;
        setModel({ ...model });
      }
    }
    model[e.target.name] = e.target.value;
    setModel({ ...model });
  };

  const createPool = async () => {
    let response = await axios.post(
      `${configUrl.AssessmentServer}/api/pools/${props.testId}`,
      model
    );

    console.log(response.data);
  };
  const handleSubmit = () => {
    console.log('x should be greater j', model.masteryScore < model.passingScore, typeof (model.masteryScore), typeof (model.passingScore), model.passingScore, 'xx', model.passingScore)
    console.log(model.isMandatoryToPass, "ismandatory");
    if (model.isMandatoryToPass == true) {
      if (model.masteryScore == 0 && model.passingScore == 0) {
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="error"
            alertMessage="Mastery Score and Passing Score must be provided when is mandatory to pass is checked."
          />
        )
        return
      }
    }
    if (parseInt(model.masteryScore) < parseInt(model.passingScore)) {
      console.log('m should be greater s')
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage="Mastery score should be greater than passing score."
        />
      )
      return
    }
    if (parseInt(model.maxQuestionForScore) > parseInt(model.questionCount)) {
      console.log('m should be greater s')
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage="Max Questions For Score shold be greater than or equal to Numbers Of Questions To Allow."
        />
      )
      return
    }
    if (model.questionCount <= 0 && model.code.length <= 0 && model.poolLanguages[0].text.length <= 0) {
      // alert('Pool Code , Pool Name and Number of Questions Allowed is mandatory');
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage="Code ,Name & Number of Questions Allowed is mandatory"
        />
      )
    }
    else if (model.questionCount <= 0) {
      // alert('Number of Questions Allowed is mandatory');
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage="Number of Questions Allowed is mandatory"
        />
      )
    } else if (model.code.length <= 0) {
      // alert('code is mandatory');
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage="Code is mandatory"
        />
      )
    } else if (model.poolLanguages[0].text.length <= 0) {
      // alert('Pool Name is Mandatory');
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage="Section name is mandatory"
        />
      )
    }
    else {
      props.createPool(model)
    }
  }
  return (
    <div>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <Today />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Code *</span>}
                id="text"
                formControlProps={{
                  fullWidth: true,
                }}
                disabled={false}
                inputProps={{
                  name: "code",
                  onChange: handleCodeChange,
                  maxLength: 20,
                  value: model.code,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Name *</span>}
                id="text"
                formControlProps={{
                  fullWidth: true,
                }}
                disabled={false}
                inputProps={{
                  name: "text",
                  onChange: handleLanguageChange,
                  value: model.poolLanguages[0].text,
                }}
              />
            </GridItem>

            {/* Commented temporarily since is not being used anywhere 
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Objective</span>}
                id="objective"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "objective",
                  onChange: handleLanguageChange,
                  value: model.poolLanguages[0].objective,
                }}
              />
            </GridItem> */}

            <GridItem xs={12} sm={12} md={5}>
              <CustomSelect
                name="difficultyLevel"
                options={[
                  {
                    id: 1,
                    name: "Easy",
                  },
                  {
                    id: 2,
                    name: "Medium",
                  },
                  {
                    id: 3,
                    name: "Difficult",
                  },
                ]}
                setvalue={(v) =>
                  handleChange({
                    target: { value: v, name: "difficultyLevel" },
                  })
                }
                value={model.difficultyLevel ? model.difficultyLevel : 1}
                label="Difficulty Level"
              />

            </GridItem>

            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Number of Questions Allowed *</span>}
                id="questionCount"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  name: "questionCount",
                  onChange: handleChange,
                  value: model.questionCount,
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Max Questions For Score <small> ( {'< or =='} Questions to Allow ) </small></span>}
                id="maxQuestionForScore"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  name: "maxQuestionForScore",
                  onChange: handleChange,
                  value: model.maxQuestionForScore,
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Points Per Question</span>}
                id="pointsPerQuestion"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  name: "pointsPerQuestion",
                  onChange: handleChange,
                  value: model.pointsPerQuestion,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Negative Points Per Question</span>}
                id="negativePointsPerQuestion"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  name: "negativePointsPerQuestion",
                  onChange: handleNegativePointsChange,
                  value: model.negativePointsPerQuestion,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Passing Score</span>}
                id="passingScore"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  name: "passingScore",
                  onChange: handleChange,
                  value: model.passingScore,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Mastery Score<small> ( {'>'} Passing Score ) </small></span>}
                id="masteryScore"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  name: "masteryScore",
                  onChange: handleChange,
                  value: model.masteryScore,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Questions Per Topic</span>}
                id="questionsPerTopic"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  name: "questionsPerTopic",
                  onChange: handleChange,
                  value: model.questionsPerTopic,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <CustomMultipleSelect
                id="questionType"
                name="questionType"
                options={[
                  {
                    id: 0,
                    name: "Select Question Type",
                  },
                  {
                    id: 1,
                    name: "MCQ",
                  },
                  {
                    id: 2,
                    name: "SCQ",
                  },
                  {
                    id: 3,
                    name: "True/False",
                  },
                  {
                    id: 4,
                    name: "Numerical",
                  },
                ]}
                setvalue={(v) => {
                  handleChange({ target: { name: "questionType", value: JSON.stringify(v) } });
                }}
                value={model &&
                  model.questionType
                  ? isJSON(model.questionType) == true
                    ? JSON.parse(model.questionType)
                    : []
                  : []
                }
                label="Question Type"
              />

            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControlLabel
                control={
                  <Switch
                    value={model.isMandatoryToPass}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: "isMandatoryToPass",
                          value: e.target.checked,
                        },
                      });
                    }}
                    checked={model.isMandatoryToPass}
                  />
                }
                label={<small>Is Mandatory to Pass</small>}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              {/* <FormControlLabel
                control={
                  <Switch
                    name="isLocked"
                    value={model.isLocked}
                    onChange={(e) => {
                      handleChange({
                        target: { name: "isLocked", value: e.target.checked },
                      });
                    }}
                    checked={model.isLocked}
                  />
                }
                label={<small>Is Locked</small>}
              /> */}
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText={<span>Expiry Date</span>}
                id="expiryDate"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  type: "date",
                  name: "expiryDate",
                  onChange: handleChange,
                  value: model.expiryDate ,
                }}
              />
            </GridItem> */}
            <GridItem xs={12} sm={12} md={5}>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              <Button color="primary" style={{ textTransform: 'none' }}
                // disabled={
                //   model.questionCount <= 0 || model.code.length <=0 || model.poolLanguages[0].text.length <=0

                // }
                onClick={handleSubmit}>
                {model.poolId ? "Update" : "Create"}
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {showAlert}
    </div>
  );
}
