import axios from "axios";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useQuestionUpdate() {
  const { user } = useContext(LoginContext);
  const queryClient = useQueryClient()
  return useMutation(async (question) => {
    question.updatedBy = user;
    question.LockedBy = question.isLocked == true ? user : null;
    console.log(new Date())
    question.updatedDate = new Date();
    await axios.put(
      `${configUrl.AssessmentServer}/api/questions/${question.questionId}`, question
    );
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries("question")
    }
  });
}
