import axios from "axios";
import { configUrl } from "config.js";

const getQuickTestData = async (testObject) => {
    return axios.post(`${configUrl.AssessmentServer}/api/SimplifiedTest/createTest`, testObject)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error.response.data, "prevError");
            return {
                messege: error.response.data,
                status: error.response.status
            }
        });

};
export default {
    getQuickTestData: getQuickTestData,
}
