//import QuestionComponent from "./QuestionComponent";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Button from "components/CustomButtons/Button.js";


// @material-ui/icons

// core components

const ConcludeScreen = ({ message }) => {
  return (
    <div>
      {/* <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <HelpOutlineIcon />
          </CardIcon>
        </CardHeader>
        <CardBody> */}
        <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: 400 }}
          >
            <GridItem xs={12} sm={12} md={3} />
            <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center",color:'black' }}>
              <h2>{message}</h2>              
            </GridItem>
            <GridItem xs={12} sm={12} md={3} />
           
          </GridContainer>
        {/* </CardBody>
      </Card> */}
    </div>
  );
};

export default ConcludeScreen;
