import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";


import dashboardStyle from "assets/newAssets/dashboardStyle";
import loginPageStyle from "assets/newAssets/loginPageStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TestContext } from "contexts/TestContext";
import QuestionWrapper from "./QuestionWrapper";

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const useStyles = makeStyles(styles);

export default function TestPlayer(props) {
  const classes = useStyles();

  // let questionsData = props.questions;
  let testAttemptDetailsData = props.testAttemptDetails;

  const [testId, setTestId] = useState(props.testId);
  const [userId, setUserId] = useState(props.userId);
  const [attempt, setAttempt] = useState(props.attempt);

  const [testAttemptDetails, setTestAttemptDetails] = useState(testAttemptDetailsData);
  // const [test, setTest] = useState(questionsData);
  const [testAnswers, setTestAnswers] = useState([]);
  const [select, setSelect] = useState()
  const [markForReview, setMarForkReview] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionIndexFlag, setQuestionIndexFlag] = useState(false);
  const [testTFlag, setTestTFlag] = React.useState(false);
  return (
    <div className={classes.wrapper}>
      <div
        style={{
          // modal Background BSS121
          background: "#ffffff ",
          //padding: "120px 0",
          position: "relative",
          Height: "70vh",
          display: "flex!important",
          margin: "0",
          border: "0",
          color: "white",
          alignItems: "center",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "100%",
        }}
      >
        {
          <TestContext.Provider
            value={{ testTFlag, setTestTFlag, questionIndex, setQuestionIndex, questionIndexFlag, setQuestionIndexFlag, select, setSelect, markForReview, setMarForkReview, testAnswers, setTestAnswers, testId, userId, testAttemptDetails, setTestAttemptDetails }}
          >
            <GridContainer justifyContent="center" style={{ width: "calc(100% + 15px)" }}>
              <GridItem xs={12} sm={12} md={12} style={{ marginLeft: 10, overflowX: 'hidden' }}>
                <QuestionWrapper topicId={props.topicId} afterfinish={props.afterfinish} attempt={props.attempt} preview={props.preview} isCourse={props.isCourse} />
              </GridItem>
            </GridContainer>
          </TestContext.Provider>
        }
      </div>
    </div>
  );
}
