import {
  successColor,
  tooltip,
  cardTitle,
  grayColor,
} from "./material-dashboard-pro-react";

import hoverCardStyle from "./hoverCardStyle";

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    textAlign:'left'
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center",
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0],
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px",
    },
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0",
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: "17px",
    height: "17px",
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  cardfooterButtons:{
    padding:'0px !important',

  },
  cardfooterButtonsIcons:{
    marginBottom:'9px',
    marginRight:'0px !important',
    marginLeft:'0px !important'
  },
  cardfooterText:{
    marginRight:'6px',
    fontSize:'12px',
    // color:'#ffa726',
    fontWeight:'700',
    fontfamily:'Roboto, Helvetica, Arial, sans-serif',
    // lineHeight:'40px'

  },
  lastfiveTestCard:{
    height:'87% !important',
  }
};

export default dashboardStyle;
