import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {configUrl} from "config.js";

export default function useDeletePool() {
  let queryClient = useQueryClient();
  return useMutation(
    async (id) => {
      await axios.delete(`${configUrl.AssessmentServer}/api/Pools/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pools");
      },
    }
  );
}
