import React from "react";
// used for making the prop types of this component
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import * as XLSX from "xlsx";

// core components
import Button from "components/CustomButtons/Button.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import CryptoJS from 'crypto-js';
import SweetAlert from "react-bootstrap-sweetalert";


import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { LoginContext } from "contexts/LoginContext";
import QuickTestBulkUploadUsers from "services/BulkUploadService/QuickTestBulkUploadUsers";
// import { config } from ;
const useStyles = makeStyles(styles);

export default function QuickTestUserFileUpload(props) {
    console.log(props, 'props in userfileUpload')
    // const { mutate: bulkUpload, isSuccess, isLoading, isError } = useQuickTestBulkUploadUsers();
    const [uploadUsersResp, setUploadUsersResp] = React.useState([]);

    console.log(uploadUsersResp, 'uploadUsersResp')
    const [file, setFile] = React.useState(null);
    const [fileName, setFileName] = React.useState("");
    const classes = useStyles();
    console.log("classes", classes)
    const [alert, setAlert] = React.useState(null);
    const [payload, setPayload] = React.useState(null);
    console.log(payload)
    const [defaultEvents, setDefaultEvents] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorLength, setErrrLength] = React.useState(null)
    let { user, providerId } = React.useContext(LoginContext);

    let fileInput = React.createRef();

    const key = 'pj23vs7nycq18uew';
    let secretKey = CryptoJS.enc.Utf8.parse(key);

    const encryptPassword = (password, secretKey) => {

        let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let encryptedString = encryptedBytes.toString();

        console.log(encryptedString, 'encryptedone');
        return encryptedString;
        // return CryptoJS.AES.encrypt(password, secretKey).toString();
    }
    const uploadUsers = async (payload) => {
        setIsLoading(true);
        let uploadResp = await QuickTestBulkUploadUsers.QuickTestBulkUploadUsers(payload)
        setUploadUsersResp(uploadResp);
        setIsLoading(false);
        console.log(uploadResp, 'uploadResp123')
    }

    const map = {
        FullName: "fullName",
        DOB: "dob",
        Email: "email",
        Pincode: "pinCode",
        Passcode: "passCode",
        Mobile: "mobile",
        Gender: "gender",

    };
    console.log(map)
    const setSelectedEvents = (e) => {
        setDefaultEvents(e);
    };

    const getMappedName = (d) => {
        if (d in map) {
            console.log(d)
            return map[d];
        }

        return null;
    };
    const validatePayload = (payload) => {
        console.log(payload, 'recv.payload')
        let filteredData = [];
        let errors = [];
        if (payload) {
            payload.forEach((x, i) => {

                if (x?.userTypeId == 4) {
                    // if (!x.questionChoices || x.questionChoices.length == 0) {
                    if (!x.fullName || x?.fullName.length == 0) {
                        errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>name</strong> for {i + 1} no. student`}</div>);
                        // return;
                    }
                    if (!x.dob || x?.dob.length == 0) {
                        errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>DOB</strong> for {x.fullName}</div>);
                        // return;
                    }
                    if (!x.email || x?.email.length == 0) {
                        errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>email</strong> for {x.fullName}</div>);
                        // return;
                    }
                    if (!x.mobile || x?.mobile.length == 0) {
                        errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>mobile</strong> for {x.fullName}</div>);
                        // return;
                    }
                    if (!x.gender || x?.gender == 0 || x.gender == null) {
                        errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>gender</strong> for {x.fullName}</div>);
                        // return;
                    }
                    if (!x.passCode || x?.passCode.length == 0) {
                        errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>Passcode</strong> for {x.fullName}</div>);
                    }
                    if (x?.fullName?.length > 0 && x?.dob?.length > 0 && x?.email?.length > 0 && x?.mobile?.length > 0 && (x.gender && x?.gender > 0 && x.gender != null) && x?.passCode?.length > 0) {
                        filteredData.push(x);
                    }
                }
            });
            console.log(filteredData, 'filData')
            console.log(errors, 'errorsData')
            setErrrLength(errors.length)
            let comObj = {
                filteredData, errors
            }
            console.log(comObj, 'comObj')
            return comObj;
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        console.log(file)
        reader.onload = (e) => {
            const btsr = e.target.result;
            const wb = XLSX.read(btsr, { type: "binary" });
            const wbname = wb.SheetNames[0];

            console.log(wb.SheetNames);

            let mappedData = [];

            wb.SheetNames.forEach((x) => {
                let data = createPayload(x, wb);
                mappedData = mappedData.concat(data);
            });

            console.log("mappedData..", mappedData);
            props.func(mappedData)
            let validateResult = validatePayload(mappedData);
            console.log(validateResult, 'is error occured')
            // TODO
            if (validateResult?.errors.length > 0) {
                // setErrorMessage(`${errors}. Fix file and try again `);
                // setShowAlert(true);
                setAlert(
                    <SweetAlert
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Alert"
                        onConfirm={() => setAlert(null)}
                        onCancel={() => setAlert(null)}
                        confirmBtnCssClass={classes.button + " " + classes.warning}
                    >
                        {validateResult?.errors}

                        <div style={{ marginTop: "10%", marginLeft: '10%', textAlign: 'left', fontWeight: '500' }}>Note: Rest of students imported successfully </div>
                    </SweetAlert>
                );
                // return;
            }
            let use = { studentInfo: validateResult?.filteredData, testId: props.testId ?? 0, classId: props.class, eduBoardId: props.eduBoard, nativeLanguageId: props.language, interestId: 0, CourseIds: props.course }
            console.log("use...", use)

            // bulkUpload(use);
            uploadUsers(use);
        };

        reader.onloadend = () => {
            setFile(file);
            setFileName(file.name);
        };

        reader.readAsBinaryString(e.target.files[0]);

        e.target.value = ""
    };

    const hideAlert = async () => {
        setAlert(null);
        props && props?.afterFinish ?
            await props?.afterFinish() : null
    };

    const createPayload = (sheetName, wb) => {
        const ws = wb.Sheets[sheetName];

        let data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            raw: false,
            blankrows: false,
            defval: "",
        });

        let header = data[0];

        let filteredData = data.filter((_, i) => i > 0);
        console.log("filteredData", filteredData);
        let mappedData = [];
        let mapped = {};
        function formatDate(d) {

            const date = new Date(d);
            const futureDate = date.getDate();
            date.setDate(futureDate);
            const defaultValue = date.toLocaleDateString('en-CA');
            return defaultValue;

        }

        //questionChoices: new Array(6)
        let choiceCount = 0;

        for (let i = 0; i < filteredData.length; i++) {
            if (filteredData[i][11] == "TRUE") {
                filteredData[i][11] = Boolean(1);
            } else {
                filteredData[i][11] = Boolean(0);
            }

            // if (filteredData[i][0] && !filteredData[i][1]) {
            //     continue;
            // }

            if (filteredData[i][0]) {
                mapped = {};
                //questionChoices: new Array(6)
                choiceCount = 0;
            }

            filteredData[i].map((x, j) => {
                if (x) {
                    let k = getMappedName(header[j]);

                    if (k != "dob") {
                        if (k) mapped[k] = x;
                    }
                    else {
                        x = formatDate(x);
                        if (k) mapped[k] = x;
                    }
                }
            });

            if (filteredData[i]) {   //filteredData[i][0]
                console.log(mapped)
                mappedData.push({
                    ...mapped,
                    state: props.state,
                    city: props.city,
                    isActive: true,
                    userName: mapped.email,
                    passCode: encryptPassword(mapped.passCode, secretKey),
                    dob: mapped.dob ?? "",
                    fullName: mapped.fullName ?? "",
                    gender: mapped.gender == "Male" ? 1 : mapped.gender == "Female" ? 2 : mapped.gender?.length > 0 ? 3 : 0, // == 'Male' ? 1 ? mapped.gender === "Female" : 2 : 3,
                    createdBy: user,
                    createdDate: new Date(),
                    userTypeId: props.userTypeId,
                    providerId: providerId,
                    testId: props.testId ?? 0,
                    updatedBy: user,
                    updatedDate: new Date()
                });
            }
        }

        console.log("mappedData", mappedData);
        return mappedData;
    };

    const successAlert = () => {
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Upload Success"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                File has been uploaded.
            </SweetAlert>
        );
    };

    const errorAlert = (message, content) => {
        console.log(message, 'Message&Content', content)
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Upload Failed"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.danger}
            >
                {message}<br />
                {content.map((x, i) => (
                    <span style={{ color: 'black' }} key={i}>{x},{' '} </span>
                ))}

            </SweetAlert>
        );
    };

    // eslint-disable-next-line
    const handleSubmit = (e) => {
        e.preventDefault();
        // file is the file/image uploaded
        // in this function you can save the image (file) on form submit
        // you have to call it yourself
    };
    const handleClick = () => {
        fileInput.current.click();
    };

    const handleUpload = async () => {
        console.log("handleUpload started");
        // let response = await taskService.createTask(1000,fileName,"BULK UPLOAD","QUESTION IMPORT")
        //console.log("response",response)

        if (payload) {
            console.log("posting payload", payload);
            // bulkUpload(payload);
            uploadUsers(payload)
        }

        //await taskService.updateTask(response)
    };

    // React.useEffect(() => {
    //     if (isSuccess == true) {
    //         successAlert();
    //         setFile(null);
    //     }
    // }, [isSuccess]);

    React.useEffect(() => {
        console.log(uploadUsersResp, 'status123')
        if (uploadUsersResp.status == 400) {
            errorAlert(uploadUsersResp.messege?.message, uploadUsersResp.messege?.content['$values']);
            setFile(null);
        }
        console.log(errorLength == 0, 'validate123', errorLength, 'findLengths', errorLength)
        if (errorLength == 0 && (uploadUsersResp.status == 200 || uploadUsersResp.status == 204)) {
            successAlert();
            setFile(null);
        }
    }, [uploadUsersResp]);

    return (
        <div>
            {alert}
            <input
                type="file"
                onChange={handleChange}
                ref={fileInput}
                accept=".xls,.xlsx"
                style={{ display: "none" }}
            />

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button
                            color="rose"
                            onClick={() => handleClick()}
                            style={{ marginTop: 20, textTransform: "none" }}
                            disabled={isLoading == true}
                        >
                            <CloudUploadIcon />
                            {isLoading == true
                                ? "Uploading..."
                                : props.label || "Import"}
                        </Button>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
QuickTestUserFileUpload.propTypes = {
    avatar: PropTypes.bool,
    addButtonProps: PropTypes.object,
    changeButtonProps: PropTypes.object,
    removeButtonProps: PropTypes.object,
};
