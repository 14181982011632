import axios from "axios";
import { configUrl } from "config.js";
import { useQuery } from "react-query";


export default function useGetAllConfigurations(configType, isActive, isContentProvider) {

  return useQuery("GetAllEduBoard", async () => {
    if (configType == null) {
      console.log(configType);
      return null;
    }
    if (isActive == undefined || isActive == "") {
      isActive = false;
    }
    if (isContentProvider == undefined) {
      isContentProvider = false;
    }
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/DemoghraphicInformation/${configType}/${isContentProvider}/${isActive}`
    );
    return response.data["$values"];
  },
    {
      staleTime: 1000 * 60 * 5,
    });
}
