import axios from "axios";
import { configUrl } from "config.js";

const getAllCourses = async (page, pageSize, contentFilter) => {
  let ContentFilter = contentFilter ? parseInt(contentFilter) : 0;

  let coursesResponse = await axios.get(
    `${configUrl.AssessmentServer}/api/Courses?pageNumber=${page}&pageSize=${pageSize}&contentFilter=${ContentFilter}`
  );
  return coursesResponse.data["$values"];
};
export default {
  getAllCourses: getAllCourses,
}