import axios from "axios";
import { configUrl } from "config.js";
import { useQuery } from "react-query";

export default function useGetQuestionsByPoolId(poolId) {
  return useQuery(["questionsByPoolId", poolId], async () => {
    let response = await axios.get(`${configUrl.AssessmentServer}/api/PoolQuestionMappings/questionsByPoolId/${poolId}`);

    return response.data["$values"].sort((a, b) => a.sequence - b.sequence);
  },
    // BSS121 - will uncomment it after making a seperate service for this call
    // {
    //   staleTime: 1000 * 60 * 5,
    // }
  );
}