import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery } from "react-query";

export default function useGetAllInterests() {
    
  const { config, providerId } = useContext(LoginContext);

  return useQuery(
    "Interests",
    async () => {
      let response = await axios.get(`${config.api}/api/Interest/getInterestByProviderId/${providerId}`);
      console.log(response)
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );
}
