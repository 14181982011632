import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import {configUrl} from "config.js";

export default function useDeletePoolQuestion() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      await axios.delete(
        `${configUrl.AssessmentServer}/api/PoolQuestionMappings/${data.poolId}/${data.questionId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("questionsByPoolId");
      },
    }
  );
}
