import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import {configUrl} from "config.js";

export default function useBulkUpdateTopiQuestionMapping() {
  let queryClient = useQueryClient();
  return useMutation(
    async ({ topicId, questionIds }) => {
      console.log(topicId, questionIds);
      await axios.put(
        `${configUrl.AssessmentServer}/api/TopicQuestionMappings/bulkUpdateTopicQuestionMapping/${topicId}`,
        {
          questionIds: questionIds,
          topicId: topicId,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );
}
