import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import {configUrl} from "config.js";

export default function useCreateTestResult() {
  const queryClient = useQueryClient();

  return useMutation(async (result) => {
    await axios.post(`${configUrl.AssessmentServer}/api/TestResults`, result);
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries("assignedTests");
    },
  });
}


