import React from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import DateRange from "@material-ui/icons/DateRange";

// core components
import FavoriteIcon from '@material-ui/icons/Favorite';
import ForumIcon from '@material-ui/icons/Forum';
import GroupIcon from '@material-ui/icons/Group';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import useGetAllUserCountByCity from "hooks/analyticHooks/useGetAllUserCountByCity";
import useGetAllUserCountByState from "hooks/analyticHooks/useGetAllUserCountByState";
import useGetAllDashboardCounts from "hooks/analyticHooks/useGetDashboardAllCounts";
import useGetTopInterest from "hooks/analyticHooks/useGetTopInterest";
import useGetAllUserCountByClass from "hooks/analyticHooks/useGetUserCountByClass";
import { withRouter } from "react-router-dom";
import SimpleBarChart from "views/Components/SimpleBarChart";



var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

const useStyles = makeStyles(styles);

function StudentDashboard(props) {
  const classes = useStyles();
  const {
    data: allCounts
  } = useGetAllDashboardCounts();


  console.log(allCounts);


  const { data: userCountByClass } = useGetAllUserCountByClass();

  const { data: userCountByCity } = useGetAllUserCountByCity();

  const { data: userCountByState } = useGetAllUserCountByState();

  const { data: userCountByInterest } = useGetTopInterest();

  console.log(userCountByClass);

  console.log(userCountByCity);

  console.log(userCountByState);
  console.log(userCountByInterest);


  // const { data: courseCount } = useGetAssignedCoursesCount();



  // const { data: testsCount } = useGetAssignedTestsCount();

  // const { data: questionsCount } = useGetQuestionsCount();

  // const { data: details } = useGetAssignedTestsDetails();


  const [trendData, setTrendData] = React.useState([["High Score Topic", "Low Score Topic"]]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => {
              props.history.push("/admin/users");
            }}

            style={{ cursor: "pointer" }}
          >
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <GroupIcon />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                Students ({allCounts == undefined ? "..." : allCounts.studentCount})
              </h4>
              <h3 className={classes.cardCategory}>{'\u00A0'} {'\u00A0'}{'\u00A0'}</h3>
              <h3 className={classes.cardCategory}>{'\u00A0'} {'\u00A0'}{'\u00A0'}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => {
              props.history.push("/admin/Configuration");
            }}

            style={{ cursor: "pointer" }}
          >
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <FavoriteIcon />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                Interests ({allCounts == undefined ? "..." : allCounts.interestCount})
              </h4>
              <h3 className={classes.cardCategory}>{'\u00A0'} {'\u00A0'}{'\u00A0'}</h3>
              <h3 className={classes.cardCategory}>{'\u00A0'} {'\u00A0'}{'\u00A0'}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => {
              props.history.push("/admin/notifications");
            }}

            style={{ cursor: "pointer" }}
          >
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <ForumIcon />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                Messages
              </h4>
              <h3 className={classes.cardCategory}> Schedule ({allCounts == undefined ? "..." : allCounts.messageCount.scheduledCount}) </h3>
              <h3 className={classes.cardCategory}> Delivered ({allCounts == undefined ? "..." : allCounts.messageCount.deliveredCount}) </h3>

            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <SimpleBarChart chartDetails={userCountByClass} title="Class wise Admissions" color="rose"></SimpleBarChart>
        <SimpleBarChart chartDetails={userCountByCity} title="City wise Admissions" color="rose"></SimpleBarChart>

      </GridContainer>
      <GridContainer>
        <SimpleBarChart chartDetails={userCountByState} title="State wise Admissions" color="rose"></SimpleBarChart>
        <SimpleBarChart chartDetails={userCountByInterest} title="Interest of Student" color="rose" ></SimpleBarChart>
      </GridContainer>
      {/* <Charts></Charts> */}
    </div>
  );
}

export default withRouter(StudentDashboard);
