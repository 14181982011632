import { LoginContext } from "contexts/LoginContext";
import useGetAssignedTests from "hooks/courseHooks/useGetAssignedTests";
import useGetUserByUserName from "hooks/userHooks/useGetUserByUserName";

import { makeStyles } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import EvaluationQuestionWrapper from "components/Questions/EvaluationQuestionWrapper";
import useGetAttempCount from "hooks/resultHooks/useGetAttempCount";
import React, { useContext } from "react";
import TestPlayer from "views/Player/TestPlayer";

const useStyles = makeStyles(styles);

function CourseTestViewer(props) {
  const { user } = useContext(LoginContext);

  const { data: userDetails, mutate: refetch } = useGetUserByUserName();

  const attempt = useGetAttempCount();

  let state = null;

  if (props.location && props.location.state) {
    state = props.location.state.state;
  }

  const { data: testDetails } = useGetAssignedTests(
    state,
    userDetails ? userDetails.userId : null
  );

  const [showTest, setShowTest] = React.useState(false);
  const [testIndex, setTestIndex] = React.useState(0);

  const [testPlayerModal, setTestPlayerModal] = React.useState(null);
  const [evaluationModel, setEvaluationModel] = React.useState(null);

  const [attemptsTaken, setAttemptsTaken] = React.useState(null);

  const [test, setTest] = React.useState(null);

  const getAttempts = async (id) => {
    let data = await attempt.mutateAsync({
      userId: user,
      testId: id,
    });

    console.log("data", data);
    return data;
  };

  const showTestModel = (i, id, a) => {
    console.log("att", a);
    setTestPlayerModal(
      <CustomModal
        modal={true}
        setModal={hideTestModal}
        content={
          <TestPlayer
            tests={getCurrentTest(i)}
            testId={id}
            userId={userDetails ? userDetails.userId : null}
            attempt={a}
            isCourse={true}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideTestModal}
        title={`Test Schedule for`}
        maxWidth="lg"
        fullWidth={true}
        hideTitle={true}
        fullScreen
      />
    );
  };

  const hideTestModal = (event, reason) => {
    if (reason) console.log("reason", reason);
    setTestPlayerModal(null);
  };

  const showEvaluationModel = (id) => {
    setEvaluationModel(
      <CustomModal
        modal={true}
        setModal={hideEvaluationModel}
        content={
          <EvaluationQuestionWrapper
            testId={id}
            userId={userDetails ? userDetails.userId : null}
            isCourse={true}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideEvaluationModel}
        title={`Evaluation`}
        maxWidth="lg"
        hideTitle={true}
        fullWidth={true}
      />
    );
  };

  const hideEvaluationModel = (event, reason) => {
    if (reason) console.log("reason", reason);
    setEvaluationModel(null);
  };

  const hidePlayer = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowTest(false);
  };

  const getCurrentTest = (i) => {
    let currentTest = testDetails[i].data;
    return JSON.parse(currentTest).value;
  };

  console.log(testDetails);

  React.useEffect(() => {
    refetch(user);
  }, []);

  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Language />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Tests assigned to you</h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                  {testDetails && testDetails.length > 0 && (
                    <>
                      {/*   <Table
                        tableData={testDetails.map((x, i) => {
                          return [
                            x.name,
                            x.objective,
                            <Button
                              color="success"
                              onClick={() => {
                                console.log("index", i);
                                setTestIndex(i);
                                showTestModel(
                                  i,
                                  x.testId,
                                  (x.attemptNumber ?? 0) + 1
                                );
                              }}
                              //disabled={x.maxAttempts <= x.attemptNumber ?? 0}
                            >
                              Take Test
                            </Button>,
                            <Button
                              color="success"
                              onClick={() => showEvaluationModel(x.testId)}
                              // disabled={!x.maxAttempts}
                            >
                              View Assessment
                            </Button>,
                          ];
                        })}
                      /> */}
                      {testDetails.map((x, i) => (
                        <Card login>
                          <CardHeader><h4>{x.name} - {x.objective}</h4></CardHeader>

                          <CardFooter style={{ width: "90%" }}>
                            <GridContainer >
                              <GridItem md={12} style={{ textAlign: "right" }}>
                                <Button
                                  color="success"
                                  onClick={() => {
                                    console.log("index", i);
                                    setTestIndex(i);
                                    showTestModel(
                                      i,
                                      x.testId,
                                      (x.attemptNumber ?? 0) + 1
                                    );
                                  }}
                                //disabled={x.maxAttempts <= x.attemptNumber ?? 0}
                                >
                                  Take Test
                                </Button>
                                ,
                                <Button
                                  color="success"
                                  onClick={() => showEvaluationModel(x.testId)}
                                // disabled={!x.maxAttempts}
                                >
                                  View Assessment
                                </Button>
                              </GridItem>
                            </GridContainer>
                          </CardFooter>
                        </Card>
                      ))}
                    </>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
            {testPlayerModal}
            {evaluationModel}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default CourseTestViewer;
