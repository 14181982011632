import React from "react";
import Player from "views/Player/Player";

export default function QuestionPreview({ model }) {
    const [prevModal, setPrevModal] = React.useState(
        {
            $id: "2",
            id: 1090,
            contentType: 1,
            text: " ",
            options: {
                $id: "3",
                $values: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ]
            },
            answer: " ",
            type: " ",
            info: " ",
            hint: null,
            image: null,
            solution: null,
            solutionText: null
        }
    )
    if (Object.keys(model).length !== 0) {
        prevModal.id = model.questionId,
            prevModal.contentType = model?.contentType,
            prevModal.text = model.questionLanguages["$values"][0]?.text,
            prevModal.options = {
                $id: "3",
                $values: [
                    model?.questionChoices["$values"][0]?.choiceId01,
                    model?.questionChoices["$values"][0]?.choiceId02,
                    model?.questionChoices["$values"][0]?.choiceId03,
                    model?.questionChoices["$values"][0]?.choiceId04,
                    model?.questionChoices["$values"][0]?.choiceId05,
                    model?.questionChoices["$values"][0]?.choiceId06,
                ]
            },
            prevModal.answer = model?.questionChoices["$values"][0]?.choiceIdAnswers,
            prevModal.type = model?.questionType == 1 ? "MCQ" : model?.questionType == 2 ? "SCQ" : model?.questionType == 3 ? "TF" : model?.questionType == 4 ? "NUMERICAL" : "",
            prevModal.info = "",
            prevModal.hint = model?.questionLanguages["$values"][0]?.hint,
            prevModal.image = model?.questionLanguages["$values"][0]?.image,
            prevModal.solution = model?.questionLanguages["$values"][0]?.solution,
            prevModal.solutionText = model?.questionLanguages["$values"][0]?.solutionText
    }
    let Prev = [];
    Prev.push(prevModal);
    return (
        <div>
            <Player questions={Prev} preview={true} />
        </div>
    )
}
