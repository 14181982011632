import { IconButton, InputAdornment, Snackbar, makeStyles } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import changePasswordService from "services/ProviderServices/changePasswordService";
const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    inputAdornmentIcon: {
        color: "#555",
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px",
    },
    ...customSelectStyle,
};

const useStyles = makeStyles(styles);



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ChangePassword(props) {
    const classes = useStyles();
    const history = useHistory();
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordState, setCurrentPasswordState] = useState("success");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordState, setNewPasswordState] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordState, setConfirmPasswordState] = useState("");
    const [loading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [severity, setSeverity] = useState("success");

    useEffect(() => {
        console.log('called1')
        setCurrentPassword("");
        // setCurrentPasswordState("")
        setNewPassword("")
        setNewPasswordState("")
        setConfirmPassword("")
        setConfirmPasswordState("")
    }, [props.isResetFields])


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };

    const verifyPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;
        return regex.test(password);
    };

    const verifyConfirmedPassword = (password, confirmPassword) => {
        return password === confirmPassword;
    };

    const changePasswordCallMutation = changePasswordService();
    console.log(changePasswordCallMutation, 'checkingloading')
    const handleSubmit = async () => {
        console.log(currentPassword, newPassword, confirmPassword, "fields");
        const data = {
            currentPassword: currentPassword,
            newPassword: newPassword
        }
        try {
            if (
                currentPasswordState === "success" &&
                newPasswordState === "success" &&
                confirmPasswordState === "success"
            ) {
                setIsLoading(true);
                const response = await changePasswordCallMutation.mutateAsync(data);
                setIsLoading(false);
                console.log(response, "data8");
                if (response?.status == true) {
                    showSnackBar(response?.data?.message ?? "Password Updated successfully");
                    setTimeout(() => {
                        props.afterFinish();
                        history.push("/letsStart");
                    }, 3000);

                } else {
                    showSnackBar(response?.data?.error ?? "The password has not been changed.", "error");
                    setTimeout(() => {
                        props.afterFinish();
                    }, 3000);
                }
            }
        } catch (error) {
            console.log(error, "error");
        }


    };

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleShowNewPassword = () => {
        setShowNewPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
    };
    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };

    return (
        <div style={{ width: "100%", height: "100%", minHeight: "450px", maxHeight: "450px", minWidth: "100px", margin: "0 auto" }}>
            {/* <Card style={{ margin: "10px" }}> */}
            <GridContainer alignItems="flex-end" style={{ marginTop: "10px" }}>
                <GridItem xs={12} sm={12}>
                    <CustomInput
                        success={currentPasswordState === "success"}
                        error={currentPasswordState === "error"}
                        labelText={
                            <span>
                                Current password <small>(required)</small>
                            </span>
                        }
                        id="currentPassword"
                        helperText={currentPasswordState == "error" && currentPassword.length > 0 ? "Password should contain special charachters uppercase,lowercase characters and number" : ''}
                        inputProps={{
                            name: "currentPassword",
                            type: showPassword ? 'text' : 'password',
                            value: currentPassword,
                            onChange: (event) => {
                                // if (!verifyPassword(event.target.value)) {
                                //     setCurrentPasswordState("error");
                                // } else {
                                //     setCurrentPasswordState("success");
                                // }
                                setCurrentPassword(event.target.value);
                            },
                            endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                    <IconButton onClick={toggleShowPassword}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),

                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}


                    />
                </GridItem>
            </GridContainer>
            <GridContainer alignItems="flex-end" style={{ marginTop: "10px" }}>
                <GridItem xs={12} sm={12}>
                    <CustomInput
                        success={newPasswordState === "success"}
                        error={newPasswordState === "error"}
                        labelText={
                            <span>
                                New password <small>(required)</small>
                            </span>
                        }
                        id="newPassword"
                        helperText={newPasswordState == "error" && newPassword.length > 0 ? "Password should contain special charachters uppercase,lowercase characters and number" : ''}
                        inputProps={{
                            name: "newPassword",
                            type: showNewPassword ? 'text' : 'password',
                            value: newPassword,
                            onChange: (event) => {
                                if (!verifyPassword(event.target.value)) {
                                    setNewPasswordState("error");
                                } else {
                                    setNewPasswordState("success");
                                }
                                setNewPassword(event.target.value);
                            },
                            endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                    <IconButton onClick={toggleShowNewPassword}>
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer alignItems="flex-end" style={{ marginTop: "10px" }}>
                <GridItem xs={12} sm={12}>
                    <CustomInput
                        success={newPassword?.trim()?.length > 0 ?? newPassword == confirmPassword}
                        error={newPassword != confirmPassword}
                        labelText={
                            <span>
                                Confirm password <small>(required)</small>
                            </span>
                        }
                        id="confirmPassword"
                        helperText={confirmPassword != newPassword ? "The confirmed password does not match the original." : ''}
                        inputProps={{
                            name: "confirmPassword",
                            type: showConfirmPassword ? 'text' : 'password',
                            value: confirmPassword,
                            onChange: (event) => {
                                if (!verifyConfirmedPassword(event.target.value, newPassword)) {
                                    setConfirmPasswordState("error");
                                } else {
                                    setConfirmPasswordState("success");
                                }
                                setConfirmPassword(event.target.value);
                            },
                            endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                    <IconButton onClick={toggleShowConfirmPassword}>
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Button color="primary" onClick={handleSubmit} style={{ float: "right", marginTop: "20px" }} disabled={loading == true}>
                        {
                            loading ? "Updating..." :
                                "Change password"
                        }
                    </Button>
                </GridItem>
            </GridContainer>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {/* </Card> */}
        </div>
    );
}

export default ChangePassword;
