import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { LoginContext } from "contexts/LoginContext";
import useGetRoles from "hooks/PrevilegesHooks/useGetRoles";
import useGetCategories from "hooks/categoryHooks/useGetCategories";
import useGetAllState from "hooks/configurationsHooks/useGetAllState";
import useCreateApplicationUser from "hooks/userHooks/useCreateApplicationUser";
import useGetUserByUserName from "hooks/userHooks/useGetUserByUserName";
import useUpdateApplicationUser from "hooks/userHooks/useUpdateApplicationUser";
import React, { useContext } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";

const TreeDropDown = React.memo(
  ({ data, onChange, className }) => {
    return (
      <>
        <DropdownTreeSelect
          data={data}
          onChange={onChange}
          className={className}
          texts={{ placeholder: "Select Category" }}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);

function User(props) {
  const [user, setUser] = React.useState(props.user);
  const [rows, setRows] = React.useState([]);
  const { providerType } = useContext(LoginContext);

  const { data: categories } = useGetCategories();
  console.log("props", props);

  const updateUser = useUpdateApplicationUser();
  const createUser = useCreateApplicationUser();

  const {
    data: userDetails,
    isLoading,
    mutate: getUserDetails,
    error: userFetchError,
  } = useGetUserByUserName();
  const [options1, setOptins1] = React.useState([]);
  const [prevOptions, setPrevOptions] = React.useState([]);
  const [prevOptionsId, setprevOptionsId] = React.useState(props.roleId);
  const [showAlert, setShowAlert] = React.useState(null)
  const [flag, setFlag] = React.useState(false)

  console.log(props, 'itsProps')
  console.log(prevOptionsId, 'propsID')
  console.log(options1);
  const { data: statedata } = useGetAllState("state", true);

  console.log(statedata);
  const { data: getRoles } = useGetRoles()
  console.log(getRoles, 'roles123')

  React.useEffect(() => {
    if (statedata) {
      setRows(
        statedata.map((x, i) => {
          console.log(x);
          return {
            ...x,
            id: i,
            name: x.valueString,
            id: x.id,
            value: x.valueString,
          };
        })
      );
    }
  }, [statedata]);
  React.useEffect(() => {
    if (getRoles != undefined) {
      let roles = [];
      getRoles.map((role) => (
        console.log(role, providerType, 'rolescheck'),
        role.id == providerType ? roles.push(role) : null
        // role.id < 5 ?
        //   roles.push(role) : null
      ))
      console.log(roles, 'roles125')
      setPrevOptions(roles)
    }
  }, [getRoles])
  const [userExists, setUserExists] = React.useState(false);

  const [categoryTree, setCategoryTree] = React.useState(null);
  const createHierarchy = (root) => {
    if (!root) return;

    root.label = root.name;
    root.value = root.id;

    let children = [...categories]
      .map((x) => {
        return { ...x };
      })
      .filter((x) => x.parent == root.id && x.isCategory == true);

    if (children && children.length > 0) {
      root.children = [];
      let cats = [];

      if (props.user && props.user.topics) {
        cats = props.user.topics.split(",");
      }

      children.forEach((x) => {
        x.children = [];
        if (x.isCategory == true) {
          if (cats && cats.length > 0) {
            if (cats.includes(x.id)) {
              x.checked = true;
            } else {
              x.checked = false;
            }
          }

          x.value = x.id;
          x.label = x.name;
          root.children.push(x);
          createHierarchy(x);
        }
      });
    }

    return;
  };

  React.useEffect(() => {
    if (categories) {
      let root = [...categories]
        .map((x) => {
          return { ...x };
        })
        .find((x) => x.parent == null);

      if (props.user && props.user.topics) {
        const cats = props.user.topics.split(",");
        if (cats.includes(root.id)) {
          root.checked = true;
        }
      }

      createHierarchy(root);
      setCategoryTree(root);
    }
  }, [categories]);

  const handleChange = (e) => {
    let { name, value } = e.target;

    //addding blank for now will add validations later
    if (!value) {
      value = " ";
    }
    if (name == 'userName') {
      setUser({ ...user, [name]: value.trim() });
      return
    }
    setUser({ ...user, [name]: value });
  };
  const handleChangeDropdown = (v) => {
    setprevOptionsId(v);
    console.log(v, 'prevOptionsId1')
    console.log(prevOptionsId, 'prevOptionsId')
  }

  React.useEffect(() => {
    if (updateUser.isSuccess) {
      <CustomAlert
        open={true}
        openFlag={() => setFlag(flag ? false : true)}
        severity="success"
        alertMessage="User updated Successfully"
      />
      setTimeout(() => {
        props.afterFinish();
      }, 2000);
    }
  }, [updateUser.isSuccess]);

  React.useEffect(() => {
    if (updateUser.isError) {
      <CustomAlert
        open={true}
        openFlag={() => setFlag(flag ? false : true)}
        severity="error"
        alertMessage="User update failed"
      />
    }
  }, [updateUser.isError])

  React.useEffect(() => {
    if (createUser.isError) {
      <CustomAlert
        open={true}
        openFlag={() => setFlag(flag ? false : true)}
        severity="error"
        alertMessage="User creation failed"
      />
    }
  }, [createUser.isError])

  React.useEffect(() => {
    if (createUser.isSuccess) {

      <CustomAlert
        open={true}
        openFlag={() => setFlag(flag ? false : true)}
        afterfinish={props.afterFinish}
        severity="success"
        alertMessage="User created Successfully"
      />
      setTimeout(() => {
        props.afterFinish();
      }, 2000);
    }
  }, [createUser.isSuccess]);

  React.useEffect(() => {
    if (userDetails && userDetails.userName) {
      setUserExists(true);
      return;
    }

    setUserExists(false);
  }, [userDetails]);

  const checkIfUserNameExists = (val) => {
    if (!val.target.value) {
      return;
    }

    getUserDetails(val.target.value);
  };

  const onChange = (currentNode, selectedNodes) => {
    let selectedCats = selectedNodes.map((x) => x.id).join();
    setUser({ ...user, ["topics"]: selectedCats });
  };
  console.log(user, 'updatedUsers')
  return (
    <div>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <props.icon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {user && (
            <form autoComplete="off">
              <GridContainer justifyContent="center">

                {/* <GridItem xs={12} sm={12} md={8} style={{ marginTop: 36 }}>
                  {categoryTree && props.userType && props.userType == 2 && (
                    <TreeDropDown
                      data={categoryTree}
                      onChange={onChange}
                      className="mdl-demo"
                    />
                  )}
                </GridItem> */}
                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText={<span>First Name</span>}
                    id="firstName"
                    // type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    inputProps={{
                      // type: "datetime-local",
                      name: "firstName",
                      onChange: handleChange,
                      value: user.firstName,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText={<span>Middle Name</span>}
                    id="middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "middleName",
                      onChange: handleChange,
                      value: user.middleName,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText={<span>Last Name</span>}
                    id="lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    inputProps={{
                      name: "lastName",
                      onChange: handleChange,
                      value: user.lastName,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={<span>Mobile</span>}
                    id="mobile"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    inputProps={{
                      name: "mobile",
                      onChange: handleChange,
                      value: user.mobile,
                      type: "number",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText={<span>Email</span>}
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    inputProps={{
                      name: "email",
                      onChange: handleChange,
                      value: user.email,
                      type: "email",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={<span>Address</span>}
                    id="address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "address",
                      onChange: handleChange,
                      value: user.address,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelect
                    name="roles"
                    label="Roles *"
                    options={prevOptions}
                    setvalue={(v) =>
                      setprevOptionsId(v)
                    }
                    value={prevOptionsId}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={<span>User Name</span>}
                    id="userName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    error={userExists}
                    helperText={userExists == true ? "User Name Exists" : ""}
                    inputProps={{
                      name: "userName",
                      onChange: handleChange,
                      onBlur: (v) => checkIfUserNameExists(v),
                      value: user.userName,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={<span>Password</span>}
                    id="passCode"
                    required={true}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "passCode",
                      onChange: handleChange,
                      value: user.passCode,
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    color="primary"
                    onClick={props.afterFinish}
                    style={{ marginTop: 20, marginRight: 20, textTransform: 'none' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      let userDataObj = {};
                      userDataObj.users = user;
                      userDataObj.prevSelectedId = prevOptionsId;
                      console.log(user, 'userId')
                      if (user.userId) {
                        console.log(userDataObj, 'inHere')
                        updateUser.mutate(userDataObj)
                      } else {
                        console.log('inHere1')
                        createUser.mutate(userDataObj)
                      };
                      //getQ();
                    }}
                    style={{ marginTop: 20, textTransform: 'none' }}
                    disabled={
                      updateUser.isLoading ||
                      createUser.isLoading ||
                      userExists ||
                      !user.firstName ||
                      !user.lastName ||
                      !user.mobile ||
                      !user.email ||
                      !user.passCode ||
                      !user.userName
                    }
                  >
                    {user.userId
                      ? updateUser.isLoading
                        ? "Updating..."
                        : "Update"
                      : "Add"}
                  </Button>
                </GridItem>
              </GridContainer>
              {showAlert}
            </form>
          )}
        </CardBody>
      </Card>
    </div >
  );
}

export default User;
