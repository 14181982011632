import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import { LoginContext } from "contexts/LoginContext";
import React, { useContext, useRef } from "react";
// import { DataGridPro } from '@material-ui/data-grid';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@material-ui/data-grid';
// import { GridFilterModel, DataGridPro } from '@material-ui/data-grid';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import radioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import CustomAlert from "components/CustomAlert/CustomAlert";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import CustomModal from "components/CustomModal/CustomModal";
import GridItem from "components/Grid/GridItem";
import useGetAllCity from "hooks/configurationsHooks/useGetAllCity";
import useGetAllClass from "hooks/configurationsHooks/useGetAllClass";
import useGetAllEduBoard from "hooks/configurationsHooks/useGetAllEduBoard";
import useGetAllEducation from "hooks/configurationsHooks/useGetAllEducation";
import useGetAllNativeLanguage from "hooks/configurationsHooks/useGetAllNativeLanguage";
import useGetAllProfession from "hooks/configurationsHooks/useGetAllProfession";
import useGetAllState from "hooks/configurationsHooks/useGetAllState";
import useGetAllCourses from "hooks/courseHooks/useGetAllCourses";
import useDeleteUser from "hooks/userHooks/useDeleteUser";
import useUpdateUser from "hooks/userHooks/useUpdateUser";
import getAllStudents from "services/ApplicationUser/getAllStudents";
import AcceptRejectStudentForm from "views/Forms/AcceptRejectStudentForm";
import StudentRegister from "views/Forms/StudentRegister";
import UploadefileUsers from "views/Forms/UploadefileUsers";
// import { DataGridPro, GridToolbar,GridLinkOperator } from "@mui/x-data-grid-pro";
var styles = {
  ...radioStyles,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
};

const useStyles = makeStyles(styles);
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function UserListing(props) {

  const updateUser = useUpdateUser();
  const deleteUser = useDeleteUser();
  const { user, userPrivileges } = useContext(LoginContext);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  console.log(rows)
  const [userEditForm, setUserEditForm] = React.useState(true);
  const [deleteModal, setDeleteModal] = React.useState(null);
  const [state, setStats] = React.useState([]);
  const [citys, setCitys] = React.useState([]);
  const [Courses, setCourses] = React.useState([])
  const [language, setLanguage] = React.useState([]);
  const [educationbord, setEducattionBord] = React.useState([]);
  const [clas, setClas] = React.useState([]);
  const [professions, setProfessions] = React.useState([]);
  const [alleducation, setAllEducation] = React.useState([]);
  const [stateValueInt, setStateValueInt] = React.useState('');
  const [pendingStudents, setPendingStudents] = React.useState(true);
  const [studentFilter, setStudentFilter] = React.useState('1')
  const [select, setSelection] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(null)
  const [flag, setFlag] = React.useState(false)

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })

  React.useEffect(() => {
    const fetchData = async () => {
      setPageState(old => ({ ...old, isLoading: true }))
      console.log("it is", studentFilter, studentFilter == 1 ? false : true);
      let filterStudents = studentFilter == 1 ? true : false;
      let getStudents = await getAllStudents.getAllStudents(pageState.page, pageState.pageSize, filterStudents);
      console.log(getStudents, 'resp222')
      let Students = getStudents["$values"].map((x, i) => { return { ...x, id: x.userId }; })

      setPageState(old => ({ ...old, isLoading: false, data: Students, total: Students.length > 0 ? Students[0].totalCount : 0 }))
    }
    if (pageState.page > 0) {
      fetchData();
    }
  }, [pageState.page, pageState.pageSize, userEditForm, studentFilter])

  console.log(stateValueInt)
  // const [st,setSt]=React.useState('');
  const ref = useRef(null);
  const [refresh, setRefresh] = React.useState(0);
  const refreshDashboard = () => {
    setRefresh(refresh + 1);
  };
  const classes = useStyles();
  const { data: statedata } = useGetAllState("state", true);
  const { data: getcity } = useGetAllCity("city", true)
  const { isLoading, error, data: courses, refetch } = useGetAllCourses();

  console.log(getcity)
  getcity
  const { data: getlanguage } = useGetAllNativeLanguage("language", true)
  const { data: geteduboard } = useGetAllEduBoard("EduBoard", true)
  const { data: getclass } = useGetAllClass("class", true)
  const { data: getprofession } = useGetAllProfession("profession", true)
  const { data: getalleducation } = useGetAllEducation("Education", true)
  console.log(getprofession)

  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.Student.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  React.useEffect(() => {
    if (statedata) {
      setStats(
        statedata.filter(o => o.isActive)
          .map((x, i) => {
            console.log(x)
            console.log("xid....", x.id)

            if (x.isDefault) {
              setStateValueInt(x.valueInt == null ?? 1)
            }
            return { ...x, name: x.valueString, id: x.valueInt, value: x.valueString };
          })
      );
    }
  }, [statedata]);
  console.log(state)
  let st = state.filter(o => o.isDefault)
  console.log(st)
  React.useEffect(() => {

    if (getcity) {
      setCitys(
        getcity.filter(o => o.isActive)
          .map((x, i) => {

            return { ...x, name: x.valueString, id: x.valueInt, value: x.valueString };
          })
      );
    }
  }, [getcity]);

  console.log(citys)
  let ci = citys.filter(o => o.isDefault)
  console.log(ci)

  React.useEffect(() => {

    if (courses) {
      setCourses(
        courses.filter(o => o.isActive && (o.status >= 2) && new Date(o.expiryDate).getTime() > new Date().getTime())
          .map((x, i) => {
            return { ...x, name: x.text, id: x.courseId, value: x.text };
          })
      );
    }
  }, [courses]);

  console.log(Courses, "Courses")
  let co = Courses.filter(o => o.isDefault)
  console.log(co)

  React.useEffect(() => {
    if (getlanguage) {
      setLanguage(
        getlanguage.filter(o => o.isActive)
          .map((x, i) => {
            // console.log(x)
            return { ...x, name: x.valueString, id: x.valueInt, value: x.valueString };
          })
      );
    }
  }, [getlanguage]);
  let la = language.filter(o => o.isDefault)
  console.log(la)
  React.useEffect(() => {
    if (geteduboard) {
      setEducattionBord(
        geteduboard.filter(o => o.isActive)
          .map((x, i) => {
            // console.log(x)
            return { ...x, name: x.valueString, id: x.valueInt, value: x.valueString };
          })
      );
    }
  }, [geteduboard]);
  let ed = educationbord.filter(o => o.isDefault)
  console.log(ed)
  React.useEffect(() => {
    if (getclass) {
      setClas(
        getclass.filter(o => o.isActive)
          .map((x, i) => {
            // console.log(x)
            return { ...x, name: x.valueString, id: x.valueInt, value: x.valueString };
          })
      );
    }
  }, [getclass]);
  let cl = clas.filter(o => o.isDefault)
  console.log(cl)
  React.useEffect(() => {
    if (getprofession) {
      setProfessions(
        getprofession.filter(o => o.isActive)
          .map((x, i) => {
            // console.log(x)
            return { ...x, name: x.valueString, id: x.valueInt, value: x.valueString };
          })
      );
    }
  }, [getprofession]);
  let pro = professions.filter(o => o.isDefault)
  console.log(pro)
  React.useEffect(() => {
    if (getalleducation) {
      setAllEducation(
        getalleducation.filter(o => o.isActive)
          .map((x, i) => {
            // console.log(x)
            return { ...x, name: x.valueString, id: x.valueInt, value: x.valueString };
          })
      );
    }
  }, [getalleducation]);
  let alledu = alleducation.filter(o => o.isDefault)
  console.log(alledu)

  const showDeleteConfirmation = (id) => {
    setDeleteModal(
      <CustomModal
        modal={true}
        setModal={() => hideDeleteCustomModal()}
        content={<h3>Are you sure you want to delete the user?</h3>}
        cancelButtonMessage="Cancel"
        okButtonMessage={deleteUser.isLoading ? "Deleting" : "Yes"}
        showOk={true}
        onCancelClick={() => hideDeleteCustomModal()}
        title="Delete User"
        maxWidth="sm"
        onOkClick={async () => {
          await deleteUser.mutateAsync(id);
          hideDeleteCustomModal();
        }}
        showCancel={true}
        padding1={true}
      />
    );
  };

  const hideDeleteCustomModal = () => {
    setDeleteModal(null);
  };

  const onDelete = async (e, id) => {
    showDeleteConfirmation(id);
  };

  const onUpdate = (e, values) => {
    e.preventDefault();

    updateUser.mutate(values.row);
  };

  const onInfo = (e, id) => {
    alert("info");
  };

  const handleRowSelection = (e) => {
    setSelection(e)
    console.log(e, 'selection');
  };

  const onCellEditCommit = (p) => { };

  const onCellBlur = (e, p) => { };

  const handleEdit = (p, e) => { };
  console.log(studentFilter, studentFilter == '2', 'contentFil');
  React.useEffect(() => {
    let cols = [
      {
        field: "fullName",
        headerName: "Full Name",
        editable: false,
        width: 150,
      },
      {
        field: "class",
        headerName: "Class",
        editable: false,
        width: 130,
      },
      {
        field: "board",
        headerName: "Board",
        editable: false,
        width: 130,
      },
      {
        field: "email",
        headerName: "Email",
        editable: false,
        width: 170,

      },
      {
        field: "mobile",
        headerName: "Mobile No",
        editable: false,
        width: 150,
      },
      {
        field: "state",
        headerName: "State",
        editable: false,
        width: 130,
        hide: true,
      },
      {
        field: "city",
        headerName: "City",
        editable: false,
        width: 130,
      },
      {
        field: "parentMobile",
        headerName: "Parent Mobile",
        editable: false,
        width: 167,
      },
      {
        field: "parentEmail",
        headerName: "Parent Email",
        editable: false,
        width: 160,
      },
      // {
      //   field: "firstName",
      //   headerName: "First Name",
      //   editable: true,
      //   width: 150,
      // },
      // {
      //   field: "lastName",
      //   headerName: "Last Name",
      //   editable: true,
      //   width: 150,
      // },


      // {
      //   field: "address",
      //   headerName: "Address",
      //   editable: false,
      //   width: 200,
      // },
      {
        field: "actions",
        headerName: "Actions",
        editable: false,
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          return (
            // fillButtons
            <>
              {
                isPrivilege("UpdateStudent") == true ?
                  <Tooltip title="Edit">
                    <Button
                      size="sm"
                      JustIcon
                      round
                      // color={"info"}
                      style={{ backgroundColor: "#DD4B39" }}
                      className={classes.actionButton}
                      key={1}
                      onClick={() =>
                        createUserEditForm(cellValues.row.userId, "Update Student", "Edit")
                      }
                    >
                      <Edit />
                    </Button>
                  </Tooltip> : ''
              }
              {/* { IconButton of Accpet} */}
              {
                studentFilter == '2' ?
                  <Tooltip title="Approved">
                    <Button
                      size="sm"
                      JustIcon
                      round
                      // color={"info"}
                      style={{ backgroundColor: "#DD4B39" }}
                      className={classes.actionButton}
                      key={2}
                      onClick={() =>
                        handleCheck("accept", [cellValues.row.userId])
                      }
                    >

                      <CheckIcon />
                    </Button>
                  </Tooltip> : null

              }
              {/* { IconButton of Reject} */}
              {

                studentFilter == '2' ?
                  <Tooltip title="Reject">
                    <Button
                      size="sm"
                      JustIcon
                      round
                      // color={"info"}
                      style={{ backgroundColor: "#DD4B39" }}
                      className={classes.actionButton}
                      key={3}
                      onClick={() =>
                        handleCheck("reject", [cellValues.row.userId])
                      }
                    >
                      <CloseIcon />
                    </Button>
                  </Tooltip> : null

              }
              {/* <Button
                size="sm"
                justIcon
                round
                color={"success"}
                className={classes.actionButton}
                key={2}
                onClick={(e) => onUpdate(e, cellValues)}
              >
                <UpdateIcon />
              </Button> */}
              {/* <Button
                size="sm"
                justIcon
                round
                color={"danger"}
                className={classes.actionButton}
                key={3}
                onClick={(e) => onDelete(e, cellValues.row.userId)}
              >
                <Close />
              </Button> */}
            </>
          );
        },
      },
    ];

    setColumns(cols);
  }, [studentFilter]);

  // React.useEffect(() => {
  //   if (pageState.data.length>0) {
  //     setRows(
  //       pageState.data["$values"]
  //         // .filter((x) => x.userTypeId === 4)
  //         .map((x, i) => {
  //           return { ...x, id: i };
  //         })
  //     );
  //   }
  // }, [pageState.data]);

  const handleFilterStudents = (event, content) => {
    // Update the page to 1st page on click on pending students
    setPageState(prevState => ({
      ...prevState,
      page: 1
    }));
    console.log(content, 'contentFilterVals2')
    setStudentFilter(content)
  };
  console.log(pageState.data, 'gridData')
  const createUserEditForm = (userId, title, status) => {

    setUserEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideUserEditForm()}
        content={
          <StudentRegister
            icon={PersonAddIcon}
            user={pageState.data}
            city={"city"}
            class={"class"}
            EduBoard={"EduBoard"}
            language={"language"}
            profession={"profession"}
            Education={"Education"}
            afterFinish={() => hideUserEditForm()}
            clo={userEditForm}
            userType={2}
            row={userId}
            state={"state"}
            status={status}

          />
          // <User
          // user={row}
          // userType={4}
          // />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideUserEditForm()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };
  const createUpoadefileUsers = (row, title) => {
    setUserEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideUserEditForm()}
        content={
          <UploadefileUsers
            icon={PersonAddIcon}
            // user={row}
            state={state}
            statevaluint={st}
            citys={citys}
            citysvalueint={ci}
            courses={Courses}
            coursevaluint={co}
            clas={clas}
            clasvalueint={cl}
            educationbord={educationbord}
            educationbordvalueint={ed}
            language={language}
            languagevalueint={la}
            afterFinish={() => hideUserEditForm()}
            userType={4}
          />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideUserEditForm()}
        title={'Import Students'}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };

  const hideUserEditForm = () => {
    setUserEditForm(false);
  };

  const getRowClassName = (row) => {
    return null;
  };
  const [filterModel, setFilterModel] = React.useState(
    // {
    // items: [
    //   { id: 1, columnField: 'class', operatorValue: 'contains', value: null  },
    //   { id: 2, columnField: 'board', operatorValue: 'contains', value: null  },
    //   { id: 2, columnField: 'state', operatorValue: 'contains', value: null  },
    // ],
    // linkOperator: GridLinkOperator.And,
    // }
  );

  const onhandlechange = (e) => {
    console.log(e)
    setFilterModel(e)
  }
  const [selectedEnabled, setSelectedEnabled] = React.useState(null);

  const handleCheck = (x, studentsIds) => {
    console.log(x, 'action', studentsIds, 'studentids')
    if (studentsIds.length <= 0) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="warning"
          alertMessage={'Please select students'}
        />
      )
      return
    }
    setSelectedEnabled(x);
    setUserEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideUserEditForm()}
        content={
          <AcceptRejectStudentForm
            icon={PersonAddIcon}
            actionVal={x}
            courses={Courses}
            selectedUsers={studentsIds}
            coursevaluint={co}
            afterFinish={() => hideUserEditForm()}
            setSelectedEnabled={() => setSelectedEnabled()}
          />
        }
        cancelButtonMessage="Close"
        okButtonMessage="Assign"
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideUserEditForm()}
        title={`Are you sure to  ${x} selected students?`}
        maxWidth="xs"
        fullWidth={true}
      />
    );
  };
  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <PersonAddIcon />
        </CardIcon>
      </CardHeader>
      <CardBody>
        {/* <GridContainer > */}
        {/* <GridItem xs={12} sm={12} md={6}> */}
        {/* <h1>Students Listing</h1> */}
        {/* </GridItem> */}
        {/* <GridItem xs={12} sm={12} md={6}> */}
        <GridContainer justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          {/* <GridItem > */}
          {/* <Button
            color="rose"
            onClick={() => {
              refreshDashboard();
              //getQ();
            }}
            // style={{
            //   textTransform: "none",
            //   marginLeft: 20,
            //   width: 190,
            //   alignItems: "flex-end",
            // }}
            style={{ marginTop: 20,width:190,textTransform: "none" ,marginRighit: 20}}
            // style={{textTransform: "none"}}
          >
            <RefreshIcon/> Refresh
          </Button> */}
          {/* &nbsp;
          &nbsp;
          &nbsp; */}

          {studentFilter == "2" ?
            <>
              <GridItem xs={11} sm={11} md={1}>
                <div
                  style={{ color: 'black', marginBottom: '15px' }}
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedEnabled === "accept"}
                        onChange={() => handleCheck("accept", select)}
                        value="a"
                        name="radio button enabled"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classes.radioChecked}
                          />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      // disabled={question.isSubmitted == true}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={"Approve"}
                  />
                </div>
              </GridItem>
              <GridItem xs={11} sm={11} md={1}>
                <div
                  style={{ color: 'black', marginBottom: '15px' }}
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedEnabled === "reject"}
                        onChange={() => handleCheck("reject", select)}
                        value="a"
                        name="radio button enabled"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classes.radioChecked}
                          />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      // disabled={question.isSubmitted == true}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={"Reject"}
                  />
                </div>
              </GridItem>
            </> : null
          }
          <GridItem xs={11} sm={11} md={4}>

          </GridItem>
          <GridItem xs={11} sm={11} md={6} style={{ textAlign: "right" }}>
            {
              <ToggleButtonGroup
                value={studentFilter}
                color="secondary"
                size="small"
                exclusive
                onChange={handleFilterStudents}
                aria-label="content"
                style={{ marginRight: 10, marginTop: 20 }}
              >
                <ToggleButton value={'1'} aria-label="Approved">
                  Active Students

                </ToggleButton>
                <ToggleButton value={'2'} aria-label="Pending">
                  Pending Students
                </ToggleButton>

              </ToggleButtonGroup>
            }
            {
              isPrivilege("AddStudent") == true ?
                <Button
                  color="rose"
                  onClick={() => {
                    createUserEditForm(null, "New Student", "Add");
                    //getQ();
                  }}
                  style={{ marginTop: 20, marginBottom: 21, textTransform: "none" }}
                  disabled={pageState.isLoading}
                >
                  + Add
                </Button> : ''
            }
            {
              isPrivilege("ImportStudent") == true ?
                <Button color="rose"
                  onClick={() => {
                    createUpoadefileUsers({ userTypeId: 4 }, "Import Students");
                    //getQ();
                  }}
                  style={{ marginTop: 20, marginBottom: 21, textTransform: "none", marginLeft: 20 }}
                  disabled={pageState.isLoading}
                ><CloudUploadIcon /> Import </Button> : ''
            }
          </GridItem>
          <GridItem xs={11} sm={11} md={12}>

            <div style={{ height: 650, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                {pageState.isLoading == false && pageState.data ? (
                  <div ref={ref} style={{ flexGrow: 1, }}>
                    {
                      isPrivilege("ViewStudent") == true ?
                        <CustomDataGrid         //DataGrid
                          rows={pageState.data}
                          rowCount={pageState.total}
                          loading={pageState.isLoading}
                          rowsPerPageOptions={[10, 30, 50, 70, 100]}
                          page={pageState.page - 1}
                          pageSize={pageState.pageSize}
                          paginationMode="server"
                          onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                          onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                          columns={columns}
                          // components={{
                          //   Toolbar: CustomToolbar,
                          // }}
                          // disableDensitySelector
                          // filterModel={filterModel}
                          // onFilterModelChange={(model) => onhandlechange(model)}
                          onEditCellPropsChange={handleEdit}
                          onCellEditCommit={onCellEditCommit}
                          onSelectionModelChange={handleRowSelection}
                          // isRowSelectable={(params: GridRowParams) => params.row.providerId == params.row.providerId ? true : false}    //not validated for select student
                          checkboxSelection
                          getRowClassName={getRowClassName}
                        // onCellBlur={onCellBlur}
                        /> : ''
                    }
                  </div>
                ) : (
                  <div style={{ flexGrow: 1 }}>
                    <CustomDataGrid
                      rows={[]}
                      loading={pageState.isLoading}
                      columns={columns}
                      checkboxSelection
                    // components={{
                    //   Toolbar: CustomToolbar,
                    // }}
                    // disableDensitySelector
                    />
                    {/* <CircularProgress style={{marginInline:'45%'}} /><h6 style={{marginInline:'45%'}}> Loading users...</h6> */}
                  </div>
                )}
              </div>
            </div>
          </GridItem>
        </GridContainer>


      </CardBody>
      {showAlert}
      {updateUser.isError == true && (
        <CustomAlert
          open={true}
          severity="error"
          alertMessage="Error while updating user"
        />
      )}
      {updateUser.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="User Updated successfully"
        />
      )}
      {deleteUser.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="User Deleted successfully"
        />
      )}
      {userEditForm}
      {deleteModal}
    </Card>
  );
}
