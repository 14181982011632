import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";


export default function useGetTopInterest() {
  const { config, providerId } = useContext(LoginContext);

  return useQuery("useGetTopInterest", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Analytics/getTopInterest/${providerId}`
    );
    let lables =response.data.lable['$values'];
    let values =response.data.values['$values']
    console.log("usetopinterest",{lables,values});
    return{
      lables,values
    };
  });
}
