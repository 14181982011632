import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import useCreateNotificationAttachment from "hooks/notificationHooks/useCreateNotificationAttachment";

export default function useCreateNotification() {
  const { config, user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(

    async (notificationsPayload,file) => {
      let formData = notificationsPayload.fData
      console.log(notificationsPayload.notification.notification)
      console.log(notificationsPayload.notification.interestList)
      // notificationsPayload.fileName=[];
      // console.log(notificationsPayload.file)
  //     formData.append('file', attachement);
      // if (!notificationsPayload.notification.title) notificationsPayload.notification.title = " ";
      // if (!notificationsPayload.notification.body) notificationsPayload.notification.body = " ";
      // if (!notificationsPayload.interestList) notificationsPayload.interestList= " ";

      // if (!notificationsPayload.notification.broadcast) notificationsPayload.notification.broadcast = false;

      notificationsPayload.notification.notification.createdBy = user ?? "system";
      notificationsPayload.notification.notification.updatedBy = user ?? "system";
      notificationsPayload.notification.notification.providerId = providerId;
      
      console.log(notificationsPayload)
      // try
      // {
        let res= await axios.post(`${config.api}/api/Notification`,notificationsPayload.notification);
        console.log(res)
      // }catch(e)
      // {
      //        throw e;
      // }
  
    //  let formData = new FormData();
    //   formData.append('file', attachement);
      //useCreateNotificationAttachment(res.notificationId, formData);
  //     console.log(notificationsPayload.attachement) 
  // console.log(notificationsPayload)

   let re = await axios.post(`${config.api}/api/Attachment/saveAttachmentFile/${res.data.notificationId}`,formData);
   console.log(re)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Notifications");
      },
    }
  );
}
