import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import {configUrl} from "config.js";

export default function useUserMapToCourse(courseId){
    let queryClient = useQueryClient()
    return useMutation(async(payload) => {
        await axios.post(
            `${configUrl.AssessmentServer}/api/CourseUserMapping/bulkinsertCourseUserMappings`,
            payload
          );
    },{
        onSuccess: () =>{
            queryClient.invalidateQueries(`availableUserByCourseIds${courseId}`)
            queryClient.invalidateQueries(`userByCourseIds${courseId}`)
        }
    })
}