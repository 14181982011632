import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import CustomAlert from "components/CustomAlert/CustomAlert";
import QuickTestUserFileUpload from 'components/CustomUpload/QuickTestUserFileUpload';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useCreateUser from "hooks/userHooks/useCreateUser";
import useUpdateUser from "hooks/userHooks/useUpdateUser";
import React from "react";


function AssignStudents(props) {
    console.log(props)
    const [rows, setRows] = React.useState([]);
    console.log(rows)
    const [citys, setCitys] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [educationbord, setEducattionBord] = React.useState([]);
    const [clas, setClas] = React.useState([]);
    const [interest, setInterest] = React.useState([]);
    const [s, setS] = React.useState(null);

    const [studentinfo, setStudenInfo] = React.useState([]);
    console.log(studentinfo)
    const updateUser = useUpdateUser();
    const createUser = useCreateUser();

    const [model, setModel] = React.useState(
        {
            state: 0,//props.state[0].valueInt,
            city: 0,//props.citys[0].valueInt,
            language: 0, //props.language[0].valueInt,
            class: 0,//props.clas[0].valueInt,
            course: [],
            interest: 0,
            eduBoard: 0,//props?.educationbord[0]?.valueInt
            testId: props?.testId
        }
    )

    const hideAlert = () => {
        setAlert(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setModel({ ...model, [name]: value })

    }

    React.useEffect(() => {
        if (updateUser.isSuccess) {
            props.setTab(0)

            // props.afterFinish();
        }
    }, [updateUser.isSuccess]);

    React.useEffect(() => {
        if (createUser.isSuccess) {
            props.setTab(0)

            // props.afterFinish();
        }
    }, [createUser.isSuccess]);
    const afterFinish = () => {
        console.log('triggered')
        // props.history.push("/admin/quickTest");
    }
    console.log(props.testId, 'testIdProps')
    const handlePrevious = () => {
        props.setTab(2)
    }
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={4} style={{ textAlign: "left" }}>
                <QuickTestUserFileUpload userTypeId={4} testId={props?.testId} state={model?.state} city={model?.city} course={model?.course} language={model?.language} eduBoard={model?.eduBoard} class={model?.class} afterFinish={afterFinish()} func={setStudenInfo} label="Import students" icon={<CloudUploadIcon />} />
                <small style={{ fontWeight: 500, color: 'red' }}>Note: Test will be assign automatically to imported students.</small>
            </GridItem>
            <GridItem xs={12} sm={12} justify="right">
                {/* <Button round small color="primary" onClick={() => handlePrevious()} style={{ textTransform: 'none', float: 'right' }}>
                    {
                        // saving ? 'Saving...' :
                        'Previous'
                    }
                </Button> */}
            </GridItem>
            {updateUser.isError && (
                <CustomAlert
                    open={true}
                    severity="error"
                    alertMessage="User update failed"
                />
            )}

            {createUser.isError && (
                <CustomAlert
                    open={true}
                    severity="error"
                    alertMessage="User creation failed"
                />
            )}
        </GridContainer>

    );
}

export default AssignStudents;
