import React, { useContext } from "react";

import Button from "components/CustomButtons/Button.js";

import CustomModal from "components/CustomModal/CustomModal";

import {
    CircularProgress,
    Snackbar
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TestScheduleComponent from "./TestScheduleComponent";
import TestUserMappingGrid from "./TestUserMappingGrid";

import { makeStyles } from "@material-ui/core/styles";

import MuiAlert from "@material-ui/lab/Alert";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CustomAlert from "components/CustomAlert/CustomAlert";
import { LoginContext } from "contexts/LoginContext";
import useDeleteTest from "hooks/testsHooks/useDeleteTest";
import useTestBulkUpdate from "hooks/testsHooks/useTestBulkUpdate";
import useUserDataCleanup from "hooks/testsHooks/useUserDataCleanup";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router-dom";
import testPreviewService from "services/Preview/testPreviewService";
import getAllTests from "services/TestService/getAllTests";
import testService from "services/TestService/testService";
import MyCard from "views/Forms/QuickTestSteps/MyCard";
import TestPlayer from "views/Player/TestPlayer";
import styles from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import Analytics from "./Analytics";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);
// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarColumnsButton />
//       <GridToolbarFilterButton/>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

function QuickTestHomeComponent(props) {
    const classes = useStyles();
    var enableSearch = false;
    let Status;
    let difficultyLevel;
    let showHint;
    let navigationAllowed;
    let SearchedObjective;
    let SearchedTargetAudience;

    // let configurationType = 'Objective';
    // let configurationTypeAudience = 'TargetAudience';
    // let isActive = true;
    let courseId = null;
    if (props.location && props.location.state) {

        courseId = props.location.state.state;
    }
    console.log(courseId, 'stateinTestHome')
    // const { data: objectiveResponse } = useGetAllObjective(configurationType, isActive, true);
    // const { data: targetAudienceResponse } = useGetAllConfigurations(configurationTypeAudience, isActive, true)
    const update = useTestBulkUpdate();

    const { user, userPrivileges, providerId } = useContext(LoginContext);
    const deleteTest = useDeleteTest();
    const cleanupTestUsers = useUserDataCleanup();


    // const lockUnlock = useTestLockUnLock();
    // const [objective, setObjectiveOptions] = React.useState([""]);
    // const [targetAudience, setTargetAudience] = React.useState([""]);
    // const [testEditModal, setTestEditModal] = React.useState(null);
    const [testSchedule, setTestSchedule] = React.useState(null);
    const [testCopy, setTestCopy] = React.useState(null);
    const [analyticsModal, setAnalyticsModel] = React.useState(null);

    const [testUserMap, setTestUserMap] = React.useState(null);
    const [quickTestModal, setQuickTestModal] = React.useState(null);
    const [popOver, setPopOver] = React.useState(null);

    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const [columns, setColumns] = React.useState([]);
    const [select, setSelection] = React.useState([]);

    const [bulkOption, setBulkOption] = React.useState({});
    // const [bulkOptions, setBulkOptions] = React.useState([]);

    const [bulkValue, setBulkValue] = React.useState("");
    const [alert, setAlert] = React.useState(null);
    const [contentFilter, setContentFilter] = React.useState('0')

    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [preview, setPreview] = React.useState(null);
    const [flag, setFlag] = React.useState(false)
    const [isLoad, setIsLoad] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [loadingCardId, setLoadingCardId] = React.useState(null);
    const [pageState, setPageState] = React.useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 9
    })
    // TODO
    var testPath = window.location.pathname;
    console.log(testPath, testPath === '/admin/simplifiedtesthome', 'testPath')
    const fetchData = async (pageNumber, pageSize, Status, difficultyLevel, showHint, navigationAllowed, SearchedObjective, SearchedTargetAudience, contentFilter) => {
        setPageState(old => ({ ...old, isLoading: true }))
        let getOjbectives = await getAllTests.getAllTests(pageNumber, pageSize, Status, difficultyLevel, showHint, navigationAllowed, SearchedObjective, SearchedTargetAudience, courseId, contentFilter, 1);
        console.log(getOjbectives, 'getObjective1234')
        setPageState(old => ({ ...old, isLoading: false, data: getOjbectives, total: getOjbectives?.length > 0 ? getOjbectives[0].totalCount : 0 }))
    }
    const filterValues = (pageNumber, pageSize, contentFilter) => {
        if (enableSearch == false) {
            if (bulkOption.name == 'Difficulty Level') {
                difficultyLevel = bulkValue;
            }
            if (bulkOption.name == 'Status') {
                Status = bulkValue;
            }
            if (bulkOption.name == 'Objective') {
                SearchedObjective = bulkValue;
            }
            if (bulkOption.name == 'Target Audience') {
                SearchedTargetAudience = bulkValue;
            }
            if (bulkOption.name == 'Navigation Allowed') {
                navigationAllowed = bulkValue;
            }
            if (bulkOption.name == 'Show Hint') {
                showHint = bulkValue;
            }
        }
        else {
            Status = 0;
            difficultyLevel = 0;
            showHint = 0;
            navigationAllowed = 0;
            SearchedObjective = null;
            SearchedTargetAudience = null;
        }
        fetchData(pageNumber, pageSize, Status, difficultyLevel, showHint, navigationAllowed, SearchedObjective, SearchedTargetAudience, contentFilter);
    }
    React.useEffect(() => {
        if (pageState.page > 0) {
            filterValues(pageState.page, pageState.pageSize, contentFilter);
        }
    }, [pageState.page, pageState.pageSize, deleteTest.isSuccess, cleanupTestUsers.isSuccess, testSchedule, testUserMap])

    // const [anchorEL, setAnchorEL] = React.useState(null);
    // const openProfile = Boolean(anchorEL);
    // const handleClickProfile = (event) => {
    //     console.log(event, 'eventonClickedTest')
    //     setAnchorEL(event.currentTarget);
    // };
    // const handleCloseActions = () => {
    //     setAnchorEL(null);
    // };
    // React.useEffect(() => {
    //     window.addEventListener("scroll", () => {
    //         if (
    //             window.innerHeight + document.documentElement.scrollTop !==
    //             document.documentElement.offsetHeight
    //         ) {
    //             return;
    //         }

    //         // fetchData();

    //         filterValues(pageState.page, pageState.pageSize, contentFilter);

    //     });

    //     return () => window.removeEventListener("scroll", filterValues(pageState.page, pageState.pageSize, contentFilter));
    // }, [pageState.page]);

    // console.log(pageState.data, 'orgTest124')
    // const handleSearch = () => {
    //     enableSearch = false
    //     if (pageState.page > 1) {
    //         setPageState({ pageSize: 12, page: 1 })
    //     } else {
    //         filterValues(1, 12, contentFilter);
    //     }
    // }
    // const handleClear = () => {
    //     enableSearch = true;
    //     if (pageState.page > 1) {
    //         setPageState({ pageSize: 12, page: 1 })
    //     } else {
    //         filterValues(1, 12, contentFilter);
    //     }
    // }
    // React.useEffect(() => {
    //     if (objectiveResponse && objectiveResponse.length > 0) {
    //         setObjectiveOptions(
    //             objectiveResponse.map((x) => {
    //                 return {
    //                     id: x.id,
    //                     name: x.valueString,
    //                 };
    //             })
    //         );
    //     }
    // }, [objectiveResponse]);

    const removeTest = (id) => {
        deleteTest.mutate(id);

    }
    const cleanupUsers = (id) => {
        cleanupTestUsers.mutate(id)
    }
    // React.useEffect(() => {
    //     if (targetAudienceResponse && targetAudienceResponse.length > 0) {
    //         setTargetAudience(
    //             targetAudienceResponse.map((x) => {
    //                 return {
    //                     id: x.id,
    //                     name: x.valueString,
    //                 };
    //             })
    //         );
    //     }
    // }, [targetAudienceResponse]);

    // const handleInputChange = (e) => {
    //     setBulkValue(e.target.value);
    // };

    // const getCustomInput = (type) => {
    //     return (
    //         <CustomInput
    //             formControlProps={{
    //                 fullWidth: true,
    //             }}
    //             inputProps={{
    //                 fullWidth: true,
    //                 onBlur: handleInputChange,
    //                 type: type,
    //                 autoComplete: "off",
    //             }}
    //         />
    //     );
    // };

    // const getCustomSelect = (options) => {
    //     if (options.length <= 0) return;
    //     return (
    //         <CustomSelect
    //             options={options.map((x) => ({ id: x.id, name: x.name }))}
    //             setvalue={(v) => {
    //                 handleInputChange({ target: { value: v } });
    //             }}
    //         />
    //     );
    // };

    // const getCustomSelectForDropdown = (options) => {
    //     if (options.length <= 0) return;
    //     return (
    //         <CustomSelect
    //             options={options.map((x) => ({ id: x.id, name: x.name }))}
    //             setvalue={(v) => handleInputChange({ target: { value: JSON.stringify(v) } })}
    //         />
    //     );
    // };

    const isPrivilege = (x) => {
        if (userPrivileges?.Privileges?.SimplifiedTest.indexOf(x) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    // const getCustomSwitch = (label) => {
    //     return (
    //         <FormControlLabel
    //             control={
    //                 <Switch
    //                     name="switch"
    //                     //value={false}
    //                     onChange={(e) => {
    //                         handleInputChange({
    //                             target: { value: e.target.checked ? 1 : 0 },
    //                         });
    //                     }}
    //                     checked={false}
    //                 />
    //             }
    //             label={<small>{label}</small>}
    //         />
    //     );
    // };

    // const updateTests = (selected, tests, option, value) => {
    //     update.mutate({ selected, option, value });
    // };

    // React.useEffect(() => {
    //     setBulkOptions([
    //         // {
    //         //   id: 1,
    //         //   name: "Is Locked",
    //         //   inputType: "bool",
    //         //   key: "isLocked",
    //         //   updateMethod: updateTests,
    //         //   component: getCustomSelect([
    //         //     { id: true, name: "true" },
    //         //     { id: false, name: "false" },
    //         //   ]),
    //         // },
    //         // {
    //         //   id: 2,
    //         //   name: "Expiry Date",
    //         //   inputType: "date",
    //         //   key: "expiryDate",
    //         //   updateMethod: updateTests,
    //         //   component: getCustomInput("date"),
    //         // },
    //         {
    //             id: 3,
    //             name: "Status",
    //             inputType: "text",
    //             key: "status",
    //             updateMethod: updateTests,
    //             component: getCustomSelect([
    //                 {
    //                     id: 1,
    //                     name: "Draft",
    //                 },
    //                 {
    //                     id: 2,
    //                     name: "Ready",
    //                 },
    //                 {
    //                     id: 5,
    //                     name: "In Progress"
    //                 }
    //             ]),
    //         },
    //         {
    //             id: 4,
    //             name: "Difficulty Level",
    //             inputType: "test",
    //             key: "difficultyLevel",
    //             updateMethod: updateTests,
    //             component: getCustomSelect([
    //                 {
    //                     id: 1,
    //                     name: "Easy",
    //                 },
    //                 {
    //                     id: 2,
    //                     name: "Medium",
    //                 },
    //                 {
    //                     id: 3,
    //                     name: "Difficult",
    //                 },
    //             ]),
    //         },
    //         {
    //             id: 5,
    //             name: "Show Hint",
    //             inputType: "bool",
    //             key: "showHint",
    //             updateMethod: updateTests,
    //             component: getCustomSelect([
    //                 { id: 2, name: "True" },
    //                 { id: 1, name: "False" },
    //             ]),
    //         },
    //         // {
    //         //   id: 6,
    //         //   name: "Owner",
    //         //   inputType: "text",
    //         //   key: "owner",
    //         //   updateMethod: updateTests,
    //         //   component: getCustomInput("text"),
    //         // },
    //         {
    //             id: 7,
    //             name: "Navigation Allowed",
    //             inputType: "bool",
    //             key: "navigationAllowed",
    //             updateMethod: updateTests,
    //             component: getCustomSelect([
    //                 { id: 1, name: "True" },
    //                 { id: 0, name: "False" },
    //             ]),
    //         },
    //         {
    //             id: 8,
    //             name: "Objective",
    //             inputType: "text",
    //             key: "objective",
    //             updateMethod: updateTests,
    //             component: getCustomSelectForDropdown(objective),
    //         },

    //         {
    //             id: 9,
    //             name: "Target Audience",
    //             inputType: "bool",
    //             key: "targetAudience",
    //             updateMethod: updateTests,
    //             component: getCustomSelectForDropdown(targetAudience)
    //         },
    //     ]);
    // }, [objective, targetAudience]);

    // const showPopover = (cellValues) => {
    //     setPopOver(
    //         <Popover
    //             style={{ width: 600 }}
    //             id="popover"
    //             anchorPosition={{ left: -900 }}
    //             open={true}
    //             anchorEl={anchorEl}
    //             onClose={hidePopOver}
    //             anchorOrigin={{
    //                 vertical: "bottom",
    //                 horizontal: "center",
    //             }}
    //             transformOrigin={{
    //                 vertical: "top",
    //                 horizontal: "center",
    //             }}
    //         >
    //             <TestUserMappingGrid state={cellValues.row} />
    //         </Popover>
    //     );
    // };

    // const hidePopOver = () => {
    //     setPopOver(null);
    // };

    const showTestUserMapModal = (x) => {
        console.log(x, 'xxkk')
        setTestUserMap(
            <CustomModal
                modal={true}
                setModal={() => hideTestUserMapModal()}
                content={
                    <TestUserMappingGrid
                        state={x}
                        afterFinish={() => {
                            hideTestUserMapModal();
                        }}
                    />
                }
                showOk={false}
                showCancel={false}
                onCancelClick={() => hideTestUserMapModal()}
                title={`Test User Mapping: ${x.text}`}
                maxWidth="lg"
                fullWidth={true}
            />
        );
    };
    const hideTestUserMapModal = () => {
        setTestUserMap(null);
        // enableSearch = true;
        // if (pageState.page > 1) {
        //   setPageState({ pageSize: 10, page: 1 });
        //   setSelection([]);
        // } else {
        //   filterValues(1, 10, contentFilter);
        //   setSelection([]);
        // }
    };
    // Todo
    // const showQuickTestModal = (x) => {
    //     setQuickTestModal(
    //         <CustomModal
    //             modal={true}
    //             setModal={() => hideQuickTestModal()}
    //             content={
    //                 <QuickTestForm
    //                 />
    //             }
    //             showOk={false}
    //             showCancel={false}
    //             onCancelClick={() => hideQuickTestModal()}
    //             title={`Quick test creation`}
    //             maxWidth="lg"
    //             fullWidth={true}
    //         />
    //     );
    // };
    // const hideQuickTestModal = () => {
    //     setQuickTestModal(null);
    // }

    const showTestScheduleModal = (test) => {
        console.log(test, 'test123')
        let testData = {};
        testData.scheduleStartDateTime = test.startDate
        testData.scheduleEndDateTime = test.endDate
        testData.assignDateTime = test.scheduleDate

        setTestSchedule(
            <CustomModal
                modal={true}
                setModal={() => hideTestScheduleModal()}
                padding1={true}
                content={
                    <TestScheduleComponent
                        state={test}
                        afterFinish={() => {
                            hideTestScheduleModal();
                            // refetch();
                        }}
                        scheduleDates={testData}

                    />
                }
                showOk={false}
                showCancel={false}
                onCancelClick={() => hideTestScheduleModal()}
                title={`${test.text}`}          //Test Schedule/Assign: 
                maxWidth="sm"
            />
        );
    };

    const hideTestScheduleModal = () => {
        setTestSchedule(null);
        // enableSearch = true;
        // if (pageState.page > 1) {
        //   setPageState({ pageSize: 10, page: 1 });
        //   setSelection([]);
        // } else {
        //   filterValues(1, 10, contentFilter);
        //   setSelection([]);
        // }
    };

    // const showTestCopyModal = (test) => {
    //     setTestCopy(
    //         <CustomModal
    //             modal={true}
    //             setModal={() => hideTestCopyModal()}
    //             padding1={true}
    //             content={
    //                 <TestCopyComponent
    //                     state={test}
    //                     afterFinish={() => {
    //                         hideTestCopyModal();
    //                         // refetch();
    //                     }}
    //                 />
    //             }
    //             showOk={false}
    //             showCancel={false}
    //             onCancelClick={() => hideTestCopyModal()}
    //             title={`Test Copy`}
    //             maxWidth="sm"
    //         />
    //     );
    // };

    const hideTestCopyModal = () => {
        setTestCopy(null);
        enableSearch = true;
        if (pageState.page > 1) {
            setPageState({ pageSize: 12, page: 1 });
            setSelection([]);
        } else {
            filterValues(1, 12, contentFilter);
            setSelection([]);
        }
    };

    const showAnalytics = async (lOid, testName) => {
        console.log(testName.text, "testName")
        setAnalyticsModel(
            <CustomModal
                modal={true}
                setModal={hideAnalyticsModel}
                content={
                    <Analytics
                        lOid={lOid}
                        studentId={12}
                    />
                }
                showOk={false}
                showCancel={false}
                onCancelClick={hideAnalyticsModel}
                title={`Analytics - ${testName.text}`}
                maxWidth="lg"
                fullWidth={true}
                showHeaderBackground={true}
            />
        )
    }
    const hideAnalyticsModel = (event, reason) => {
        if (reason) console.log("reason", reason);
        setAnalyticsModel(null);
    };

    const showTestEditModal = async (test) => {
        console.log(test, 'testClicked')
        let testData = await testService.getTestData(test.testId);
        console.log(testData, 'testData123');
        if (testData?.testType == 1) {
            props.history.push("/admin/quickTest", { state: testData });
        } else {
            props.history.push("/admin/test", { state: testData });
        }

        /*    setTestEditModal(
          <CustomModal
            modal={true}
            setModal={() => hideTestEditModal()}
            content={
              <TestWizard
                state={test}
                hideTitle={true}
                afterFinish={() => {
                  hideTestEditModal();
                  refetch();
                }}
              />
            }
            showOk={false}
            showCancel={false}
            onCancelClick={() => hideTestEditModal()}
            title="Edit Test"
            maxWidth="lg"
            fullWidth={true}
          />
        ); */
    };

    // const hideTestEditModal = () => {
    //     setTestEditModal(null);
    // };

    // const handleUpload = async () => {
    //     if (!bulkOption.updateMethod) {
    //         showSnackBar("Please select field to update", "warning");
    //         return;
    //     }
    //     if (bulkValue == 5) {
    //         showSnackBar("Cannot update status as In-Progress ", "error");
    //         return
    //     }
    //     if (bulkValue == "") {
    //         showSnackBar("Please select values to update", "warning");
    //         return
    //     }
    //     if (select.length <= 0) {
    //         showSnackBar("Please select tests to update", "warning");
    //         return
    //     }
    //     console.log(pageState.data, 'pageState123');
    //     // console.log(orgTests, 'pageState124');
    //     // pageState.data
    //     await bulkOption.updateMethod(select, pageState.data, bulkOption, bulkValue);

    // };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };

    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };

    React.useEffect(() => {
        console.log(deleteTest, 'successf')
        if (deleteTest.isSuccess == true) {
            console.log('Test deleted successfully')
            showSnackBar("Test deleted successfully");
            setAlert(null);
        }
    }, [deleteTest.isSuccess]);

    React.useEffect(() => {
        console.log(deleteTest, 'successf')
        if (deleteTest.isError == true) {
            showSnackBar(deleteTest?.error?.response?.data, 'error');
            setAlert(null);
        }
    }, [deleteTest.isError]);

    React.useEffect(() => {
        console.log(cleanupTestUsers, 'successf')
        if (cleanupTestUsers.isSuccess == true) {
            showSnackBar("Test cleanup successfully");
            setAlert(null);
        }
    }, [cleanupTestUsers.isSuccess]);

    React.useEffect(() => {
        console.log(cleanupTestUsers, 'successf')
        if (cleanupTestUsers.isError == true) {
            showSnackBar(cleanupTestUsers?.error?.response?.data, 'error');
            setAlert(null);
        }
    }, [cleanupTestUsers.isError]);

    React.useEffect(() => {
        if (update.isSuccess == true) {
            console.log(update, "update223");
            if (update.data.totalCount == undefined) {
                showSnackBar("Tests updated successfully");
            } else {
                setAlert(
                    <SweetAlert
                        style={{ display: "block", marginTop: "-100px" }}
                        title={''}
                        onConfirm={() => setAlert(null)}
                        onCancel={() => setAlert(null)}
                        confirmBtnCssClass={classes.button + " " + classes.success}
                    >
                        <b style={{ marginBottom: '10px' }}> Test(s) updated successfully</b>  <br />
                        <b></b> <br />
                        Total tests: {update.data.totalCount} <br />
                        Ready tests: {update.data.readyCount} <br />
                        Draft tests: {update.data.draftCount} <br />
                        <br />
                        <small>Note: If the test status is not set to 'Ready', it means that the 'Ready' test criteria have not been met.</small>
                    </SweetAlert>
                )
                // showSnackBar("Total tests: " + update.data.totalCount + ", Ready tests: " + update.data.readyCount + ", Draft tests: " + update.data.draftCount);
            }

            enableSearch = true;
            if (pageState.page > 1) {
                setPageState({ pageSize: 12, page: 1 });
                setSelection([]);
            } else {
                filterValues(1, 12, contentFilter);
                setSelection([]);
            }
        }
    }, [update.isSuccess]);

    const handleAlignment = (event, content) => {
        console.log(content, 'contentFilterVals2')
        setContentFilter(content)
        filterValues(pageState.page, pageState.pageSize, content)
    };
    let testIdforView;
    const onHandleView = async (x) => {
        console.log(x, 'edithandle')
        setIsLoad(true)
        testIdforView = x.testId;
        let response = await testPreviewService.getPreviewTests(x.testId);

        let data = response?.data;
        setIsLoad(false)
        let qcount = 0
        console.log(response, 'responseforProvider')
        data && data?.pools['$values']?.map((x, i) => {
            qcount = qcount + x?.questions['$values']?.length;
        })
        setPreview(
            response.status == 400 ?
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage={response.messege}
                /> :
                qcount ?
                    <CustomModal
                        modal={true}
                        setModal={hidePlayer}
                        content={<TestPlayer testStatus={x.status} isShowPublish={false} tests={data} testId={x.testId} preview={true} />}
                        showOk={false}
                        showCancel={false}
                        onCancelClick={() => hidePlayer()}
                        title={`Test Preview : ${x?.text}`}
                        maxWidth="lg"
                        fullWidth={true}
                        hideTitle={false}
                        showHeaderBackground={true}
                    // fullScreen 
                    />
                    :
                    <CustomAlert
                        open={true}
                        openFlag={() => setFlag(flag ? false : true)}
                        severity="warning"
                        alertMessage="Questions are yet to be added to the test. Please do the needful."
                    />
        );
    }
    const hidePlayer = (event, reason) => {

        if (reason === "backdropClick") {
            return;
        }

        setPreview(null);
    };
    const handleChange = (event, value) => {
        // setPage(value);
        console.log(value, 'kkll')
        setPageState({ pageSize: 12, page: value });

    };

    // const [anchorEls, setAnchorEls] = React.useState(null);

    // const handleMenuClick = (event) => {
    //     setAnchorEls(event.currentTarget);
    // };

    // const handleMenuClose = () => {
    //     setAnchorEls(null);
    // };

    // const handleMenuItemClick = (action) => {
    // Handle the click event for each menu item
    //     console.log(`Clicked on "${action}"`);
    //     handleMenuClose();
    // };
    // 


    const handleEdit = (cardId) => {
        console.log(`Edit clicked for card with ID ${cardId}`);
        showTestEditModal(cardId)
    };

    const handleView = (cardId) => {
        setLoadingCardId(cardId.id)
        onHandleView(cardId)
    };
    const handleUserMap = (cardId) => {
        showTestUserMapModal(cardId);
    }
    const handleTestSchedule = (cardId) => {
        showTestScheduleModal(cardId)
    }
    const handleAnalytics = (card) => {
        showAnalytics(card.testId, card)
    }
    const handleRemoveTest = (testId) => {
        removeTest(testId)
    }
    const handleClearTest = (testId) => {
        cleanupUsers(testId)
    }
    // 

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={1}>
                    <h6 style={{ fontWeight: '500', color: '#ec9706', textTransform: 'none', marginTop: '-18px', marginBottom: '0px' }}><span style={{ fontSize: '6rem' }}>.</span> <span>Draft</span></h6>

                    <h6 style={{ fontWeight: '500', color: '#0fb7cb', textTransform: 'none', marginTop: '-21px' }}><span style={{ fontSize: '6rem' }}>.</span>Scheduled</h6>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                    <h6 style={{ fontWeight: '500', color: '#53ad57', textTransform: 'none', marginTop: '-18px', marginBottom: '0px' }}><span style={{ fontSize: '6rem' }}>.</span> <span>Ready</span></h6>

                    <h6 style={{ fontWeight: '500', color: '#c74488', textTransform: 'none', marginTop: '-21px' }}><span style={{ fontSize: '6rem' }}>.</span>In-Progress</h6>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                </GridItem>

                <GridItem xs={12} sm={12} md={4} style={{ textAlign: 'right' }}>
                    {/* <ToggleButtonGroup
                        value={contentFilter}
                        color="secondary"
                        size="small"
                        exclusive
                        onChange={handleAlignment}
                        aria-label="content"
                        style={{ marginRight: 10, marginTop: 20 }}
                    >
                        <ToggleButton value={'0'} aria-label="All content">
                            All
                        </ToggleButton>
                        <ToggleButton value={'1'} aria-label="Owned content">
                            Owned
                        </ToggleButton>
                        <ToggleButton value={'2'} aria-label="Subscribed content">
                            Subscribed
                        </ToggleButton>
                    </ToggleButtonGroup> */}
                    {isPrivilege("CreateSimplifiedTest") == true ?
                        <Button
                            color="primary"
                            onClick={() => { props.history.push("/admin/quickTest") }}
                            style={{ marginTop: 4, textTransform: "none" }}
                        >
                            + New
                        </Button> : ''}
                </GridItem>

            </GridContainer>
            <GridContainer>
                {
                    pageState.isLoading && (
                        <>
                            <CircularProgress style={{ marginInline: '45%' }} />
                            <h6 style={{ marginInline: '45%' }}>Loading...</h6>
                        </>
                    )
                }
                {
                    !pageState.isLoading && pageState?.data?.length == 0 && (
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <h4>Test (s) not available.</h4>
                        </GridItem>
                    )
                }
                {pageState?.data?.map((x, i) => (
                    <GridItem xs={12} sm={6} md={6} lg={4} key={i}>
                        <MyCard
                            key={x.id}
                            cardData={x}
                            onEdit={handleEdit}
                            onView={handleView}
                            onUserMaps={handleUserMap}
                            onTestSchedule={handleTestSchedule}
                            onAnalyticsClick={handleAnalytics}
                            onRemoveTest={handleRemoveTest}
                            onClearTest={handleClearTest}
                            onLoading={isLoad == true && loadingCardId === x.id ? true : false}
                        />
                        {/* old card */}
                        {/* <Card
                            key={i}
                            style={x.status > 3 ? { border: '2px #0fb7cb solid', cursor: 'pointer' } : { border: '2px #53ad57 solid', cursor: 'pointer' }}
                        >
                            <CardHeader color="warning" icon
                                onClick={(e) => {
                                    e.preventDefault
                                    isPrivilege("ViewSimplifiedTest") == true ?
                                        onHandleView(x) : ''
                                }}
                                style={{ height: '90px' }}
                            >

                                <p className={classes.cardCategory}></p>
                                <h5 className={classes.cardTitle} style={{ fontSize: '16px', textAlign: 'left', color: '#E63673', fontWeight: '500' }}>{x.text?.length > 43 ? x.text?.substr(0, 43) + '...' : x.text}</h5>
                                {isLoad == true && (<h4 style={{ color: 'gray' }}>Opening...</h4>
                                )}
                            </CardHeader>
                            <CardFooter style={x.status > 3 ? { margin: '0', padding: '0 15px 10px', height: '40px', backgroundColor: '#0fb7cb' } : { margin: '0', padding: '0 15px 10px', height: '40px', backgroundColor: '#53ad57' }} stats>
                                {
                                    isPrivilege("UpdateSimplifiedTest") == true ?
                                        <Tooltip title="Edit">
                                            <Button
                                                size="sm"
                                                justIcon
                                                round
                                                // color="reddit"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    showTestEditModal(x);
                                                }}
                                                style={{ size: "1px", backgroundColor: "transparent" }}
                                                disabled={x.providerId == providerId ? x.isLocked == true ? x.lockedBy == user ? false : true : x.status >= 3 ? true : false : true}
                                            >
                                                <EditIcon style={{ color: '#ffffff' }} />
                                            </Button>
                                        </Tooltip> : ''
                                }
                                {
                                    isPrivilege("AssignStudents") == true ?
                                        <Tooltip title="Assign To Student">
                                            <Button
                                                size="sm"
                                                justIcon
                                                round
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    showTestUserMapModal(x);
                                                }}
                                                style={{ size: "1px", backgroundColor: "transparent" }}
                                            >
                                                <PersonAddIcon style={{ color: '#ffffff' }} />
                                            </Button>
                                        </Tooltip> : ''
                                }
                                {
                                    isPrivilege("AssignSimplifiedTest") == true ?
                                        <Tooltip title="Schedule">
                                            <Button
                                                size="sm"
                                                justIcon
                                                round
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    showTestScheduleModal(x);
                                                }}
                                                style={{ size: "1px", backgroundColor: "transparent" }}
                                                disabled={x.isLocked == true ? true : !x.isActive}
                                            >
                                                <ScheduleIcon style={{ color: '#ffffff' }} />
                                            </Button>
                                        </Tooltip> : ''
                                }
                                {
                                    isPrivilege("SimplifiedTestAnalytics") == true ?
                                        <Tooltip title="Analytics">
                                            <Button
                                                size="sm"
                                                justIcon
                                                round
                                                // color="reddit"
                                                style={{ size: "1px", backgroundColor: "transparent" }}
                                                onClick={() => showAnalytics(x.testId, x.row)}
                                                disabled={x.status != 5}
                                            >
                                                <TimelineOutlined style={{ color: '#ffffff' }} />
                                            </Button>
                                        </Tooltip> : ''
                                }
                                {
                                    isPrivilege("DeleteSimplifiedTest") == true ?
                                        <Tooltip title="Delete">
                                            <Button
                                                size="sm"
                                                justIcon
                                                round
                                                style={{ size: "1px", backgroundColor: "transparent" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setAlert(
                                                        <SweetAlert
                                                            style={{ display: "block", marginTop: "-100px" }}
                                                            title={''}
                                                            onConfirm={() => removeTest(x.testId)}
                                                            showCancel={true}
                                                            cancelBtnText={'CANCEL'}
                                                            confirmBtnText={'OK'}
                                                            onCancel={() => setAlert(null)}
                                                            confirmBtnCssClass={classes.button + " " + classes.success}
                                                            cancelBtnCssClass={classes.button + " " + classes.warning}
                                                        >
                                                            <div style={{ display: 'inline-flex' }}>
                                                                <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                                                                <h4> Are you sure you want to delete the test ?</h4>
                                                            </div>
                                                        </SweetAlert>
                                                    );
                                                }}
                                                disabled={x.providerId == providerId ? x.isLocked == true ? x.lockedBy == user ? false : true : x.status >= 3 ? true : false : true}
                                            >
                                                <DeleteForeverIcon style={{ color: '#ffffff' }} />
                                            </Button>
                                        </Tooltip> : ''
                                }
                                {
                                    isPrivilege("CleanData") == true ?
                                        <Tooltip title="Clean Data">
                                            <Button
                                                size="sm"
                                                justIcon
                                                round
                                                // color="reddit"
                                                style={{ size: "1px", backgroundColor: "transparent" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setAlert(
                                                        <SweetAlert
                                                            style={{ display: "block", marginTop: "-100px" }}
                                                            title={''}
                                                            onConfirm={() => cleanupUsers(x.testId)}
                                                            showCancel={true}
                                                            cancelBtnText={'CANCEL'}
                                                            confirmBtnText={'OK'}
                                                            onCancel={() => setAlert(null)}
                                                            confirmBtnCssClass={classes.button + " " + classes.success}
                                                            cancelBtnCssClass={classes.button + " " + classes.warning}
                                                        >
                                                            <div style={{ display: 'inline-flex' }}>
                                                                <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                                                                <h4> Are you sure you want to cleanup the test?</h4>
                                                            </div>
                                                        </SweetAlert>
                                                    );
                                                }}
                                                disabled={x.status != 5}
                                            >
                                                <ClearAllOutlined style={{ color: '#ffffff' }} />
                                            </Button>
                                        </Tooltip> : ''
                                }
                                <CardActions>
                                    <IconButton size="small" onClick={handleMenuClick}>
                                        <GridMoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEls}
                                        open={Boolean(anchorEls)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => handleMenuItemClick(x.text)}>Edit</MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick(x.text)}>Analytics</MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick(x.text)}>Clear</MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick(x.text)}>Delete</MenuItem>
                                    </Menu>
                                </CardActions>
                            </CardFooter>
                        </Card> */}
                    </GridItem>
                ))}
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert onClose={handleClose} severity={severity}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
                {alert}
                {/* {testEditModal} */}
                {testSchedule}
                {testCopy}
                {testUserMap}
                {quickTestModal}
                {popOver}
                {analyticsModal}
                {preview}
            </GridContainer >
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Stack spacing={2} style={{ float: 'right' }}>
                        <Pagination count={pageState?.data?.length > 0 ? Math.ceil(pageState?.data[0]?.totalCount / pageState.pageSize) : 0} page={pageState?.page} onChange={handleChange} />
                    </Stack>
                </GridItem>
            </GridContainer>
        </>

    );
}

export default withRouter(QuickTestHomeComponent);
