import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetAssignedCourses() {
  const { user } = useContext(LoginContext);

  return useQuery("assignedcourses", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Courses/assignedCourses/${user}`
    );
    return response.data["$values"];
  });
}


