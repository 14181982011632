import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import CryptoJS from 'crypto-js';
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useCreateUserRegiste() {
  const { config, user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  const key = 'pj23vs7nycq18uew';
  let secretKey = CryptoJS.enc.Utf8.parse(key);

  const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
  }
  const decryptPassword = (encryptedPassword, secretKey) => {

    let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedText, 'decryptedone');
    return decryptedText;
    // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    // return bytes.toString(CryptoJS.enc.Utf8);
  }
  return useMutation(
    async (studentPayload) => {
      // if (!interestPayload.address) interestPayload.address = " ";
      // if (!interestPayload.middleName) interestPayload.middleName = " ";

      // if (!interestPayload.passCode) interestPayload.passCode = "test";
      console.log(studentPayload)
      studentPayload.studetnInfo.passCode = encryptPassword(studentPayload.studetnInfo.passCode, secretKey)
      studentPayload.studetnInfo.createdBy = user ?? "system";
      studentPayload.studetnInfo.updatedBy = user ?? "system";
      studentPayload.studetnInfo.userTypeId = 4;
      // studentPayload.studetnInfo.userName = user ?? "system";
      studentPayload.studetnInfo.providerId = providerId;
      studentPayload.studetnInfo.isActive = true;
      studentPayload.educationInfo.createdBy = user ?? "system";
      studentPayload.educationInfo.updatedBy = user ?? "system";
      studentPayload.siblingDetailsList.createdBy = user ?? "system";
      studentPayload.siblingDetailsList.updatedBy = user ?? "system";
      //  studentPayload.iblingDetailsList.map((x,i=>{
      //   x.createdBy=user?? "system"
      //   x.updatedBy=user?? "system"
      //  }))

      console.log(studentPayload, "finalPayload")
      await axios.post(`${config.api}/api/User/studentRegistration`, studentPayload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("students");
      },
    }
  );
}
