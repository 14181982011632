
import { createTheme, makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.type === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
        }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
      width: 8,
      height: 8,
      backgroundColor: "#1890ff",
      transform: "none",
      top: "39%",
      border: 0,
    },
  };
}

const defaultTheme = createTheme();
const useStyles2 = makeStyles(
  (theme) => ({
    root: {
      border: 0,
      color:
        theme.palette.type === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.type === "light" ? "#fafafa" : "#1d1d1d",
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${theme.palette.type === "light" ? "#f0f0f0" : "#303030"
          }`,
      },
      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${theme.palette.type === "light" ? "#f0f0f0" : "#303030"
          }`,
      },
      "& .MuiDataGrid-cell": {
        color:
          theme.palette.type === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.65)",
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      ...customCheckbox(theme),
    },
  }),
  { defaultTheme }
);

const styles = {
  gray: {
    backgroundColor: "lightgray",
  },
  white: {
    backgroundColor: "white",
  },
  lightpink: {
    backgroundColor: "lightpink",
  },
};

const useStyles = makeStyles(styles);

export default function CustomDataGrid(props) {
  const onSelectionModelChange = (e) => {
    if (props.onSelectionModelChange) {
      props.onSelectionModelChange(e);
    }
  };

  const classes = useStyles();
  const classes2 = useStyles2();
  //console.log(props, 'props')
  return (
    <DataGrid

      rows={props.rows}
      rowCount={props.rowCount}
      loading={props.loading}
      rowsPerPageOptions={props.rowsPerPageOptions}
      pagination
      page={props.page}
      pageSize={props.pageSize}
      paginationMode={props.paginationMode}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      columns={props.columns}
      className={classes2.root}

      onEditCellPropsChange={props.onEditCellPropsChange}
      editMode="row"
      onCellEditCommit={props.onCellEditCommit}
      {...props}
      disableSelectionOnClick
      onSelectionModelChange={onSelectionModelChange}
      getRowClassName={(params) => {
        let cl = props.getRowClassName
          ? props.getRowClassName(params.row)
          : null;
        return classes[cl ?? "white"];
      }}
    />
  );
}
