import axios from "axios";
import { configUrl } from "config";

export async function useCreateTopicwiseTest(topicwisetests) {
  console.log(topicwisetests, "topicwisetests");
  return axios
    .post(
      `${configUrl.AssessmentServer}/api/TopicWiseTest/createTests`, topicwisetests
    )
    .then((respose) => {
      console.log(respose);
      return respose;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
