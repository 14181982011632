//import QuestionComponent from "./QuestionComponent";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import { PinDropSharp } from "@material-ui/icons";

// @material-ui/icons

// core components

const FeedbackPassScreen = ({ message, score, totalScore, handleSubmit }) => {
  return (
    <div>
      {/* <Card> */}
        {/* <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <HelpOutlineIcon />
          </CardIcon>
        </CardHeader> */}
        {/* <CardBody> */}
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: 400 }}
          >
            <GridItem xs={12} sm={12} md={3} />
            <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center",color:'black' }}>
              <h2>{message}</h2>
              <h4>{`You have scored: ${score}/${totalScore}`}</h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} />
            <GridItem xs={12} sm={12} md={3} />
            <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
              <Button color="primary" round onClick={handleSubmit}>Ok</Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} />
          </GridContainer>
        {/* </CardBody>
      </Card> */}
    </div>
  );
};

export default FeedbackPassScreen;
