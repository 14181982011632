import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetTrendingTestSeriesData() {
  return useQuery("trendCount", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Courses/getTrendingCourses`
    );

    
    return response.data["$values"];
  });
}



