import { Button } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CustomActionAlert({ open, openFlag, onOk, afterfinish, severity, alertMessage, handleClose, timeout }) {
  const history = useHistory();
  const [alertOpen, setAlertOpen] = useState(open);
  console.log("AlertOpened")
  React.useEffect(() => {
    setAlertOpen(open);
  }, [open, openFlag]);

  const onclose = () => {
    setAlertOpen(false);
  };
  const handleButtonClick = () => {
    if (afterfinish) { afterfinish(); }
    history.push(onOk),
      setAlertOpen(false);
  }
  return (
    <Snackbar
      open={alertOpen}
      // autoHideDuration={timeout ? timeout : 2000}
      onClose={handleClose ? handleClose : onclose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleClose ? handleClose : onclose} action={
        <Button onClick={handleButtonClick} color="inherit" size="small">
          Ok
        </Button>} severity={severity}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

export default CustomActionAlert;
