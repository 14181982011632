import { makeStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import EvaluationQuestionWrapper from "components/Questions/EvaluationQuestionWrapper";
import { LoginContext } from "contexts/LoginContext";
import useGetAttempCount from "hooks/resultHooks/useGetAttempCount";
import useGetAssignedTests from "hooks/testsHooks/useGetAssignedTests";
import useGetAttemptDetails from "hooks/testsHooks/useGetAttemptDetails";
import useGetUserByUserName from "hooks/userHooks/useGetUserByUserName";
import React, { useContext } from "react";
import TestPlayer from "views/Player/TestPlayer";

const useStyles = makeStyles(styles);

function TestViewer(props) {
  const { user } = useContext(LoginContext);

  const { data: userDetails, mutate: refetch } = useGetUserByUserName();

  const attempt = useGetAttempCount();

  const { data: testDetails } = useGetAssignedTests(
    userDetails ? userDetails.userId : null,
    "ALL"
  );

  const [showTest, setShowTest] = React.useState(false);
  const [testIndex, setTestIndex] = React.useState(0);

  const [testPlayerModal, setTestPlayerModal] = React.useState(null);
  const [evaluationModel, setEvaluationModel] = React.useState(null);

  const [attemptsTaken, setAttemptsTaken] = React.useState(null);

  const [test, setTest] = React.useState(null);

  React.useEffect(() => {
    refetch(user);
  }, []);

  React.useEffect(() => {
    let d = {};

    if (testDetails) {
      console.log("testDetails", testDetails);
      for (let i = 0; i < testDetails.length; i++) { }
    }
  }, [testDetails]);

  const getAttempts = async (id) => {
    let data = await attempt.mutateAsync({
      userId: user,
      testId: id,
    });

    console.log("data", data);
    return data;
  };

  const showTestModel = async (i, id, a) => {

    const testDetail = await useGetAttemptDetails(userDetails.userId, id, a);
    setTestPlayerModal(
      <CustomModal
        modal={true}
        setModal={hideTestModal}
        content={
          <TestPlayer
            tests={JSON.parse(testDetail.attemptDetails).value}
            testAttemptDetails={testDetail}
            testId={id}
            userId={userDetails ? userDetails.userId : null}
            attempt={a}
            isCourse={false}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideTestModal}
        title={`Test Schedule for`}
        maxWidth="lg"
        fullWidth={true}
        hideTitle={true}
        fullScreen
      />
    );
  };

  const hideTestModal = (event, reason) => {
    if (reason) console.log("reason", reason);
    setTestPlayerModal(null);
  };

  const showEvaluationModel = (id) => {
    setEvaluationModel(
      <CustomModal
        modal={true}
        setModal={hideEvaluationModel}
        content={
          <EvaluationQuestionWrapper
            testId={id}
            userId={userDetails ? userDetails.userId : null}
            isCourse={false}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideEvaluationModel}
        title={`Evaluation`}
        maxWidth="lg"
        hideTitle={true}
        fullWidth={true}

      />
    );
  };

  const hideEvaluationModel = (event, reason) => {
    if (reason) console.log("reason", reason);
    setEvaluationModel(null);
  };

  const hidePlayer = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowTest(false);
  };



  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={11}>
              {testDetails &&
                testDetails.length > 0 &&
                testDetails
                  .filter((x) => {
                    let dateDiff = new Date() - new Date(x.schedule);
                    console.log("dateDiff", dateDiff);
                    return dateDiff > 0;
                  })
                  .map((x, i) => (
                    <div>
                      <GridContainer
                        alignItems="center"
                        style={{
                          backgroundColor: "white",
                          boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
                          borderRight: "5px solid blue",
                          padding: "10px",
                        }}
                      >
                        <GridItem xs={12} sm={12} md={2}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <h3>{x.name}</h3>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <h5>
                                {x.objective}
                              </h5>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12} >
                              <h5>
                                {x.result == undefined ? "--" : x.result == 1 ? "Failed" : "Passed"}
                              </h5>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              Result
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12} >
                              <h5>
                                {x.actualScore ?? "--"}/{x.totalScore ?? 0}
                              </h5>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              Score
                            </GridItem>
                          </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={2}>
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <h5>{x.attemptNumber ?? 0}/{x.maxAttempts ?? 1}</h5>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              Attempts
                            </GridItem>
                          </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={2}>
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <h5>{x.attemptedOn == undefined ? "--" : x.attemptedOn}</h5>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              Last Attempt
                            </GridItem>

                          </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={2}>
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <h5>{x.expiryDate}</h5>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              Expired On
                            </GridItem>

                          </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={2}>
                          <GridContainer alignItems="center">
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <Button
                                color="primary"
                                style={{ width: "150px" }}
                                onClick={() => {
                                  setTestIndex(i);
                                  showTestModel(
                                    i,
                                    x.testId,
                                    (x.attemptNumber ?? 0) + 1
                                  );
                                }}
                                disabled={x.maxAttempts <= x.attemptNumber ?? 0}
                              >
                                {x.maxAttempts <= x.attemptNumber ?? 0
                                  ? "No More Attempts"
                                  : "Take Test"}
                              </Button>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <Button
                                color="primary"
                                style={{ width: "150px" }}
                                onClick={() => showEvaluationModel(x.testId)}
                                disabled={!x.attemptNumber}
                              >
                                View Assessment
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ))}
            </GridItem>
          </GridContainer>

          {testPlayerModal}
          {evaluationModel}
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default TestViewer;
