import axios from "axios";
import { configUrl } from "config.js";
import { useMutation, useQueryClient } from "react-query";

export default function useUpdateQuestions() {
  let queryClient = useQueryClient();
  return useMutation(
    async ({ selected, option, stringifyValue }) => {

      //  let tempQuestion = questions;
      //  const { providerId } = React.useContext(LoginContext);
      // let modifiedQuestionData = tempQuestion.map((x)=>(
      //   x,
      //   x.questionId = x.id
      //   ));
      console.log(stringifyValue, 'updateQuestion7')
      let response = await axios.post(
        `${configUrl.AssessmentServer}/api/Questions/api/Questions/bulkQuestionsUpdate?option=${option.key}&value=${stringifyValue}`, selected

      );
      return response.data;
    },
    {
      onSuccess: () => {

        queryClient.invalidateQueries('question');

      },
    }
  );
}
