import { CircularProgress, Paper, Slide, Snackbar, Tooltip, makeStyles } from "@material-ui/core";
import { DeleteForever, Publish, WarningOutlined } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAlert from "components/CustomAlert/CustomAlert.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import CustomModal from "components/CustomModal/CustomModal.js";
import QuickTestFileUpload from "components/CustomUpload/QuickTestFileUpload.js";
import QuickTestZipUpload from "components/CustomUpload/QuickTestZipUpload.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import useDeletePool from "hooks/poolHooks/useDeletePool.js";
import useGetPoolsByTest from "hooks/poolHooks/useGetPoolsByTest.js";
import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import activateService from "services/TestService/activateService.js";
import QuickTestPoolQuestionMappedGrid from "views/Components/QuickTestPoolQuestionMappedGrid.js";
import Button from "../../../components/CustomButtons/Button.js";
import QuickTestPoolForm from "../PoolForms/QuickTestPoolForm.js";
import QuickTestQuestionForm from "../QuestionForm/QuickTestQuestionForm.js";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles1 = {
    ...styles,
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornment: {
        position: "relative",
    },
    tooltip: {
        fontSize: "1em",
    },
};
const useStyles = makeStyles(styles1);
export default function EditSections(props) {
    console.log('im here')
    const classes = useStyles();
    // will reduce this calls using creating new function.
    const { data: poolsRaw, isLoading, refetch } = useGetPoolsByTest(props.testId);
    const deletePool = useDeletePool();

    console.log(poolsRaw, 'poolsRaw')
    const [alert, setAlert] = useState(null);
    const [poolEditModal, setPoolEditModal] = useState(null);
    const [pools, setPools] = useState([]);
    const [questionImportModal, setQuestionImportModal] = useState(null);
    const [modal, setModal] = useState(true);
    const [flag, setFlag] = useState(false)
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [severity, setSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (poolsRaw) {
            if (poolsRaw.length == 1) {

                console.log('in here')

                setPools(poolsRaw.map(x => {
                    x.showQuestions = true
                    return x
                }))

                return
            }
            setPools(poolsRaw);
        }
    }, [poolsRaw]);

    useEffect(() => {
        refetch();
        console.log('fetched new')
    }, [props.isSearch])

    useEffect(() => {
        if (deletePool.isError) {
            setAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage="Error occured"
                />
            )
        }
    }, [deletePool.isError])

    useEffect(() => {
        if (deletePool.isSuccess) {
            setAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="success"
                    alertMessage="Pool deleted successfully"
                />
            )
        }
    }, [deletePool.isSuccess])
    // deletePool.isLoading

    const excelImportModal = (nodes) => {
        console.log(nodes, 'nodes');
        let title = `Upload excel file under section "${nodes.poolLanguages[0].text}"`;

        setModal(true);
        setQuestionImportModal(
            <CustomModal
                modal={modal}
                padding1={true}
                setModal={() => hideImportQuestionModal()}
                content={
                    <QuickTestFileUpload
                        topicId={0}  //nodes.id.replace("T", "")}
                        callback={() => {
                            hideImportQuestionModal();
                            // props.refresh();
                        }}
                        poolId={nodes.poolId}
                    />
                }
                cancelButtonMessage="Cancel"
                okButtonMessage="Ok"
                showOk={false}
                showCancel={false}
                onCancelClick={() => hideImportQuestionModal()}
                title={title}
                maxWidth="lg"

            />
        );
    };
    const hideImportQuestionModal = () => {
        setQuestionImportModal(null);
    };

    const zipImportModal = (nodes) => {
        console.log(nodes, 'nodes');
        let title = `Upload zip file under section "${nodes.poolLanguages[0].text}"`;

        setModal(true);
        setQuestionImportModal(
            <CustomModal
                modal={modal}
                padding1={true}
                setModal={() => hidezipImportModal()}
                content={
                    <QuickTestZipUpload
                        testId={props.testId}
                        poolId={nodes.poolId}
                        afterFinish={() => hidezipImportModal()}
                    />
                }
                cancelButtonMessage="Cancel"
                okButtonMessage="Ok"
                showOk={false}
                showCancel={false}
                onCancelClick={() => hidezipImportModal()}
                title={title}
                maxWidth="lg"

            />
        );
    };
    const hidezipImportModal = () => {
        setQuestionImportModal(null);
    };

    const createPoolEditModal = (pool) => {
        let title = pool == null ? "Create new section" : "Update Section";
        console.log(props.testId, 'testid')
        setPoolEditModal(
            <CustomModal
                modal={true}
                setModal={() => hidePoolEditModal()}
                content={<QuickTestPoolForm hidePoolEditModal={hidePoolEditModal} refetch={refetch} testId={props.testId} state={pool} />}                    //createPool={createPool}
                cancelButtonMessage="cancel"
                okButtonMessage="Create"
                showOk={false}
                onCancelClick={() => hidePoolEditModal()}
                title={title}
                maxWidth="md"
                fullWidth={false}
            />
        );
    };
    const hidePoolEditModal = () => {
        setPoolEditModal(null);
    };
    console.log(pools, 'pools123')

    const handleQuestionType = (e, poolId) => {
        console.log('selected type is ', e, poolId)

        let title = e;
        setPoolEditModal(
            <CustomModal
                modal={true}
                setModal={() => hidePoolEditModal()}
                content={
                    <QuickTestQuestionForm
                        questionType={title == "MCQ" ? 1 : title == "SCQ" ? 2 : title == "TF" ? 3 : title == "Numerical" ? 4 : title}
                        state={null}
                        poolId={poolId}
                        afterFinish={() => hidePoolEditModal()}
                        id={0}
                    />
                }
                cancelButtonMessage="cancel"
                okButtonMessage="Create"
                showOk={false}
                onCancelClick={() => hidePoolEditModal()}
                title={`${title} type question`}
                maxWidth="md"
                fullWidth={false}
            />
        );

    }

    console.log(props, 'propsinEdit')
    const handleSaveandNext = async () => {
        // for test activation just uncomment below code. (read comment from QuickTestForm.js file by searching this keyword - BSS121
        try {
            setLoading(true);
            let activateTest = await activateService.activateTestService(props.testId)
            console.log(activateTest, 'activateTest');
            if (activateTest.status == 200) {
                showSnackBar("Test activated and saved successfully. ");
                setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                    props.setTab(2)
                }, 3000);
            }
            else {
                showSnackBar(activateTest?.messege ?? "Test not activated.", "warning");
                setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                    props.setTab(2)
                }, 3000);
                console.log('error in activate test')
            }
        } catch (error) {
            console.error('Error:', error);
            showSnackBar("An error occurred while activating the test.", "error");
            setTimeout(() => {
                setLoading(false);
                props.setTab(2)
            }, 3000);
        } finally {
            setLoading(false);
        }
    }
    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };
    const handlePrevious = () => {
        props.setTab(0)
    }

    const showQuestion = (pool) => {
        let tempPools = pools.map((x) => {
            return { ...x, showQuestions: false };
        });

        let p = tempPools.find((x) => x.poolId == pool.poolId);
        p.showQuestions = true;
        setPools([...tempPools]);
    };

    const hideQuestion = (pool) => {
        let p = pools.find((x) => x.poolId == pool.poolId);
        p.showQuestions = false;
        setPools([...pools]);
    };

    const removePool = (id) => {
        console.log(id, 'id4Delete')
        setAlert(null)
        deletePool.mutate(id);
    }

    return (
        <GridContainer justify="center">

            {alert}
            {poolEditModal}

            <GridItem xs={12} sm={12}>
                <div style={{ textAlign: 'end' }}>
                    <Button
                        // round
                        size="small"
                        small
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            //props.addCategory("", nodes.id);
                            createPoolEditModal(null);
                        }}
                        style={{ marginRight: 7, borderRadius: '5px' }}
                    >
                        + Section
                    </Button>
                </div>
                <Paper
                    style={{
                        maxHeight: 320,
                        minHeight: 200,
                        // BSS121 change if controls is going out of paper
                        overflow: "auto",
                        boxShadow:
                            "0px 0px 0px 0px rgb(0 0 0), 0px 0px 0px 0px rgb(0 0 0), 0px 0px 0px 0px rgb(0 0 0)",
                    }}
                >
                    {isLoading == false &&
                        pools &&
                        pools.map((x, i) => (
                            <div key={i} style={{ margin: 5 }}>
                                <Card style={{ marginTop: 5, marginBottom: 5 }}>
                                    <CardBody
                                        style={{
                                            backgroundColor: "black", // x.isLocked ? "grey" : "black",
                                            borderRadius: 10,
                                            color: "white",
                                            // zIndex: 5,
                                            paddingRight: 20,
                                            paddingLeft: 20,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                        }}
                                    >
                                        <GridContainer alignItems="center">
                                            <GridItem xs={12} sm={12} md={5}>
                                                <h6>{x.poolLanguages[0].text}</h6>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <small><b>Allow Questions</b>  - {x.questionCount} </small>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} style={{ textAlign: "right" }}>
                                                {console.log(x, 'this is x')}
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={4} style={{ textAlign: "right" }}>
                                                        <Tooltip classes={{ tooltip: classes.tooltip }} title={'Create Questions'}>
                                                            <CustomDropdown
                                                                size="sm"
                                                                hoverColor="info"
                                                                buttonText="+ Question"
                                                                buttonProps={{
                                                                    round: true,
                                                                    fullWidth: false,
                                                                    style: { marginBottom: "0" },
                                                                    color: "info",
                                                                }}
                                                                // dropdownHeader="Dropdown header"
                                                                onClick={(e) => handleQuestionType(e, x?.poolId)}
                                                                dropdownList={[
                                                                    "MCQ",
                                                                    "SCQ",
                                                                    // { divider: true },
                                                                    "TF",
                                                                    "Numerical",

                                                                ]}
                                                            />
                                                        </Tooltip>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <Tooltip classes={{ tooltip: classes.tooltip }} title={'Upload excel'}>
                                                            <Button
                                                                size="sm"
                                                                justIcon
                                                                round
                                                                color="behance"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    //props.addCategory("", nodes.id);
                                                                    excelImportModal(x);
                                                                }}
                                                                style={{ marginRight: 7 }}
                                                            >
                                                                <InsertDriveFileIcon />
                                                                {/* <Publish /> */}
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip classes={{ tooltip: classes.tooltip }} title={'Upload zip'}>
                                                            <Button
                                                                size="sm"
                                                                justIcon
                                                                round
                                                                color="behance"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    //props.addCategory("", nodes.id);
                                                                    zipImportModal(x);
                                                                }}
                                                                style={{ marginRight: 7 }}
                                                            >
                                                                {/* <InsertDriveFileIcon /> */}
                                                                <Publish />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip classes={{ tooltip: classes.tooltip }} title={'Edit'}>
                                                            <Button
                                                                size="sm"
                                                                justIcon
                                                                round
                                                                color="reddit"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    createPoolEditModal(x);          //x
                                                                }}
                                                                style={{ marginRight: 7 }}

                                                            >
                                                                <EditIcon />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip classes={{ tooltip: classes.tooltip }} title={'Delete'}>

                                                            <Button
                                                                size="sm"
                                                                justIcon
                                                                round
                                                                color="reddit"
                                                                style={{ marginRight: 7 }}
                                                                disabled={pools.length == 1 || deletePool.isLoading}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    // to do
                                                                    setAlert(
                                                                        <SweetAlert
                                                                            style={{ display: "block", marginTop: "-100px" }}
                                                                            title={' '}
                                                                            onConfirm={() => removePool(x.poolId)}
                                                                            showCancel={true}
                                                                            cancelBtnText={'CANCEL'}
                                                                            confirmBtnText={'OK'}
                                                                            onCancel={() => setAlert(null)}
                                                                            confirmBtnCssClass={classes.button + " " + classes.success}
                                                                            cancelBtnCssClass={classes.button + " " + classes.warning}
                                                                        >
                                                                            <div style={{ display: 'inline-flex' }}>
                                                                                <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                                                                                <h4> Are you sure you want to delete the section ?</h4>
                                                                            </div>
                                                                        </SweetAlert>
                                                                    );
                                                                }}
                                                            >
                                                                < DeleteForever />
                                                            </Button>
                                                        </Tooltip>
                                                        {x.showQuestions == false ? (
                                                            <Button
                                                                size="sm"
                                                                justIcon
                                                                round
                                                                color="google"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    showQuestion(x);
                                                                }}
                                                            >
                                                                <ExpandMoreIcon />
                                                            </Button>
                                                        ) : null}
                                                        {x.showQuestions == true ? (
                                                            <Button
                                                                size="sm"
                                                                justIcon
                                                                round
                                                                color="google"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    hideQuestion(x);
                                                                }}
                                                            >
                                                                <ExpandLessIcon />
                                                            </Button>
                                                        ) : null}

                                                    </GridItem>
                                                </GridContainer>

                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                                {x.showQuestions == true ? (
                                    <Slide
                                        direction="down"
                                        in={true}
                                        mountOnEnter
                                        unmountOnExit
                                        timeout={400}
                                    >
                                        <div style={{ marginTop: -5 }}>
                                            <QuickTestPoolQuestionMappedGrid
                                                poolId={x.poolId}
                                                poolName={x.text}
                                                hideHeader={true}
                                            />
                                        </div>
                                    </Slide>
                                ) : null}
                            </div>
                        ))}
                    {
                        pools && pools.length == 0 && (
                            <div key={1} style={{ margin: 5 }}>
                                <Card style={{ marginTop: 5, marginBottom: 5 }}>
                                    <CardBody
                                        style={{
                                            backgroundColor: "grey", // x.isLocked ? "grey" : "black",
                                            borderRadius: 10,
                                            color: "white",
                                            zIndex: 5,
                                            paddingRight: 20,
                                            paddingLeft: 20,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                        }}
                                    >
                                        <GridContainer alignItems="center">
                                            <GridItem xs={12} sm={12} md={10} style={{ textAlign: "left", color: 'white' }}>
                                                <h6>{'Loading...'}<CircularProgress style={{ color: 'white', marginInline: '1%', height: '2%', width: '2%' }} /></h6>

                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={2} style={{ textAlign: "right" }}>
                                                <Button
                                                    size="sm"
                                                    justIcon
                                                    round
                                                    color="behance"
                                                    disabled
                                                    style={{ marginRight: 7 }}
                                                >
                                                    <InsertDriveFileIcon />
                                                    {/* <Publish /> */}
                                                </Button>

                                                <Button
                                                    size="sm"
                                                    justIcon
                                                    round
                                                    color="behance"
                                                    disabled
                                                    style={{ marginRight: 7 }}
                                                >
                                                    {/* <InsertDriveFileIcon /> */}
                                                    <Publish />
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    justIcon
                                                    round
                                                    color="reddit"
                                                    disabled
                                                // onClick={(e) => {
                                                //     e.preventDefault();
                                                //     createPoolEditModal(x);          //x
                                                // }}
                                                >
                                                    <EditIcon />
                                                </Button>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                    }
                </Paper>
            </GridItem>
            <GridItem xs={12} sm={12} justify="center">
                <Button round small color="primary" onClick={() => handleSaveandNext()} style={{ textTransform: 'none', float: 'right' }}>
                    {
                        loading ? 'Saving...' :
                            'Save & Next'
                    }
                </Button>
                <Button round small color="primary" onClick={() => handlePrevious()} style={{ textTransform: 'none', marginRight: '7px', float: 'right' }}>
                    {
                        // saving ? 'Saving...' :
                        'Previous'
                    }
                </Button>
            </GridItem>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {questionImportModal}
        </GridContainer>
    )
}
