import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";

export default function useDeleteUser() {
  const queryClient = useQueryClient();

  const { config } = useContext(LoginContext);

  return useMutation(
    async (id) => {
     
        await axios.delete(
          `${config.api}/api/Interest/${id}`
        );
     
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Interests");
      },
    }
  );
}
