import axios from "axios";
import { configUrl } from "config.js";
import { useMutation, useQueryClient } from "react-query";

export default function useDeleteQuestions() {
  let queryClient = useQueryClient();
  return useMutation(
    async (id) => {
      await axios.delete(`${configUrl.AssessmentServer}/api/Questions/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("questions");
      },
    }
  );
}
