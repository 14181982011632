import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { useContext, useEffect, useState } from "react";

import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { CircularProgress } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import CustomAccordion from "components/Accordion/Accordion";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { LoginContext } from "contexts/LoginContext";
import useMapToState from "hooks/testUserMappingsHooks/useMapToTest";
import UserMappingGridForTest from "services/TestUserMappingService/UserMappingGridForTest";
import TestUserMappedGrid from "views/Components/TestUserMappedGrid";

function TestUserMappingGrid(props) {
  const { state } = props;
  console.log(state, 'stateinTestuser')
  // const { data: users, isLoading, refetch } = useGetAvailableUserForTest(state.id);
  const mapTest = useMapToState(state?.testId);

  const { user } = useContext(LoginContext);
  const [refresh, setRefresh] = useState(false);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  const fetchTaskData = async (Id, pageNumber, pageSize) => {
    setPageState(old => ({ ...old, isLoading: true }))
    let getUsers = await UserMappingGridForTest.getAvailableUserForTest(Id, pageNumber, pageSize);
    console.log(getUsers, 'getObjective1234')
    setPageState(old => ({ ...old, isLoading: false, data: getUsers, total: getUsers?.length > 0 ? getUsers[0].totalCount : 0 }))
  }
  useEffect(() => {
    fetchTaskData(state?.testId, pageState.page, pageState.pageSize)
    setRefresh(refresh ? false : true);
  }, [state?.testId, pageState.page, pageState.pageSize, mapTest.isSuccess])

  const [cols, setCols] = useState([]);
  const [select, setSelection] = useState([]);

  const handleRowSelection = (e) => {
    setSelection(e);
  };

  useEffect(() => {
    let columns = [
      {
        field: "userId",
        headerName: "Id",
        editable: false,
        width: 100,
        hide: true
      },
      {
        field: "fullName",
        headerName: "Full Name",
        editable: false,
        width: 200,
      },
      {
        field: "userName",
        headerName: "User Name",
        editable: false,
        width: 200,
      },
      {
        field: "mobile",
        headerName: "Mobile No",
        editable: false,
        width: 200,
      }
    ];

    setCols(columns);
  }, []);

  const mapToTest = async () => {
    let payload = select.map((x) => {
      return {
        testId: state?.testId,
        userId: x,
        attemptsAllowed: state.attemptsAllowed,
        totalScore: state.totalScore,
        assessmentData: "",
        assessmentAnswers: "",
        createdBy: user,
        createdDate: new Date(),
        updatedBy: user,
        updatedDate: new Date(),
      };
    });
    console.log(payload, 'assigning')
    mapTest.mutate(payload);
  };

  const handleEdit = (p, e) => { };

  const onCellEditCommit = (p) => {
    let row = questionData.questions.find((x) => x.id == p.id);
    if (row[p.field] == p.value) {
      return;
    }
  };
  const getRowClassName = (row) => {
    if (row.isLocked == true) {
      return "gray";
    }

    return null;
  };

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <PersonAdd />
        </CardIcon>
      </CardHeader>
      <CardBody>
        <CustomAccordion
          active={1}
          collapses={[
            {
              title: <h3>Assigned Student List</h3>,
              content: state?.testId ? <TestUserMappedGrid status={state?.status} testId={state?.testId} isRefreshState={refresh} /> : "",
            },
            {
              title: <h3>Available Student List</h3>,
              content: (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                      <Button
                        color="primary"
                        onClick={mapToTest}
                        disabled={mapTest.isLoading}
                      >
                        {mapTest.isLoading ? "Assigning .." : "Assign Student"}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {pageState.data && cols.length > 0 && pageState.isLoading == false ? (
                        <div style={{ height: 500, width: "100%" }}>
                          <div style={{ display: "flex", height: "90%" }}>
                            <div style={{ flexGrow: 1 }}>
                              <CustomDataGrid
                                columns={cols}
                                rows={pageState.data}
                                rowCount={pageState.total}
                                loading={pageState.isLoading}
                                rowsPerPageOptions={[10, 30, 50, 70, 100]}
                                page={pageState.page - 1}
                                pageSize={pageState.pageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                                onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                                onEditCellPropsChange={handleEdit}
                                onCellEditCommit={onCellEditCommit}
                                getRowClassName={getRowClassName}
                                onSelectionModelChange={handleRowSelection}
                                checkboxSelection
                              />
                            </div>
                          </div>
                        </div>
                      ) : <CircularProgress />}
                    </GridItem>
                  </GridContainer>
                  {mapTest.isSuccess && (
                    <CustomAlert
                      open={true}
                      severity="success"
                      alertMessage="Users Mapped to Test"
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      </CardBody>
    </Card>
  );
}

export default TestUserMappingGrid;
