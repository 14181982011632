
import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default function useGetAssignedTestsDetails() {
  const { user } = useContext(LoginContext);

  return useQuery(["getAssignedTestsDetails"], async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Tests/getAssignedTestsDetails/${user}`
    );
      
    return response.data["$values"];
  },
  {
    staleTime: 1000 * 60 * 5,
  });
}
