export const configUrl = {
    AssessmentServer: process.env.REACT_APP_Assessment
};
  
export const allowedExtensions =["jepg","jpg","png","gif","pptx","pdf","docx","xlsx"];

export const password ="Con@#323"

export const re = /(\.jpg|\.jpeg|\.gif|\.png|\.pdf|\.mp4|\.mov|\.wmv|\.avi|\.xlsx|\.xls|\.pptx|\.ppt|\.docx|\.doc)$/i;
export const re1 = /(\.zip)$/i;
