import axios from "axios";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useCreatePool() {
  const { user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async ({ testId, testCode, poolName }) => {
      let payload = {
        providerId: providerId,
        code: `${testCode}-Section`,
        sequenceId: 1,
        difficultyLevel: 0,
        status: 1,
        isLocked: false,
        lockedBy: "",
        lockedDate: new Date(),
        isActive: true,
        expiryDate: null,
        duration: null,
        pointsPerQuestion: 0,
        negativePointsPerQuestion: 0,
        totalScore: 0,
        masteryScore: 0,
        passingScore: 0,
        randomizeType: 0,
        questionCount: 5,
        questionsPerPage: 0,
        isMandatory: 0,
        displayType: 0,
        owner: user,
        createdBy: user,
        createdDate: new Date(),
        updatedBy: user,
        updatedDate: new Date(),
        isMandatoryToPass: false,
        poolLanguages: [
          {
            languageId: 1,
            text: poolName,
            image: "",
            objective: "",
            textHtml: "",
            passFeedback: "",
            failFeedback: "",
            masteryFeedback: "",
            isActive: true,
            status: 1,
            createdBy: user,
            createdDate: new Date(),
            updatedBy: user,
            updatedDate: new Date(),
          },
        ],
      };
      console.log(testId, "payloadd");
      let response = await axios.post(
        `${configUrl.AssessmentServer}/api/pools/${testId}`,
        payload
      );

      let poolId = response.data.poolId;
      if (poolId > 0) {
        await axios.post(`${configUrl.AssessmentServer}/api/TestPoolMappings`, {
          testId: testId,
          poolId: poolId,
          sequenceId: 1,
          status: 0,
          isLocked: false,
          lockedBy: "",
          lockedDate: new Date(),
          duration: null,
          points: 0,
          isMandatory: 0,
          createdBy: user,
          createdDate: new Date(),
          updatedBy: user,
          updatedDate: new Date(),
        });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pools");
      },
    }
  );
}
