import { Box } from '@mui/material';
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import Latex from 'react-latex-next';

{/* latex CSS */ }
export default function Solution({ question, contentType, updateQuestion, preview }) {
  console.log(Solution, "solution")
  return (
    <div>
      <Box p={2} style={{ color: 'black', padding: '36px' }} >
        <h3>Solution: </h3>
        <Box p={1} style={{ color: 'black' }}>
          {
            contentType === 1 ?
              /* Text Based */
              <p>{question.solutionText && question.solutionText}</p>
              :
              contentType === 3 ?
                /* latex Based */
                (question.solutionText == null ? '' :
                  <Latex>{question.solutionText}</Latex>)
                :
                contentType === 4 ?
                  /* for mathMl need to modify with html parser */
                  (question.solutionText == null ? '' :
                    <div style={{ color: 'black' }}>{ReactHtmlParser(question.solutionText)}</div>)
                  :
                  <p>{question.solutionText && question.solutionText}</p>
          }
        </Box>
        <Box p={1}>
          {question.solution && <img src={question.solution} alt="..." />}
        </Box>
      </Box>
    </div>
  )
}
