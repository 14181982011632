import React, { useContext } from "react";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Box from "@material-ui/core/Box";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Divider from "@material-ui/core/Divider";
import radioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Hint from "components/Hint/Hint";
import { TestContext } from "contexts/TestContext";
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import Latex from 'react-latex-next';
{/* latex CSS */ }

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  ...radioStyles,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function SingleChoiceQuestion({ question, contentType, updateQuestion, preview, key, qnoes }) {
  const { test } = useContext(TestContext);
  console.log("question123", key, qnoes, question);

  const [selectedEnabled, setSelectedEnabled] = React.useState(null);

  const handleCheck = (x) => {
    console.log(x?.trim()?.toLowerCase()?.replace(/[(),]/g, ''), 'xis')
    setSelectedEnabled(x);

    question.response = x;
    question.isCorrect = x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') == question?.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '');
  };

  React.useEffect(() => {
    //needs to be clear to avoid retaining of options
    setSelectedEnabled(null);
    setSelectedEnabled(question.response);
  }, [question]);

  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" p={2}>
        <Box p={2} sx={{ width: "100%" }}>
          <Box display="flex" p={1} style={{ padding: 0 }}>
            <Box p={1} style={{ padding: 0, color: 'black', fontSize: '20px' }}>
              <div style={{ float: 'left', marginRight: '5px', fontSize: '21px' }}> Q {qnoes}. </div>
            </Box>
            {/* BSS121 will check and use common color css  */}
            <Box p={1} flexGrow={1} style={{ padding: 0, color: 'black', fontSize: '20px' }}>
              {
                question && question.text && question.text.length > 0 ? (
                  contentType === 1 ?
                    <div >{question?.text}</div>
                    : contentType === 3 ?
                      <Latex >{question?.text}</Latex>
                      : contentType === 4 ?
                        <div>{question.text && ReactHtmlParser(question.text)}</div>
                        :
                        <div style={{ marginBottom: '10px' }}>{question.text}</div>
                ) : null
              }
              {/* <div style={{color:'black',fontSize:'20px'}}>{question.text && ReactHtmlParser(question.text)}</div> */}
              {/* <Box p={2} > */}
              <Box p={1} >
                {question.image && <img src={question.image} alt="..." />}
              </Box>
              {/* </Box> */}
            </Box>

            <Box p={1}>
              {test && test.showHint == 2 ? null : (
                <Hint message={question.hint} ContentType={contentType} questionId={question.id} />
              )}
            </Box>
          </Box>
          {
            contentType === 1 ?
              // have added below 1 line for horizontal choices
              <GridContainer alignItems="center" spacing={1}>
                {
                  question?.options["$values"]
                    .filter((x) => x != null)
                    .map((x, i) => (
                      // have added below 1 line for horizontal choices
                      <GridItem xs={11} sm={11} md={6}>
                        <GridContainer alignItems="center" key={i} spacing={1}>
                          <GridItem xs={10} sm={10} md={10}>
                            <div
                              className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                              }
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                    onChange={() => handleCheck(x)}
                                    value="a"
                                    name="radio button enabled"
                                    aria-label="A"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord
                                        className={classes.radioChecked}
                                      />
                                    }
                                    classes={{
                                      checked: classes.radio,
                                      root: classes.radioRoot,
                                    }}
                                    disabled={question.isSubmitted == true}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                }}
                                label={
                                  x
                                }
                              />
                            </div>
                          </GridItem>
                          {(question.isSubmitted == true || preview == true) && question.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                            <GridItem xs={1} sm={1} md={1}>
                              <CheckIcon style={{ color: "green" }} />
                            </GridItem>
                          ) : null}

                          {question.isSubmitted == true &&
                            question.isCorrect == false &&
                            selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                            <GridItem xs={1} sm={1} md={1}>
                              <CloseIcon style={{ color: "red" }} />
                            </GridItem>
                          ) : null}
                          <GridItem xs={11} sm={11} md={11}>
                            <Divider />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    ))
                }
              </GridContainer>
              :
              contentType === 3 ?
                // have added below 1 line for horizontal choices
                <GridContainer alignItems="center" spacing={1}>
                  {
                    question?.options["$values"]
                      .filter((x) => x != null)
                      .map((x, i) => (
                        // have added below 1 line for horizontal choices
                        <GridItem xs={11} sm={11} md={6}>
                          <GridContainer alignItems="center" key={i} spacing={1}>
                            <GridItem xs={10} sm={10} md={10}>
                              <div
                                className={
                                  classes.checkboxAndRadio +
                                  " " +
                                  classes.checkboxAndRadioHorizontal
                                }
                              >
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                      onChange={() => handleCheck(x)}
                                      value="a"
                                      name="radio button enabled"
                                      aria-label="A"
                                      icon={
                                        <FiberManualRecord
                                          className={classes.radioUnchecked}
                                        />
                                      }
                                      checkedIcon={
                                        <FiberManualRecord
                                          className={classes.radioChecked}
                                        />
                                      }
                                      classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                      }}
                                      disabled={question.isSubmitted == true}
                                    />
                                  }
                                  classes={{
                                    label: classes.label,
                                  }}
                                  label={
                                    <Latex style={{ color: 'black' }}>{x}</Latex>
                                  }
                                />
                              </div>
                            </GridItem>
                            {(question.isSubmitted == true || preview == true) && question.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                              <GridItem xs={1} sm={1} md={1}>
                                <CheckIcon style={{ color: "green" }} />
                              </GridItem>
                            ) : null}

                            {question.isSubmitted == true &&
                              question.isCorrect == false &&
                              selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                              <GridItem xs={1} sm={1} md={1}>
                                <CloseIcon style={{ color: "red" }} />
                              </GridItem>
                            ) : null}
                            <GridItem xs={11} sm={11} md={11}>
                              <Divider />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      ))
                  }
                </GridContainer>
                : contentType === 4 ?
                  // have added below 1 line for horizontal choices
                  <GridContainer alignItems="center" spacing={1}>
                    {
                      question?.options["$values"]
                        .filter((x) => x != null)
                        .map((x, i) => (
                          // have added below 1 line for horizontal choices
                          <GridItem xs={11} sm={11} md={6}>
                            <GridContainer alignItems="center" key={i} spacing={1}>
                              <GridItem xs={10} sm={10} md={10}>
                                <div
                                  className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                  }
                                >
                                  {/* {console.log(selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, ''), 'checkingchoices')} */}
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                        onChange={() => handleCheck(x)}
                                        value="a"
                                        name="radio button enabled"
                                        aria-label="A"
                                        icon={
                                          <FiberManualRecord
                                            className={classes.radioUnchecked}
                                          />
                                        }
                                        checkedIcon={
                                          <FiberManualRecord
                                            className={classes.radioChecked}
                                          />
                                        }
                                        classes={{
                                          checked: classes.radio,
                                          root: classes.radioRoot,
                                        }}
                                        disabled={question.isSubmitted == true}
                                      />
                                    }
                                    classes={{
                                      label: classes.label,
                                    }}
                                    label={
                                      ReactHtmlParser(x)
                                    }
                                  />
                                </div>
                              </GridItem>

                              {(question.isSubmitted == true || preview == true) && question.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                                <GridItem xs={1} sm={1} md={1}>
                                  <CheckIcon style={{ color: "green" }} />
                                </GridItem>
                              ) : null}

                              {question.isSubmitted == true &&
                                question.isCorrect == false &&
                                selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                                <GridItem xs={1} sm={1} md={1}>
                                  <CloseIcon style={{ color: "red" }} />
                                </GridItem>
                              ) : null}
                              <GridItem xs={11} sm={11} md={11}>
                                <Divider />
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        ))
                    }
                  </GridContainer>
                  :
                  null
          }
        </Box>
      </Box>
    </div>
  );
}
