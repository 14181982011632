import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";

export default function useCreateConfiguration() {
  const { config, user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (configurationPayload) => {
      
      configurationPayload.valueString=configurationPayload.valueString.trim()
      configurationPayload.createdBy = user ?? "system";
      configurationPayload.updatedBy = user ?? "system";
      configurationPayload.providerId = providerId;

      await axios.post(`${config.api}/api/DemoghraphicInformation`, configurationPayload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetAllEduBoard");
      },
    }
  );
}
