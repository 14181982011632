import axios from "axios";
import {configUrl} from "config.js";

import { useMutation, useQueryClient } from "react-query";
export default function useDeleteTest() {
  let queryClient = useQueryClient();
  return useMutation(
    async (testId) => {
      await axios.delete(
        `${configUrl.AssessmentServer}/api/Tests/${testId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tests");
      },
    }
  );
}
