import axios from "axios";
import { configUrl } from "config.js";

const useGetQuestionByIdService = async (id) => {
    let response = await axios.get(`${configUrl.AssessmentServer}/api/questions/${id}`)

    return response.data
}

export default {
    useGetQuestionByIdService: useGetQuestionByIdService,
}
