import axios from "axios";
import { configUrl } from "config.js";

const getTestsByTopicIds = async (courseId, topicIds, objective, targetAudience) => {

    const newSelectedTopics = topicIds?.filter(element => element != null);
    console.log(newSelectedTopics, 'newSelectedTopics')
    const testsForTopicObj = new Object();
    testsForTopicObj.courseId = courseId;
    testsForTopicObj.topicIds = JSON.stringify(newSelectedTopics);
    testsForTopicObj.objective = JSON.stringify(objective);
    testsForTopicObj.targetAudience = JSON.stringify(targetAudience);
    console.log(testsForTopicObj, 'testsForTopicObj');

    let response = await axios.post(
        `${configUrl.AssessmentServer}/api/Courses/getTestsForTopics`, testsForTopicObj
    );

    let result = response.data["$values"];

    return result.map((x) => {
        x.id = x.testId;
        return x;
    });

};
export default {
    getTestsByTopicIds: getTestsByTopicIds,
}