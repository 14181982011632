import axios from "axios";
import { configUrl } from "config.js";

const getPreviewTests = async (testId) => {
  return axios.get(`${configUrl.AssessmentServer}/api/Tests/testDetails/${testId}/true`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data, "prevError");
      return {
        messege: error.response.data,
        status: error.response.status
      }
    });

};
export default {
  getPreviewTests: getPreviewTests,
}
