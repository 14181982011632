import React from "react";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
// core components
import Box from "@material-ui/core/Box";
// @material-ui/icons
// core components
import radioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import dashboardStyle from "assets/newAssets/dashboardStyle";
import loginPageStyle from "assets/newAssets/loginPageStyle";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import Hint from "components/Hint/Hint";
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import Latex from 'react-latex-next';
{/* latex CSS */ }

var styles = {
    ...dashboardStyle,
    ...loginPageStyle,
    ...radioStyles,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

const useStyles = makeStyles(styles);

export default function NumericalChoiceQuestion({ qNoes, question, contentType, updateQuestion, preview }) {
    console.log("NumericalChoiceQuestion", question);


    const [selectedEnabled, setSelectedEnabled] = React.useState(null);

    const handleCheck = (x) => {
        console.log(x, 'NumericalhandledX123')
        setSelectedEnabled(x.target.value);
        question.questionChoices.choiceIdAnswers = x.target.value;
    };

    React.useEffect(() => {
        //needs to be clear to avoid retaining of options
        setSelectedEnabled(null);
        setSelectedEnabled(question.questionChoices.choiceIdAnswers);
    }, [question]);

    const classes = useStyles();

    return (
        <div style={{ width: "100%" }}>
            <Box display="flex" p={2}>
                <Box p={2} sx={{ width: "100%" }}>
                    <Box display="flex" p={1} style={{ padding: 0 }}>
                        <Box p={1} flexGrow={1} style={{ padding: 0, float: 'left', color: 'black', fontSize: '22px' }}>
                            <div style={{ float: 'left', marginRight: '5px', marginTop: '7px' }}> Q{qNoes}. </div>
                            {
                                question && question.text && question.text.length > 0 ?
                                    (contentType === 1 ?
                                        <div >{question?.text}</div>
                                        : contentType === 3 && question?.text != null ?
                                            <Latex >{question?.text}</Latex>
                                            : contentType === 4 && question?.text != null ?
                                                <div>{question.text && ReactHtmlParser(question.text)}</div>
                                                :
                                                <div style={{ marginBottom: '10px' }}>{question.text}</div>
                                    ) : null
                            }
                            {question.image && question?.image != null &&
                                <Box p={2}>
                                    {question.image && <img src={question.image} alt="..." />}
                                </Box>
                            }
                        </Box>
                        <Box p={1}>
                            <Hint message={question.hint} ContentType={contentType} questionId={question.id} />
                        </Box>
                    </Box>
                    <Box>
                        {
                            <GridContainer alignItems="center" spacing={1}>
                                <CustomInput
                                    labelText={<span> Type your Answer</span>}
                                    id="choiceId01"
                                    formControlProps={{
                                        fullWidth: false,
                                    }}
                                    inputProps={{
                                        name: 'choiceId01',
                                        onChange: (x) => handleCheck(x),
                                        type: "number",
                                        value:
                                            question.questionChoices.choiceIdAnswers
                                    }}
                                />
                            </GridContainer>
                        }
                    </Box>
                </Box>
            </Box>
        </div>
    );
}
