
import axios from "axios";
import { configUrl } from "config";
import { useQuery } from "react-query";


export default async function useGetTopFiveStudentAnalytics(lOid) {
  
  return axios.get(`${configUrl.AssessmentServer}/api/MentorAnalytics/getTopFiveStudentAnalytics/${lOid}`)
    .then((response) => {
      console.log("resp for top5",response)
      return response.data;
      
    })
    // .catch((error) => {
    //   console.log(error);
    //   return error;
    // });
}