import axios from "axios";
import questionType from "utils/questionType";
import { useQuery, useMutation, QueryCache } from "react-query";
import {configUrl} from "config.js";

  const getTestSeriesData = async (testId) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Courses/${testId}`
      );
    let data = response.data;
  return data;
  };
  export default{
    getTestSeriesData:getTestSeriesData,
}