
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import NumericalChoiceQuestion from "./NumericalChoiceQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";

const Question = ({ question, updateQuestion, preview, key, qnoes }) => {
  console.log(key, qnoes, question.type, 'questiontype123')
  const getQuestionComponent = () => {
    switch (question.type) {
      case "SCQ":
        return (
          <SingleChoiceQuestion
            key={key}
            qnoes={qnoes}
            question={question}
            contentType={question?.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
          />
        );
      case "TF":
        return (
          <SingleChoiceQuestion
            key={key}
            qnoes={qnoes}
            question={question}
            contentType={question?.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
          />
        );
      case "MCQ":
        return (
          <MultipleChoiceQuestion
            key={key}
            qnoes={qnoes}
            question={question}
            contentType={question?.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
          />
        );
      case "NUMERICAL":
        return (
          <NumericalChoiceQuestion
            key={key}
            qnoes={qnoes}
            question={question}
            contentType={question?.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
          />
        );
      default:
        return null;
    }
  };

  return <>{getQuestionComponent()}</>;
};

export default Question;
