import React from "react";

import { CircularProgress } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Tooltip } from "@mui/material";
import Button from "components/CustomButtons/Button.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useDeleteCourseUserMapping from "hooks/courseTestMappingHooks/useDeleteCourseUserMapping";
import useGetCourseUserMappingByCourseId from "hooks/courseTestMappingHooks/useGetCourseUserMappingByCourseId";

function CourseUserMappedGrid(props) {
  console.log("props", props);

  const { data: users, isLoading } = useGetCourseUserMappingByCourseId(
    props.courseId
  );

  const deleteUser = useDeleteCourseUserMapping(props.courseId);

  console.log("users", users);

  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    setColumns([
      {
        field: "userId",
        headerName: "Id",
        editable: false,
        width: 100,
        hide: true,
      },
      {
        field: "fullName",
        headerName: "Full Name",
        editable: false,
        width: 200,
      },
      {
        field: "userName",
        headerName: "User Name",
        editable: false,
        width: 200,
      },
      {
        field: "mobile",
        headerName: "Mobile No",
        editable: false,
        width: 200,
      },
      {
        field: "actions",
        headerName: "Action",
        editable: false,
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          return (
            <Tooltip title="Delete">
              <Button
                size="sm"
                justIcon
                round
                color="reddit"
                disabled={props.status >= 3 ? true : false}
                onClick={(e) => {
                  e.preventDefault();
                  deleteUser.mutate(cellValues.row.userId);
                }}
              >
                <DeleteForeverIcon />
              </Button>
            </Tooltip>
          );
        },
      },
    ]);
  }, []);

  return (
    <GridContainer>
      {isLoading == false && users ? (
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ height: 400, width: "100%" }}>
            <div style={{ display: "flex", height: "90%" }}>
              <div style={{ flexGrow: 1 }}>
                <CustomDataGrid
                  columns={columns}
                  rows={users}
                  autoPageSize={true}
                />
              </div>
            </div>
          </div>
        </GridItem>
      ) : (
        <CircularProgress />
      )}
    </GridContainer>
  );
}

export default CourseUserMappedGrid;
