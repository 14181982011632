import { makeStyles } from "@material-ui/core";
import Today from "@material-ui/icons/Today";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";

import Switch from "@material-ui/core/Switch";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import axios from "axios";

import { FormControlLabel } from "@material-ui/core";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";

const useStyles = makeStyles(styles);

export default function QuickTestPoolForm(props) {
    const { user, providerId } = React.useContext(LoginContext);
    console.log(props, 'propsofstate')
    const [model, setModel] = React.useState(
        props.state ??

        {
            providerId: providerId,
            code: "",
            questionCount: 0,
            pointsPerQuestion: 0,
            negativePointsPerQuestion: 0,
            passingScore: 0,
            isMandatoryToPass: false,
            UpdatedBy: user,
            createdBy: user,
            poolLanguages: [
                {
                    text: "",
                    createdBy: user,
                    createdDate: new Date(),
                    updatedBy: user,
                    updatedDate: new Date(),
                },
            ],
        },
    );
    const [flag, setFlag] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(null)

    const classes = useStyles();

    const handleChange = (e) => {
        console.log(e, "e");
        console.log(e.target.name, "e.target.name");
        console.log(e.target.value, "e.target.value");
        if ((e.target.name == 'questionCount') || (e.target.name == 'maxQuestionForScore') || (e.target.name == 'pointsPerQuestion') || (e.target.name == 'passingScore') || (e.target.name == 'masteryScore') || (e.target.name == 'questionsPerTopic')) {

            if ((e.target.name == 'questionCount') || (e.target.name == 'maxQuestionForScore') || (e.target.name == 'pointsPerQuestion') || (e.target.name == 'passingScore') || (e.target.name == 'masteryScore') || (e.target.name == 'questionsPerTopic') && e.target.value == '') {
                setModel({ ...model, [e.target.name]: 0 });
            }
            if (e.target.value >= 0) {
                setModel({ ...model, [e.target.name]: e.target.value });
            }
            else {
                setModel({ ...model, [e.target.name]: 0 });
                console.log("negative values will not update")
                return
            }
        } else {
            setModel({ ...model, [e.target.name]: e.target.value });
        }
    };
    const handleLanguageChange = (e) => {
        console.log(e.target.name, e.target.value);
        model.poolLanguages[0][e.target.name] = e.target.value;
        setModel({ ...model });
    };
    const handleNegativePointsChange = (e) => {
        if (e.target.value <= 0) {
            setModel({ ...model, [e.target.name]: e.target.value });
        }
        else {
            setModel({ ...model, [e.target.name]: 0 });
            return
        }
    };

    const updatePool = async (model) => {
        let response = await axios.put(
            `${configUrl.AssessmentServer}/api/SimplifiedTest/updatePool/${model.PoolId}`,
            model
        );

        console.log(response.data, 'poolupdated resp data');

        props.hidePoolEditModal();

        props.refetch()
    };

    const createPool = async (model, testId) => {
        try {
            let response = await axios.put(
                `${configUrl.AssessmentServer}/api/SimplifiedTest/createPool/${testId}`,
                model
            );
            console.log(response, 'poolupdated resp data');
            if (response.status == 200 || response.status == 201) {
                setShowAlert(
                    <CustomAlert
                        open={true}
                        openFlag={() => setFlag(flag ? false : true)}
                        severity="success"
                        alertMessage="Section created successfully"
                    />
                )
                setTimeout(() => {
                    props.hidePoolEditModal();
                }, 2000);
                props.refetch()
            } else {
                console.log('error occured')
            }

        } catch (error) {
            console.error(error, 'poolcreateion error')
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage={error?.response?.data}
                />
            )
        }


    };

    const handleSubmit = () => {
        let obj = {}
        obj.ProviderId = model.providerId,
            obj.PoolId = model.poolId,
            obj.Code = model.code,
            obj.PoolName = model?.poolLanguages[0]?.text,
            obj.QuestionCount = model.questionCount,
            obj.PointsPerQuestion = model.pointsPerQuestion,
            obj.NegativePointsPerQuestion = model.negativePointsPerQuestion,
            obj.PassingScore = model.passingScore,
            obj.IsMandatoryToPass = model.isMandatoryToPass,
            obj.CreatedBy = model.createdBy,
            obj.UpdatedBy = model.updatedBy
        console.log(model?.poolLanguages[0]?.text?.length, 'checking text length')
        if (model?.poolLanguages[0]?.text?.length <= 0) {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage="Section name is Mandatory"
                />
            )
            return
        }
        if (model.isMandatoryToPass == true && model.passingScore <= 0) {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage="Passing score required while 'Is Mandatory to Pass' is on "
                />
            )
            return
        }
        if (model.questionCount <= 0) {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage="Number of Questions Allowed is mandatory"
                />
            )
            return
        }
        console.log(model, 'model in handleSubmit')
        if (props.state == null) {

            console.log(props.testId, 'getting testId')

            createPool(obj, props.testId);
        } else {
            updatePool(obj);
        }
    }

    console.log(model, 'poolLang')
    return (
        <div>
            <Card>
                <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <Today />
                    </CardIcon>
                </CardHeader>
                <CardBody>
                    <GridContainer justifyContent="center">
                        {/* <GridItem xs={12} sm={12} md={5}>
                            <CustomInput
                                labelText={<span>Code *</span>}
                                id="text"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    name: "code",
                                    onChange: handleChange,
                                    maxLength: 20,
                                    value: model.code,
                                }}
                            />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={5}>
                            <CustomInput
                                labelText={<span>Name *</span>}
                                id="text"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    name: "text",
                                    onChange: handleLanguageChange,
                                    value: model?.poolLanguages[0]?.text,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                            <CustomInput
                                labelText={<span>Questions to Allowed *</span>}
                                id="questionCount"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    type: "number",
                                    name: "questionCount",
                                    onChange: handleChange,
                                    value: model.questionCount,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                            <CustomInput
                                labelText={<span>Passing Score</span>}
                                id="passingScore"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    type: "number",
                                    name: "passingScore",
                                    onChange: handleChange,
                                    value: model.passingScore,
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={5}>
                            <CustomInput
                                labelText={<span>Points Per Question</span>}
                                id="pointsPerQuestion"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    type: "number",
                                    name: "pointsPerQuestion",
                                    onChange: handleChange,
                                    value: model.pointsPerQuestion,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                            <CustomInput
                                labelText={<span>Negative Points Per Question</span>}
                                id="negativePointsPerQuestion"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    type: "number",
                                    name: "negativePointsPerQuestion",
                                    onChange: handleNegativePointsChange,
                                    value: model.negativePointsPerQuestion,
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={5}>
                            <FormControlLabel
                                style={{ marginTop: '7%' }}
                                control={
                                    <Switch
                                        value={model.isMandatoryToPass}
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: "isMandatoryToPass",
                                                    value: e.target.checked,
                                                },
                                            });
                                        }}
                                        checked={model.isMandatoryToPass}
                                    />
                                }
                                label={<small>Is Mandatory to Pass</small>}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={7}></GridItem>

                        <GridItem xs={12} sm={12} md={11} style={{ textAlign: "right" }}>
                            <Button color="primary" style={{ textTransform: 'none' }}
                                onClick={handleSubmit}>
                                {"Save"}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
            {showAlert}
        </div>
    );
}
