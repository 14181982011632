import axios from "axios";
import { configUrl } from "config";

const createTopicService = async (name, parentId, categoryId, user, providerId) => {
    // const { user, providerId } = useContext(LoginContext)
    console.log('debbuger is here')
    let parentTopicId = parentId.includes("T")
        ? parentId.replace("T", "")
        : null;

    return axios.post(`${configUrl.AssessmentServer}/api/Topics`,
        {
            parentTopicId: parentTopicId,
            categoryId: categoryId,
            providerId: providerId,
            isActive: true,
            owner: user,
            createdBy: user,
            createdDate: new Date(),
            updatedBy: user,
            updatedDate: new Date(),
            topicLanguages: [
                {
                    languageId: 1,
                    text: name,
                    isActive: true,
                    status: 1,
                    createdBy: user,
                    createdDate: new Date(),
                    updatedBy: user,
                    updatedDate: new Date(),
                },
            ],
        })
        .then((response) => {
            console.log(response, 'this is resp')
            return response;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status
            }
        });
};
export default {
    createTopicService: createTopicService,
}