import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import {configUrl} from "config.js";

export default function useMapToTest(testId){
    let queryClient = useQueryClient()
    return useMutation(async(payload) => {
        await axios.post(
            `${configUrl.AssessmentServer}/api/TestUserMappings/bulkinsertTestUserMappings`,
            payload
          );
    },{
        onSuccess: () =>{
            queryClient.invalidateQueries(`availableUserByTestIds${testId}`)
            queryClient.invalidateQueries(`userByTestIds${testId}`)
        }
    })
}