import axios from "axios";
import { useQuery } from "react-query";
import {configUrl} from "config.js";

export default async function useGetAttemptDetails(userId, testId, attemptNumber) {
  
  return axios.get(`${configUrl.AssessmentServer}/api/Tests/getAttemptDetails/${userId}/${testId}/${attemptNumber}`)
    .then((response) => {
   
      return response.data;
      
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}