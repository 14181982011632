/*eslint-disable*/
// react plugin for creating charts

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridItem from "components/Grid/GridItem.js";

import BarChartIcon from '@material-ui/icons/BarChart';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { Bar } from 'react-chartjs-2';
Chart.register(CategoryScale);


function getRandomColor() {
  var letters = '0123456789ABCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default function SimpleBarChart(props) {
  var delays2 = 80,
    durations2 = 500;
  let data = props.chartDetails;

  let colors = [];
  let backgroundColor = [
    'rgb(222, 94, 224)',
    'rgba(75, 192, 192)',
    'rgb(255, 126, 59)',
    'rgba(255, 205, 86)',
    'rgba(54, 162, 235)',
    'rgba(153, 102, 255)',
    'rgba(201, 203, 207)'
  ];


  // if(data !=undefined)
  // {

  //     data.lables.forEach(element => {
  //         let color =getRandomColor()
  //         // if(!colours.find(color))  // TODO: Check if colour is exist
  //             colours.push(color)
  //     });
  // }



  const simpleBarChart = {
    data: {
      labels: data == undefined ? [] : data.lables,
      datasets: [
        {
          data: data == undefined ? [] : data.values,
          backgroundColor: backgroundColor,
          borderWidth: 1
        }
      ],
      //  series: [data == undefined?[]:data.values],
    },
    options:
    {
      scales: {
        y: {
          ticks: {
            precision: 0
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      },
      title: {
        display: false,
        text: props.title,
        fontSize: 25
      },
    },
    //  {
    //   seriesBarDistance: 10,
    //   axisX: {
    //     showGrid: false,
    //   },
    // },
    responsiveOptions: [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            },
          },
        },
      ],
    ],
    animation: {
      draw: function (data) {
        if (data.type === "bar") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
      },
    },
  };

  console.log(simpleBarChart);

  const useStyles = makeStyles(styles);


  const classes = useStyles();
  return (

    <GridItem xs={12} sm={12} md={6}>
      <Card>
        <CardHeader color={props.color} icon>
          <CardIcon color={props.color}>
            <BarChartIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            {props.title}<small></small>
          </h4>
        </CardHeader>
        <CardBody>
          {/* <ChartistGraph
                data={simpleBarChart.data}
                type="Bar"
                options={simpleBarChart.options}
                listener={simpleBarChart.animation}
    /> */}

          <Bar
            data={simpleBarChart.data}
            options={simpleBarChart.options}
          />
        </CardBody>
      </Card>
    </GridItem>

  );
}