import { InputLabel, Snackbar, TextField } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { configUrl } from "config.js";
import React, { useState } from "react";
import generateQuestionService from "services/QuestionService/generateQuestionService";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function CourseScheduleComponent(props) {
  const { state, afterFinish, scheduleDates } = props;

  console.log(state)

  const [assignDateValue, setAssignDateValue] = useState(new Date());
  const [scheduleStartDateValue, setScheduleDateValue] = useState(null);
  const [scheduleEndDateValue, setScheduleEndDateValue] = useState(null);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");

  React.useEffect(() => {
    if (scheduleDates && scheduleDates.scheduleStartDateTime !== null && scheduleDates.scheduleEndDateTime !== null && scheduleDates.assignDateTime !== null) {
      // // existing scheduledStart date assigning
      // let startDateTime = new Date(scheduleDates.scheduleStartDateTime);
      // let assignedDateTime = new Date(startDateTime);
      // setScheduleDateValue(assignedDateTime.toISOString().slice(0, 16));
      setScheduleDateValue(scheduleDates.scheduleStartDateTime)

      // // existing scheduledEnd date assigning
      // let exisingStartDate = new Date(scheduleDates.scheduleEndDateTime);
      // let endDateTime = new Date(exisingStartDate);
      // setScheduleEndDateValue(endDateTime.toISOString().slice(0, 16));
      setScheduleEndDateValue(scheduleDates.scheduleEndDateTime)

      // // existing ScheduledAssigned date assigning
      // let exisingAssignDate = new Date(scheduleDates.assignDateTime);
      // let assignDateTime = new Date(exisingAssignDate);
      // console.log(assignDateTime.toISOString(), 'assignExistingTime')
      // setAssignDateValue(assignDateTime.toISOString().slice(0, 10));
      setAssignDateValue(scheduleDates.assignDateTime)
    }
    if (scheduleDates.assignDateTime != null && scheduleDates.scheduleEndDateTime == null && scheduleDates.scheduleStartDateTime == null) {
      setValue("1")
    }
  }, [])
  const handleChange = (e) => {
    // if (e.target.name == "scheduleStartDateValue") {
    //   var scheduleStartDateTime = e.target.value;
    //   setScheduleDateValue(scheduleStartDateTime);
    //   // const givenDate = new Date(scheduleStartDateTime);
    //   // givenDate.setDate(givenDate.getHours() - 48);
    //   // console.log(givenDate, "givenDate");
    //   // const assignDateTime = givenDate.toISOString().slice(0, 16);
    //   // console.log(assignDateTime, "assignDateTime");
    //   // setAssignDateValue(assignDateTime);
    // }
    // if (e.target.name == "scheduleEndDateValue") {
    //   setScheduleEndDateValue(e.target.value);
    // }
    if (e.target.name == "assignDateValue") {
      setAssignDateValue(e.target.value);
    }
  };
  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setScheduleDateValue(newStartDate);

    // Automatically set the assigned date to be 48 hours before the start date
    const startDateTime = new Date(newStartDate);
    const assignedDateTime = new Date(startDateTime.getTime() - 48 * 60 * 60 * 1000);
    setAssignDateValue(assignedDateTime.toISOString().slice(0, 10)); // Format as "YYYY-MM-DDTHH:mm"
  };
  const handleEndDateChange = (event) => {
    setScheduleEndDateValue(event.target.value);
  };
  const showSnackBar = (message, sev) => {
    setSeverity(sev ?? "success");
    setAlertMessage(message);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(null);
    setOpen(false);
  };

  const updateCourseSchedule = async () => {

    let response;
    const startDateTime = new Date(scheduleStartDateValue);
    const endDateTime = new Date(scheduleEndDateValue);
    const assignedDateTime = new Date(assignDateValue);
    const currentDateTime = new Date();
    if (startDateTime - assignedDateTime < 48 * 60 * 60 * 1000) {
      showSnackBar('Assigned date must be 48 hours before the start date.', "error");
      return;
    }
    if (startDateTime - currentDateTime < 46 * 60 * 60 * 1000) {
      showSnackBar('Start date must be at least 48 hours in the future.', "error");
      return;
    }
    if (endDateTime - startDateTime < 15 * 60 * 1000) {
      showSnackBar("End date must be at least 15 minutes after the start date.", "error")
      return;
    } else {

      setIsScheduling(true);
      response = await axios.put(`${configUrl.AssessmentServer}/api/Courses/updateSchedule/${state.courseId}`, {
        scheduleStartDateTime: scheduleStartDateValue,
        scheduleEndDateTime: scheduleEndDateValue,
        assignDateTime: assignDateValue
      })
      setIsScheduling(false);
    }
    if (response.status == 200 || response.status == 204) {
      showSnackBar("Test Series scheduled successfully")
    }

    setTimeout(() => {
      afterFinish()
    }, 2000);
  }

  const assignCoruse = async () => {
    setIsScheduling(true);
    const startDateTime = null //new Date(scheduleStartDateValue);
    const endDateTime = null //new Date(scheduleEndDateValue);
    const assignedDateTime = new Date();
    // let response = await axios.put(`${configUrl.AssessmentServer}/api/Tests/assignCourseTests/${state.courseId}`)
    let response = await generateQuestionService.generateQuestion(state?.courseId, startDateTime, endDateTime, assignedDateTime);

    setIsScheduling(false);
    if (response.status == 200 || response.status == 204) {
      showSnackBar("Test Series assigned successfully")
      setTimeout(() => {
        afterFinish()
      }, 2000);
    } else if (response?.status == 400 || response?.status == 404) {
      console.log(response?.messege, 'inerror')
      showSnackBar("Error occured", 'error');
    }

  }
  const [radioValue, setValue] = React.useState("2");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <GridContainer alignItems="flex-end">
        <GridItem sm={12} md={12} lg={12}>
          <fieldset style={{ border: 'none', boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', }}>
            <legend style={{ marginBottom: '0px !important' }}>
              <InputLabel shrink><h4><b>Schedule</b></h4></InputLabel>
            </legend>
            <GridContainer>
              <GridItem sm={12} md={6} lg={4}>
                <FormControl>
                  <RadioGroup aria-label="schedule" name="schedule1" value={radioValue} onChange={handleRadioChange}>
                    <FormControlLabel value="1" control={<Radio />} label="Now" />
                    <FormControlLabel value="2" control={<Radio />} label="Later" />
                  </RadioGroup>
                </FormControl>
              </GridItem>
              <GridItem sm={12} md={6} lg={8}>
                <TextField
                  type="date"
                  name="assignDateValue"
                  value={assignDateValue}
                  onChange={handleChange}
                  label="Assign Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ borderColor: "primary", marginTop: 28, marginBottom: 11, marginLeft: 15 }}
                  disabled={radioValue == '1' ? true : false}
                />

              </GridItem>
            </GridContainer>

          </fieldset>
        </GridItem>
      </GridContainer>
      <GridContainer alignItems="flex-end">
        <GridItem sm={12} md={12} lg={12}>
          <fieldset style={{ border: 'none', boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', }}>
            <legend>
              <InputLabel shrink><h4><b>Test Series Window</b></h4></InputLabel>
            </legend>
            <GridContainer>
              <GridItem sm={12} md={6} lg={6}>
                <TextField
                  type="datetime-local"
                  name="scheduleStartDateValue"
                  value={scheduleStartDateValue}
                  onChange={handleStartDateChange}
                  label="Start DateTime"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ borderColor: "primary", marginBottom: 11 }}
                // disabled={radioValue == '1' ? true : false}
                />
              </GridItem>
              <GridItem sm={12} md={6} lg={6}>
                <TextField
                  type="datetime-local"
                  name="scheduleEndDateValue"
                  value={scheduleEndDateValue}
                  onChange={handleEndDateChange}
                  label="End DateTime"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ borderColor: "primary", marginBottom: 11, float: 'inline-end' }}
                // disabled={radioValue == '1' ? true : false}

                />
              </GridItem>
            </GridContainer>

          </fieldset>
        </GridItem>

        <GridItem sm={12} md={12} lg={12}>
          <Button color="primary" disabled={isScheduling == true || state.scheduleStatus == 5} style={{ marginTop: '15px', float: 'right' }} onClick={radioValue == '1' ? assignCoruse : updateCourseSchedule}>
            {isScheduling == true ? 'Saving...' : 'Save'}
          </Button>
        </GridItem>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={severity}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </GridContainer>
      {/* <GridContainer alignItems="flex-end">
        <GridItem sm={12} md={6} lg={6}>
          <TextField
            type="datetime-local"
            name="scheduleStartDateValue"
            value={scheduleStartDateValue}
            onChange={handleChange}
            label="Start DateTime"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ borderColor: "primary" }}
          />
        </GridItem>
        <GridItem sm={12} md={6} lg={6}>
          <TextField
            type="datetime-local"
            name="scheduleEndDateValue"
            value={scheduleEndDateValue}
            onChange={handleChange}
            label="End DateTime"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ borderColor: "primary" }}
          />
        </GridItem>
        <GridItem sm={12} md={6} lg={6}>
          <TextField
            type="datetime-local"
            name="assignDateValue"
            value={assignDateValue}
            onChange={handleChange}
            label="Assign Date Time"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ borderColor: "primary" }}
          />
        </GridItem>
        <GridItem sm={12} md={12} lg={12}>
          <Button color="primary" onClick={updateCourseSchedule}>
            {isScheduling == true ? 'Scheduling Test Series...' : 'Schedule Test Series'}
          </Button>
          <Button color="primary" disabled={isAssigning == true} style={{ marginLeft: '10px' }} onClick={assignCoruse}>
            {isAssigning == true ? 'Assigning Test Series...' : 'Assign Test Series'}
          </Button>
        </GridItem>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={severity}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </GridContainer> */}
    </>
  );
}

export default CourseScheduleComponent;
