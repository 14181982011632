import axios from "axios";
import { configUrl } from "config.js";

import { useMutation, useQueryClient } from "react-query";
export default function usePublishCourse() {
    let queryClient = useQueryClient();
    return useMutation(
        async (courseId) => {
            await axios.put(
                `${configUrl.AssessmentServer}/api/Courses/publishCourse/${courseId}`
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("tests");
            },
        }
    );
}
