import axios from "axios";
import {configUrl} from "config.js";

import { useMutation, useQueryClient } from "react-query";
export default function useUserDataCleanup() {
  let queryClient = useQueryClient();
  return useMutation(
    async (courseId) => {
      await axios.delete(
        `${configUrl.AssessmentServer}/api/Courses/userDataCleanUp/${courseId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tests");
      },
    }
  );
}
