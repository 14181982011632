import axios from "axios";
import { configUrl } from "config.js";
import CryptoJS from 'crypto-js';

const getStudentDetailByEmail = async (email) => {
    console.log(email, 'topicId')
    const key = 'pj23vs7nycq18uew';
    let secretKey = CryptoJS.enc.Utf8.parse(key);
    const decryptPassword = (encryptedPassword, secretKey) => {

        let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

        console.log(decryptedText, 'decryptedone');
        return decryptedText;
        // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
        // return bytes.toString(CryptoJS.enc.Utf8);
    }
    return axios.get(`${configUrl.AssessmentServer}/api/User/getStudentDetailByEmail/${email}`)
        .then((response) => {
            response.data.studetnInfo.passCode = decryptPassword(response.data.studetnInfo.passCode, secretKey)
            return response;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status
            }
        });

};
export default {
    getStudentDetailByEmail: getStudentDetailByEmail,
}
