import axios from "axios";
import { configUrl } from "config.js";

import { useMutation, useQueryClient } from "react-query";
export default function usePublishQuestion() {
    let queryClient = useQueryClient();
    return useMutation(
        async (Id) => {
            await axios.put(
                `${configUrl.AssessmentServer}/api/Questions/publishQuestion/${Id}`
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("question");
            },
        }
    );
}
