import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery } from "react-query";

export default function useGetCategories(isContentProvider) {
  const { config } = useContext(LoginContext);
  console.log(isContentProvider, "isContentProvider Before");
  if (isContentProvider == undefined) {
    isContentProvider = false;
  }
  console.log(isContentProvider, "isContentProvider After");
  return useQuery("categories", async () => {
    let response = await axios.get(`${config.api}/api/Categories/getCategories/${isContentProvider}`);

    let categories = response.data["$values"].map((x) => {
      //console.log("x...",x)
      return {
        id: x.categoryId + "",
        name: x.categoryLanguages["$values"][0].text,
        parent: x.parentCategoryId,
        isCategory: true,
      };
    });

    let topiResponse = await axios.get(`${config.api}/api/Topics/getTopics/${isContentProvider}`);

    let topics = topiResponse.data["$values"].map((x) => {
      //console.log("Topic..",x)
      return {
        id: "T" + x.topicId,
        name: x.topicLanguages["$values"][0].text,
        categoryId: x.categoryId,
        parent: x.parentTopicId ? "T" + x.parentTopicId : x.categoryId + "",
        isCategory: false,
        topicId: x.topicId,
      };
    });

    let questionCounts = await axios.get(
      `${config.api}/api/Topics/questionCount`
    );

    if (
      questionCounts &&
      questionCounts.data &&
      questionCounts.data["$values"]
    ) {
      questionCounts.data["$values"].forEach((x) => {
        let t = topics.find((y) => y.topicId == x.id);
        t.questionCount = x.count["$values"][0];
      });
    }


    categories.push(...topics);

    return categories;
  }
    // ,
    //   {
    //     staleTime: 1000 * 60 * 5,
    //   }
  );
}
